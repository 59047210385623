import {
    SESSION_GROUP_SET,
    SESSION_GROUP_END,
    SESSION_GROUP_CONNECTION_AUTH,
    SESSION_GROUP_SET_SESSION,
    SESSION_GROUP_GET_SESSION,
    AUTH_LOGOUT,
} from 'actions'

const replaceSession = (sessionGroup, newSession) => {
    sessionGroup.sessions = sessionGroup.sessions.filter(
        s => s.id !== newSession.id
    )
    sessionGroup.sessions.push(newSession)

    return sessionGroup
}

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_GROUP_SET:
            return {
                ...state,
                ...action.payload,
            }
        case SESSION_GROUP_CONNECTION_AUTH:
            return {
                ...state,
                sessionGroupAuthToken: action.payload.token,
            }
        case SESSION_GROUP_SET_SESSION:
            return {
                ...replaceSession(state, action.meta),
            }
        case SESSION_GROUP_GET_SESSION:
            return {
                ...replaceSession(state, action.payload),
            }
        case SESSION_GROUP_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
