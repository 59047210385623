import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { closePopup } from 'actions/popup'
import StudentLoginErrorNudge from './StudentLoginErrorNudge'

export default withRouter(
    connect(
        null,
        { closePopup }
    )(StudentLoginErrorNudge)
)
