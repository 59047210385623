import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

const TutorRoute = ({ component: Component, ...props }) => {
    const tutor = useSelector(state => state.tutor)
    let url
    if (!tutor) {
        url = `/tutor/login?redirect=${props.location.pathname}`
    } else if (tutor.forcePasswordChange) {
        url = `/tutor/onboarding?redirect=${props.location.pathname}`
    }

    if (url) {
        return <Redirect to={url} />
    }

    return <Route {...props} render={props => <Component {...props} />} />
}

export default withRouter(TutorRoute)
