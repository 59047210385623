import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import onClickOutside from 'react-onclickoutside'

import { VERSION } from 'config'
import { openAlert } from 'actions/popup'
import { checkForUpdates } from 'actions/app'

import styles from './index.css'

class AdminMenu extends Component {
    constructor() {
        super()
        this.toggleMenu = this.toggleMenu.bind(this)
        this.onNav = this.onNav.bind(this)
        this.onShowVersionClicked = this.onShowVersionClicked.bind(this)
        this.onWindowClick = this.onWindowClick.bind(this)
        this.onMenuClick = this.onMenuClick.bind(this)
        this.state = { open: false }
    }

    handleClickOutside() {
        this.onWindowClick()
    }

    onMenuClick(event) {
        this.toggleMenu()
        event.stopPropagation()
    }

    onWindowClick() {
        this.setState({ open: false })
    }

    toggleMenu() {
        this.setState({ open: !this.state.open })
    }

    onNav(url) {
        return () => this.props.history.push(url)
    }

    onShowVersionClicked() {
        const buildDate = new Date(VERSION)
        const versionInfo = (
            <div>
                <div>Version Number: {VERSION}</div>
                <div>Build Date: {buildDate.toString()}</div>
                <div
                    className={styles.button}
                    onClick={this.props.checkForUpdates}
                >
                    Check for updates
                </div>
            </div>
        )
        this.props.openAlert(versionInfo)
    }

    render() {
        let popup
        let tabStyle = styles.tab

        if (this.state.open) {
            tabStyle = `${styles.tab} ${styles.tabActive}`
            if (!!this.props.publisherOnly) {
                popup = (
                    <div className={styles.popup}>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/lessons')}
                        >
                            Lessons
                        </div>
                        {this.props.publisherAdmin.some(
                            item => item.code === 'BOOKNOOK'
                        ) ? (
                            <div
                                className={styles.menuItem}
                                onClick={this.onNav('/admin/books')}
                            >
                                Books
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                )
            } else {
                popup = (
                    <div className={styles.popup}>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/books')}
                        >
                            Books
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/lessons')}
                        >
                            Lessons
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/schools')}
                        >
                            Schools
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/multisites')}
                        >
                            Multisites
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/standards')}
                        >
                            Standards
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/tutors')}
                        >
                            Guides
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/students')}
                        >
                            Students
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/passages')}
                        >
                            Passages
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/platform_metrics')}
                        >
                            Platform Metrics
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onNav('/admin/school_metrics')}
                        >
                            School Metrics
                        </div>
                        <div
                            className={styles.menuItem}
                            onClick={this.onShowVersionClicked}
                        >
                            Show Version
                        </div>
                    </div>
                )
            }
        }

        return (
            <div
                className={styles.menu}
                onClick={this.onMenuClick}
                onBlur={this.onMenuClick}
            >
                <div className={tabStyle}>Admin</div>
                {popup}
            </div>
        )
    }
}

AdminMenu.contextTypes = {
    publisherOnly: PropTypes.bool,
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ openAlert, checkForUpdates }, dispatch)
}

export default withRouter(
    connect(null, mapDispatchToProps)(onClickOutside(AdminMenu))
)
