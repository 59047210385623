import Messenger from 'util/messenger'
import axios from 'axios'
import { randomString } from 'util/random-string'
import { BASE_API_URL } from 'config'

const SEND_MESSAGE_ENDPOINT = `${BASE_API_URL}/network_test/pusher/message`
const PUSHER_TEST_CHANNEL = 'network_test'
const PUSHER_TEST_EVENT = 'ping'
const MESSAGE_TIMEOUT = 10000

export default class PusherTestMessage {
    constructor(client, onCompletedCallback) {
        this.client = client
        this.messageId = randomString(8)
        this.onCompletedCallback = onCompletedCallback

        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null

        this.transport = null
        this.success = null
        this.timeoutId = null
        this.errorMessage = null
        this.onMessageTimedOut = this.onMessageTimedOut.bind(this)
        this.onMessageReceived = this.onMessageReceived.bind(this)
    }

    start() {
        Messenger.subscribe(
            this.client,
            PUSHER_TEST_CHANNEL,
            PUSHER_TEST_EVENT,
            this.onMessageReceived
        )
            .then(() =>
                axios.post(SEND_MESSAGE_ENDPOINT, {
                    message_id: this.messageId,
                })
            )
            .then(() => {
                this.startedAt = new Date()
                this.isStarted = true
                this.timeoutId = setTimeout(
                    this.onMessageTimedOut,
                    MESSAGE_TIMEOUT
                )
            })
            .catch(e => this.onCompleted(false, e.message))
    }

    getTotalSeconds() {
        return (this.completedAt - this.startedAt) / 1000
    }

    onMessageTimedOut() {
        if (!this.completed) {
            this.onCompleted(false, 'Message timed out')
        }
    }

    onMessageReceived(message) {
        if (message.message_id === this.messageId) {
            this.onCompleted(true)
        }
    }

    onCompleted(success, errorMessage) {
        this.success = success
        this.errorMessage = errorMessage
        this.transport = Messenger.getTransport(this.client)
        this.completedAt = new Date()
        this.isCompleted = true
        clearTimeout(this.timeoutId)
        Messenger.unsubscribeCallback(
            this.client,
            PUSHER_TEST_CHANNEL,
            PUSHER_TEST_EVENT,
            this.onMessageReceived
        )

        if (this.onCompletedCallback) {
            this.onCompletedCallback(this)
        }
    }

    generateReport(id) {
        const status = this.success ? 'success' : 'failed'
        const error = this.errorMessage || ''
        return `Message ${id} - Result: ${status} Transport: ${
            this.transport
        } Duration: ${this.getTotalSeconds()}s ${error}`
    }
}
