import _ from 'lodash'
import {
    ADMIN_STANDARDS_EDIT,
    ADMIN_STANDARDS_GET_ALL,
    ADMIN_STANDARDS_GET_STANDARD_TYPES,
    ADMIN_STANDARDS_SWAP_STANDARD_SEQUENCES,
    ADMIN_STANDARDS_UPLOAD_MEDIA,
    ADMIN_STANDARDS_DELETE_MEDIA,
    ADMIN_STANDARDS_UPLOAD_QUESTION_MEDIA,
    ADMIN_STANDARDS_DELETE_QUESTION_MEDIA,
} from 'actions'

const DEFAULT_STATE = {
    standards: [],
    standardTypes: [],
    selectedStandard: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_STANDARDS_GET_ALL:
            return {
                ...state,
                standards: action.payload,
            }
        case ADMIN_STANDARDS_GET_STANDARD_TYPES:
            return {
                ...state,
                standardTypes: action.payload,
            }
        case ADMIN_STANDARDS_EDIT:
            return {
                ...state,
                selectedStandard: action.payload,
            }
        case ADMIN_STANDARDS_SWAP_STANDARD_SEQUENCES:
            let standards = [...state.standards]
            let s1 = standards.find(s => s.id === action.meta.standard1Id)
            let s2 = standards.find(s => s.id === action.meta.standard2Id)

            let tmp = s1.sequence
            s1.sequence = s2.sequence
            s2.sequence = tmp

            standards = _.sortBy(standards, 'sequence')

            return {
                ...state,
                standards,
            }
        case ADMIN_STANDARDS_UPLOAD_MEDIA:
            return {
                ...state,
                selectedStandard: {
                    ...state.selectedStandard,
                    media: [...state.selectedStandard.media, action.payload],
                },
            }
        case ADMIN_STANDARDS_DELETE_MEDIA:
            return {
                ...state,
                selectedStandard: {
                    ...state.selectedStandard,
                    media: state.selectedStandard.media.filter(
                        m => m.id !== action.meta.mediaId
                    ),
                },
            }
        case ADMIN_STANDARDS_UPLOAD_QUESTION_MEDIA:
            return {
                ...state,
                selectedStandard: {
                    ...state.selectedStandard,
                    questions: [
                        ...state.selectedStandard.questions.filter(
                            q => q.id !== action.meta.questionId
                        ),
                        {
                            ...state.selectedStandard.questions.find(
                                q => q.id === action.meta.questionId
                            ),
                            media: [
                                ...state.selectedStandard.questions.find(
                                    q => q.id === action.meta.questionId
                                ).media,
                                action.payload,
                            ],
                        },
                    ],
                },
            }
        case ADMIN_STANDARDS_DELETE_QUESTION_MEDIA:
            return {
                ...state,
                selectedStandard: {
                    ...state.selectedStandard,
                    questions: [
                        ...state.selectedStandard.questions.filter(
                            q => q.id !== action.meta.questionId
                        ),
                        {
                            ...state.selectedStandard.questions.find(
                                q => q.id === action.meta.questionId
                            ),
                            media: state.selectedStandard.questions
                                .find(q => q.id === action.meta.questionId)
                                .media.filter(
                                    m => m.id !== action.meta.mediaId
                                ),
                        },
                    ],
                },
            }
        default:
            return state
    }
}
