import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import InputFormField from '../input-form-field'

import styles from './index.css'

import classNames from 'classnames'

const propTypes = {
    initialValue: PropTypes.node,
    isRequired: PropTypes.bool,
    items: PropTypes.array,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onValidChange: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
    isHorizontal: PropTypes.bool,
}

const defaultProps = {
    primaryFocus: false,
    isRequired: true,
    minLength: 0,
    items: [],
}

class RadioGroupFormField extends InputFormField {
    componentDidMount() {
        const fieldValue =
            this.props.initialValue ||
            (this.props.items[0] ? this.props.items[0].id : null)
        this.changeFieldValue(fieldValue)

        if (this.props.primaryFocus) {
            ReactDOM.findDOMNode(this.refs.input).focus()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.items !== this.props.items &&
            nextProps.items.length > 0 &&
            !nextProps.initialValue &&
            !this.props.initialValue
        ) {
            this.changeFieldValue(nextProps.items[0].id)
        }
    }

    validateFieldValue(value) {
        return (
            !this.props.isRequired ||
            (!!value &&
                (!this.props.onValidateFieldValue ||
                    this.props.onValidateFieldValue(value)))
        )
    }

    buildInput() {
        return (
            <div
                className={classNames(styles.radioGroup, {
                    [styles.horizontal]: this.props.isHorizontal,
                })}
            >
                {this.props.items.map(i => (
                    <div key={i.id} onClick={() => this.changeFieldValue(i.id)}>
                        <input
                            type="radio"
                            name={i.name}
                            checked={this.state.fieldValue === i.id}
                            value={i.id}
                            readOnly
                        />
                        {i.name}
                        <br />
                    </div>
                ))}
            </div>
        )
    }
}

RadioGroupFormField.propTypes = propTypes
RadioGroupFormField.defaultProps = defaultProps

export default RadioGroupFormField
