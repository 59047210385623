import { NETWORK_TEST_GET_BOOKS } from 'actions'

const DEFAULT_STATE = {
    books: [],
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case NETWORK_TEST_GET_BOOKS:
            return {
                ...state,
                books: action.payload,
            }
        default:
            return state
    }
}
