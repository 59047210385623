import {
    AUTH_LOGOUT,
    SESSION_END,
    SESSION_FLUENCY_SET_FLUENCY_TYPES,
    SESSION_FLUENCY_GET_READING_PASSAGE,
    SESSION_FLUENCY_GET_READING_SCORE,
    SESSION_SET,
} from 'actions'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_SET:
            if (!action.payload.state.fluency) return state

            state = !!state ? state : {}
            const result = {
                ...state,
                ...action.payload.state.fluency,
                readingProgress: {
                    ...state.readingProgress,
                    ...action.payload.state.fluency.readingProgress,
                },
            }

            if (!result.readingProgress.selectedStudentId) {
                result.readingProgress.selectedStudent = null
            } else if (
                !!action.payload.students &&
                !!result.readingProgress.selectedStudentId
            ) {
                result.readingProgress.selectedStudent = action.payload.students.find(
                    s => s.id === result.readingProgress.selectedStudentId
                )
            }

            if (!result.readingProgress.nextStudentId) {
                result.readingProgress.nextStudent = null
            } else if (
                !!action.payload.students &&
                !!result.readingProgress.nextStudentId
            ) {
                result.readingProgress.nextStudent = action.payload.students.find(
                    s => s.id === result.readingProgress.nextStudentId
                )
            }

            return result
        case SESSION_FLUENCY_SET_FLUENCY_TYPES:
            return {
                ...state,
                fluencyTypes: action.payload,
            }
        case SESSION_FLUENCY_GET_READING_PASSAGE:
            return {
                ...state,
                readingProgress: {
                    ...state.readingProgress,
                    passage: action.payload,
                },
            }
        case SESSION_FLUENCY_GET_READING_SCORE:
            return {
                ...state,
                readingProgress: {
                    ...state.readingProgress,
                    readingScore: action.payload,
                },
            }
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
