import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import InputFormField from '../input-form-field'

import styles from './index.css'

const propTypes = {
    initialValue: PropTypes.node,
    isRequired: PropTypes.bool,
    items: PropTypes.array,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onValidChange: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
    setPropsOnLoad: PropTypes.bool,
    'data-testid': PropTypes.string,
}

const defaultProps = {
    primaryFocus: false,
    isRequired: true,
    minLength: 0,
    items: [],
}

class SelectFormField extends InputFormField {
    constructor() {
        super()

        this.state = { value: null }
        this.onFieldValueChanged = this.onFieldValueChanged.bind(this)
    }

    componentDidMount() {
        const fieldValue =
            this.props.initialValue ||
            (this.props.items[0] ? this.props.items[0].id : null)

        if (this.props.setPropsOnLoad) {
            this.setState({ fieldValue })
        } else {
            this.changeFieldValue(fieldValue)
        }

        if (this.props.primaryFocus) {
            ReactDOM.findDOMNode(this.refs.input).focus()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.items !== this.props.items &&
            nextProps.items.length > 0 &&
            !nextProps.initialValue &&
            !this.props.initialValue
        ) {
            this.changeFieldValue(nextProps.items[0].id)
        }
    }

    validateFieldValue(value) {
        return (
            !this.props.isRequired ||
            (!!value &&
                (!this.props.onValidateFieldValue ||
                    this.props.onValidateFieldValue(value)))
        )
    }

    onFieldValueChanged(event) {
        this.setState({ value: event.target.value })
        super.onFieldValueChanged(event)
    }

    buildInput() {
        return (
            <select
                data-testid={this.props['data-testid']}
                className={styles.select}
                value={this.state.value || this.props.initialValue}
                onChange={this.onFieldValueChanged}
            >
                {this.props.items.map(i => (
                    <option key={i.id} id={i.id} value={i.id}>
                        {i.name}
                    </option>
                ))}
            </select>
        )
    }
}

SelectFormField.propTypes = propTypes
SelectFormField.defaultProps = defaultProps

export default SelectFormField
