import { APP_UPDATE } from 'actions'

const defaultState = {
    update: false,
}

export default (state = defaultState, action) => {
    if (action.error) return state

    switch (action.type) {
        case APP_UPDATE:
            return {
                ...state,
                update: true,
            }
        default:
            return state
    }
}
