import React, { useState } from 'react'
import ExtendTrialForm from 'components/extend-trial-form'
import PropTypes from 'prop-types'
import Button from 'components/button'
import Link from 'components/link'
import arrowImg from './images/arrow.png'
import monstersImg from './images/monsters.png'
import monsterImg from './images/monster.png'
import styles from './index.css'

const renderFeedback = onClose => (
    <div className={styles.feedback}>
        <p className={styles.header}>
            We’re so happy you’ve extended your free trial!
        </p>
        <img src={monstersImg} alt="monsters" className={styles.monstersImg} />
        <p className={styles.footer}>
            You can keep using BookNook, and you’ll also receive a confirmation
            email within 72 hours.
        </p>
        <Button size="lg" onClick={onClose}>
            GOT IT
        </Button>
    </div>
)

const renderForm = (onSubmit, onClose, isFormBusy) => (
    <div className={styles.wrapper}>
        <div>
            <div className={styles.text}>
                <p>Your BookNook free trial has come to an end…</p>
                <p>
                    But, wait! If you want to keep using BookNook, we’ll keep
                    your trial going for free!
                </p>
            </div>
            <img src={arrowImg} alt="arrow" className={styles.arrowImg} />
            <img
                src={monstersImg}
                alt="monsters"
                className={styles.monstersImg}
            />
        </div>
        <div className={styles.form}>
            <ExtendTrialForm
                onFormSubmit={onSubmit}
                onClose={onClose}
                isFormBusy={isFormBusy}
            />
        </div>
        <button className={styles.closeBtn} onClick={onClose}>
            &#x2715;
        </button>
    </div>
)

const renderCannotExtendWarning = onClose => (
    <div className={styles.warningWrapper}>
        <div>
            <img
                src={monsterImg}
                alt="monsters"
                className={styles.monstersImg}
            />
            <section>
                <h3>Sorry, you can only extend your Free Trial once.</h3>
                <p>
                    Learn more about purchasing options by contacting our{' '}
                    <Link
                        fontFamily="montserrat"
                        href="https://www.booknooklearning.com/demo/"
                    >
                        Partnerships team
                    </Link>
                </p>
            </section>
        </div>
        <div className={styles.bottomBtnWrapper}>
            <Button size="xl" onClick={onClose}>
                GOT IT
            </Button>
        </div>
        <button className={styles.closeBtn} onClick={onClose}>
            &#x2715;
        </button>
    </div>
)

const ExtendFreeTrial = ({ onClose, onSubmit, isFormBusy, tutor = {} }) => {
    const [submitted, setSubmitted] = useState(false)

    const handleSubmit = (formValid, values) => {
        onSubmit(values, () => {
            setSubmitted(true)
        })
    }
    return tutor.trialExtensionCount > 0
        ? renderCannotExtendWarning(onClose)
        : !submitted
        ? renderForm(handleSubmit, onClose, isFormBusy)
        : renderFeedback(onClose)
}

ExtendFreeTrial.propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    isFormBusy: PropTypes.bool,
    submitSuccess: PropTypes.bool,
    tutor: PropTypes.shape({
        id: PropTypes.string,
    }),
}

ExtendFreeTrial.defaultProps = {
    onClose: () => {},
    onSubmit: () => {},
}

export default ExtendFreeTrial
