const MAX_BACKOFF_LEVEL = 5
const BACKOFF_LEVEL_REPETITIONS = 60

export default class Timer {
    constructor(callback, frequency, backoffEnabled = false) {
        this.callback = callback
        this.frequency = frequency
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.performCallback = this.performCallback.bind(this)

        // https://en.wikipedia.org/wiki/Exponential_backoff
        this.maxBackoffLevel = backoffEnabled ? MAX_BACKOFF_LEVEL : 0
        this.backoffLevel = 0
        this.count = 0
    }

    start() {
        this.stop()
        this.count = 0
        this.intervalId = setInterval(
            this.performCallback,
            this.frequency * Math.pow(2, this.backoffLevel)
        )
    }

    performCallback() {
        this.count += 1
        this.checkBackoff()
        if (this.callback) {
            this.callback()
        }
    }

    checkBackoff() {
        if (this.count >= BACKOFF_LEVEL_REPETITIONS) {
            this.backoffLevel = Math.min(
                this.backoffLevel + 1,
                this.maxBackoffLevel
            )
            this.start()
        }
    }

    stop() {
        if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = null
        }
    }
}
