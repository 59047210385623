import ClaimTutor from './claim-tutor'
import OAuth from './oauth'
import Student from './student'
import Tutor from './tutor'

export default {
    ClaimTutor,
    OAuth,
    Student,
    Tutor,
}
