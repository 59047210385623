import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import InputFormField from '../input-form-field'
import Text from 'components/text'
import ShowPasswordEnabledIcon from './images/password-form-field-show-password-enabled.png'
import ShowPasswordDisabledIcon from './images/password-form-field-show-password-disabled.png'
import styles from './index.css'

const propTypes = {
    label: PropTypes.string,
    minLength: PropTypes.number,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    onValidChange: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    allowShowPassword: PropTypes.bool,
    validationError: PropTypes.string,
}

const defaultProps = {
    initialValue: '',
    isRequired: true,
    label: '',
    minLength: 8,
    placeholder: '',
    primaryFocus: false,
    allowShowPassword: true,
    validationError: 'A valid password must be at least 8 characters.',
    errorsAsTooltip: false,
}

class PasswordFormField extends InputFormField {
    constructor() {
        super()
        this.onKeyPress = this.onKeyPress.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
        this.state = { showPassword: false }
    }

    onKeyPress(event) {
        if (event.charCode === 32) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    toggleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    shouldShowValidationError() {
        return !this.props.errorsAsTooltip
    }

    buildInput() {
        const showTootipError = this.props.errorsAsTooltip && this.showError
        let inputType = 'password'
        let showPasswordComponent
        if (this.props.allowShowPassword) {
            let showPasswordIcon
            if (this.state.showPassword) {
                inputType = 'text'
                showPasswordIcon = ShowPasswordEnabledIcon
            } else {
                showPasswordIcon = ShowPasswordDisabledIcon
            }

            showPasswordComponent = (
                <div
                    onClick={this.toggleShowPassword}
                    className={styles.showPasswordIcon}
                >
                    <img src={showPasswordIcon} alt="" />
                </div>
            )
        }

        return (
            <div className={styles.passwordFormField}>
                {showPasswordComponent}
                <input
                    className={classnames(styles.input, {
                        [styles.error]: showTootipError,
                    })}
                    ref="input"
                    type={inputType}
                    placeholder={this.props.placeholder}
                    onChange={this.onFieldValueChanged}
                    onBlur={this.changeFieldEntered}
                    onKeyPress={this.onKeyPress}
                    defaultValue={this.props.initialValue}
                />
                {showTootipError ? (
                    <Text className={styles.errorTooltip}>
                        {this.props.validationError}
                    </Text>
                ) : this.props.error ? (
                    <Text className={styles.errorTooltip}>
                        {this.props.error}
                    </Text>
                ) : (
                    ''
                )}
            </div>
        )
    }
}

PasswordFormField.propTypes = propTypes
PasswordFormField.defaultProps = defaultProps

export default PasswordFormField
