import { lazy } from 'react'

const GuideDetails = lazy(() => import('./guides/details'))
const GuideList = lazy(() => import('./guides/list'))
const GuideOverview = lazy(() => import('./guides/details/overview'))
const GuideProfile = lazy(() => import('./guides/details/profile'))
const GuideSessionDetails = lazy(() =>
    import('./guides/details/sessions/details')
)
const GuideSessionList = lazy(() => import('./guides/details/sessions/list'))
const GuideStudents = lazy(() => import('./guides/details/students'))
const Home = lazy(() => import('./home'))
const ImportStudents = lazy(() => import('./students/import'))
const ImportGuides = lazy(() => import('./guides/import'))
const LessonList = lazy(() => import('./lessons/list'))
const LessonPreview = lazy(() => import('./lessons/preview'))
const NewGuide = lazy(() => import('./guides/new'))
const NewStudent = lazy(() => import('./students/new'))
const Overview = lazy(() => import('./overview'))
const Reports = lazy(() => import('./reports'))
const ActivityExport = lazy(() => import('./reports/activity-export'))
const ActivityStrugglingExport = lazy(() =>
    import('./reports/activity-struggling-export')
)
const SessionDetails = lazy(() => import('./sessions/details'))
const SessionList = lazy(() => import('./sessions/list'))
const StandardList = lazy(() => import('./lessons/standards'))
const StudentDetails = lazy(() => import('./students/details'))
const StudentList = lazy(() => import('./students/list'))
const StudentOverview = lazy(() => import('./students/details/overview'))
const StudentProfile = lazy(() => import('./students/details/profile'))
const StudentSessionDetails = lazy(() =>
    import('./students/details/sessions/details')
)
const StudentSessionList = lazy(() =>
    import('./students/details/sessions/list')
)
const GroupList = lazy(() => import('./groups/list'))
const NewGroup = lazy(() => import('./groups/new'))
const GroupDetails = lazy(() => import('./groups/details'))

export default {
    GuideDetails,
    GuideList,
    GuideOverview,
    GuideProfile,
    GuideSessionDetails,
    GuideSessionList,
    GuideStudents,
    Home,
    ImportStudents,
    ImportGuides,
    LessonList,
    LessonPreview,
    NewGuide,
    NewStudent,
    Overview,
    Reports,
    ActivityExport,
    ActivityStrugglingExport,
    SessionDetails,
    SessionList,
    StandardList,
    StudentDetails,
    StudentList,
    StudentOverview,
    StudentProfile,
    StudentSessionDetails,
    StudentSessionList,
    GroupList,
    GroupDetails,
    NewGroup,
}
