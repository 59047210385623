import ApiClient from 'util/book-nook-api-client'
import { AUTH_LOGOUT, AUTH_STUDENT, AUTH_TUTOR } from 'actions'
import Auth from 'util/auth'

export function authenticateStudent(username) {
    const payload = ApiClient.authenticateStudent(username).then(
        Auth.postStudentAuthentication
    )

    return {
        type: AUTH_STUDENT,
        payload,
    }
}

export function authenticateStudentBadge(badgeId) {
    const payload = ApiClient.authenticateStudentBadge(badgeId).then(
        Auth.postStudentAuthentication
    )

    return {
        type: AUTH_STUDENT,
        payload,
    }
}

export function authenticateCleverOAuth(code) {
    const payload = ApiClient.authenticateCleverOAuth(code).then(
        Auth.postStudentAuthentication
    )

    return {
        type: AUTH_STUDENT,
        payload,
    }
}

export function authenticateTutor(email, password) {
    const payload = ApiClient.authenticateTutor(email, password).then(
        Auth.postTutorAuthentication
    )

    return {
        type: AUTH_TUTOR,
        payload,
    }
}

export function claimTutor(email) {
    const payload = ApiClient.claimTutor(email).then(
        Auth.postTutorAuthentication
    )

    return {
        type: AUTH_TUTOR,
        payload,
    }
}

export function logout() {
    return { type: AUTH_LOGOUT }
}
