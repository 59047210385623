import { AUTH_TUTOR, INITIALIZE } from 'actions'

/**
 * @type SessionTypesState = SessionType[]
 */
const initialState = []

const sessionTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_TUTOR:
        case INITIALIZE:
            return action.payload.sessionTypes || []
        default:
            return state
    }
}

export default sessionTypeReducer
