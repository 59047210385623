import PropTypes from 'prop-types'
import React, { Component, createRef } from 'react'
import GenericFormField from '../generic-form-field'
import styles from './index.css'

const propTypes = {
    accept: PropTypes.string,
    initialValue: PropTypes.node,
    inputType: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    onValidChange: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    validation: PropTypes.object,
    inputStyle: PropTypes.object,
    errorTop: PropTypes.bool,
}

const defaultProps = {
    accept: '',
    initialValue: '',
    inputType: 'text',
    primaryFocus: false,
    errorTop: false,
    validation: {
        required: true,
    },
}

class InputFormField extends Component {
    constructor() {
        super()
        this.inputRef = createRef()
        this.state = {
            fieldValue: '',
            fieldEntered: false,
            fieldValid: null,
            error: '',
        }
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setState({ fieldValue: this.props.initialValue })
            this.changeFieldValue(this.props.initialValue)
        }
        if (this.props.primaryFocus) {
            this.inputRef.current.focus()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.showError =
            nextProps.isRequired &&
            nextState.fieldEntered &&
            !nextState.fieldValid
        return true
    }

    changeFieldEntered = () => {
        if (!this.state.fieldEntered && !this.state.fieldValue) {
            //field is touched but never received any value change event
            this.changeFieldValue(this.state.fieldValue)
        }
        this.setState({ fieldEntered: true })
    }

    onFieldValueChanged = event => {
        let newFieldValue
        if (this.inputRef.current.type === 'file') {
            newFieldValue = event.target.files[0]
        } else {
            newFieldValue = event.target.value
        }
        this.changeFieldValue(newFieldValue)
    }

    changeFieldValue = newFieldValue => {
        const requiredMsg = this.isRequired(newFieldValue)
        const { validate, validateAsync } = this.props.validation
        if (requiredMsg) {
            return this.validityChange(newFieldValue, false, requiredMsg)
        }
        const minLengthMsg = this.isMinLength(newFieldValue)
        if (minLengthMsg) {
            return this.validityChange(newFieldValue, false, minLengthMsg)
        }
        const maxLengthMsg = this.isMaxLength(newFieldValue)
        if (maxLengthMsg) {
            return this.validityChange(newFieldValue, false, maxLengthMsg)
        }
        if (validateAsync) {
            // it's async validate function
            // just add delay to avoid data overlaping
            if (this.timerId) {
                clearTimeout(this.timerId)
            }
            this.timerId = setTimeout(() => {
                validateAsync(newFieldValue).then(msg => {
                    return this.validityChange(newFieldValue, !msg, msg)
                })
            }, 300)
            return this.timerId
        }
        if (validate) {
            // it's sync function
            const msg = validate(newFieldValue)
            if (msg) {
                return this.validityChange(newFieldValue, false, msg)
            }
        }

        // all validation pass
        return this.validityChange(newFieldValue, true)
    }

    validityChange = (newFieldValue, newFieldValid, error) => {
        const currentFieldValid = this.state.fieldValid
        if (typeof error !== 'string' && typeof error !== 'boolean') {
            throw error('validation return non-string error message')
        }
        this.setState({
            fieldValue: newFieldValue,
            fieldValid: newFieldValid,
            error: error,
        })

        if (this.props.onChange) {
            this.props.onChange(newFieldValue, newFieldValid)
        }

        if (newFieldValid !== currentFieldValid && this.props.onValidChange) {
            this.props.onValidChange(newFieldValid)
        }
    }

    isRequired = value => {
        const { required } = this.props.validation
        if (typeof required === 'object') {
            return required.value && !value ? required.message : false
        }
        return required && !value ? 'This field is required' : false
    }

    isMinLength = value => {
        const { minLength } = this.props.validation
        if (typeof minLength === 'object') {
            return value.length < minLength.value ? minLength.message : false
        }
        return minLength && value.length < minLength
            ? `Must be at least ${minLength} characters`
            : false
    }

    isMaxLength = value => {
        const { maxLength } = this.props.validation
        if (typeof maxLength === 'object') {
            return value.length > maxLength.value ? maxLength.message : false
        }
        return maxLength && value.length > maxLength
            ? `Must not exceed ${maxLength} characters`
            : false
    }

    onKeyPress = event => {
        if (this.props.onKeyPress) this.props.onKeyPress(event)
    }

    buildInput() {
        return (
            <input
                style={this.props.inputStyle}
                className={styles.input}
                ref={this.inputRef}
                autoComplete="off"
                accept={this.props.accept}
                type={this.props.inputType}
                placeholder={this.props.placeholder}
                onChange={this.onFieldValueChanged}
                onBlur={this.changeFieldEntered}
                onKeyPress={this.onKeyPress}
                defaultValue={this.props.initialValue}
                maxLength={this.props.maxLength}
                id={this.props.id}
            />
        )
    }

    shouldShowValidationError() {
        return true
    }

    render() {
        const input = this.buildInput()
        const { errorTop } = this.props
        const showError = this.state.fieldEntered && !this.state.fieldValid

        return (
            <GenericFormField
                label={this.props.label}
                isRequired={!!this.props.validation.required}
                style={this.props.style}
                hint={this.props.hint}
                id={this.props.id}
            >
                {showError && errorTop ? (
                    <div className={`${styles.alert} ${styles.alertVisible}`}>
                        {this.state.error}
                    </div>
                ) : (
                    ''
                )}
                {input}
                {showError && !errorTop ? (
                    <div className={`${styles.alert} ${styles.alertVisible}`}>
                        {this.state.error}
                    </div>
                ) : (
                    ''
                )}
            </GenericFormField>
        )
    }
}

InputFormField.propTypes = propTypes
InputFormField.defaultProps = defaultProps

export default InputFormField
