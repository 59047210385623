import { INITIALIZE, AUTH_TUTOR, ADMIN_TUTORS_IMPERSONATE } from 'actions'

export default (state = [], action) => {
    if (action.error) return state

    switch (action.type) {
        case AUTH_TUTOR:
        case INITIALIZE:
        case ADMIN_TUTORS_IMPERSONATE:
            return action.payload.tutorPublisherAdmin
        default:
            return state
    }
}
