import React from 'react'
import Auth from 'util/auth'
import { Redirect, Route, withRouter } from 'react-router-dom'

const StudentRoute = ({ component: Component, ...props }) => {
    if (Auth.isStudentLoggedIn()) {
        return <Route {...props} render={props => <Component {...props} />} />
    } else {
        let url = `/student/login?redirect=${props.location.pathname}`
        return <Redirect to={url} />
    }
}

export default withRouter(StudentRoute)
