import Download from './download'

export default class Batch {
    constructor(books, onDownloadCompletedCallback, onBatchCompletedCallback) {
        this.downloads = []
        this.books = books
        this.onDownloadCompletedCallback = onDownloadCompletedCallback
        this.onBatchCompletedCallback = onBatchCompletedCallback
        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null
        this.success = null
        this.size = null
        this.onDownloadCompleted = this.onDownloadCompleted.bind(this)
    }

    start() {
        this.isStarted = true
        this.startedAt = new Date()
        this.downloads = this.books.map(
            b => new Download(b, this.onDownloadCompleted)
        )
        this.downloads.forEach(d => d.start())
    }

    onDownloadCompleted(download) {
        this.onDownloadCompletedCallback(download)
        if (this.downloads.every(d => d.isCompleted)) {
            this.isCompleted = true
            this.completedAt = new Date()
            this.size = this.downloads.reduce((a, b) => a + b.size, 0)
            this.success = this.downloads.every(d => d.success)
            this.onBatchCompletedCallback(this)
        }
    }

    getSuccessPercentage() {
        return (
            (this.downloads.filter(d => d.success).length /
                this.downloads.length) *
            100
        ).toFixed(0)
    }

    getStatus() {
        return this.success ? 'successfully' : 'unsuccessfully'
    }

    getTotalSeconds() {
        return (this.completedAt - this.startedAt) / 1000
    }

    generateReport() {
        const downloadReports = this.downloads
            .map(d => d.generateReport())
            .join('\n')
        return `Batch - Count: ${
            this.downloads.length
        } Duration: ${this.getTotalSeconds()}s Success: ${this.getSuccessPercentage()}%\n${downloadReports}`
    }
}
