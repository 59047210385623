export function getQueryParameter(name, url) {
    if (!url) url = window.location.href
    /*eslint-disable */
    name = name.replace(/[\[\]]/g, '\\$&')
    /*eslint-enable */
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
