import { SESSION_SET, SESSION_END, AUTH_LOGOUT } from 'actions'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_SET:
            if (!action.payload.state.comprehension) return state

            return {
                ...state,
                ...action.payload.state.comprehension,
            }
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
