import {
    ADMIN_BOOKS_GET_ALL,
    ADMIN_BOOKS_EDIT,
    ADMIN_BOOKS_GET_FICTION_TYPES,
} from 'actions'

const DEFAULT_STATE = {
    books: [],
    readingLevels: [],
    fictionTypes: [],
    selectedBook: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_BOOKS_GET_ALL:
            return {
                ...state,
                books: action.payload,
            }
        case ADMIN_BOOKS_GET_FICTION_TYPES:
            return {
                ...state,
                fictionTypes: action.payload,
            }
        case ADMIN_BOOKS_EDIT:
            return {
                ...state,
                selectedBook: action.payload,
            }
        default:
            return state
    }
}
