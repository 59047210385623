import { randomString } from 'util/random-string'
import ApiClient from 'util/book-nook-api-client'

const MESSAGE_TIMEOUT = 10000

export default class FirebaseTest {
    constructor(firebaseId, onCompletedCallback) {
        this.firebaseId = firebaseId
        this.messageId = randomString(8)
        this.onCompletedCallback = onCompletedCallback

        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null

        this.success = null
        this.timeoutId = null
        this.errorMessage = null
        this.onMessageTimedOut = this.onMessageTimedOut.bind(this)
        this.onMessageReceived = this.onMessageReceived.bind(this)
    }

    start() {
        this.startedAt = new Date()
        this.isStarted = true
        this.timeoutId = setTimeout(this.onMessageTimedOut, MESSAGE_TIMEOUT)

        ApiClient.updateFirebaseNetworkTest(
            this.firebaseId,
            this.messageId
        ).catch(console.error)
    }

    getTotalSeconds() {
        return (this.completedAt - this.startedAt) / 1000
    }

    onMessageTimedOut() {
        if (!this.isCompleted) {
            this.onCompleted(false, 'Message timed out')
        }
    }

    onMessageReceived(message) {
        if (message.messageId === this.messageId) {
            this.onCompleted(true)
        }
    }

    onCompleted(success, errorMessage) {
        this.success = success
        this.errorMessage = errorMessage
        this.completedAt = new Date()
        this.isCompleted = true
        clearTimeout(this.timeoutId)

        if (this.onCompletedCallback) {
            this.onCompletedCallback(this)
        }
    }

    generateReport(id) {
        const status = this.success ? 'success' : 'failed'
        const error = this.errorMessage || ''
        return `Message ${id} - Result: ${status} Duration: ${this.getTotalSeconds()}s ${error}`
    }
}
