import { FIREBASE_CONFIG } from 'config'
import { updateDemoSessionState } from 'actions/session-demo'

import { DEMO_SESSION_ID } from 'util/demo-session-manager'

let unsubCallbacks = {}

class FirebaseManager {
    static async initialize() {
        if (!!FirebaseManager.firebase) return

        let [{ firebase }] = await Promise.all([
            import('@firebase/app'),
            import('@firebase/firestore'),
            import('@firebase/auth'),
        ])

        if (firebase.apps.length === 0) {
            firebase.initializeApp(FIREBASE_CONFIG)
            const firestore = firebase.firestore()
            const settings = {}
            firestore.settings(settings)

            FirebaseManager.firebase = firebase
        }
    }

    static signIn(token) {
        return FirebaseManager.initialize().then(() => {
            return FirebaseManager.firebase
                .auth()
                .setPersistence(
                    FirebaseManager.firebase.auth.Auth.Persistence.NONE
                )
                .then(() => {
                    return FirebaseManager.firebase
                        .auth()
                        .signInWithCustomToken(token)
                })
        })
    }

    static signOut() {
        return FirebaseManager.initialize().then(() => {
            return FirebaseManager.firebase.auth().signOut()
        })
    }

    static modifySessionState(sessionId, state, merge = true) {
        if (sessionId === DEMO_SESSION_ID) {
            // set timeout to mimic network
            setTimeout(() => updateDemoSessionState(state, merge), 0)
            return Promise.resolve()
        }

        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        return document.set({ state }, { merge })
    }

    static modifySessionStudents(sessionId, students, merge = true) {
        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        return document.set({ students }, { merge })
    }

    static getSession(sessionId) {
        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        return document.get().then(res => res.data())
    }

    static monitorSession(sessionId, handle, callback) {
        FirebaseManager.stopMonitoringSession(handle)

        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        const onNext = d => callback(d.data())
        const onError = e => callback(null, e)
        const cancel = document.onSnapshot(onNext, onError)

        unsubCallbacks[handle] = cancel
    }

    static stopMonitoringSession(handle) {
        const cancel = unsubCallbacks[handle]
        if (!!cancel) cancel()
        unsubCallbacks[handle] = undefined
    }

    static getNetworkTestDocument(testId) {
        return FirebaseManager.firebase
            .firestore()
            .doc(`networkTests/${testId}`)
    }
}

export default FirebaseManager
