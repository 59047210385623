import React from 'react'
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'

import FullScreen from 'components/session-common/full-screen'
import { update as updateApp } from 'actions/app'

import errorMonsters from './images/app-error-monsters.png'
import errorOhNo from './images/app-error-oh-no.png'

import styles from './index.css'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
    }

    static getDerivedStateFromError(error) {
        let state = { error }

        if (
            error.message.match(/Loading chunk \d+ failed/) ||
            error.message.match(/Unexpected token < in JSON/)
        ) {
            updateApp()
            state.updating = true
        }
        return state
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ error: null })
        }
    }

    render() {
        let query = parse(this.props.location.search)
        if (this.state.updating && !query.reloaded) {
            return <div />
        }

        if (!!this.state.error) {
            return (
                <div className={styles.root}>
                    <img className={styles.ohNo} src={errorOhNo} alt="Oh No!" />
                    <div className={styles.message}>
                        An unexpected error has occurred. Please{' '}
                        <a href="/">reload the app</a>, or{' '}
                        <a href="https://support.booknooklearning.com/hc/en-us">
                            contact the BookNook support team
                        </a>{' '}
                        if the error persists.
                    </div>
                    <div className={styles.error}>
                        Error: {this.state.error.message}
                    </div>
                    <img
                        className={styles.errorMonsters}
                        src={errorMonsters}
                        alt="monsters"
                    />

                    <FullScreen />
                </div>
            )
        }

        return this.props.children
    }
}

export default withRouter(ErrorBoundary)
