import React, { useRef } from 'react'
import Prompt from 'components/session-common/prompt'
import Header from 'components/session-common/header'
import { connect } from 'react-redux'
import { isVIPKidTutor } from 'util/tutors'
import { SALESFORCE_ORG_ID } from 'config'

import styles from './index.css'

const HelpForm = function({ tutor = {}, onClose, school }) {
    const formRef = useRef(null)

    return (
        <div className={styles.root}>
            {isVIPKidTutor(tutor.email) ? (
                <div>
                    Sorry, it looks like your account is associated with a
                    placeholder email address. Please reach out directly to your
                    employer for support requests.
                </div>
            ) : (
                <Prompt
                    primaryButtonText="Submit"
                    secondaryButtonText="Cancel"
                    onPrimaryClicked={() => formRef.current.submit()}
                    onSecondaryClicked={onClose}
                >
                    <Header>Submit a Help Request</Header>
                    <div className={styles.main}>
                        <form
                            className={styles.form}
                            action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                            method="POST"
                            ref={formRef}
                        >
                            <input
                                type="hidden"
                                name="orgid"
                                value={SALESFORCE_ORG_ID}
                            />
                            <input
                                type="hidden"
                                name="retURL"
                                value={`${
                                    window.location.href
                                }?help_form_success`}
                            />
                            <input
                                id="company"
                                maxLength="80"
                                name="company"
                                size="20"
                                type="hidden"
                                defaultValue={school ? school.id : null}
                                readOnly
                            />
                            <div className={styles.field}>
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    maxLength="80"
                                    name="email"
                                    size="20"
                                    type="text"
                                    defaultValue={tutor.email}
                                    readOnly
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="phone">Phone</label>
                                <input
                                    id="phone"
                                    maxLength="40"
                                    name="phone"
                                    size="20"
                                    type="text"
                                    defaultValue={tutor.mobileNumber}
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="subject">Subject</label>
                                <input
                                    id="subject"
                                    maxLength="80"
                                    name="subject"
                                    size="20"
                                    type="text"
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="description">Description</label>
                                <textarea name="description" />
                            </div>
                        </form>
                    </div>
                </Prompt>
            )}
            <button className={styles.closeBtn} onClick={onClose}>
                &#x2715;
            </button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        school: state.manageSchool.school,
    }
}

export default connect(mapStateToProps)(HelpForm)
