import _ from 'lodash'

import {
    SESSION_DEMO_CONFIGURE,
    SESSION_DEMO_STUDENT_PROGRESS,
    SESSION_SET,
} from 'actions'
import FirebaseManager from 'util/firebase-manager'
import { Store } from 'containers/app'
import { checkUpdateTriggers, DEMO_SESSION_ID } from 'util/demo-session-manager'

// FirebaseManager isn't connected to redux, so dispatch it directly.
// Firebase usually returns the entire session object, but we don't have it in
// the action. Instead, grab it from the store and update it here
export function updateDemoSessionState(sessionState, merge = true) {
    const activeSession = Store.getState().activeSession
    const updatedSession = {
        ...Store.getState().activeSession,
        state: merge
            ? _.merge(activeSession.state, sessionState)
            : sessionState,
    }

    checkUpdateTriggers(sessionState, activeSession)

    return Store.dispatch({
        type: SESSION_SET,
        payload: Promise.resolve(updatedSession),
    })
}

export function configuireDemoSession({ school }) {
    const selectedPublisher = school.access_publishers.find(
        p => p.code === 'BOOKNOOK'
    )

    const selectedStudents = [
        {
            id: -1,
            first_name: 'David',
            last_name: 'Hernandez',
            is_dual_language_learner: false,
            needs_spanish: false,
            reading_level_id: 3,
            reading_level: {
                id: 3,
                sequence: 3,
                reading_level: 'C',
                grade_level: { id: 1, name: 'Kindergarten', sequence: 1 },
            },
        },
        {
            id: -2,
            first_name: 'Lachelle',
            last_name: 'Kendall',
            is_dual_language_learner: false,
            needs_spanish: false,
            reading_level_id: 3,
            reading_level: {
                id: 3,
                sequence: 3,
                reading_level: 'C',
                grade_level: { id: 1, name: 'Kindergarten', sequence: 1 },
            },
        },
        {
            id: -3,
            first_name: 'Jamar',
            last_name: 'Ellis',
            is_dual_language_learner: false,
            needs_spanish: false,
            reading_level_id: 3,
            reading_level: {
                id: 3,
                sequence: 3,
                reading_level: 'C',
                grade_level: { id: 1, name: 'Kindergarten', sequence: 1 },
            },
        },
    ]

    return {
        type: SESSION_DEMO_CONFIGURE,
        payload: { selectedPublisher, selectedStudents },
    }
}

export function startDemoSession(
    sessionType,
    students,
    tutor,
    lesson,
    isSpanish = false,
    state
) {
    const tutorData = {
        first_name: tutor.firstName,
        last_name: tutor.lastName,
    }

    const session = {
        id: DEMO_SESSION_ID,
        session_type: sessionType,
        students,
        tutor: tutorData,
        isSpanish,
        lesson,
        state,
    }

    checkUpdateTriggers(state, session)

    return {
        type: SESSION_SET,
        payload: session,
    }
}

export function demonstrateStudentProgress() {
    const payload = FirebaseManager.modifySessionState(DEMO_SESSION_ID, {
        demonstrateStudentProgress: true,
    })

    return {
        type: SESSION_DEMO_STUDENT_PROGRESS,
        payload: payload,
    }
}
