import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { parse } from 'query-string'

import BaseComponent, { mapDispatch } from 'components/base-component'
import BusyIndicator from 'components/busy-indicator'
import { claimTutor } from 'actions/auth'
import { GAEvent } from 'util/analytics'

import styles from './index.css'

const RETRY_DELAY_MILLIS = 2000
const MAX_RETRIES = 3

class ClaimTutor extends BaseComponent {
    constructor() {
        super()
        this.state = {
            loading: true,
            error: false,
            retries: MAX_RETRIES,
        }

        this.claim = this.claim.bind(this)
    }

    componentDidMount() {
        const { email } = parse(this.props.location.search)

        GAEvent.claimedTutorAccount()

        if (!email) {
            this.setState({ error: true })
        } else {
            this.claim(email)
        }
    }

    claim(email) {
        this.props.claimTutor(email).then(response => {
            if (response.error) {
                if (response.payload.message === 'Tutor Already Claimed') {
                    this.setState({ loading: false })
                    this.props.history.push(`/tutor/login?email=${email}`)
                } else if (response.payload.message === 'Authentication Failed')
                    if (this.state.retries <= 0) {
                        this.setState({
                            error: true,
                            loading: false,
                        })
                    } else {
                        this.setState({
                            retries: this.state.retries - 1,
                        })
                        setTimeout(() => {
                            this.claim(email)
                        }, RETRY_DELAY_MILLIS)
                    }
            }
        })
    }

    componentDidUpdate() {
        const query = parse(this.props.location.search)
        if (!!this.props.tutor) {
            if (this.props.tutor.forcePasswordChange) {
                if (query.redirect) {
                    this.props.history.push(
                        `/tutor/onboarding?redirect=${query.redirect}`
                    )
                } else {
                    this.props.history.push('/tutor/onboarding')
                }
            } else if (query.redirect) {
                this.props.history.push(query.redirect)
            } else {
                this.props.history.push('/')
            }
        }
    }

    render() {
        let body
        if (this.state.loading) {
            body = (
                <div className={styles.subheader}>
                    Hold on while we set up your account...
                    <div className={styles.busyIndicator}>
                        <BusyIndicator />
                    </div>
                </div>
            )
        } else if (this.state.error) {
            body = (
                <div className={styles.subheader}>
                    <p>
                        Uh oh, Something went wrong! Try checking your email to
                        verify your login.
                    </p>
                    <p>
                        Please{' '}
                        <a href="https://support.booknooklearning.com/hc/en-us">
                            contact the BookNook support team
                        </a>{' '}
                        if the error persists.
                    </p>
                </div>
            )
        }

        return (
            <div className={styles.root}>
                <div className={styles.header}>Welcome to BookNook!</div>
                {body}
                <div className={styles.footer}>
                    <div className={styles.legal}>
                        By signing in, you are agreeing to BookNook's{' '}
                        <a
                            href="https://www.booknooklearning.com/tos/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.booknooklearning.com/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        .
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch({
            claimTutor,
        })
    )(ClaimTutor)
)
