import React from 'react'
import styles from './index.css'

export const ActionBar = props => {
    return <div className={styles.actionBar}>{props.children}</div>
}

const SubmitButton = props => {
    const onClick = () => {
        if (props.enabled && props.onClick) props.onClick()
    }
    const enabledStyle = !props.enabled ? styles.disabled : ''

    return (
        <button
            type="submit"
            onClick={onClick}
            className={`${styles.button} ${
                props.buttonTypeStyle
            } ${enabledStyle}`}
        >
            {props.text}
        </button>
    )
}

SubmitButton.defaultProps = {
    enabled: true,
}

export const PrimaryButton = props => {
    return (
        <SubmitButton
            text={props.text}
            enabled={props.enabled}
            onClick={props.onClick}
            buttonTypeStyle={styles.primary}
        />
    )
}

export const SecondaryButton = props => {
    return (
        <SubmitButton
            text={props.text}
            enabled={props.enabled}
            onClick={props.onClick}
            buttonTypeStyle={styles.secondary}
        />
    )
}
