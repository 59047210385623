import {
    GET_LIST_PROGRAMS,
    GET_PROGRAM,
    CREATE_PROGRAM,
    UPDATE_PROGRAM,
    REMOVE_PROGRAM,
    INITIALIZE,
} from 'actions'

const initialState = {
    programs: [],
    program: {},
    createProgram: {},
    updateProgram: {},
    removeProgram: {},
}

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case GET_LIST_PROGRAMS:
            return {
                ...state,
                programs: action.payload,
            }
        case GET_PROGRAM:
            return {
                ...state,
                program: action.payload,
            }
        case CREATE_PROGRAM:
            return {
                ...state,
                createProgram: action.payload,
            }
        case UPDATE_PROGRAM:
            return {
                ...state,
                updateProgram: action.payload,
            }
        case REMOVE_PROGRAM:
            return {
                ...state,
                removeProgram: action.payload,
            }
        case INITIALIZE:
            return {
                ...initialState,
                isLoggedIn: true,
            }
        default:
            return state
    }
}
