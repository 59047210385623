import React from 'react'
import { reduxForm } from 'redux-form'
import Forms from 'components/forms'
import Link from 'components/link'
import styles from './index.css'

class ExtendTrialForm extends Forms.Form {
    onFormSubmit = event => {
        event.preventDefault()

        const formValid = this.isFormValid()
        if (this.props.onFormSubmit && formValid && !this.props.isFormBusy) {
            const formFields = this.getFormFields()
            const { principalEmail, principalName, ...otherFields } = formFields
            const values = {
                ...otherFields,
                principal: {
                    name: principalName,
                    email: principalEmail,
                },
            }
            this.props.onFormSubmit(formValid, values)
        }
    }

    render() {
        const guide = this.props.guide || {}
        const {
            fields: {
                firstName,
                lastName,
                email,
                principalName,
                principalEmail,
                notes,
            },
        } = this.props

        return (
            <div className={styles.wrapper}>
                <form onSubmit={this.onFormSubmit}>
                    <div>
                        <div className={styles.formField}>
                            <Forms.InputFormField
                                initialValue={guide.firstName}
                                label="First Name"
                                id="firstName"
                                onChange={this.onFieldChange(firstName)}
                            />
                        </div>
                        <div className={styles.formField}>
                            <Forms.InputFormField
                                initialValue={guide.lastName}
                                label="Last Name"
                                id="lastName"
                                onChange={this.onFieldChange(lastName)}
                            />
                        </div>
                    </div>
                    <div className={styles.formField}>
                        <Forms.EmailFormField
                            initialValue={guide.email}
                            label="Email"
                            id="email"
                            onChange={this.onFieldChange(email)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <Forms.InputFormField
                            label="Principal's Name"
                            id="principalName"
                            onChange={this.onFieldChange(principalName)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <Forms.EmailFormField
                            label="Principal's Email"
                            id="principalEmail"
                            onChange={this.onFieldChange(principalEmail)}
                        />
                    </div>
                    <div className={styles.formField}>
                        <Forms.TextAreaFormField
                            label="Notes"
                            id="notes"
                            placeholder="What are your thoughts about BookNook so far?"
                            rows={5}
                            onChange={this.onFieldChange(notes)}
                        />
                    </div>
                    <Forms.SubmitButton
                        content="EXTEND MY TRIAL"
                        allowSubmit={this.allowSubmit()}
                        shape="basic"
                        className={styles.submitBtn}
                    />
                    <div className={styles.cancelBtn}>
                        <Link onClick={this.props.onClose}>
                            No thanks, I'm done
                        </Link>
                    </div>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'ExtendTrialForm',
    fields: [
        'firstName',
        'lastName',
        'email',
        'principalName',
        'principalEmail',
        'notes',
    ],
})(ExtendTrialForm)
