import _ from 'lodash'
import {
    ADMIN_TUTORS_IMPERSONATE,
    AUTH_TUTOR,
    MANAGE_SCHOOL_CREATE_OR_UPDATE_GUIDE,
    MANAGE_SCHOOL_ENROLL_STUDENT,
    MANAGE_SCHOOL_GET_GUIDES,
    MANAGE_SCHOOL_GET_ALL_GUIDES,
    MANAGE_SCHOOL_GET_LESSONS_FOR_STANDARD,
    MANAGE_SCHOOL_GET_SELECTED_GUIDE,
    MANAGE_SCHOOL_GET_SELECTED_GUIDE_STUDENTS,
    MANAGE_SCHOOL_GET_SELECTED_LESSON,
    MANAGE_SCHOOL_GET_SELECTED_SESSION,
    MANAGE_SCHOOL_GET_SELECTED_SESSION_READING_LEVEL_CHANGES,
    MANAGE_SCHOOL_GET_SELECTED_STUDENT,
    MANAGE_SCHOOL_GET_SELECTED_STUDENT_STANDARDS,
    MANAGE_SCHOOL_GET_SESSIONS,
    MANAGE_SCHOOL_GET_STANDARDS,
    MANAGE_SCHOOL_GET_STUDENTS,
    MANAGE_SCHOOL_GET_STUDENT_COUNT,
    MANAGE_SCHOOL_GET_STUDENT_GROUPS,
    MANAGE_SCHOOL_GET_SELECTED_STUDENT_GROUP,
    MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT_GROUP,
    MANAGE_SCHOOL_ADD_STUDENT_TO_SELECTED_GROUP,
    MANAGE_SCHOOL_REMOVE_STUDENT_FROM_SELECTED_GROUP,
    MANAGE_SCHOOL_RESET_NEW_GROUP,
    MANAGE_SCHOOL_ADD_STUDENT_TO_NEW_GROUP,
    MANAGE_SCHOOL_REMOVE_STUDENT_FROM_NEW_GROUP,
    MANAGE_SCHOOL_DELETE_STUDENTS,
    MANAGE_SCHOOL_UNENROLL_STUDENTS,
    MANAGE_SCHOOL_ENROLL_ALL_STUDENTS,
    MANAGE_SCHOOL_UNENROLL_ALL_STUDENTS,
    MANAGE_SCHOOL_GET_TEACHERS,
    MANAGE_SCHOOL_GET_TUTOR_TYPES,
    MANAGE_SCHOOL_MAKE_SCHOOL_TEACHER,
    MANAGE_SCHOOL_READING_LEVEL_REPORT,
    MANAGE_SCHOOL_REMOVE_SCHOOL_TEACHER,
    MANAGE_SCHOOL_RESET,
    MANAGE_SCHOOL_RESET_GUIDE,
    MANAGE_SCHOOL_SET_SCHOOL,
    MANAGE_SCHOOL_SET_SELECTED_SESSION_STUDENT,
    MANAGE_SCHOOL_STRUGGLING_REPORT,
    MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT,
    MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT,
    MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT,
    MANAGE_SCHOOL_STUDENT_READING_LEVEL_ROLLUP_REPORT,
    MANAGE_SCHOOL_UNENROLL_STUDENT,
    MANAGE_SCHOOL_UPDATE_SELECTED_GUIDE,
    MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT,
    MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT,
    MANAGE_SCHOOL_USAGE_REPORT,
    MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT,
    MANAGE_SCHOOL_GET_STUDENTS_NEED_STANDARD,
    MANAGE_SCHOOL_SESSION_TYPES_REPORT,
    MANAGE_SCHOOL_GET_MULTISITES,
    MANAGE_SCHOOL_MULTISITE_USAGE_REPORT,
    MANAGE_SCHOOL_MULTISITE_STRUGGLING_REPORT,
    MANAGE_SCHOOL_MULTISITE_STUDENT_PROGRESS_REPORT,
    MANAGE_SCHOOL_MULTISITE_READING_LEVEL_REPORT,
    MANAGE_SCHOOL_MULTISITE_SESSION_TYPES_REPORT,
    MANAGE_SCHOOL_MULTISITE_SCHOOL_USAGE_REPORTS,
    MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_STRUGGLING_REPORT,
    MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_REPORT,
    MANAGE_SCHOOL_REMOVE_ALL_STUDENTS_FROM_SELECTED_GROUP,
} from 'actions'

const DEFAULT_STATE = {
    allGuides: { data: [], total: 0 },
    dailySessionRollupReport: null,
    races: [],
    genderTypes: [],
    gradeLevels: [],
    guides: { data: [], total: 0 },
    lessons: [],
    newGroupSelectedStudents: [],
    newGuide: null,
    readingLevels: [],
    school: null,
    isTutorSchoolAdmin: false,
    isTutorSchoolTeacher: false,
    selectedGuide: null,
    selectedGuideStudents: [],
    selectedSession: null,
    selectedStudent: null,
    selectedStudentStandards: [],
    selectedStudentGroup: null,
    sessions: [],
    sessionTotalsReport: null,
    standards: [],
    studentGroups: [],
    students: [],
    studentCount: 0,
    teachers: [],
    tutorTypes: [],
    usageReport: null,
    weeklySessionRollupReport: null,
}

let students

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_TUTORS_IMPERSONATE:
        case AUTH_TUTOR:
            return {
                ...DEFAULT_STATE,
            }
        case MANAGE_SCHOOL_SET_SCHOOL:
            return {
                ...DEFAULT_STATE,
                school: action.payload.school,
                isTutorSchoolAdmin: action.payload.isTutorSchoolAdmin,
                isTutorSchoolTeacher: action.payload.isTutorSchoolTeacher,
                readingLevels: state.readingLevels,
                gradeLevels: state.gradeLevels,
            }
        case MANAGE_SCHOOL_GET_TEACHERS:
            return {
                ...state,
                teachers: action.payload,
            }
        case MANAGE_SCHOOL_GET_STANDARDS:
            return {
                ...state,
                standards: action.payload,
            }
        case MANAGE_SCHOOL_GET_LESSONS_FOR_STANDARD:
            return {
                ...state,
                lessons: action.payload,
            }
        case MANAGE_SCHOOL_GET_SESSIONS:
            return {
                ...state,
                sessions: action.payload,
            }
        case MANAGE_SCHOOL_GET_SELECTED_LESSON:
            return {
                ...state,
                selectedLesson: action.payload,
            }
        case MANAGE_SCHOOL_GET_SELECTED_SESSION:
            return {
                ...state,
                selectedSession: action.payload,
            }
        case MANAGE_SCHOOL_GET_SELECTED_SESSION_READING_LEVEL_CHANGES:
            return {
                ...state,
                selectedSessionReadingLevelChanges: action.payload,
            }
        case MANAGE_SCHOOL_SET_SELECTED_SESSION_STUDENT:
            return {
                ...state,
                selectedSessionStudent: action.payload,
            }
        case MANAGE_SCHOOL_GET_STUDENT_GROUPS:
            return {
                ...state,
                studentGroups: action.payload,
            }
        case MANAGE_SCHOOL_GET_SELECTED_STUDENT_GROUP:
        case MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT_GROUP:
            return {
                ...state,
                selectedStudentGroup: action.payload,
            }
        case MANAGE_SCHOOL_ADD_STUDENT_TO_SELECTED_GROUP:
            students = [...state.selectedStudentGroup.students]
            students.push(action.payload)

            return {
                ...state,
                selectedStudentGroup: {
                    ...state.selectedStudentGroup,
                    students,
                },
            }
        case MANAGE_SCHOOL_REMOVE_STUDENT_FROM_SELECTED_GROUP:
            students = [...state.selectedStudentGroup.students].filter(
                s => s.id !== action.payload.id
            )
            return {
                ...state,
                selectedStudentGroup: {
                    ...state.selectedStudentGroup,
                    students,
                },
            }
        case MANAGE_SCHOOL_REMOVE_ALL_STUDENTS_FROM_SELECTED_GROUP:
            return {
                ...state,
                selectedStudentGroup: {
                    ...state.selectedStudentGroup,
                    students: [],
                },
            }
        case MANAGE_SCHOOL_RESET_NEW_GROUP:
            return {
                ...state,
                newGroupSelectedStudents: [],
            }
        case MANAGE_SCHOOL_ADD_STUDENT_TO_NEW_GROUP:
            let newGroupSelectedStudents = state.newGroupSelectedStudents.slice(
                0
            )
            newGroupSelectedStudents.push(action.payload)
            return { ...state, newGroupSelectedStudents }
        case MANAGE_SCHOOL_REMOVE_STUDENT_FROM_NEW_GROUP:
            return {
                ...state,
                newGroupSelectedStudents: [
                    ...state.newGroupSelectedStudents.filter(
                        s => s.id !== action.payload.id
                    ),
                ],
            }

        case MANAGE_SCHOOL_GET_STUDENTS:
            return {
                ...state,
                students: action.payload,
            }
        case MANAGE_SCHOOL_ENROLL_ALL_STUDENTS:
            students = _.cloneDeep(state.students)
            students.forEach(s => (s.is_enrolled = true))
            return {
                ...state,
                students,
            }
        case MANAGE_SCHOOL_UNENROLL_ALL_STUDENTS:
            students = _.cloneDeep(state.students)
            students.forEach(s => (s.is_enrolled = false))
            return {
                ...state,
                students,
            }
        case MANAGE_SCHOOL_DELETE_STUDENTS: {
            students = state.students.filter(
                s => !action.meta.studentIds.find(sid => s.id === sid)
            )
            return {
                ...state,
                students,
            }
        }
        case MANAGE_SCHOOL_UNENROLL_STUDENTS: {
            students = state.students.filter(
                s => !action.meta.studentIds.find(sid => s.id === sid)
            )
            return {
                ...state,
                students: state.students.map(s =>
                    action.meta.studentIds.find(sid => s.id === sid)
                        ? { ...s, is_enrolled: false }
                        : s
                ),
            }
        }
        case MANAGE_SCHOOL_ENROLL_STUDENT:
        case MANAGE_SCHOOL_UNENROLL_STUDENT:
        case MANAGE_SCHOOL_GET_SELECTED_STUDENT:
        case MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT:
            const newStudents = [...state.students]
            const studentIndex = newStudents.indexOf(
                newStudents.find(s => s.id === action.payload.id)
            )
            if (studentIndex !== -1) {
                newStudents[studentIndex] = action.payload
            }
            return {
                ...state,
                selectedStudent: {
                    ...action.payload,
                },
                students: newStudents,
            }
        case MANAGE_SCHOOL_GET_SELECTED_STUDENT_STANDARDS:
            return {
                ...state,
                selectedStudentStandards: action.payload,
            }
        case MANAGE_SCHOOL_GET_GUIDES:
            return {
                ...state,
                guides: action.payload,
            }
        case MANAGE_SCHOOL_GET_STUDENT_COUNT:
            return {
                ...state,
                studentCount: action.payload.count,
            }
        case MANAGE_SCHOOL_GET_ALL_GUIDES:
            return {
                ...state,
                allGuides: action.payload,
            }
        case MANAGE_SCHOOL_GET_SELECTED_GUIDE:
        case MANAGE_SCHOOL_MAKE_SCHOOL_TEACHER:
        case MANAGE_SCHOOL_REMOVE_SCHOOL_TEACHER:
        case MANAGE_SCHOOL_UPDATE_SELECTED_GUIDE:
            return {
                ...state,
                selectedGuide: action.payload,
            }
        case MANAGE_SCHOOL_GET_SELECTED_GUIDE_STUDENTS:
            return {
                ...state,
                selectedGuideStudents: action.payload,
            }
        case MANAGE_SCHOOL_USAGE_REPORT:
            return {
                ...state,
                usageReport: action.payload,
            }
        case MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT:
            return {
                ...state,
                usageDailyRollupReport: action.payload,
            }
        case MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT:
            return {
                ...state,
                usageWeeklyRollupReport: action.payload,
            }
        case MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT:
            return {
                ...state,
                studentProgressReport: action.payload,
            }
        case MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT:
            return {
                ...state,
                studentProgressDailyRollupReport: action.payload,
            }
        case MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT:
            return {
                ...state,
                studentProgressWeeklyRollupReport: action.payload,
            }
        case MANAGE_SCHOOL_READING_LEVEL_REPORT:
            return {
                ...state,
                readingLevelReport: action.payload,
            }
        case MANAGE_SCHOOL_STUDENT_READING_LEVEL_ROLLUP_REPORT:
            return {
                ...state,
                studentReadingLevelRollupReport: action.payload,
            }
        case MANAGE_SCHOOL_STRUGGLING_REPORT:
            return {
                ...state,
                strugglingReport: action.payload,
            }
        case MANAGE_SCHOOL_GET_TUTOR_TYPES:
            return {
                ...state,
                tutorTypes: action.payload,
            }
        case MANAGE_SCHOOL_CREATE_OR_UPDATE_GUIDE:
            return {
                ...state,
                newGuide: action.payload,
            }
        case MANAGE_SCHOOL_RESET_GUIDE:
            return {
                ...state,
                newGuide: null,
            }
        case MANAGE_SCHOOL_RESET:
            return {
                ...DEFAULT_STATE,
            }
        case MANAGE_SCHOOL_GET_STUDENTS_NEED_STANDARD:
            return {
                ...state,
                studentsNeedStandard: action.payload,
            }
        case MANAGE_SCHOOL_SESSION_TYPES_REPORT:
            return {
                ...state,
                sessionTypesReport: action.payload,
            }
        case MANAGE_SCHOOL_GET_MULTISITES:
            return {
                ...state,
                multisites: action.payload,
            }
        case MANAGE_SCHOOL_MULTISITE_USAGE_REPORT:
            return {
                ...state,
                multisiteUsageReport: action.payload,
            }
        case MANAGE_SCHOOL_MULTISITE_STRUGGLING_REPORT:
            return {
                ...state,
                multisiteStrugglingReport: action.payload,
            }
        case MANAGE_SCHOOL_MULTISITE_STUDENT_PROGRESS_REPORT:
            return {
                ...state,
                multisiteStudentProgressReport: action.payload,
            }
        case MANAGE_SCHOOL_MULTISITE_READING_LEVEL_REPORT:
            return {
                ...state,
                multisiteReadingLevelReport: action.payload,
            }
        case MANAGE_SCHOOL_MULTISITE_SESSION_TYPES_REPORT:
            return {
                ...state,
                multisiteSessionTypesReport: action.payload,
            }
        case MANAGE_SCHOOL_MULTISITE_SCHOOL_USAGE_REPORTS:
            return {
                ...state,
                multisiteSchoolUsageReports: action.payload,
            }
        case MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_STRUGGLING_REPORT:
            return {
                ...state,
                phonicsActivityStrugglingReport: action.payload,
            }
        case MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_REPORT:
            return {
                ...state,
                phonicsActivityReport: action.payload,
            }
        default:
            return state
    }
}
