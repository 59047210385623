import React from 'react'
import { reduxForm } from 'redux-form'
import Forms from 'components/forms'
import styles from './index.css'

class LoginForm extends Forms.Form {
    render() {
        const {
            fields: { username },
        } = this.props

        return (
            <form onSubmit={this.onSubmit}>
                <div className={styles.fieldWrapper}>
                    <Forms.InputFormField
                        placeholder="Type Your Username Here"
                        minLength={1}
                        validationError="Username is required to login"
                        onChange={this.onFieldChange(username)}
                    />
                </div>
                <Forms.SubmitButton
                    content="Sign In"
                    allowSubmit={this.allowSubmit()}
                />
            </form>
        )
    }
}

export default reduxForm({
    form: 'StudentLoginForm',
    fields: ['username'],
})(LoginForm)
