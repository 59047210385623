import React from 'react'
import { POPUP_OPEN, ALERT_OPEN, POPUP_CLOSE, AUTH_STUDENT } from 'actions'
import StudentLoginErrorNudge from 'components/student-login-error-nudge'

export function openPopup(
    content,
    style = {},
    onClose = null,
    windowStyle = {}
) {
    return {
        type: POPUP_OPEN,
        payload: {
            content,
            style,
            onClose,
            windowStyle,
        },
    }
}

export function openAlert(content, onClose) {
    return {
        type: ALERT_OPEN,
        payload: {
            content,
            onClose,
        },
    }
}

/**
 * Called when redux action that call API throw error
 * @param {object} payload - Http Error payload
 * @param {string} type - Action type
 */
export function alertError(payload, type) {
    switch (payload.status) {
        case 401: {
            if (type === AUTH_STUDENT) {
                const content = <StudentLoginErrorNudge />
                return openPopup(content)
            } else {
                const message = `Please confirm that you have entered the correct credentials.  
                For tutors this is an email address and password.`
                return openAlert(message)
            }
        }
        case 400: {
            const message = (
                <div>
                    The following errors occurred:
                    <br />
                    {payload.validationErrors.map(e => (
                        <div key={e.field} className="validation-error">
                            {e.field} {e.message}
                        </div>
                    ))}
                </div>
            )
            return openAlert(message)
        }
        case 500: {
            const message = `An unexpect error has occurred with the BookNook servers.  
            If this continues please contact technical support.`
            return openAlert(message)
        }
        default:
            const message = payload.message
            openAlert(message)
    }
}

export function closePopup() {
    return {
        type: POPUP_CLOSE,
    }
}
