import _ from 'lodash'

import {
    ADMIN_LESSONS_CREATE,
    ADMIN_LESSONS_DELETE,
    ADMIN_LESSONS_DELETE_ACTIVITY_BLOCK_MEDIA,
    ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_MEDIA,
    ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_OPTION_MEDIA,
    ADMIN_LESSONS_EDIT,
    ADMIN_LESSONS_GET_ACTIVITY_TYPES,
    ADMIN_LESSONS_GET_ALL,
    ADMIN_LESSONS_GET_BOOKS,
    ADMIN_LESSONS_GET_MEDIA_TYPES,
    ADMIN_LESSONS_GET_PASSAGES,
    ADMIN_LESSONS_GET_STANDARDS,
    ADMIN_LESSONS_UPDATE,
    ADMIN_LESSONS_DUPLICATE,
    ADMIN_LESSONS_UPLOAD_VOCAB_FILE,
} from 'actions'

const DEFAULT_STATE = {
    lessons: [],
    books: [],
    publishers: [],
    standards: [],
    selectedLesson: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_LESSONS_GET_ALL:
            return {
                ...state,
                lessons: action.payload,
            }
        case ADMIN_LESSONS_GET_STANDARDS:
            return {
                ...state,
                standards: action.payload,
            }
        case ADMIN_LESSONS_GET_BOOKS:
            return {
                ...state,
                books: action.payload,
            }
        case ADMIN_LESSONS_CREATE:
            return {
                ...state,
                newLesson: action.payload,
            }
        case ADMIN_LESSONS_EDIT:
            return {
                ...state,
                selectedLesson: action.payload,
            }
        case ADMIN_LESSONS_UPDATE:
            return {
                ...state,
                selectedLesson: action.payload,
            }
        case ADMIN_LESSONS_DUPLICATE:
            return {
                ...state,
                selectedLesson: action.payload,
            }
        case ADMIN_LESSONS_DELETE: {
            const lessons = state.lessons.filter(l => l.id !== action.meta.id)
            return {
                ...state,
                lessons,
            }
        }
        case ADMIN_LESSONS_DELETE_ACTIVITY_BLOCK_MEDIA:
            let activities = _.cloneDeep(state.selectedLesson.activities)

            let block = activities
                .find(a => a.id === action.meta.activityId)
                .blocks.find(b => b.id === action.meta.activityBlockId)

            block.media = _.filter(
                block.media,
                m => m.id !== action.meta.mediaId
            )

            return {
                ...state,
                selectedLesson: {
                    ...state.selectedLesson,
                    activities,
                },
            }
        case ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_MEDIA:
            activities = _.cloneDeep(state.selectedLesson.activities)

            let question = activities
                .find(a => a.id === action.meta.activityId)
                .questions.find(q => q.id === action.meta.questionId)

            question.media = _.filter(
                question.media,
                m => m.id !== action.meta.mediaId
            )

            return {
                ...state,
                selectedLesson: {
                    ...state.selectedLesson,
                    activities,
                },
            }
        case ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_OPTION_MEDIA:
            activities = _.cloneDeep(state.selectedLesson.activities)

            let questionOption = activities
                .find(a => a.id === action.meta.activityId)
                .questions.find(q => q.id === action.meta.questionId)
                .options.find(qo => qo.id === action.meta.questionOptionId)

            questionOption.media = _.filter(
                questionOption.media,
                m => m.id !== action.meta.mediaId
            )

            return {
                ...state,
                selectedLesson: {
                    ...state.selectedLesson,
                    activities,
                },
            }
        case ADMIN_LESSONS_UPLOAD_VOCAB_FILE:
            const vocabulary = _.cloneDeep(state.selectedLesson.vocabulary)
            vocabulary
                .find(v => v.id === action.meta.vocabId)
                .media.push(action.payload)

            return {
                ...state,
                selectedLesson: {
                    ...state.selectedLesson,
                    vocabulary,
                },
            }
        case ADMIN_LESSONS_GET_PASSAGES:
            return {
                ...state,
                passages: action.payload,
            }
        case ADMIN_LESSONS_GET_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypes: action.payload,
            }
        case ADMIN_LESSONS_GET_MEDIA_TYPES:
            return {
                ...state,
                mediaTypes: action.payload,
            }
        default:
            return state
    }
}
