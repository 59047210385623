import PropTypes from 'prop-types'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import DayPicker, { DateUtils } from 'react-day-picker'

/*eslint-disable */
import '!!style-loader!css-loader!react-day-picker/lib/style.css' // Bypass CSS modules
import '!!style-loader!css-loader!./dayPicker.css' // Bypass CSS modules
/*eslint-enable */

import styles from './index.css'

function formatDateString(date) {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

// Lifted from react-day-picker.DateUtils, overriding because the date picker
// does not behave as you'd expect. The new behavior mimics the date range
// selector from google flights.
function addDayToRange(day, range) {
    const { isDayBefore } = DateUtils //helpers

    let { from, to, lastSet } = range

    const FROM = 'FROM'
    const TO = 'TO'

    if (lastSet !== TO && lastSet !== FROM) {
        lastSet = TO // set left bound first
    }

    let set = lastSet

    if (lastSet === TO) {
        // setting left bound
        if (isDayBefore(to, day)) {
            // outside right case
            from = day
            to = null
            set = FROM
        } else {
            // outside left or inside case
            from = day
            set = FROM
        }
    } else {
        // setting right bound
        if (isDayBefore(day, from)) {
            // outside left case
            // set left bound anyway
            from = day
            set = FROM
        } else {
            // outside right or inside case
            to = day
            set = TO
        }
    }

    return { from, to, lastSet: set }
}

class DateRangeSelector extends Component {
    constructor() {
        super()
        this.onMenuClick = this.onMenuClick.bind(this)
        this.onDropDownClicked = this.onDropDownClicked.bind(this)
        this.onWindowClick = this.onWindowClick.bind(this)
        this.onDateRangeApplied = this.onDateRangeApplied.bind(this)
        this.handleDayClick = this.handleDayClick.bind(this)
        this.handleResetClick = this.handleResetClick.bind(this)

        this.state = {
            menuOpen: false,
            from: null,
            to: null,
            lastSet: null,
        }
    }

    componentDidMount() {
        this.setState({
            from: this.props.selectedStartDate,
            to: this.props.selectedEndDate,
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const dateValid = date => !!date && !isNaN(date.getTime())

        let state = {}
        if (!dateValid(prevState.from)) {
            state.from = nextProps.selectedStartDate
        }
        if (!dateValid(prevState.to)) {
            state.to = nextProps.selectedEndDate
        }

        return state
    }

    handleClickOutside() {
        this.onWindowClick()
    }

    onMenuClick(event) {
        this.toggleMenu()
        event.stopPropagation()
    }

    onDropDownClicked(event) {
        event.stopPropagation()
    }

    onWindowClick() {
        this.setState({ menuOpen: false })
    }

    toggleMenu() {
        this.setState({ menuOpen: !this.state.menuOpen })
    }

    onDateRangeApplied() {
        this.props.onDateRangeChanged(this.state.from, this.state.to)
        this.setState({ menuOpen: false })
    }

    handleDayClick(day) {
        const range = addDayToRange(day, this.state)
        this.setState(range)
    }

    handleResetClick() {
        this.setState({
            from: this.props.selectedStartDate,
            to: this.props.selectedEndDate,
            lastSet: null,
        })
    }

    render() {
        const label = `${formatDateString(
            this.props.selectedStartDate
        )} - ${formatDateString(this.props.selectedEndDate)}`
        const menuStyle = this.state.menuOpen ? styles.open : styles.closed

        const { from, to } = this.state
        const modifiers = { start: from, end: to }

        return (
            <div className={styles.root}>
                <div className={styles.select} onClick={this.onMenuClick}>
                    {label}
                    <div className={styles.arrow} />
                </div>
                <div
                    className={`${styles.menu} ${menuStyle}`}
                    onClick={this.onDropDownClicked}
                >
                    <div className={styles.prompt}>
                        {!from && !to && 'Please select the first day.'}
                        {from && !to && 'Please select the last day.'}
                        {from &&
                            to &&
                            `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                        <div
                            className={styles.resetButton}
                            onClick={this.handleResetClick}
                        >
                            Reset
                        </div>
                    </div>
                    <div>
                        <DayPicker
                            className="Selectable"
                            numberOfMonths={2}
                            selectedDays={[from, { from, to }]}
                            modifiers={modifiers}
                            onDayClick={this.handleDayClick}
                        />
                    </div>
                    <div
                        className={styles.submit}
                        onClick={this.onDateRangeApplied}
                    >
                        Apply
                    </div>
                </div>
            </div>
        )
    }
}

DateRangeSelector.propTypes = {
    selectedStartDate: PropTypes.object.isRequired,
    selectedEndDate: PropTypes.object.isRequired,
    onDateRangeChanged: PropTypes.func.isRequired,
}

export default onClickOutside(DateRangeSelector)
