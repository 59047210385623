import {
    IMPORT_STUDENTS_COLUMN_MAPPINGS_SELECTED,
    IMPORT_STUDENTS_DATA_SELECTED,
    IMPORT_STUDENTS_READING_SCALE_SELECTED,
    IMPORT_STUDENTS_RESET,
    IMPORT_STUDENTS_SET_SUMMARY,
    IMPORT_STUDENTS_SET_USE_DEFAULT_READING_LEVELS,
} from 'actions'

const defaultState = {
    studentData: null,
    columnMappings: null,
    readingScale: null,
    readingLevels: [],
    genderTypes: [],
    races: [],
    summary: null,
    useDefaultReadingLevels: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case IMPORT_STUDENTS_DATA_SELECTED:
            return {
                ...defaultState,
                studentData: action.payload,
            }
        case IMPORT_STUDENTS_COLUMN_MAPPINGS_SELECTED:
            return {
                ...state,
                columnMappings: action.payload,
            }
        case IMPORT_STUDENTS_READING_SCALE_SELECTED:
            return {
                ...state,
                readingScale: action.payload,
            }
        case IMPORT_STUDENTS_SET_SUMMARY:
            return {
                ...state,
                summary: action.payload,
            }
        case IMPORT_STUDENTS_SET_USE_DEFAULT_READING_LEVELS:
            return {
                ...state,
                useDefaultReadingLevels: action.payload,
            }
        case IMPORT_STUDENTS_RESET:
            return defaultState
        default:
            return state
    }
}
