import { connect } from 'react-redux'
import { mapDispatch } from 'components/base-component'
import { _TermsOfServicePopup } from './terms-of-service-popup.js'
import { updateAccount } from 'actions/tutor'

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
    }
}

export const TermsOfServicePopup = connect(
    mapStateToProps,
    mapDispatch({
        updateAccount,
    })
)(_TermsOfServicePopup)
