import Message from './message'

export default class PusherTest {
    constructor(
        client,
        maxSeconds,
        maxMessages,
        onMessageCompletedCallback,
        onTestCompletedCallback
    ) {
        this.client = client
        this.nTries = 0
        this.messages = []
        this.maxSeconds = maxSeconds
        this.maxMessages = maxMessages
        this.onMessageCompletedCallback = onMessageCompletedCallback
        this.onTestCompletedCallback = onTestCompletedCallback
        this.messageCount = null
        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null
        this.onMessageCompleted = this.onMessageCompleted.bind(this)
    }

    getConnectionStatus() {
        return this.client.connection.state
    }

    getTotalSeconds() {
        const end = !!this.completedAt ? this.completedAt : new Date()
        return (end - this.startedAt) / 1000
    }

    isActive() {
        return this.isStarted && !this.isCompleted
    }

    start() {
        this.startedAt = new Date()
        this.isStarted = true
        this.messages = []
        this.messageCount = 0
        this.sendNextMessage()
    }

    stop() {
        this.completedAt = new Date()
        this.isCompleted = true
        this.currentMessage = null
    }

    sendNextMessage() {
        this.messageCount++
        const message = new Message(this.client, this.onMessageCompleted)
        this.messages.push(message)
        message.start()
    }

    onMessageCompleted(message) {
        if (
            this.maxSeconds > this.getTotalSeconds() &&
            this.messageCount < this.maxMessages &&
            !this.isCompleted
        ) {
            this.sendNextMessage()
            if (!!this.onMessageCompletedCallback) {
                this.onMessageCompletedCallback(message)
            }
        } else {
            this.stop()
            if (!!this.onMessageCompletedCallback) {
                this.onMessageCompletedCallback(message)
            }
            if (!!this.onTestCompletedCallback) {
                this.onTestCompletedCallback(this)
            }
        }
    }

    generateReport() {
        const messageReports = this.messages
            .map((m, i) => m.generateReport(i + 1))
            .join('\n')
        return `***Real Time Messaging Report***\n${messageReports}`
    }
}
