import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import GenericFormField from '../generic-form-field'
import styles from './index.css'

const propTypes = {
    accept: PropTypes.string,
    initialValue: PropTypes.node,
    inputType: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    onValidChange: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
    inputStyle: PropTypes.object,
    setPropsOnLoad: PropTypes.bool,
}

const defaultProps = {
    accept: '',
    initialValue: '',
    isRequired: true,
    inputType: 'text',
    minLength: 1,
    primaryFocus: false,
}

class InputFormField extends Component {
    constructor() {
        super()
        this.changeFieldEntered = this.changeFieldEntered.bind(this)
        this.changeFieldValue = this.changeFieldValue.bind(this)
        this.onFieldValueChanged = this.onFieldValueChanged.bind(this)
        this.isFileInput = this.isFileInput.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.state = {
            fieldValue: '',
            fieldEntered: false,
            fieldValid: null,
        }
    }

    componentDidMount() {
        let fieldValue = this.props.initialValue
        if (fieldValue && fieldValue.constructor.name === 'Number') {
            fieldValue = fieldValue.toString()
        }
        if (this.props.setPropsOnLoad) {
            this.setState({ fieldValue })
        } else {
            this.changeFieldValue(fieldValue)
        }

        if (this.props.primaryFocus) {
            ReactDOM.findDOMNode(this.refs.input).focus()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.showError =
            nextProps.isRequired &&
            nextState.fieldEntered &&
            !nextState.fieldValid
        return true
    }

    isFileInput() {
        return this.props.inputType === 'file'
    }

    changeFieldEntered() {
        this.setState({ fieldEntered: true })
    }

    onFieldValueChanged(event) {
        let newFieldValue
        if (this.isFileInput()) {
            newFieldValue = event.target.files[0]
        } else {
            newFieldValue = event.target.value
        }
        this.changeFieldValue(newFieldValue)
    }

    changeFieldValue(newFieldValue) {
        const newFieldValid = this.validateFieldValue(newFieldValue)
        const currentFieldValid = this.state.fieldValid

        this.setState({
            fieldValue: newFieldValue,
            fieldValid: newFieldValid,
        })

        if (this.props.onChange) {
            this.props.onChange(newFieldValue, newFieldValid)
        }

        if (newFieldValid !== currentFieldValid && this.props.onValidChange) {
            this.props.onValidChange(newFieldValid)
        }
    }

    validateFieldValue(value) {
        if (this.isFileInput()) {
            return !this.props.isRequired || !!value
        } else {
            return (
                !this.props.isRequired ||
                (!!value &&
                    value.length >= this.props.minLength &&
                    (!this.props.onValidateFieldValue ||
                        this.props.onValidateFieldValue(value)))
            )
        }
    }

    onKeyPress(event) {
        if (this.props.onKeyPress) this.props.onKeyPress(event)
    }

    buildInput() {
        return (
            <input
                style={this.props.inputStyle}
                className={styles.input}
                ref="input"
                autoComplete="off"
                accept={this.props.accept}
                type={this.props.inputType}
                placeholder={this.props.placeholder}
                onChange={this.onFieldValueChanged}
                onBlur={this.changeFieldEntered}
                onKeyPress={this.onKeyPress}
                defaultValue={this.props.initialValue}
                maxLength={this.props.maxLength}
                id={this.props.id}
            />
        )
    }

    shouldShowValidationError() {
        return true
    }

    render() {
        const input = this.buildInput()
        const showError =
            this.props.isRequired &&
            this.state.fieldEntered &&
            !this.state.fieldValid

        return (
            <GenericFormField
                label={this.props.label}
                isRequired={this.props.isRequired}
                style={this.props.style}
                hint={this.props.hint}
                id={this.props.id}
            >
                {this.shouldShowValidationError() ? (
                    <div
                        className={`${styles.alert} ${
                            showError ? styles.alertVisible : ''
                        }`}
                    >
                        {this.props.validationError}
                    </div>
                ) : (
                    ''
                )}

                {input}
            </GenericFormField>
        )
    }
}

InputFormField.propTypes = propTypes
InputFormField.defaultProps = defaultProps

export { default as NewInputFormField } from './NewInputFormField'

export default InputFormField
