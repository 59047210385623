import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import ReduxPromise from 'redux-promise'
import ReactGA from 'util/analytics'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

import AppContainer from './app-container'
import Routes from './Routes'

import reducers from 'reducers'

const composeEnhancers =
    (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

const store = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(ReduxPromise))
)

const history = createBrowserHistory()

history.listen((location, action) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <AppContainer>
                    <Routes />
                </AppContainer>
            </Router>
        </Provider>
    )
}

export default App

export const Store = store
