import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'

const TutorPublisherAdminRoute = ({ component: Component, ...props }) => {
    const tutor = useSelector(state => state.tutor)
    const publishers = useSelector(state => state.tutorPublisherAdmin)
    if (tutor && publishers.length) {
        return <Route {...props} render={props => <Component {...props} />} />
    } else {
        let url = `/?redirect=${props.location.pathname}`
        return <Redirect to={url} />
    }
}

export default withRouter(TutorPublisherAdminRoute)
