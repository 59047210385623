export default class Download {
    constructor(book, onCompletedCallback) {
        this.book = book
        this.onCompletedCallback = onCompletedCallback
        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null
        this.size = null
        this.success = null
        this.statusCode = null
        this.errorBody = null
        this.onDownloadReadyStateChanged = this.onDownloadReadyStateChanged.bind(
            this
        )
    }

    start(download) {
        const cacheId = Math.floor(Math.random() * 10000)
        const xhr = new XMLHttpRequest()
        xhr.open('GET', `${this.book.book_url}?cacheId=${cacheId}`, true)
        xhr.addEventListener(
            'readystatechange',
            () => this.onDownloadReadyStateChanged(xhr),
            false
        )
        xhr.setRequestHeader('Cache-Control', 'no-cache')
        xhr.send()

        this.isStarted = true
        this.startedAt = new Date()
    }

    onDownloadReadyStateChanged(xhr) {
        if (xhr.readyState === 4) {
            this.isCompleted = true
            this.completedAt = new Date()
            this.statusCode = xhr.status
            this.size = xhr.responseText.length
            this.success = xhr.status === 200
            if (!this.success) {
                this.errorBody = xhr.responseText
            }
            this.onCompletedCallback(this)
        }
    }

    getStatus() {
        return this.success ? 'successfully' : 'unsuccessfully'
    }

    getTotalSeconds() {
        return (this.completedAt - this.startedAt) / 1000
    }

    generateReport() {
        const error = !this.success ? `Response: ${this.errorBody}` : ''
        return `Download - Book: ${
            this.book.id
        } Duration: ${this.getTotalSeconds()}s Status: ${
            this.statusCode
        } Size: ${this.size} ${error}`
    }
}
