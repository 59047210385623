import {
    ADMIN_TUTORS_GET_ALL,
    ADMIN_TUTORS_GET_SCHOOLS,
    ADMIN_TUTORS_GET_MULTISITES,
    ADMIN_TUTORS_GET_TUTOR_STUDENTS,
    ADMIN_TUTORS_EDIT,
    ADMIN_TUTORS_SELECT,
    ADMIN_TUTORS_SELECTED_TUTOR_GET_SCHOOLS,
    ADMIN_TUTORS_SELECTED_TUTOR_GET_ADMIN_SCHOOLS,
    ADMIN_TUTORS_SELECTED_TUTOR_GET_MULTISITES,
    ADMIN_TUTORS_GET_ALL_STUDENTS,
    ADMIN_TUTORS_ADD_SCHOOL,
    ADMIN_TUTORS_REMOVE_SCHOOL,
    ADMIN_TUTORS_ADD_MULTISITE,
    ADMIN_TUTORS_REMOVE_MULTISITE,
    ADMIN_TUTORS_MAKE_SCHOOL_ADMIN,
    ADMIN_TUTORS_REMOVE_SCHOOL_ADMIN,
    ADMIN_TUTORS_GET_TUTOR_TYPES,
    ADMIN_TUTORS_GET_COUNT,
    ADMIN_TUTORS_GET_PUBLISHERS,
    ADMIN_TUTORS_SELECTED_TUTOR_GET_PUBLISHER_ADMIN,
} from 'actions'

const DEFAULT_STATE = {
    tutors: [],
    tutorsCount: 0,
    schools: [],
    multisites: [],
    students: [],
    publishers: [],
    selectedTutor: null,
    selectedTutorStudents: [],
    selectedTutorMultisites: [],
    selectedTutorSchools: [],
    selectedTutorAdminSchools: [],
    selectedTutorPublisherAdmin: [],
    tutortypes: [],
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_TUTORS_GET_ALL:
            return {
                ...state,
                tutors: action.payload,
            }
        case ADMIN_TUTORS_GET_SCHOOLS:
            return {
                ...state,
                schools: action.payload,
            }
        case ADMIN_TUTORS_GET_MULTISITES:
            return {
                ...state,
                multisites: action.payload,
            }
        case ADMIN_TUTORS_GET_ALL_STUDENTS:
            return {
                ...state,
                students: action.payload,
            }
        case ADMIN_TUTORS_EDIT:
        case ADMIN_TUTORS_SELECT:
            return {
                ...state,
                selectedTutor: action.payload,
            }
        case ADMIN_TUTORS_SELECTED_TUTOR_GET_MULTISITES:
            return {
                ...state,
                selectedTutorMultisites: action.payload,
            }
        case ADMIN_TUTORS_SELECTED_TUTOR_GET_SCHOOLS:
            return {
                ...state,
                selectedTutorSchools: action.payload,
            }
        case ADMIN_TUTORS_SELECTED_TUTOR_GET_ADMIN_SCHOOLS:
            return {
                ...state,
                selectedTutorAdminSchools: action.payload,
            }
        case ADMIN_TUTORS_MAKE_SCHOOL_ADMIN:
            return {
                ...state,
                selectedTutorAdminSchools: [
                    ...state.selectedTutorAdminSchools,
                    state.schools.find(s => s.id === action.payload),
                ],
            }
        case ADMIN_TUTORS_REMOVE_SCHOOL_ADMIN:
            return {
                ...state,
                selectedTutorAdminSchools: state.selectedTutorAdminSchools.filter(
                    s => s.id !== action.payload
                ),
            }
        case ADMIN_TUTORS_GET_TUTOR_STUDENTS:
            return {
                ...state,
                selectedTutorStudents: action.payload,
            }
        case ADMIN_TUTORS_GET_TUTOR_TYPES:
            return {
                ...state,
                tutorTypes: action.payload,
            }
        case ADMIN_TUTORS_ADD_SCHOOL:
            return {
                ...state,
                selectedTutorSchools: [
                    ...state.selectedTutorSchools,
                    action.meta.school,
                ],
            }
        case ADMIN_TUTORS_REMOVE_SCHOOL:
            return {
                ...state,
                selectedTutorSchools: state.selectedTutorSchools.filter(
                    s => s.id !== action.meta.school.id
                ),
            }
        case ADMIN_TUTORS_ADD_MULTISITE:
            return {
                ...state,
                selectedTutorMultisites: [
                    ...state.selectedTutorMultisites,
                    action.meta.multisite,
                ],
            }
        case ADMIN_TUTORS_REMOVE_MULTISITE:
            return {
                ...state,
                selectedTutorMultisites: state.selectedTutorMultisites.filter(
                    s => s.id !== action.meta.multisite.id
                ),
            }
        case ADMIN_TUTORS_GET_COUNT:
            return {
                ...state,
                tutorsCount: action.payload.count,
            }
        case ADMIN_TUTORS_GET_PUBLISHERS:
            return {
                ...state,
                publishers: action.payload,
            }
        case ADMIN_TUTORS_SELECTED_TUTOR_GET_PUBLISHER_ADMIN:
            return {
                ...state,
                selectedTutorPublisherAdmin: action.payload,
            }

        default:
            return state
    }
}
