import React from 'react'
import _ from 'lodash'
import styles from './grid-layout.css'
import classNames from 'classnames'

const Row = ({ children, gap }) => {
    return (
        <div
            className={styles.row}
            style={{
                padding: `${gap}`,
            }}
        >
            {children}
        </div>
    )
}

const Column = ({ children, gap, nCols, columnNumber }) => {
    if (columnNumber === 0) {
        return (
            <div
                className={styles.column}
                style={{
                    paddingRight: `${gap}`,
                }}
            >
                {children}
            </div>
        )
    } else if (columnNumber === nCols - 1) {
        return (
            <div
                className={styles.column}
                style={{
                    paddingLeft: `${gap}`,
                }}
            >
                {children}
            </div>
        )
    }
    return (
        <div
            className={styles.column}
            style={{
                paddingLeft: `calc(${gap} / 2)`,
                paddingRight: `calc(${gap} / 2)`,
            }}
        >
            {children}
        </div>
    )
}

const Space = () => {
    return <div className={styles.space} />
}

export const _GridLayout = ({ children, nCols, gap, classes }) => {
    let componentRows = _.chunk(_.reject(children, _.isNil), nCols)

    let lastRow = componentRows.slice(
        componentRows.length - 1,
        componentRows.length
    )[0]
    const firstRow = componentRows.slice(0, 1)[0]

    if (lastRow.length !== firstRow.length) {
        const nMissingEls = firstRow.length - lastRow.length
        lastRow = [...lastRow, ..._.fill(Array(nMissingEls), <Space />)]
    }

    componentRows = [
        ...componentRows.slice(0, componentRows.length - 1),
        lastRow,
    ]

    return (
        <div className={classNames(styles.root, ...classes)}>
            {componentRows.map((components, iRow) => (
                <Row key={`col-${iRow}`} gap={gap.row}>
                    {components.map((component, iComponent) => (
                        <Column
                            key={`row-${iComponent}`}
                            gap={gap.column}
                            columnNumber={iComponent}
                            nCols={nCols}
                        >
                            {component}
                        </Column>
                    ))}
                </Row>
            ))}
        </div>
    )
}

_GridLayout.defaultProps = {
    classes: [],
}
