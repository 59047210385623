import { AUTH_TUTOR, INITIALIZE } from 'actions'

/**
 * @type GradeLevelState = GradeLevel[]
 */
const initialState = []

const gradeLevelReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_TUTOR:
        case INITIALIZE:
            return action.payload.gradeLevels || []
        default:
            return state
    }
}

export default gradeLevelReducer
