import {
    SESSION_SET,
    SESSION_WRAPUP_GET_COMPLETED_SESSION,
    SESSION_WRAPUP_GET_SESSION_READING_LEVEL_CHANGES,
    SESSION_END,
    AUTH_LOGOUT,
} from 'actions'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_SET:
            return {
                ...state,
                ...action.payload.state.wrapup,
            }
        case SESSION_WRAPUP_GET_COMPLETED_SESSION:
            return {
                ...state,
                completedSession: action.payload,
            }
        case SESSION_WRAPUP_GET_SESSION_READING_LEVEL_CHANGES:
            return {
                ...state,
                sessionReadingLevelChanges: action.payload,
            }
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
