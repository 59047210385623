export const INITIALIZE = 'INITIALIZE'
export const INITIALIZE_STUDENT = 'INITIALIZE_STUDENT'
//APP
export const APP_CHECK_FOR_UPDATES = 'APP_CHECK_FOR_UPDATES'
export const APP_UPDATE = 'APP_UPDATE'

//PUSHER

export const PUSHER_CONNECTION_STATE_CHANGE = 'PUSHER_CONNECTION_STATE_CHANGE'
export const PUSHER_SET_CLIENT = 'PUSHER_SET_CLIENT'
export const PUSHER_CONNECTION_ERROR = 'PUSHER_CONNECTION_ERROR'

//CLEVER
export const CLEVER_SYNC_START = 'CLEVER_SYNC_START'
export const CLEVER_SYNC_GET_LATEST_FOR_MULTISITE =
    'CLEVER_SYNC_GET_LATEST_FOR_MULTISITE'
export const CLEVER_SYNC_VALIDATE_GET_LATEST_FOR_MULTISITE =
    'CLEVER_SYNC_VALIDATE_GET_LATEST_FOR_MULTISITE'
export const CLEVER_SYNC_VALIDATE_START = 'CLEVER_SYNC_VALIDATE_START'

//AUTH
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_STUDENT = 'AUTH_STUDENT'
export const AUTH_TUTOR = 'AUTH_TUTOR'

//TUTOR
export const TUTOR_UPDATE_ACCOUNT = 'TUTOR_UPDATE_ACCOUNT'
export const TUTOR_CHANGE_PASSWORD = 'TUTOR_CHANGE_PASSWORD'
export const TUTOR_REQUEST_PASSWORD_RESET = 'TUTOR_REQUEST_PASSWORD_RESET'
export const TUTOR_RESET_PASSWORD = 'TUTOR_RESET_PASSWORD'
export const TUTOR_GET_TUTOR_TYPES = 'TUTOR_GET_TUTOR_TYPES'
export const TUTOR_SET_ONBOARDED = 'TUTOR_SET_ONBOARDED'
export const TUTOR_SET_TRIED_DEMO_SESSION = 'TUTOR_SET_TRIED_DEMO_SESSION'
export const TUTOR_RESET_FREE_TRIAL = 'TUTOR_RESET_FREE_TRIAL'
export const TUTOR_SET_SHOW_FREE_TRIAL_TUTORIAL =
    'TUTOR_SET_SHOW_FREE_TRIAL_TUTORIAL'

//LAYOUT
export const POPUP_OPEN = 'POPUP_OPEN'
export const ALERT_OPEN = 'ALERT_OPEN'
export const POPUP_CLOSE = 'POPUP_CLOSE'
export const APP_LAYOUT_CONFIGURED = 'APP_LAYOUT_CONFIGURED'
export const APP_LAYOUT_RESET = 'APP_LAYOUT_RESET'
export const APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED =
    'APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED'

//ADMIN BOOKS
export const ADMIN_BOOKS_CREATE = 'ADMIN_BOOKS_CREATE'
export const ADMIN_BOOKS_EDIT = 'ADMIN_BOOKS_EDIT'
export const ADMIN_BOOKS_UPDATE = 'ADMIN_BOOKS_UPDATE'
export const ADMIN_BOOKS_GET_ALL = 'ADMIN_BOOKS_GET_ALL'
export const ADMIN_BOOKS_DELETE = 'ADMIN_BOOKS_DELETE'
export const ADMIN_BOOKS_SET_STOPPING_POINTS = 'ADMIN_BOOKS_SET_STOPPING_POINTS'
export const ADMIN_BOOKS_ACTIVATE = 'ADMIN_BOOKS_ACTIVATE'
export const ADMIN_BOOKS_DEACTIVATE = 'ADMIN_BOOKS_DEACTIVATE'
export const ADMIN_BOOKS_GET_FICTION_TYPES = 'ADMIN_BOOKS_GET_FICTION_TYPES'
export const ADMIN_BOOKS_UPLOAD_BOOK_PAGE_MEDIA =
    'ADMIN_BOOKS_UPLOAD_BOOK_PAGE_MEDIA'
export const ADMIN_BOOKS_ADD_BOOK_PAGE_MEDIA = 'ADMIN_BOOKS_ADD_BOOK_PAGE_MEDIA'
export const ADMIN_BOOKS_DELETE_BOOK_PAGE_MEDIA =
    'ADMIN_BOOKS_DELETE_BOOK_PAGE_MEDIA'

//ADMIN PASSAGES
export const ADMIN_PASSAGES_ACTIVATE = 'ADMIN_PASSAGES_ACTIVATE'
export const ADMIN_PASSAGES_CREATE = 'ADMIN_PASSAGES_CREATE'
export const ADMIN_PASSAGES_DEACTIVATE = 'ADMIN_PASSAGES_DEACTIVATE'
export const ADMIN_PASSAGES_DELETE = 'ADMIN_PASSAGES_DELETE'
export const ADMIN_PASSAGES_DELETE_FILE = 'ADMIN_PASSAGES_DELETE_FILE'
export const ADMIN_PASSAGES_EDIT = 'ADMIN_PASSAGES_EDIT'
export const ADMIN_PASSAGES_GET_ALL = 'ADMIN_PASSAGES_GET_ALL'
export const ADMIN_PASSAGES_UPDATE = 'ADMIN_PASSAGES_UPDATE'
export const ADMIN_PASSAGES_UPLOAD_FILE = 'ADMIN_PASSAGES_UPLOAD_FILE'

//ADMIN LESSONS
export const ADMIN_LESSONS_CREATE = 'ADMIN_LESSONS_CREATE'
export const ADMIN_LESSONS_DELETE = 'ADMIN_LESSONS_DELETE'
export const ADMIN_LESSONS_DELETE_ACTIVITY_MEDIA =
    'ADMIN_LESSONS_DELETE_ACTIVITY_MEDIA'
export const ADMIN_LESSONS_DELETE_ACTIVITY_BLOCK_MEDIA =
    'ADMIN_LESSONS_DELETE_ACTIVITY_BLOCK_MEDIA'
export const ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_MEDIA =
    'ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_MEDIA'
export const ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_OPTION_MEDIA =
    'ADMIN_LESSONS_DELETE_ACTIVITY_QUESTION_OPTION_MEDIA'
export const ADMIN_LESSONS_DELETE_VOCAB_FILE = 'ADMIN_LESSONS_DELETE_VOCAB_FILE'
export const ADMIN_LESSONS_EDIT = 'ADMIN_LESSONS_EDIT'
export const ADMIN_LESSONS_GET_ACTIVITY_TYPES =
    'ADMIN_LESSONS_GET_ACTIVITY_TYPES'
export const ADMIN_LESSONS_GET_ALL = 'ADMIN_LESSONS_GET_ALL'
export const ADMIN_LESSONS_GET_BOOKS = 'ADMIN_LESSONS_GET_BOOKS'
export const ADMIN_LESSONS_GET_MEDIA_TYPES = 'ADMIN_LESSONS_GET_MEDIA_TYPES'
export const ADMIN_LESSONS_GET_PASSAGES = 'ADMIN_LESSONS_GET_PASSAGES'
export const ADMIN_LESSONS_GET_STANDARDS = 'ADMIN_LESSONS_GET_STANDARDS'
export const ADMIN_LESSONS_UPDATE = 'ADMIN_LESSONS_UPDATE'
export const ADMIN_LESSONS_UPLOAD_ACTIVITY_MEDIA =
    'ADMIN_LESSONS_UPLOAD_ACTIVITY_MEDIA'
export const ADMIN_LESSONS_UPLOAD_ACTIVITY_BLOCK_MEDIA =
    'ADMIN_LESSONS_UPLOAD_ACTIVITY_BLOCK_MEDIA'
export const ADMIN_LESSONS_UPLOAD_ACTIVITY_QUESTION_MEDIA =
    'ADMIN_LESSONS_UPLOAD_ACTIVITY_QUESTION_MEDIA'
export const ADMIN_LESSONS_UPLOAD_ACTIVITY_QUESTION_OPTION_MEDIA =
    'ADMIN_LESSONS_UPLOAD_ACTIVITY_QUESTION_OPTION_MEDIA'
export const ADMIN_LESSONS_UPLOAD_VOCAB_FILE = 'ADMIN_LESSONS_UPLOAD_VOCAB_FILE'
export const ADMIN_LESSONS_UPLOAD_MEDIA_FILE = 'ADMIN_LESSONS_UPLOAD_MEDIA_FILE'
export const ADMIN_LESSONS_DELETE_MEDIA_FILE = 'ADMIN_LESSONS_DELETE_MEDIA_FILE'
export const ADMIN_LESSONS_ADD_VOCABULARY_MEDIA =
    'ADMIN_LESSONS_ADD_VOCABULARY_MEDIA'
export const ADMIN_LESSONS_ADD_ACTIVITY_MEDIA =
    'ADMIN_LESSONS_ADD_ACTIVITY_MEDIA'
export const ADMIN_LESSONS_ADD_ACTIVITY_BLOCK_MEDIA =
    'ADMIN_LESSONS_ADD_ACTIVITY_BLOCK_MEDIA'
export const ADMIN_LESSONS_ADD_QUESTION_MEDIA =
    'ADMIN_LESSONS_ADD_QUESTION_MEDIA'
export const ADMIN_LESSONS_ADD_QUESTION_OPTION_MEDIA =
    'ADMIN_LESSONS_ADD_QUESTION_OPTION_MEDIA'
export const ADMIN_LESSONS_ACTIVATE = 'ADMIN_LESSONS_ACTIVATE'
export const ADMIN_LESSONS_DEACTIVATE = 'ADMIN_LESSONS_DEACTIVATE'
export const ADMIN_LESSONS_DUPLICATE = 'ADMIN_LESSONS_DUPLICATE'

//ADMIN MULTISITES
export const ADMIN_MULTISITES_CREATE = 'ADMIN_MULTISITES_CREATE'
export const ADMIN_MULTISITES_UPDATE = 'ADMIN_MULTISITES_UPDATE'
export const ADMIN_MULTISITES_GET_ALL = 'ADMIN_MULTISITES_GET_ALL'
export const ADMIN_MULTISITES_DELETE = 'ADMIN_MULTISITES_DELETE'
export const ADMIN_MULTISITES_GET = 'ADMIN_MULTISITES_GET'
export const ADMIN_MULTISITES_ADD_SCHOOL = 'ADMIN_MULTISITES_ADD_SCHOOL'
export const ADMIN_MULTISITES_REMOVE_SCHOOL = 'ADMIN_MULTISITES_REMOVE_SCHOOL'
export const ADMIN_MULTISITES_GET_ALL_SCHOOLS =
    'ADMIN_MULTISITES_GET_ALL_SCHOOLS'
//ADMIN SCHOOLS
export const ADMIN_SCHOOLS_CREATE = 'ADMIN_SCHOOLS_CREATE'
export const ADMIN_SCHOOLS_UPDATE = 'ADMIN_SCHOOLS_UPDATE'
export const ADMIN_SCHOOLS_DELETE = 'ADMIN_SCHOOLS_DELETE'
export const ADMIN_SCHOOLS_EDIT = 'ADMIN_SCHOOLS_EDIT'
export const ADMIN_SCHOOLS_GET_ALL_FEATURES = 'ADMIN_SCHOOLS_GET_ALL_FEATURES'
export const ADMIN_SCHOOLS_ADD_FEATURE_ACCESS =
    'ADMIN_SCHOOLS_ADD_FEATURE_ACCESS'
export const ADMIN_SCHOOLS_GET_ALL = 'ADMIN_SCHOOLS_GET_ALL'
export const ADMIN_SCHOOLS_REMOVE_FEATURE_ACCESS =
    'ADMIN_SCHOOLS_REMOVE_FEATURE_ACCESS'
export const ADMIN_SCHOOLS_GET_ALL_PUBLISHERS =
    'ADMIN_SCHOOLS_GET_ALL_PUBLISHERS'
export const ADMIN_SCHOOLS_ADD_PUBLISHER_ACCESS =
    'ADMIN_SCHOOLS_ADD_PUBLISHER_ACCESS'
export const ADMIN_SCHOOLS_REMOVE_PUBLISHER_ACCESS =
    'ADMIN_SCHOOLS_REMOVE_PUBLISHER_ACCESS'

//ADMIN STANDARDS
export const ADMIN_STANDARDS_CREATE = 'ADMIN_STANDARDS_CREATE'
export const ADMIN_STANDARDS_UPDATE = 'ADMIN_STANDARDS_UPDATE'
export const ADMIN_STANDARDS_GET_ALL = 'ADMIN_STANDARDS_GET_ALL'
export const ADMIN_STANDARDS_DELETE = 'ADMIN_STANDARDS_DELETE'
export const ADMIN_STANDARDS_EDIT = 'ADMIN_STANDARDS_EDIT'
export const ADMIN_STANDARDS_SWAP_STANDARD_SEQUENCES =
    'ADMIN_STANDARDS_SWAP_STANDARD_SEQUENCES'
export const ADMIN_STANDARDS_UPLOAD_MEDIA = 'ADMIN_STANDARDS_UPLOAD_MEDIA'
export const ADMIN_STANDARDS_ADD_MEDIA = 'ADMIN_STANDARDS_ADD_MEDIA'
export const ADMIN_STANDARDS_DELETE_MEDIA = 'ADMIN_STANDARDS_DELETE_MEDIA'
export const ADMIN_STANDARDS_UPLOAD_QUESTION_MEDIA =
    'ADMIN_STANDARDS_UPLOAD_QUESTION_MEDIA'
export const ADMIN_STANDARDS_ADD_QUESTION_MEDIA =
    'ADMIN_STANDARDS_ADD_QUESTION_MEDIA'
export const ADMIN_STANDARDS_DELETE_QUESTION_MEDIA =
    'ADMIN_STANDARDS_DELETE_QUESTION_MEDIA'
export const ADMIN_STANDARDS_GET_STANDARD_TYPES =
    'ADMIN_STANDARDS_GET_STANDARD_TYPES'

//ADMIN STUDENTS
export const ADMIN_STUDENTS_CREATE = 'ADMIN_STUDENTS_CREATE'
export const ADMIN_STUDENTS_SELECTED = 'ADMIN_STUDENT_SELECTED'
export const ADMIN_STUDENTS_EDIT = 'ADMIN_STUDENT_EDIT'
export const ADMIN_STUDENTS_UPDATE = 'ADMIN_STUDENT_UPDATE'
export const ADMIN_STUDENTS_ACTIVATE = 'ADMIN_STUDENT_ACTIVATE'
export const ADMIN_STUDENTS_DEACTIVATE = 'ADMIN_STUDENT_DEACTIVATE'
export const ADMIN_STUDENTS_GET_ALL = 'ADMIN_STUDENT_GET_ALL'
export const ADMIN_STUDENTS_GET_SCHOOLS = 'ADMIN_STUDENTS_GET_SCHOOLS'
export const ADMIN_STUDENTS_GET_COUNT = 'ADMIN_STUDENTS_GET_COUNT'

//ADMIN TUTORS
export const ADMIN_TUTORS_CREATE = 'ADMIN_TUTORS_CREATE'
export const ADMIN_TUTORS_EDIT = 'ADMIN_TUTORS_EDIT'
export const ADMIN_TUTORS_SELECT = 'ADMIN_TUTORS_SELECT'
export const ADMIN_TUTORS_SELECTED_TUTOR_GET_SCHOOLS =
    'ADMIN_TUTORS_SELECTED_TUTOR_GET_SCHOOLS'
export const ADMIN_TUTORS_SELECTED_TUTOR_GET_ADMIN_SCHOOLS =
    'ADMIN_TUTORS_SELECTED_TUTOR_GET_ADMIN_SCHOOLS'
export const ADMIN_TUTORS_SELECTED_TUTOR_GET_MULTISITES =
    'ADMIN_TUTORS_SELECTED_TUTOR_GET_MULTISITES'
export const ADMIN_TUTORS_UPDATE = 'ADMIN_TUTORS_UPDATE'
export const ADMIN_TUTORS_GET_TUTORS = 'ADMIN_TUTORS_GET_TUTORS'
export const ADMIN_TUTORS_ACTIVATE = 'ADMIN_TUTORS_ACTIVATE'
export const ADMIN_TUTORS_DEACTIVATE = 'ADMIN_TUTORS_DEACTIVATE'
export const ADMIN_TUTORS_MAKE_ADMIN = 'ADMIN_TUTORS_MAKE_ADMIN'
export const ADMIN_TUTORS_REMOVE_ADMIN = 'ADMIN_TUTORS_REMOVE_ADMIN'
export const ADMIN_TUTORS_ADD_STUDENT_ACCESS = 'ADMIN_TUTORS_ADD_STUDENT_ACCESS'
export const ADMIN_TUTORS_REMOVE_STUDENT_ACCESS =
    'ADMIN_TUTORS_REMOVE_STUDENT_ACCESS'
export const ADMIN_TUTORS_IMPERSONATE = 'ADMIN_TUTORS_IMPERSONATE'
export const ADMIN_TUTORS_GET_ALL = 'ADMIN_TUTORS_GET_ALL'
export const ADMIN_TUTORS_GET_MULTISITES = 'ADMIN_TUTORS_GET_MULTISITES'
export const ADMIN_TUTORS_GET_TUTOR_STUDENTS = 'ADMIN_TUTORS_GET_TUTOR_STUDENTS'
export const ADMIN_TUTORS_GET_TUTOR_STUDENTS_COUNT =
    'ADMIN_TUTORS_GET_TUTOR_STUDENTS_COUNT'
export const ADMIN_TUTORS_GET_ALL_STUDENTS = 'ADMIN_TUTORS_GET_ALL_STUDENTS'
export const ADMIN_TUTORS_ADD_SCHOOL = 'ADMIN_TUTORS_ADD_SCHOOL'
export const ADMIN_TUTORS_REMOVE_SCHOOL = 'ADMIN_TUTORS_REMOVE_SCHOOL'
export const ADMIN_TUTORS_GET_SCHOOLS = 'ADMIN_TUTORS_GET_SCHOOLS'
export const ADMIN_TUTORS_ADD_MULTISITE = 'ADMIN_TUTORS_ADD_MULTISITE'
export const ADMIN_TUTORS_REMOVE_MULTISITE = 'ADMIN_TUTORS_REMOVE_MULTISITE'
export const ADMIN_TUTORS_MAKE_SCHOOL_ADMIN = 'ADMIN_TUTORS_MAKE_SCHOOL_ADMIN'
export const ADMIN_TUTORS_REMOVE_SCHOOL_ADMIN =
    'ADMIN_TUTORS_REMOVE_SCHOOL_ADMIN'
export const ADMIN_TUTORS_GET_TUTOR_TYPES = 'ADMIN_TUTORS_GET_TUTOR_TYPES'
export const ADMIN_TUTORS_GET_COUNT = 'ADMIN_TUTORS_GET_COUNT'
export const ADMIN_TUTORS_GET_PUBLISHERS = 'ADMIN_TUTORS_GET_PUBLISHERS'
export const ADMIN_TUTORS_SELECTED_TUTOR_GET_PUBLISHER_ADMIN =
    'ADMIN_TUTORS_SELECTED_TUTOR_GET_PUBLISHER_ADMIN'

//ADMIN REPORTS
export const ADMIN_REPORTS_GET_STUDENT_PROGRESS_REPORT =
    'ADMIN_REPORTS_GET_STUDENT_PROGRESS_REPORT'
export const ADMIN_REPORTS_GET_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT =
    'ADMIN_REPORTS_GET_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT'
export const ADMIN_REPORTS_GET_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT =
    'ADMIN_REPORTS_GET_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT'
export const ADMIN_REPORTS_GET_USAGE_REPORT = 'ADMIN_REPORTS_GET_USAGE_REPORT'
export const ADMIN_REPORTS_GET_USAGE_DAILY_ROLLUP_REPORT =
    'ADMIN_REPORTS_GET_USAGE_DAILY_ROLLUP_REPORT'
export const ADMIN_REPORTS_GET_USAGE_WEEKLY_ROLLUP_REPORT =
    'ADMIN_REPORTS_GET_USAGE_WEEKLY_ROLLUP_REPORT'
export const ADMIN_REPORTS_PLATFORM_METRICS = 'ADMIN_REPORTS_PLATFORM_METRICS'
export const ADMIN_REPORTS_SCHOOL_METRICS = 'ADMIN_REPORTS_SCHOOL_METRICS'

//MANAGE SCHOOL
export const MANAGE_SCHOOL_SET_SCHOOL = 'MANAGE_SCHOOL_SET_SCHOOL'
export const MANAGE_SCHOOL_MAKE_SCHOOL_TEACHER =
    'MANAGE_SCHOOL_MAKE_SCHOOL_TEACHER'
export const MANAGE_SCHOOL_REMOVE_SCHOOL_TEACHER =
    'MANAGE_SCHOOL_REMOVE_SCHOOL_TEACHER'
export const MANAGE_SCHOOL_GET_LESSONS_FOR_STANDARD =
    'MANAGE_SCHOOL_GET_LESSONS_FOR_STANDARD'
export const MANAGE_SCHOOL_GET_STUDENT_GROUPS =
    'MANAGE_SCHOOL_GET_STUDENT_GROUPS'
export const MANAGE_SCHOOL_GET_SELECTED_STUDENT_GROUP =
    'MANAGE_SCHOOL_GET_SELECTED_STUDENT_GROUP'
export const MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT_GROUP =
    'MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT_GROUP'
export const MANAGE_SCHOOL_DELETE_SELECTED_STUDENT_GROUP =
    'MANAGE_SCHOOL_DELETE_SELECTED_STUDENT_GROUP'
export const MANAGE_SCHOOL_ADD_STUDENT_TO_SELECTED_GROUP =
    'MANAGE_SCHOOL_ADD_STUDENT_TO_SELECTED_GROUP'
export const MANAGE_SCHOOL_REMOVE_STUDENT_FROM_SELECTED_GROUP =
    'MANAGE_SCHOOL_REMOVE_STUDENT_FROM_SELECTED_GROUP'
export const MANAGE_SCHOOL_REMOVE_ALL_STUDENTS_FROM_SELECTED_GROUP =
    'MANAGE_SCHOOL_REMOVE_ALL_STUDENTS_FROM_SELECTED_GROUP'
export const MANAGE_SCHOOL_CREATE_STUDENT_GROUP =
    'MANAGE_SCHOOL_CREATE_STUDENT_GROUP'
export const MANAGE_SCHOOL_RESET_NEW_GROUP = 'MANAGE_SCHOOL_RESET_NEW_GROUP'
export const MANAGE_SCHOOL_ADD_STUDENT_TO_NEW_GROUP =
    'MANAGE_SCHOOL_ADD_STUDENT_TO_NEW_GROUP'
export const MANAGE_SCHOOL_REMOVE_STUDENT_FROM_NEW_GROUP =
    'MANAGE_SCHOOL_REMOVE_STUDENT_FROM_NEW_GROUP'
export const MANAGE_SCHOOL_GET_STUDENTS = 'MANAGE_SCHOOL_GET_STUDENTS'
export const MANAGE_SCHOOL_GET_STUDENT_COUNT = 'MANAGE_SCHOOL_GET_STUDENT_COUNT'
export const MANAGE_SCHOOL_DELETE_STUDENTS = 'MANAGE_SCHOOL_DELETE_STUDENTS'
export const MANAGE_SCHOOL_UNENROLL_STUDENTS = 'MANAGE_SCHOOL_UNENROLL_STUDENTS'
export const MANAGE_SCHOOL_UNENROLL_ALL_STUDENTS =
    'MANAGE_SCHOOL_UNENROLL_ALL_STUDENTS'
export const MANAGE_SCHOOL_ENROLL_ALL_STUDENTS =
    'MANAGE_SCHOOL_ENROLL_ALL_STUDENTS'
export const MANAGE_SCHOOL_GET_TEACHERS = 'MANAGE_SCHOOL_GET_TEACHERS'
export const MANAGE_SCHOOL_CREATE_STUDENT = 'MANAGE_SCHOOL_CREATE_STUDENT'
export const MANAGE_SCHOOL_GET_SELECTED_STUDENT =
    'MANAGE_SCHOOL_GET_SELECTED_STUDENT'
export const MANAGE_SCHOOL_GET_SELECTED_STUDENT_STANDARDS =
    'MANAGE_SCHOOL_GET_SELECTED_STUDENT_STANDARDS'
export const MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT =
    'MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT'
export const MANAGE_SCHOOL_DELETE_SELECTED_STUDENT =
    'MANAGE_SCHOOL_DELETE_SELECTED_STUDENT'
export const MANAGE_SCHOOL_GET_SELECTED_LESSON =
    'MANAGE_SCHOOL_GET_SELECTED_LESSON'
export const MANAGE_SCHOOL_GET_SESSIONS = 'MANAGE_SCHOOL_GET_SESSIONS'
export const MANAGE_SCHOOL_GET_SELECTED_SESSION =
    'MANAGE_SCHOOL_GET_SELECTED_SESSION'
export const MANAGE_SCHOOL_GET_SELECTED_SESSION_READING_LEVEL_CHANGES =
    'MANAGE_SCHOOL_GET_SELECTED_SESSION_READING_LEVEL_CHANGES'
export const MANAGE_SCHOOL_SET_SELECTED_SESSION_STUDENT =
    'MANAGE_SCHOOL_SET_SELECTED_SESSION_STUDENT'
export const MANAGE_SCHOOL_GET_GUIDES = 'MANAGE_SCHOOL_GET_GUIDES'
export const MANAGE_SCHOOL_GET_ALL_GUIDES = 'MANAGE_SCHOOL_GET_ALL_GUIDES'
export const MANAGE_SCHOOL_CREATE_OR_UPDATE_GUIDE =
    'MANAGE_SCHOOL_CREATE_OR_UPDATE_GUIDE'
export const MANAGE_SCHOOL_REMOVE_GUIDE = 'MANAGE_SCHOOL_REMOVE_GUIDE'
export const MANAGE_SCHOOL_GET_SELECTED_GUIDE =
    'MANAGE_SCHOOL_GET_SELECTED_GUIDE'
export const MANAGE_SCHOOL_UPDATE_SELECTED_GUIDE =
    'MANAGE_SCHOOL_UPDATE_SELECTED_GUIDE'
export const MANAGE_SCHOOL_GET_SELECTED_GUIDE_STUDENTS =
    'MANAGE_SCHOOL_GET_SELECTED_GUIDE_STUDENTS'
export const MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS =
    'MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS'
export const MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS_BATCH =
    'MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS_BATCH'
export const MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS =
    'MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS'
export const MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS_BATCH =
    'MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS_BATCH'
export const MANAGE_SCHOOL_USAGE_REPORT = 'MANAGE_SCHOOL_USAGE_REPORT'
export const MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT =
    'MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT'
export const MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT =
    'MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT'
export const MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT =
    'MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT'
export const MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT =
    'MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT'
export const MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT =
    'MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT'
export const MANAGE_SCHOOL_READING_LEVEL_REPORT =
    'MANAGE_SCHOOL_READING_LEVEL_REPORT'
export const MANAGE_SCHOOL_STUDENT_READING_LEVEL_ROLLUP_REPORT =
    'MANAGE_SCHOOL_STUDENT_READING_LEVEL_ROLLUP_REPORT'
export const MANAGE_SCHOOL_STRUGGLING_REPORT = 'MANAGE_SCHOOL_STRUGGLING_REPORT'
export const MANAGE_SCHOOL_GET_STANDARDS = 'MANAGE_SCHOOL_GET_STANDARDS'
export const MANAGE_SCHOOL_GET_TUTOR_TYPES = 'MANAGE_SCHOOL_GET_TUTOR_TYPES'
export const MANAGE_SCHOOL_RESET_GUIDE = 'MANAGE_SCHOOL_RESET_GUIDE'
export const MANAGE_SCHOOL_IMPERSONATE_STUDENT =
    'MANAGE_SCHOOL_IMPERSONATE_STUDENT'
export const MANAGE_SCHOOL_ENROLL_STUDENT = 'MANAGE_SCHOOL_ENROLL_STUDENT'
export const MANAGE_SCHOOL_UNENROLL_STUDENT = 'MANAGE_SCHOOL_UNENROLL_STUDENT'
export const MANAGE_SCHOOL_RESET = 'MANAGE_SCHOOL_RESET'
export const MANAGE_SCHOOL_GET_STUDENTS_NEED_STANDARD =
    'MANAGE_SCHOOL_GET_STUDENTS_NEED_STANDARD'
export const MANAGE_SCHOOL_SESSION_TYPES_REPORT =
    'MANAGE_SCHOOL_SESSION_TYPES_REPORT'
export const MANAGE_SCHOOL_GET_MULTISITES = 'MANAGE_SCHOOL_GET_MULTISITES'
export const MANAGE_SCHOOL_MULTISITE_USAGE_REPORT =
    'MANAGE_SCHOOL_MULTISITE_USAGE_REPORT'
export const MANAGE_SCHOOL_MULTISITE_STRUGGLING_REPORT =
    'MANAGE_SCHOOL_MULTISITE_STRUGGLING_REPORT'
export const MANAGE_SCHOOL_MULTISITE_STUDENT_PROGRESS_REPORT =
    'MANAGE_SCHOOL_MULTISITE_STUDENT_PROGRESS_REPORT'
export const MANAGE_SCHOOL_MULTISITE_READING_LEVEL_REPORT =
    'MANAGE_SCHOOL_MULTISITE_READING_LEVEL_REPORT'
export const MANAGE_SCHOOL_MULTISITE_SESSION_TYPES_REPORT =
    'MANAGE_SCHOOL_MULTISITE_SESSION_TYPES_REPORT'
export const MANAGE_SCHOOL_MULTISITE_SCHOOL_USAGE_REPORTS =
    'MANAGE_SCHOOL_MULTISITE_SCHOOL_USAGE_REPORTS'
export const MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_STRUGGLING_REPORT =
    'MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_STRUGGLING_REPORT'
export const MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_REPORT =
    'MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_REPORT'

//IMPORT STUDENTS
export const IMPORT_STUDENTS_DATA_SELECTED = 'IMPORT_STUDENTS_DATA_SELECTED'
export const IMPORT_STUDENTS_COLUMN_MAPPINGS_SELECTED =
    'IMPORT_STUDENTS_COLUMN_MAPPINGS_SELECTED'
export const IMPORT_STUDENTS_READING_SCALE_SELECTED =
    'IMPORT_STUDENTS_READING_SCALE_SELECTED'
export const IMPORT_STUDENTS_RESET = 'IMPORT_STUDENTS_RESET'
export const IMPORT_STUDENTS_IMPORT_STUDENT = 'IMPORT_STUDENTS_IMPORT_STUDENT'
export const IMPORT_STUDENTS_SET_SUMMARY = 'IMPORT_STUDENTS_SET_SUMMARY'
export const IMPORT_STUDENTS_SET_USE_DEFAULT_READING_LEVELS =
    'IMPORT_STUDENTS_SET_USE_DEFAULT_READING_LEVELS'

//IMPORT GUIDES
export const IMPORT_GUIDES_DATA_SELECTED = 'IMPORT_GUIDES_DATA_SELECTED'
export const IMPORT_GUIDES_COLUMN_MAPPINGS_SELECTED =
    'IMPORT_GUIDES_COLUMN_MAPPINGS_SELECTED'
export const IMPORT_GUIDES_RESET = 'IMPORT_GUIDES_RESET'
export const IMPORT_GUIDES_IMPORT_GUIDE = 'IMPORT_GUIDES_IMPORT_GUIDE'
export const IMPORT_GUIDES_SET_SUMMARY = 'IMPORT_GUIDES_SET_SUMMARY'
export const IMPORT_GUIDES_GET_GUIDE_TYPES = 'IMPORT_GUIDES_GET_GUIDE_TYPES'

//SESSION SETUP
export const SESSION_SETUP_ADD_AVAILABLE_STUDENT =
    'SESSION_SETUP_ADD_AVAILABLE_STUDENT'
export const SESSION_SETUP_ADD_SELECTED_STUDENT =
    'SESSION_SETUP_ADD_SELECTED_STUDENT'
export const SESSION_SETUP_CLEAR_AVAILABLE_STUDENTS =
    'SESSION_SETUP_CLEAR_AVAILABLE_STUDENTS'
export const SESSION_SETUP_REMOVE_AVAILABLE_STUDENT =
    'SESSION_SETUP_REMOVE_AVAILABLE_STUDENT'
export const SESSION_SETUP_REMOVE_SELECTED_STUDENT =
    'SESSION_SETUP_REMOVE_SELECTED_STUDENT'
export const SESSION_SETUP_RESET = 'SESSION_SETUP_RESET'
export const SESSION_SETUP_SET_AVAILABLE_LESSONS =
    'SESSION_SETUP_SET_AVAILABLE_LESSONS'
export const SESSION_SETUP_SET_STUDENTS_FOR_SCHOOL =
    'SESSION_SETUP_SET_STUDENTS_FOR_SCHOOL'
export const SESSION_SETUP_ADD_STUDENT_FOR_SCHOOL =
    'SESSION_SETUP_ADD_STUDENT_FOR_SCHOOL'
export const SESSION_SETUP_SET_GROUP_RECOMMENDATIONS =
    'SESSION_SETUP_SET_GROUP_RECOMMENDATIONS'
export const SESSION_SETUP_SET_LESSON_TYPES = 'SESSION_SETUP_SET_LESSON_TYPES'
export const SESSION_SETUP_SET_PREVIOUS_SESSION_GROUPS =
    'SESSION_SETUP_SET_PREVIOUS_SESSION_GROUPS'
export const SESSION_SETUP_SET_STUDENT_GROUPS =
    'SESSION_SETUP_SET_STUDENT_GROUPS'
export const SESSION_SETUP_CREATE_STUDENT = 'SESSION_SETUP_CREATE_STUDENT'
export const SESSION_SETUP_CREATE_STUDENT_GROUP =
    'SESSION_SETUP_CREATE_STUDENT_GROUP'
export const SESSION_SETUP_SELECT_STUDENT_GROUP =
    'SESSION_SETUP_SELECT_STUDENT_GROUP'
export const SESSION_SETUP_REMOVE_STUDENT_GROUP =
    'SESSION_SETUP_REMOVE_STUDENT_GROUP'
export const SESSION_SETUP_SET_READING_LEVELS =
    'SESSION_SETUP_SET_READING_LEVELS'
export const SESSION_SETUP_SET_RECOMMENDED_LESSON =
    'SESSION_SETUP_SET_RECOMMENDED_LESSON'
export const SESSION_SETUP_SET_RECOMMENDED_LESSON_FOR_CLASSROOM_GROUP =
    'SESSION_SETUP_SET_RECOMMENDED_LESSON_FOR_CLASSROOM_GROUP'
export const SESSION_SETUP_SET_SELECTED_LESSON =
    'SESSION_SETUP_SET_SELECTED_LESSON'
export const SESSION_SETUP_SET_SELECTED_LESSON_BY_ID =
    'SESSION_SETUP_SET_SELECTED_LESSON_BY_ID'
export const SESSION_SETUP_SET_SELECTED_PUBLISHER =
    'SESSION_SETUP_SET_SELECTED_PUBLISHER'
export const SESSION_SETUP_SET_SELECTED_SESSION_TYPE =
    'SESSION_SETUP_SET_SELECTED_SESSION_TYPE'
export const SESSION_SETUP_SET_SESSION_TYPES = 'SESSION_SETUP_SET_SESSION_TYPES'
export const SESSION_SETUP_SET_STANDARDS = 'SESSION_SETUP_SET_STANDARDS'

//SESSION
export const SESSION_SET = 'SESSION_SET'
export const SESSION_REMOVE_STUDENTS = 'SESSION_REMOVE_STUDENTS'
export const SESSION_CONNECTION_AUTH = 'SESSION_CONNECTION_AUTH'
export const SESSION_GET_FOR_STUDENT = 'SESSION_GET_FOR_STUDENT'
export const SESSION_STATE_SET_CHECKPOINT = 'SESSION_STATE_SET_CHECKPOINT'
export const SESSION_ALERT_TEACHER = 'SESSION_ALERT_TEACHER'
export const SESSION_DISMISS_ALERT = 'SESSION_DISMISS_ALERT'
export const SESSION_UPDATE_STUDENT_STATUS = 'SESSION_UPDATE_STUDENT_STATUS'
export const SESSION_RESET_STUDENT_STATUS_TIMEOUT =
    'SESSION_RESET_STUDENT_STATUS_TIMEOUT'
export const SESSION_RESET_STUDENT_NEEDS_ATTENTION =
    'SESSION_RESET_STUDENT_NEEDS_ATTENTION'
export const SESSION_ACKNOWLEDGE_STUDENT_STATUS_TIMEOUT_RESET =
    'SESSION_ACKNOWLEDGE_STUDENT_STATUS_TIMEOUT_RESET'
export const SESSION_SYNC_STUDENTS = 'SESSION_SYNC_STUDENTS'
export const SESSION_STUDENT_SYNC_READY = 'SESSION_STUDENT_SYNC_READY'
export const SESSION_END = 'SESSION_END'
export const SESSION_DELETE = 'SESSION_DELETE'
export const SESSION_COMPLETE = 'SESSION_COMPLETE'
export const SESSION_LAST_SEEN = 'SESSION_LAST_SEEN'
export const SESSION_ADD_FEATURE = 'SESSION_ADD_FEATURE'
export const SESSION_SUBMIT_STUDENT_FEEDBACK = 'SESSION_SUBMIT_STUDENT_FEEDBACK'
export const SESSION_GET_FEEDBACK_TYPES = 'SESSION_GET_FEEDBACK_TYPES'
export const SESSION_UPDATE_STUDENT_FEEDBACK_START_FEELING =
    'SESSION_UPDATE_STUDENT_FEEDBACK_START_FEELING'
export const SESSION_SUBMIT_STRUGGLING_ACTIVITY =
    'SESSION_SUBMIT_STRUGGLING_ACTIVITY'
export const SESSION_DELETE_STRUGGLING_ACTIVITY =
    'SESSION_DELETE_STRUGGLING_ACTIVITY'
export const SESSION_STUDENT_UPDATE_LAST_CHECKPOINT =
    'SESSION_STUDENT_UPDATE_LAST_CHECKPOINT'

//DEMO SESSION
export const SESSION_DEMO_CONFIGURE = 'SESSION_DEMO_CONFIGURE'
export const SESSION_DEMO_STUDENT_PROGRESS = 'SESSION_DEMO_STUDENT_PROGRESS'

//SESSION GROUP
export const SESSION_GROUP_SET = 'SESSION_GROUP_SET'
export const SESSION_GROUP_END = 'SESSION_GROUP_END'
export const SESSION_GROUP_CONNECTION_AUTH = 'SESSION_GROUP_CONNECTION_AUTH'
export const SESSION_GROUP_GET_SESSION = 'SESSION_GROUP_GET_SESSION'
export const SESSION_GROUP_SET_SESSION = 'SESSION_GROUP_SET_SESSION'
export const SESSION_GROUP_COMPLETE = 'SESSION_GROUP_COMPLETE'
export const SESSION_GROUP_END_SESSIONS = 'SESSION_GROUP_END_SESSIONS'

//SESSION LEVELING
export const SESSION_LEVELING_SELECT_NEXT_STUDENT =
    'SESSION_LEVELING_SELECT_NEXT_STUDENT'
export const SESSION_LEVELING_SKIP_STUDENT = 'SESSION_LEVELING_SKIP_STUDENT'
export const SESSION_LEVELING_UPDATE_RECORD = 'SESSION_LEVELING_UPDATE_RECORD'
export const SESSION_LEVELING_NAVIGATE_TO_COMPREHENSION =
    'SESSION_LEVELING_NAVIGATE_TO_COMPREHENSION'
export const SESSION_LEVELING_UPDATE_ASSESSMENT_PROGRESS =
    'SESSION_LEVELING_UPDATE_ASSESSMENT_PROGRESS'
export const SESSION_LEVELING_GET_PASSAGE = 'SESSION_LEVELING_GET_PASSAGE'
export const SESSION_LEVELING_COMPLETE_SCORE = 'SESSION_LEVELING_COMPLETE_SCORE'
export const SESSION_LEVELING_GET_SCORE = 'SESSION_LEVELING_GET_SCORE'
export const SESSION_LEVELING_GET_PASSAGE_QUESTION_RESPONSES =
    'SESSION_LEVELING_GET_PASSAGE_QUESTION_RESPONSES'
export const SESSION_LEVELING_SET_SCORE_ACCURACY =
    'SESSION_LEVELING_SET_SCORE_ACCURACY'
export const SESSION_LEVELING_SUBMIT_PASSAGE_QUESTION_RESPONSE =
    'SESSION_LEVELING_SUBMIT_PASSAGE_QUESTION_RESPONSE'

//SESSION VOCABULARY
export const SESSION_VOCABULARY_NAVIGATE_TO_ASSESSMENT =
    'SESSION_VOCABULARY_NAVIGATE_TO_ASSESSMENT'
export const SESSION_VOCABULARY_NAVIGATE_TO_GAME =
    'SESSION_VOCABULARY_NAVIGATE_TO_GAME'
export const SESSION_VOCABULARY_SUBMIT_GAME_QUESTION_SCORE =
    'SESSION_VOCABULARY_SUBMIT_GAME_QUESTION_SCORE'
export const SESSION_VOCABULARY_SUBMIT_QUESTION_RESPONSE =
    'SESSION_VOCABULARY_SUBMIT_QUESTION_RESPONSE'
export const SESSION_VOCABULARY_UPDATE_ASSESSMENT_PROGRESS =
    'SESSION_VOCABULARY_UPDATE_ASSESSMENT_PROGRESS'
export const SESSION_VOCABULARY_UPDATE_COMPLETED_FLASH_CARDS =
    'SESSION_VOCABULARY_UPDATE_COMPLETED_FLASH_CARDS'
export const SESSION_VOCABULARY_UPDATE_FLASH_CARDS_OUTRO_SCORE_UPDATED =
    'SESSION_VOCABULARY_UPDATE_FLASH_CARDS_OUTRO_SCORE_UPDATED'
export const SESSION_VOCABULARY_UPDATE_GAME_PROGRESS =
    'SESSION_VOCABULARY_UPDATE_GAME_PROGRESS'
export const SESSION_VOCABULARY_UPDATE_MATCHING_GAME_SUCCESS_COUNT =
    'SESSION_VOCABULARY_UPDATE_MATCHING_GAME_SUCCESS_COUNT'
export const SESSION_VOCABULARY_UPDATE_MATCHING_GAME_COMPLETE =
    'SESSION_VOCABULARY_UPDATE_MATCHING_GAME_COMPLETE'
export const SESSION_VOCABULARY_UPDATE_MATCHING_GAME_OUTRO_SCORE_UPDATED =
    'SESSION_VOCABULARY_UPDATE_MATCHING_GAME_OUTRO_SCORE_UPDATED'
export const SESSION_VOCABULARY_UPDATE_DISCUSSION_COMPLETE =
    'SESSION_VOCABULARY_UPDATE_DISCUSSION_COMPLETE'
export const SESSION_VOCABULARY_UPDATE_ASSESSMENT_OUTRO_SCORE_UPDATED =
    'SESSION_VOCABULARY_UPDATE_ASSESSMENT_OUTRO_SCORE_UPDATED'
export const SESSION_VOCABULARY_UPDATE_STUDENT_BONUS_GAME =
    'SESSION_VOCABULARY_UPDATE_STUDENT_BONUS_GAME'
export const SESSION_VOCABULARY_GET_RECENT_GAME_QUESTIONS =
    'SESSION_VOCABULARY_GET_RECENT_GAME_QUESTIONS'

//SESSION FLUENCY
export const SESSION_FLUENCY_UPDATE_COMPLETED_FLASH_CARDS =
    'SESSION_FLUENCY_UPDATE_COMPLETED_FLASH_CARDS'
export const SESSION_FLUENCY_UPDATE_STUDENT_PASSAGE_HEARD =
    'SESSION_FLUENCY_UPDATE_STUDENT_PASSAGE_HEARD'
export const SESSION_FLUENCY_UPDATE_STUDENT_PASSAGE_RATING =
    'SESSION_FLUENCY_UPDATE_STUDENT_PASSAGE_RATING'

//SESSION COMPREHENSION
export const SESSION_COMPREHENSION_NAVIGATE_TO_ASSESSMENT =
    'SESSION_COMPREHENSION_NAVIGATE_TO_ASSESSMENT'
export const SESSION_COMPREHENSION_NAVIGATE_TO_GAME =
    'SESSION_COMPREHENSION_NAVIGATE_TO_GAME'
export const SESSION_COMPREHENSION_SUBMIT_GAME_QUESTION_RESPONSE =
    'SESSION_COMPREHENSION_SUBMIT_GAME_QUESTION_RESPONSE'
export const SESSION_COMPREHENSION_SUBMIT_GAME_QUESTION_SCORE =
    'SESSION_COMPREHENSION_SUBMIT_GAME_QUESTION_SCORE'
export const SESSION_COMPREHENSION_SUBMIT_QUESTION_RESPONSE =
    'SESSION_COMPREHENSION_SUBMIT_QUESTION_RESPONSE'
export const SESSION_COMPREHENSION_SUBMIT_STANDARD_QUESTION_RESPONSE =
    'SESSION_COMPREHENSION_SUBMIT_STANDARD_QUESTION_RESPONSE'
export const SESSION_COMPREHENSION_UPDATE_ASSESSMENT_OUTRO_SCORE_UPDATED =
    'SESSION_COMPREHENSION_UPDATE_ASSESSMENT_OUTRO_SCORE_UPDATED'
export const SESSION_COMPREHENSION_UPDATE_ASSESSMENT_PROGRESS =
    'SESSION_COMPREHENSION_UPDATE_ASSESSMENT_PROGRESS'
export const SESSION_COMPREHENSION_UPDATE_BOOK_OUTRO_SCORE_UPDATED =
    'SESSION_COMPREHENSION_UPDATE_BOOK_OUTRO_SCORE_UPDATED'
export const SESSION_COMPREHENSION_UPDATE_CURRENT_PAGE =
    'SESSION_COMPREHENSION_UPDATE_CURRENT_PAGE'
export const SESSION_COMPREHENSION_UPDATE_GAME_PROGRESS =
    'SESSION_COMPREHENSION_UPDATE_GAME_PROGRESS'
export const SESSION_COMPREHENSION_UPDATE_SELECTED_STUDENT =
    'SESSION_COMPREHENSION_UPDATE_SELECTED_STUDENT'
export const SESSION_COMPREHENSION_UPDATE_STUDENT_BOOK_RATING =
    'SESSION_COMPREHENSION_UPDATE_STUDENT_BOOK_RATING'
export const SESSION_COMPREHENSION_UPDATE_STANDARD_QUESTIONS_STUDENT_PROGRESS =
    'SESSION_COMPREHENSION_UPDATE_STANDARD_QUESTIONS_STUDENT_PROGRESS'
export const SESSION_COMPREHENSION_UPDATE_STANDARD_QUESTIONS_COMPLETED_PROGRESS =
    'SESSION_COMPREHENSION_UPDATE_STANDARD_QUESTIONS_COMPLETED_PROGRESS'

//SESSION FLUENCY
export const SESSION_FLUENCY_NAVIGATE_TO_GAME =
    'SESSION_FLUENCY_NAVIGATE_TO_GAME'
export const SESSION_FLUENCY_SET_FLUENCY_TYPES =
    'SESSION_FLUENCY_SET_FLUENCY_TYPES'
export const SESSION_FLUENCY_SUBMIT_GAME_QUESTION_SCORE =
    'SESSION_FLUENCY_SUBMIT_GAME_QUESTION_SCORE'
export const SESSION_FLUENCY_UPDATE_GAME_PROGRESS =
    'SESSION_FLUENCY_UPDATE_GAME_PROGRESS'
export const SESSION_FLUENCY_NAVIGATE_TO_READING_INSTRUCTIONS =
    'SESSION_FLUENCY_NAVIGATE_TO_READING_INSTRUCTIONS'
export const SESSION_FLUENCY_GET_READING_PASSAGE =
    'SESSION_FLUENCY_GET_READING_PASSAGE'
export const SESSION_FLUENCY_UPDATE_READING_RECORD =
    'SESSION_FLUENCY_UPDATE_READING_RECORD'
export const SESSION_FLUENCY_SELECT_NEXT_READING_STUDENT =
    'SESSION_FLUENCY_SELECT_NEXT_READING_STUDENT'
export const SESSION_FLUENCY_SKIP_READING_STUDENT =
    'SESSION_FLUENCY_SKIP_READING_STUDENT'
export const SESSION_FLUENCY_SET_READING_SCORE =
    'SESSION_FLUENCY_SET_READING_SCORE'
export const SESSION_FLUENCY_GET_READING_SCORE =
    'SESSION_FLUENCY_GET_READING_SCORE'
export const SESSION_FLUENCY_NAVIGATE_TO_READING_RESULTS =
    'SESSION_FLUENCY_NAVIGATE_TO_READING_RESULTS'

//SESSION K-ONE
export const SESSION_K_ONE_NAVIGATE_TO_NEXT_ACTIVITY =
    'SESSION_K_ONE_NAVIGATE_TO_NEXT_ACTIVITY'
export const SESSION_K_ONE_SET_ACTIVITY_CHECKPOINT =
    'SESSION_K_ONE_SET_ACTIVITY_CHECKPOINT'
export const SESSION_K_ONE_SET_ACTIVITY_PROGRESS =
    'SESSION_K_ONE_SET_ACTIVITY_PROGRESS'
export const SESSION_K_ONE_SET_ACTIVITY_STUDENT_PROGRESS =
    'SESSION_K_ONE_SET_ACTIVITY_STUDENT_PROGRESS'
export const SESSION_K_ONE_SET_BOOK_ACTIVITY_PAGE =
    'SESSION_K_ONE_SET_BOOK_ACTIVITY_PAGE'
export const SESSION_K_ONE_SUBMIT_ACTIVITY_QUESTION_RESPONSE =
    'SESSION_K_ONE_SUBMIT_ACTIVITY_QUESTION_RESPONSE'

//SESSION WRAPUP
export const SESSION_WRAPUP_NAVIGATE_TO_DOCUMENTATION =
    'SESSION_WRAPUP_NAVIGATE_TO_DOCUMENTATION'
export const SESSION_WRAPUP_UPDATE_STUDENT_PROGRESS =
    'SESSION_WRAPUP_UPDATE_STUDENT_PROGRESS'
export const SESSION_WRAPUP_SUBMIT_NOTES = 'SESSION_WRAPUP_SUBMIT_NOTES'
export const SESSION_WRAPUP_SUBMIT_BOOK_RATING =
    'SESSION_WRAPUP_SUBMIT_BOOK_RATING'
export const SESSION_WRAPUP_GET_COMPLETED_SESSION =
    'SESSION_WRAPUP_GET_COMPLETED_SESSION'
export const SESSION_WRAPUP_GET_SESSION_READING_LEVEL_CHANGES =
    'SESSION_WRAPUP_GET_SESSION_READING_LEVEL_CHANGES'
export const SESSION_WRAPUP_COMPLETE = 'SESSION_WRAPUP_COMPLETE'

//STUDENT
export const STUDENT_GET_STANDARDS = 'STUDENT_GET_STANDARDS'
export const STUDENT_GET_GAME_HIGH_SCORES = 'STUDENT_GET_GAME_HIGH_SCORES'

// NETWORK TEST
export const NETWORK_TEST_GET_BOOKS = 'NETWORK_TEST_GET_BOOKS'

// TOAST
export const ADD_TOAST = 'NOTIFICATION_ADD_TOAST'

export const LOAD_PDF_BOOK = 'LOAD_PDF_BOOK'

// PROGRAMS
export const GET_LIST_PROGRAMS = 'GET_LIST_PROGRAMS'
export const GET_PROGRAM = 'GET_PROGRAM'
export const CREATE_PROGRAM = 'CREATE_PROGRAM'
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM'
export const REMOVE_PROGRAM = 'REMOVE_PROGRAM'
