import PropTypes from 'prop-types'
import React, { Component } from 'react'

const propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    isFormBusy: PropTypes.bool,
}

class Form extends Component {
    constructor() {
        super()
        this.onSubmit = this.onSubmit.bind(this)
        this.allowSubmit = this.allowSubmit.bind(this)
        this.state = { isValid: false }
    }

    onSubmit(e) {
        e.preventDefault()

        const formValid = this.isFormValid()
        if (this.props.onFormSubmit && formValid && !this.props.isFormBusy) {
            const formFields = this.getFormFields()
            this.props.onFormSubmit(formValid, formFields)
        }
    }

    onFieldChange(field) {
        return (value, valid) => {
            field.value = value
            field.valid = valid

            const formValid = this.isFormValid()
            if (formValid !== this.state.formValid) {
                this.setState({ isValid: formValid })
            }
        }
    }

    isFormValid() {
        const fields = Object.keys(this.props.fields)
        return fields.every(f => this.props.fields[f].valid)
    }

    getFormFields() {
        let result = {}
        const fields = Object.keys(this.props.fields)

        fields.forEach(f => {
            result[f] = this.props.fields[f].value
        })

        return result
    }

    allowSubmit() {
        return this.state.isValid && !this.props.isFormBusy
    }

    render() {
        return <div />
    }
}

Form.propTypes = propTypes

export default Form
