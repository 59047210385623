import PropTypes from 'prop-types'
import InputFormField from '../input-form-field'

const propTypes = {
    accept: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onValidChange: PropTypes.func,
    validationError: PropTypes.string,
}

const defaultProps = {
    inputType: 'file',
    isRequired: true,
    minLength: 0,
    primaryFocus: false,
}

class FileFormField extends InputFormField {}

FileFormField.propTypes = propTypes
FileFormField.defaultProps = defaultProps

export default FileFormField
