import {
    ADMIN_PASSAGES_CREATE,
    ADMIN_PASSAGES_DELETE_FILE,
    ADMIN_PASSAGES_EDIT,
    ADMIN_PASSAGES_GET_ALL,
    ADMIN_PASSAGES_UPDATE,
    ADMIN_PASSAGES_UPLOAD_FILE,
} from 'actions'

const DEFAULT_STATE = {
    passages: [],
    readingLevels: [],
    selectedPassage: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state
    let passage

    switch (action.type) {
        case ADMIN_PASSAGES_CREATE:
            return {
                ...DEFAULT_STATE,
                newPassage: action.payload,
            }
        case ADMIN_PASSAGES_UPDATE:
            return {
                ...state,
                selectedPassage: action.payload,
            }
        case ADMIN_PASSAGES_GET_ALL:
            return {
                ...state,
                passages: action.payload,
            }
        case ADMIN_PASSAGES_EDIT:
            return {
                ...state,
                selectedPassage: action.payload,
            }
        case ADMIN_PASSAGES_UPLOAD_FILE:
            passage = state.selectedPassage
            return {
                ...state,
                selectedPassage: {
                    ...passage,
                    has_image:
                        passage.has_image || action.meta.type === 'image',
                    has_accuracy_audio:
                        passage.has_accuracy_audio ||
                        action.meta.type === 'accuracy',
                    has_expression_audio:
                        passage.has_expression_audio ||
                        action.meta.type === 'expression',
                    has_normal_audio:
                        passage.has_normal_audio ||
                        action.meta.type === 'normal',
                    has_pace_audio:
                        passage.has_pace_audio || action.meta.type === 'pace',
                },
            }
        case ADMIN_PASSAGES_DELETE_FILE:
            passage = state.selectedPassage
            return {
                ...state,
                selectedPassage: {
                    ...passage,
                    has_image:
                        passage.has_image && action.meta.filename !== 'image',
                    has_accuracy_audio:
                        passage.has_accuracy_audio &&
                        action.meta.filename !== 'accuracy_audio',
                    has_expression_audio:
                        passage.has_expression_audio &&
                        action.meta.filename !== 'expression_audio',
                    has_normal_audio:
                        passage.has_normal_audio &&
                        action.meta.filename !== 'normal_audio',
                    has_pace_audio:
                        passage.has_pace_audio &&
                        action.meta.filename !== 'pace_audio',
                },
            }
        default:
            return state
    }
}
