import _ from 'lodash'
/**
 * General purpose utlities
 */

import {
    isObject,
    mapKeys,
    isArray,
    snakeCase,
    camelCase,
    isDate,
} from 'lodash'

const changeObjectCase = (data, recursive, funcCase = () => {}) => {
    if (!data || !isObject(data)) return data
    if (!recursive) return mapKeys(data, (value, key) => funcCase(key))

    const recurseArray = item =>
        item.map(subItem =>
            isObject(subItem)
                ? changeObjectCase(subItem, true, funcCase)
                : subItem
        )

    const item = mapKeys(data, (value, key) => funcCase(key))

    return Object.keys(item).reduce(
        (acc, key) => ({
            ...acc,
            [key]: isArray(item[key])
                ? recurseArray(item[key])
                : isObject(item[key]) && !isDate(item[key])
                ? changeObjectCase(item[key], true, funcCase)
                : item[key],
        }),
        {}
    )
}
/**
 *
 * @param {string} gradeLevelId
 * @param {[object]} gradeLevels
 * @param {[object]} readingLevels
 */
export const generateReadingLevel = (
    gradeLevelId,
    gradeLevels,
    readingLevels
) => {
    let readingLevelId
    let gradeLevel = gradeLevels.find(
        grade => grade.id === parseInt(gradeLevelId)
    )
    if (!gradeLevel) {
        throw new Error(
            `Unable to generate default Reading Level due to invalid Grade Level`
        )
    }

    let previousGradeLevel = gradeLevels.find(
        grade => grade.sequence === gradeLevel.sequence - 1
    )
    if (previousGradeLevel) {
        // set reading level to highest of previous grade level
        const level = readingLevels.find(
            rl => rl.grade_level.id === previousGradeLevel.id
        )
        readingLevelId = level.id
    } else {
        // set reading level to lowest of current grade level (Kindergarten)
        const level = readingLevels.find(
            rl => rl.grade_level.id === gradeLevel.id
        )
        readingLevelId = level.id
    }

    return readingLevelId
}

export const isNullOrUndefined = a => {
    return _.isNull(a) || _.isUndefined(a)
}
export const objKeysToSnakeCase = (data, recursive) =>
    changeObjectCase(data, recursive, snakeCase)

export const objKeysToCamelCase = (data, recursive) =>
    changeObjectCase(data, recursive, camelCase)
