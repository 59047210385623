import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hideMenu, resetAppLayout } from 'actions/app-layout'

function FullScreen() {
    const dispatch = useDispatch()
    const menuHidden = useSelector(state => state.appLayout.hideMenu)

    // ensure menu is hidden while mounted
    useEffect(() => {
        if (!menuHidden) {
            dispatch(hideMenu())
        }
    }, [menuHidden, dispatch])

    // show menu when unmounting
    useEffect(() => {
        return () => {
            dispatch(resetAppLayout())
        }
    }, [dispatch])

    return <div />
}

export default FullScreen
