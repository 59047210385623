import { ADD_TOAST } from 'actions'

const DEFAULT_STATE = {}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADD_TOAST:
            return { ...action.payload }
        default:
            return state
    }
}
