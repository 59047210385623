import PropTypes from 'prop-types'
import React from 'react'
import InputFormField from '../input-form-field'
import styles from './index.css'

const propTypes = {
    cols: PropTypes.number,
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    onValidChange: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    rows: PropTypes.number,
    validationError: PropTypes.string,
}

const defaultProps = {
    initialValue: '',
    isRequired: true,
    minLength: 0,
    primaryFocus: false,
}

class TextAreaFormField extends InputFormField {
    constructor() {
        super()
        this.onKeyPress = this.onKeyPress.bind(this)
    }

    onKeyPress(event) {
        if (event.nativeEvent.charCode === 13 && this.props.onSubmit) {
            this.props.onSubmit(event)
        } else if (this.props.onKeyPress) {
            this.props.onKeyPress(event)
        }
    }

    buildInput() {
        return (
            <textarea
                data-testid={`textarea-${this.props.label}`}
                className={styles.textarea}
                rows={this.props.rows}
                cols={this.props.cols}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholder}
                onChange={this.onFieldValueChanged}
                onBlur={this.changeFieldEntered}
                onKeyPress={this.onKeyPress}
                defaultValue={this.props.initialValue}
                id={this.props.id}
            />
        )
    }
}

TextAreaFormField.propTypes = propTypes
TextAreaFormField.defaultProps = defaultProps

export default TextAreaFormField
