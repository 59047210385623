import { POPUP_OPEN, ALERT_OPEN, POPUP_CLOSE } from 'actions'

const DEFAULT_STATE = {
    popupContent: null,
    alertContent: null,
    onClose: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case POPUP_CLOSE:
            return DEFAULT_STATE
        case ALERT_OPEN:
            return {
                alertContent: action.payload.content,
                onClose: action.payload.onClose,
            }
        case POPUP_OPEN:
            return {
                popupContent: action.payload.content,
                popupStyle: action.payload.style,
                onClose: action.payload.onClose,
                popupWindowStyle: action.payload.windowStyle,
            }
        default:
            return state
    }
}
