import {
    PUSHER_CONNECTION_STATE_CHANGE,
    PUSHER_SET_CLIENT,
    PUSHER_CONNECTION_ERROR,
} from 'actions'

export function pusherConnectionStateChange(newState) {
    return {
        type: PUSHER_CONNECTION_STATE_CHANGE,
        payload: newState,
    }
}

export function setPusherClient(client) {
    return {
        type: PUSHER_SET_CLIENT,
        payload: client,
    }
}

export function pusherConnectionError(error) {
    console.error(error)
    return {
        type: PUSHER_CONNECTION_ERROR,
        payload: { error },
    }
}
