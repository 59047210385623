import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import BaseComponent, { mapDispatch } from 'components/base-component'
import { connect } from 'react-redux'
import { authenticateStudent, authenticateStudentBadge } from 'actions/auth'
import { setBackgroundColor, resetAppLayout } from 'actions/app-layout'
import { checkForUpdates } from 'actions/app'

import Form from './form'
import BadgeScanner from './badge-scanner'

import styles from './index.css'

class Login extends BaseComponent {
    constructor() {
        super()
        this.state = { showScanner: true }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onBadgeScanned = this.onBadgeScanned.bind(this)
        this.onBadgeScannerFailed = this.onBadgeScannerFailed.bind(this)
    }

    componentDidMount() {
        this.props.checkForUpdates()
        if (this.props.activeSession) {
            this.props.history.push(
                `/student/sessions/${this.props.activeSession.id}`
            )
        } else if (this.props.student) {
            this.props.history.push('/')
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (!!props.student) {
            this.props.history.push('/')
        }
    }

    onFormSubmit(formValid, formFields) {
        this.startRequests([
            this.props.authenticateStudent(formFields.username),
        ])
    }

    onBadgeScanned(data) {
        this.startRequests([this.props.authenticateStudentBadge(data)])
    }

    onBadgeScannerFailed(err) {
        this.setState({ showScanner: false })
    }

    render() {
        let scanner, scannerStyle

        if (this.state.showScanner) {
            scannerStyle = styles.showScanner
            scanner = (
                <div className={`${styles.item} ${styles.right}`}>
                    <BadgeScanner
                        onBadgeScanned={this.onBadgeScanned}
                        onBadgeScannerFailed={this.onBadgeScannerFailed}
                    />
                </div>
            )
        }

        return (
            <div className={`${styles.root} ${scannerStyle}`}>
                <div className={styles.header}>Let's start reading!</div>
                <div className={`${styles.subheader} ${styles.scanner}`}>
                    Type in your user name, or just show your badge.
                </div>
                <div className={`${styles.subheader} ${styles.noScanner}`}>
                    Type in your user name to start. Need help? Ask your Reading
                    Guide!
                </div>
                <div className={styles.form}>
                    <div className={`${styles.item} ${styles.left}`}>
                        <Form
                            onFormSubmit={this.onFormSubmit}
                            onEmailChanged={this.onEmailChanged}
                            isFormBusy={this.state.isRequestActive}
                        />
                        <Link className={styles.link} to="/tutor/login">
                            ➡ Not a student? Sign in as a Reading Guide
                        </Link>
                    </div>
                    {scanner}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        student: state.student,
        activeSession: state.activeSession,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch({
            checkForUpdates,
            authenticateStudent,
            authenticateStudentBadge,
            setBackgroundColor,
            resetAppLayout,
        })
    )(Login)
)
