import { INITIALIZE, AUTH_TUTOR, ADMIN_TUTORS_IMPERSONATE } from 'actions'

/**
 * @type tutorTypesState = tutorTypes[]
 */
const initialState = []

export default (state = initialState, action) => {
    if (action.error) return state

    switch (action.type) {
        case AUTH_TUTOR:
        case INITIALIZE:
        case ADMIN_TUTORS_IMPERSONATE:
            return action.payload.tutorTypes
        default:
            return state
    }
}
