import { SESSION_GET_FEEDBACK_TYPES } from 'actions'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_GET_FEEDBACK_TYPES:
            return {
                ...state,
                feedbackTypes: action.payload,
            }
        default:
            return state
    }
}
