import {
    ADMIN_SCHOOLS_GET_ALL,
    ADMIN_SCHOOLS_EDIT,
    ADMIN_SCHOOLS_GET_ALL_FEATURES,
    ADMIN_SCHOOLS_ADD_FEATURE_ACCESS,
    ADMIN_SCHOOLS_REMOVE_FEATURE_ACCESS,
    ADMIN_SCHOOLS_GET_ALL_PUBLISHERS,
    ADMIN_SCHOOLS_ADD_PUBLISHER_ACCESS,
    ADMIN_SCHOOLS_REMOVE_PUBLISHER_ACCESS,
} from 'actions'

const DEFAULT_STATE = {
    schools: [],
    selectedSchool: null,
    features: [],
    publishers: [],
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_SCHOOLS_GET_ALL:
            return {
                ...DEFAULT_STATE,
                schools: action.payload,
            }
        case ADMIN_SCHOOLS_EDIT:
            return {
                ...state,
                selectedSchool: action.payload,
            }
        case ADMIN_SCHOOLS_GET_ALL_FEATURES:
            return {
                ...state,
                features: action.payload,
            }
        case ADMIN_SCHOOLS_ADD_FEATURE_ACCESS:
            return {
                ...state,
                selectedSchool: {
                    ...state.selectedSchool,
                    access_features: state.selectedSchool.access_features.concat(
                        action.meta.feature
                    ),
                },
            }
        case ADMIN_SCHOOLS_REMOVE_FEATURE_ACCESS:
            return {
                ...state,
                selectedSchool: {
                    ...state.selectedSchool,
                    access_features: state.selectedSchool.access_features.filter(
                        s => s.id !== action.meta.feature.id
                    ),
                },
            }
        case ADMIN_SCHOOLS_GET_ALL_PUBLISHERS:
            return {
                ...state,
                publishers: action.payload,
            }
        case ADMIN_SCHOOLS_ADD_PUBLISHER_ACCESS:
            return {
                ...state,
                selectedSchool: {
                    ...state.selectedSchool,
                    access_publishers: state.selectedSchool.access_publishers.concat(
                        action.meta.publisher
                    ),
                },
            }
        case ADMIN_SCHOOLS_REMOVE_PUBLISHER_ACCESS:
            return {
                ...state,
                selectedSchool: {
                    ...state.selectedSchool,
                    access_publishers: state.selectedSchool.access_publishers.filter(
                        s => s.id !== action.meta.publisher.id
                    ),
                },
            }
        default:
            return state
    }
}
