import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logout } from 'actions/auth'
import * as serviceWorker from 'serviceWorker'
import styles from './index.css'

const Logout = props => {
    useEffect(() => {
        serviceWorker.unregister()
        props.logout()
        props.history.push('/student/login')
    }, [props])

    return (
        <div className={styles.root}>
            <h1>Now Logging Out of BookNook</h1>
            <p>If you are not logged out please contact BookNook support.</p>
        </div>
    )
}

const mapDispatch = dispatch => {
    return bindActionCreators({ logout }, dispatch)
}

export default withRouter(
    connect(
        null,
        mapDispatch
    )(Logout)
)
