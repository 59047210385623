import React, { Component } from 'react'
import styles from './index.css'

const UPDATE_FREQUENCY_MILLIS = 500

export default class OnlineIndicator extends Component {
    constructor() {
        super()
        this.updateOnline = this.updateOnline.bind(this)
    }

    componentDidMount() {
        this.intervalId = setInterval(
            this.updateOnline,
            UPDATE_FREQUENCY_MILLIS
        )
    }

    componentWillUnmount() {
        if (this.intervalId) clearInterval(this.intervalId)
    }

    updateOnline() {
        this.forceUpdate()
    }

    render() {
        if (navigator.onLine) {
            return <div />
        } else {
            return <div className={styles.root}>Lost Network Connection</div>
        }
    }
}
