import PropTypes from 'prop-types'
import React, { Component } from 'react'
import QRReader from 'react-qr-reader'
import styles from './index.css'
import enableScannerImage from './images/login-student-badge-scanner-badge.png'

/*eslint-disable */
const UUID_REGEX = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/
/*eslint-enable */

class BadgeScanner extends Component {
    constructor() {
        super()
        this.state = { readerKey: 1, scannerEnabled: false, ready: false }
        this.onScan = this.onScan.bind(this)
        this.onScanError = this.onScanError.bind(this)
        this.onScannerEnabled = this.onScannerEnabled.bind(this)
    }

    isBadgeScannerSupported() {
        return (
            navigator.mediaDevices &&
            typeof navigator.mediaDevices.getUserMedia === 'function' &&
            typeof navigator.mediaDevices.getSupportedConstraints ===
                'function' &&
            typeof navigator.mediaDevices.enumerateDevices === 'function'
        )
    }

    onScan(data) {
        if (data && UUID_REGEX.test(data)) {
            this.setState({ readerKey: this.state.readerKey + 1 })
            this.props.onBadgeScanned(data)
        }
    }

    onScanError() {
        this.props.onBadgeScannerFailed()
    }

    onScannerEnabled() {
        this.setState({ scannerEnabled: true })
    }

    render() {
        let qrReader

        if (this.isBadgeScannerSupported()) {
            qrReader = (
                <QRReader
                    style={{
                        transform: 'scale(-2, 2)',
                        width: 360,
                        height: 360,
                    }}
                    facingMode={'front'}
                    onScan={this.onScan}
                    onError={this.onScanError}
                />
            )
        } else {
            this.props.onBadgeScannerFailed()
        }

        let enableScanner
        let scanner

        if (!this.state.scannerEnabled) {
            enableScanner = (
                <div
                    className={styles.enableScanner}
                    onClick={this.onScannerEnabled}
                >
                    <span>
                        <img
                            src={enableScannerImage}
                            alt="Enable Badge Scanner"
                        />
                    </span>
                    <span>
                        <div>Have a badge?</div>
                        <div>Click/tap here.</div>
                    </span>
                </div>
            )
        } else {
            scanner = (
                <div>
                    <div className={styles.notice}>
                        Hold your badge so that the QR code is in the center
                    </div>
                    <div className={styles.scannerWrap}>
                        <div className={`${styles.mask} ${styles.top}`} />
                        <div className={`${styles.mask} ${styles.right}`} />
                        <div className={`${styles.mask} ${styles.bottom}`} />
                        <div className={`${styles.mask} ${styles.left}`} />
                        <div
                            className={`${styles.guide} ${styles.top} ${
                                styles.left
                            }`}
                        />
                        <div
                            className={`${styles.guide} ${styles.top} ${
                                styles.right
                            }`}
                        />
                        <div
                            className={`${styles.guide} ${styles.bottom} ${
                                styles.right
                            }`}
                        />
                        <div
                            className={`${styles.guide} ${styles.bottom} ${
                                styles.left
                            }`}
                        />
                        {qrReader}
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.root}>
                {enableScanner}
                {scanner}
            </div>
        )
    }
}

BadgeScanner.propTypes = {
    onBadgeScanned: PropTypes.func.isRequired,
    onBadgeScannerFailed: PropTypes.func.isRequired,
}

export default BadgeScanner
