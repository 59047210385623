import {
    PUSHER_CONNECTION_ERROR,
    PUSHER_CONNECTION_STATE_CHANGE,
    PUSHER_SET_CLIENT,
} from 'actions'

export const PUSHER_CONNECTION_STATE = {
    INITIALIZED: 'initialized',
    CONNECTION: 'connecting',
    CONNECTED: 'connected',
    UNAVAILABLE: 'unavailable',
    FAILED: 'failed',
    DISCONNECTED: 'disconnected',
}

const DEFAULT_STATE = {
    connectionState: PUSHER_CONNECTION_STATE.DISCONNECTED,
    client: null,
    error: null,
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case PUSHER_CONNECTION_STATE_CHANGE:
            return {
                ...state,
                connectionState: action.payload,
            }
        case PUSHER_SET_CLIENT:
            return {
                ...state,
                client: action.payload,
                connectionState: action.payload
                    ? state.connectionState
                    : PUSHER_CONNECTION_STATE.DISCONNECTED,
            }
        case PUSHER_CONNECTION_ERROR:
            return {
                ...state,
                error: action.payload.error,
            }
        default:
            return state
    }
}
