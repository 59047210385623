//CLASSROOM
export const CLASSROOM_COMPREHENSION_ASSESSMENT_INTRO =
    'CLASSROOM_COMPREHENSION_ASSESSMENT_INTRO'
export const CLASSROOM_COMPREHENSION_ASSESSMENT_MAIN =
    'CLASSROOM_COMPREHENSION_ASSESSMENT_MAIN'
export const CLASSROOM_COMPREHENSION_ASSESSMENT_OUTRO =
    'CLASSROOM_COMPREHENSION_ASSESSMENT_OUTRO'
export const CLASSROOM_COMPREHENSION_ASSESSMENT_WAITING =
    'CLASSROOM_COMPREHENSION_ASSESSMENT_WAITING'
export const CLASSROOM_COMPREHENSION_BOOK_INTRO =
    'CLASSROOM_COMPREHENSION_BOOK_INTRO'
export const CLASSROOM_COMPREHENSION_BOOK_MAIN =
    'CLASSROOM_COMPREHENSION_BOOK_MAIN'
export const CLASSROOM_COMPREHENSION_BOOK_OUTRO =
    'CLASSROOM_COMPREHENSION_BOOK_OUTRO'
export const CLASSROOM_COMPREHENSION_BOOK_RATING =
    'CLASSROOM_COMPREHENSION_BOOK_RATING'
export const CLASSROOM_COMPREHENSION_BOOK_WAITING =
    'CLASSROOM_COMPREHENSION_BOOK_WAITING'
export const CLASSROOM_COMPREHENSION_BOOK_COUNTDOWN =
    'CLASSROOM_COMPREHENSION_BOOK_COUNTDOWN'
export const CLASSROOM_COMPREHENSION_GAME_INTRO =
    'CLASSROOM_COMPREHENSION_GAME_INTRO'
export const CLASSROOM_COMPREHENSION_GAME_MAIN =
    'CLASSROOM_COMPREHENSION_GAME_MAIN'
export const CLASSROOM_COMPREHENSION_GAME_COUNTDOWN =
    'CLASSROOM_COMPREHENSION_GAME_COUNTDOWN'
export const CLASSROOM_COMPREHENSION_INTRO = 'CLASSROOM_COMPREHENSION_INTRO'
export const CLASSROOM_VOCABULARY_INTRO = 'CLASSROOM_VOCABULARY_INTRO'
export const CLASSROOM_VOCABULARY_INTRO_WORDS =
    'CLASSROOM_VOCABULARY_INTRO_WORDS'
export const CLASSROOM_VOCABULARY_CARDS_INTRO =
    'CLASSROOM_VOCABULARY_CARDS_INTRO'
export const CLASSROOM_VOCABULARY_CARDS_MAIN = 'CLASSROOM_VOCABULARY_CARDS_MAIN'
export const CLASSROOM_VOCABULARY_CARDS_OUTRO =
    'CLASSROOM_VOCABULARY_CARDS_OUTRO'
export const CLASSROOM_VOCABULARY_MATCHING_INTRO =
    'CLASSROOM_VOCABULARY_MATCHING_INTRO'
export const CLASSROOM_VOCABULARY_MATCHING_MAIN =
    'CLASSROOM_VOCABULARY_MATCHING_MAIN'
export const CLASSROOM_VOCABULARY_MATCHING_OUTRO =
    'CLASSROOM_VOCABULARY_MATCHING_OUTRO'
export const CLASSROOM_VOCABULARY_MATCHING_WAITING =
    'CLASSROOM_VOCABULARY_MATCHING_WAITING'
export const CLASSROOM_VOCABULARY_GAME_INTRO = 'CLASSROOM_VOCABULARY_GAME_INTRO'
export const CLASSROOM_VOCABULARY_GAME_MAIN = 'CLASSROOM_VOCABULARY_GAME_MAIN'
export const CLASSROOM_VOCABULARY_GAME_WAITING =
    'CLASSROOM_VOCABULARY_GAME_WAITING'
export const CLASSROOM_VOCABULARY_DISCUSSION_INTRO =
    'CLASSROOM_VOCABULARY_DISCUSSION_INTRO'
export const CLASSROOM_VOCABULARY_DISCUSSION_MAIN =
    'CLASSROOM_VOCABULARY_DISCUSSION_MAIN'
export const CLASSROOM_VOCABULARY_DISCUSSION_OUTRO =
    'CLASSROOM_VOCABULARY_DISCUSSION_OUTRO'
export const CLASSROOM_VOCABULARY_ASSESSMENT_INTRO =
    'CLASSROOM_VOCABULARY_ASSESSMENT_INTRO'
export const CLASSROOM_VOCABULARY_ASSESSMENT_MAIN =
    'CLASSROOM_VOCABULARY_ASSESSMENT_MAIN'
export const CLASSROOM_VOCABULARY_ASSESSMENT_OUTRO =
    'CLASSROOM_VOCABULARY_ASSESSMENT_OUTRO'
export const CLASSROOM_VOCABULARY_ASSESSMENT_WAITING =
    'CLASSROOM_VOCABULARY_ASSESSMENT_WAITING'

//GUIDED
export const GUIDED_INTRO = 'GUIDED_INTRO'
export const GUIDED_VOCABULARY_NEW_WORDS = 'GUIDED_VOCABULARY_NEW_WORDS'
export const GUIDED_VOCABULARY_INTRO = 'GUIDED_VOCABULARY_INTRO'
export const GUIDED_VOCABULARY_ACTIVITIES = 'GUIDED_VOCABULARY_ACTIVITIES'
export const GUIDED_VOCABULARY_GAME = 'GUIDED_VOCABULARY_GAME'
export const GUIDED_VOCABULARY_ASSESSMENT = 'GUIDED_VOCABULARY_ASSESSMENT'
export const GUIDED_COMPREHENSION_INTRO = 'GUIDED_COMPREHENSION_INTRO'
export const GUIDED_COMPREHENSION_BOOK_INTRO = 'GUIDED_COMPREHENSION_BOOK_INTRO'
export const GUIDED_COMPREHENSION_BOOK = 'GUIDED_COMPREHENSION_BOOK'
export const GUIDED_COMPREHENSION_BOOK_DISCUSSION =
    'GUIDED_COMPREHENSION_BOOK_DISCUSSION'
export const GUIDED_COMPREHENSION_BOOK_COMPLETED =
    'GUIDED_COMPREHENSION_BOOK_COMPLETED'
export const GUIDED_COMPREHENSION_GAME = 'GUIDED_COMPREHENSION_GAME'
export const GUIDED_COMPREHENSION_ASSESSMENT = 'GUIDED_COMPREHENSION_ASSESSMENT'
export const GUIDED_FLUENCY_INTRO = 'GUIDED_FLUENCY_INTRO'
export const GUIDED_FLUENCY_ACTIVITIES = 'GUIDED_FLUENCY_ACTIVITIES'
export const GUIDED_FLUENCY_MODELING = 'GUIDED_FLUENCY_MODELING'
export const GUIDED_FLUENCY_GAME = 'GUIDED_FLUENCY_GAME'
export const GUIDED_FLUENCY_STUDENT_READ_INTRO =
    'GUIDED_FLUENCY_STUDENT_READ_INTRO'
export const GUIDED_FLUENCY_STUDENT_READ_INSTRUCTIONS =
    'GUIDED_FLUENCY_STUDENT_READ_INSTRUCTIONS'
export const GUIDED_FLUENCY_STUDENT_READ_MAIN =
    'GUIDED_FLUENCY_STUDENT_READ_MAIN'
export const GUIDED_FLUENCY_STUDENT_READ_RESULTS =
    'GUIDED_FLUENCY_STUDENT_READ_RESULTS'

//K-ONE
export const K_ONE_INTRO = 'K_ONE_INTRO'
export const K_ONE_INTRODUCTION = 'K_ONE_INTRODUCTION'
export const K_ONE_MATCHING_INTRO = 'K_ONE_MATCHING_INTRO'
export const K_ONE_MATCHING_MAIN = 'K_ONE_MATCHING_MAIN'
export const K_ONE_CHOOSING_INTRO = 'K_ONE_CHOOSING_INTRO'
export const K_ONE_CHOOSING_MAIN = 'K_ONE_CHOOSING_MAIN'
export const K_ONE_WORD_LADDER_INTRO = 'K_ONE_WORD_LADDER_INTRO'
export const K_ONE_WORD_LADDER_MAIN = 'K_ONE_WORD_LADDER_MAIN'
export const K_ONE_WORD_BUILDER_INTRO = 'K_ONE_WORD_BUILDER_INTRO'
export const K_ONE_WORD_BUILDER_MAIN = 'K_ONE_WORD_BUILDER_MAIN'
export const K_ONE_GROUPING_INTRO = 'K_ONE_GROUPING_INTRO'
export const K_ONE_GROUPING_MAIN = 'K_ONE_GROUPING_MAIN'
export const K_ONE_ASSESSMENT_INTRO = 'K_ONE_ASSESSMENT_INTRO'
export const K_ONE_ASSESSMENT_MAIN = 'K_ONE_ASSESSMENT_MAIN'
export const K_ONE_BOOK_INTRO = 'K_ONE_BOOK_INTRO'
export const K_ONE_BOOK_MAIN = 'K_ONE_BOOK_MAIN'
export const K_ONE_BOOK_OUTRO = 'K_ONE_BOOK_OUTRO'
export const K_ONE_OUTRO = 'K_ONE_OUTRO'

//LEVELING
export const LEVELING_INTRO = 'LEVELING_INTRO'
export const LEVELING_MAIN = 'LEVELING_MAIN'
export const LEVELING_COMPREHENSION_INTRO = 'LEVELING_COMPREHENSION_INTRO'
export const LEVELING_COMPREHENSION = 'LEVELING_COMPREHENSION'
export const LEVELING_CONFIRMATION = 'LEVELING_CONFIRMATION'

//WRAP UP
export const WRAP_UP_DOCUMENTATION = 'WRAP_UP_DOCUMENTATION'
export const WRAP_UP_RESULTS = 'WRAP_UP_RESULTS'
export const WRAP_UP_COMPLETE = 'WRAP_UP_COMPLETE'

//NEW SESSION
export const NEW_SESSION = 'NEW_SESSION'

// SEQUENCE
export const CLASSROOM_CHECKPOINTS_SEQUENCE = [
    CLASSROOM_VOCABULARY_INTRO,
    CLASSROOM_VOCABULARY_INTRO_WORDS,
    CLASSROOM_VOCABULARY_CARDS_INTRO,
    CLASSROOM_VOCABULARY_CARDS_MAIN,
    CLASSROOM_VOCABULARY_CARDS_OUTRO,
    CLASSROOM_VOCABULARY_MATCHING_INTRO,
    CLASSROOM_VOCABULARY_MATCHING_MAIN,
    CLASSROOM_VOCABULARY_MATCHING_OUTRO,
    CLASSROOM_VOCABULARY_MATCHING_WAITING,
    CLASSROOM_VOCABULARY_DISCUSSION_INTRO,
    CLASSROOM_VOCABULARY_DISCUSSION_MAIN,
    CLASSROOM_VOCABULARY_DISCUSSION_OUTRO,
    CLASSROOM_VOCABULARY_GAME_INTRO,
    CLASSROOM_VOCABULARY_GAME_MAIN,
    CLASSROOM_VOCABULARY_GAME_WAITING,
    CLASSROOM_VOCABULARY_ASSESSMENT_INTRO,
    CLASSROOM_VOCABULARY_ASSESSMENT_MAIN,
    CLASSROOM_VOCABULARY_ASSESSMENT_OUTRO,
    CLASSROOM_VOCABULARY_ASSESSMENT_WAITING,
    CLASSROOM_COMPREHENSION_INTRO,
    CLASSROOM_COMPREHENSION_BOOK_INTRO,
    CLASSROOM_COMPREHENSION_BOOK_MAIN,
    CLASSROOM_COMPREHENSION_BOOK_OUTRO,
    CLASSROOM_COMPREHENSION_BOOK_RATING,
    CLASSROOM_COMPREHENSION_BOOK_WAITING,
    CLASSROOM_COMPREHENSION_BOOK_COUNTDOWN,
    CLASSROOM_COMPREHENSION_GAME_INTRO,
    CLASSROOM_COMPREHENSION_GAME_MAIN,
    CLASSROOM_COMPREHENSION_GAME_COUNTDOWN,
    CLASSROOM_COMPREHENSION_ASSESSMENT_INTRO,
    CLASSROOM_COMPREHENSION_ASSESSMENT_MAIN,
    CLASSROOM_COMPREHENSION_ASSESSMENT_OUTRO,
    CLASSROOM_COMPREHENSION_ASSESSMENT_WAITING,
    WRAP_UP_DOCUMENTATION,
]
