import React, { useState } from 'react'
import Notice from 'components/notice'
import HelpForm from 'components/help-form'

const SmallDeviceAlert = () => {
    const [showHelp, setShowHelp] = useState(false)
    const handleNeedSupport = () => {
        setShowHelp(true)
    }
    return showHelp ? (
        <HelpForm />
    ) : (
        <Notice
            header="Sorry, but we need some space."
            details="If you’re seeing this error message, your device’s screen is too small to properly display BookNook."
            ctaBtnText="Questions? Let us know."
            onClick={handleNeedSupport}
            linkBtn
        />
    )
}

export default SmallDeviceAlert
