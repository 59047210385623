import ApiClient from 'util/book-nook-api-client'
import { NETWORK_TEST_GET_BOOKS } from 'actions'

export function getBooks() {
    const payload = ApiClient.getNetworkTestBooks()
    return {
        type: NETWORK_TEST_GET_BOOKS,
        payload,
    }
}
