import {
    AUTH_LOGOUT,
    SESSION_SETUP_ADD_AVAILABLE_STUDENT,
    SESSION_SETUP_ADD_SELECTED_STUDENT,
    SESSION_SETUP_CLEAR_AVAILABLE_STUDENTS,
    SESSION_SETUP_CREATE_STUDENT,
    SESSION_SETUP_CREATE_STUDENT_GROUP,
    SESSION_SETUP_REMOVE_AVAILABLE_STUDENT,
    SESSION_SETUP_REMOVE_SELECTED_STUDENT,
    SESSION_SETUP_RESET,
    SESSION_SETUP_SELECT_STUDENT_GROUP,
    SESSION_SETUP_SET_AVAILABLE_LESSONS,
    SESSION_SETUP_SET_STUDENTS_FOR_SCHOOL,
    SESSION_SETUP_ADD_STUDENT_FOR_SCHOOL,
    SESSION_SETUP_SET_GROUP_RECOMMENDATIONS,
    SESSION_SETUP_SET_LESSON_TYPES,
    SESSION_SETUP_SET_PREVIOUS_SESSION_GROUPS,
    SESSION_SETUP_SET_RECOMMENDED_LESSON,
    SESSION_SETUP_SET_RECOMMENDED_LESSON_FOR_CLASSROOM_GROUP,
    SESSION_SETUP_SET_SELECTED_LESSON,
    SESSION_SETUP_SET_SELECTED_LESSON_BY_ID,
    SESSION_SETUP_SET_SELECTED_PUBLISHER,
    SESSION_SETUP_SET_SELECTED_SESSION_TYPE,
    SESSION_SETUP_SET_SESSION_TYPES,
    SESSION_SETUP_SET_STANDARDS,
    SESSION_SETUP_SET_STUDENT_GROUPS,
    SESSION_DEMO_CONFIGURE,
    SESSION_SETUP_REMOVE_STUDENT_GROUP,
    SESSION_COMPLETE,
} from 'actions'

import _ from 'lodash'

const defaultState = {
    availableStudents: [],
    previouslyAvailableStudents: [],
    previouslyNotFoundStudentIds: [],
    studentsForSchool: [],
    selectedStudents: [],
    availableLessons: [],
    selectedLesson: null,
    sessionTypes: [],
    lessonTypes: [],
    gradeLevels: [],
    readingLevels: [],
    studentGroups: [],
    groupRecommendations: [],
    selectedPublisher: null,
    selectedSessionType: null,
    selectedStudentGroup: null,
    hasStudentAccess: null,
    completedSessions: [],
}

const removeStudent = (id, arr) => {
    const result = arr.slice(0)
    const student = arr.find(s => s.id === id)
    if (student) {
        const index = result.indexOf(student)
        result.splice(index, 1)
    }

    return result
}

export default (state = defaultState, action) => {
    if (action.error) return state

    let selectedStudents

    switch (action.type) {
        case SESSION_SETUP_ADD_AVAILABLE_STUDENT: {
            const { studentId, student } = action.payload
            const availableStudents = [...state.availableStudents]
            const previouslyNotFoundStudentIds = [
                ...state.previouslyNotFoundStudentIds,
            ]
            if (student) {
                availableStudents.push(student)
            } else {
                previouslyNotFoundStudentIds.push(studentId)
            }
            return {
                ...state,
                availableStudents,
                previouslyNotFoundStudentIds,
            }
        }
        case SESSION_SETUP_REMOVE_AVAILABLE_STUDENT:
            const previouslyAvailableStudents = [
                ...state.previouslyAvailableStudents,
            ]
            const student = action.payload
            if (!previouslyAvailableStudents.find(s => s.id === student.id)) {
                previouslyAvailableStudents.push(student)
            }

            return {
                ...state,
                availableStudents: removeStudent(
                    student.id,
                    state.availableStudents
                ),
                previouslyAvailableStudents,
            }
        case SESSION_SETUP_ADD_SELECTED_STUDENT:
            selectedStudents = state.selectedStudents.slice(0)
            selectedStudents.push(action.payload)
            return { ...state, selectedStudents }
        case SESSION_SETUP_REMOVE_SELECTED_STUDENT:
            return {
                ...state,
                selectedStudents: removeStudent(
                    action.payload.id,
                    state.selectedStudents
                ),
            }
        case SESSION_SETUP_CLEAR_AVAILABLE_STUDENTS:
            return {
                ...state,
                availableStudents: [],
                selectedStudents: [],
                selectedStudentGroup: null,
            }
        case SESSION_SETUP_SET_STUDENTS_FOR_SCHOOL:
            return {
                ...state,
                studentsForSchool: action.payload,
            }
        case SESSION_SETUP_ADD_STUDENT_FOR_SCHOOL:
            return {
                ...state,
                studentsForSchool: _.uniqBy(
                    [...state.studentsForSchool, action.payload],
                    student => student.id
                ),
            }
        case SESSION_SETUP_SET_GROUP_RECOMMENDATIONS:
            let groupRecommendations = action.payload
            groupRecommendations.forEach(g => {
                if (!g.lesson) {
                    g.noLessonAvailable = true
                }
            })

            return {
                ...state,
                groupRecommendations,
            }
        case SESSION_SETUP_SET_PREVIOUS_SESSION_GROUPS:
            return {
                ...state,
                previousSessionGroups: action.payload,
            }
        case SESSION_SETUP_SET_STUDENT_GROUPS:
            return {
                ...state,
                studentGroups: action.payload,
            }
        case SESSION_SETUP_CREATE_STUDENT:
            return {
                ...state,
                studentsForSchool: _.uniqBy(
                    [...state.studentsForSchool, action.payload],
                    student => student.id
                ),
            }
        case SESSION_SETUP_CREATE_STUDENT_GROUP:
            let studentGroups = state.studentGroups.slice(0)
            studentGroups.push(action.payload)
            return {
                ...state,
                studentGroups,
            }
        case SESSION_SETUP_SELECT_STUDENT_GROUP:
            return {
                ...state,
                selectedStudents: action.payload.students,
                selectedStudentGroup: action.payload,
            }
        case SESSION_SETUP_REMOVE_STUDENT_GROUP:
            return {
                ...state,
                selectedStudents: [],
                selectedStudentGroup: undefined,
            }
        case SESSION_SETUP_SET_RECOMMENDED_LESSON_FOR_CLASSROOM_GROUP:
            action.meta.noLessonAvailable = action.payload.length === 0
            action.meta.lesson = action.payload[0]
            return { ...state }
        case SESSION_SETUP_SET_SESSION_TYPES:
            return {
                ...state,
                sessionTypes: action.payload,
            }
        case SESSION_SETUP_SET_LESSON_TYPES:
            return {
                ...state,
                lessonTypes: action.payload,
            }
        case SESSION_SETUP_SET_STANDARDS:
            return {
                ...state,
                standards: action.payload,
            }
        case SESSION_SETUP_SET_SELECTED_PUBLISHER:
            return {
                ...state,
                selectedPublisher: action.payload,
            }
        case SESSION_SETUP_SET_SELECTED_SESSION_TYPE:
            return {
                ...state,
                selectedSessionType: action.payload,
            }
        case SESSION_SETUP_SET_AVAILABLE_LESSONS:
            let availableLessons = action.payload

            // To allow guide to choose a lesson as spanish or not, just duplicate
            // all the spanish lessons and pretend they aren't spanish
            if (!action.meta.spanishOnly) {
                availableLessons = availableLessons.reduce((res, lesson) => {
                    if (lesson.has_spanish) {
                        let dupeLesson = Object.assign({}, lesson)
                        dupeLesson.has_spanish = false
                        return res.concat([lesson, dupeLesson])
                    } else {
                        return res.concat([lesson])
                    }
                }, [])
            }

            return { ...state, availableLessons }
        case SESSION_SETUP_SET_RECOMMENDED_LESSON:
            let lesson = action.payload[0]
            if (!!lesson && !action.meta.useSpanish) {
                lesson.has_spanish = false
            }
            return { ...state, selectedLesson: lesson }
        case SESSION_SETUP_SET_SELECTED_LESSON:
            return { ...state, selectedLesson: action.payload }
        case SESSION_SETUP_SET_SELECTED_LESSON_BY_ID:
            const overrides = action.meta.overrides
            return {
                ...state,
                selectedLesson: overrides
                    ? { ...action.payload, ...overrides }
                    : action.payload,
            }
        case SESSION_DEMO_CONFIGURE:
            return {
                ...state,
                selectedStudents: action.payload.selectedStudents,
                selectedSessionType: null,
                selectedPublisher: action.payload.selectedPublisher,
            }

        case SESSION_SETUP_RESET:
            return {
                ...defaultState,
                completedSessions: state.completedSessions,
                hasStudentAccess: state.hasStudentAccess,
            }
        case SESSION_COMPLETE:
            return {
                ...state,
                completedSessions: [...state.completedSessions, action.payload],
            }
        case AUTH_LOGOUT:
            return defaultState
        default:
            return state
    }
}
