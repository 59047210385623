import React from 'react'
import styles from './index.css'

export default props => {
    const enabledStyle = !props.allowSubmit ? styles.disabled : ''
    const allowSubmit =
        props.allowSubmit === undefined || props.allowSubmit === true
    const onClick = () => {
        if (allowSubmit && props.onClick) props.onClick()
    }
    const style = {}
    if (props.height) style.height = `${props.height}px`
    if (props.width) style.width = `${props.width}px`
    if (props.fontSize) style.fontSize = `${props.fontSize}px`

    return (
        <button
            onClick={onClick}
            className={`${styles.button} ${enabledStyle}`}
            style={style}
        >
            {props.content}
        </button>
    )
}
