import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { logout } from 'actions/auth'
import AdminMenu from './admin-menu'
import AdminTutoringMenu from './admin-tutoring-menu'
import SchoolMenu from './school-menu'

import userIcon from './images/user.png'
import styles from './index.css'

const Nav = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const tutor = useSelector(state => state.tutor)
    const tutorSchools = useSelector(state => state.tutorSchools)
    const tutorPublisherAdmin = useSelector(state => state.tutorPublisherAdmin)
    const student = useSelector(state => state.student)

    const onTutorAccountClicked = () => {
        history.push('/tutor/account')
    }

    const onLogOutClicked = () => {
        const redirect = tutor ? '/tutor/login' : '/student/login'
        dispatch(logout())
        history.push(redirect)
    }

    return (
        <div className={styles.menu}>
            {tutor && (tutor.isAdmin || tutorPublisherAdmin.length) ? (
                <div className={styles.menuItem}>
                    <AdminTutoringMenu key="admin-tutoring-menu" />
                </div>
            ) : null}
            {tutor && (tutor.isAdmin || tutorPublisherAdmin.length) ? (
                <div className={styles.menuItem}>
                    <AdminMenu
                        publisherOnly={!tutor.isAdmin}
                        publisherAdmin={tutorPublisherAdmin}
                        key="admin-menu"
                    />
                </div>
            ) : null}
            {tutorSchools.length ? (
                <div className={styles.menuItem}>
                    <SchoolMenu key="school-menu" />
                </div>
            ) : null}
            {tutor ? (
                <div className={styles.menuItem}>
                    <div
                        className={styles.user}
                        onClick={onTutorAccountClicked}
                    >
                        <img alt="" src={userIcon} />
                        <span>{`${tutor.firstName} ${tutor.lastName.charAt(
                            0
                        )}`}</span>
                    </div>
                </div>
            ) : null}
            {student ? (
                <div className={styles.menuItem}>
                    <div className={styles.user}>
                        <img alt="" src={userIcon} />
                        <span>{student.username}</span>
                    </div>
                </div>
            ) : null}
            {tutor || student ? (
                <div className={styles.menuItem}>
                    <div className={styles.logout} onClick={onLogOutClicked}>
                        Sign Out
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Nav
