import PropTypes from 'prop-types'
import React from 'react'
import styles from './index.css'

const propTypes = {
    label: PropTypes.string,
}

const GenericFormField = props => {
    return (
        <div style={props.style}>
            <label
                className={`${styles.label} ${props.hint ? styles.hint : ''}`}
                htmlFor={props.id}
            >
                <span>{props.label}</span>
                {props.label && props.isRequired && (
                    <span className={styles.required}>*</span>
                )}
                {props.hint && (
                    <span className={styles.hintText}>{props.hint}</span>
                )}
            </label>
            {props.children}
        </div>
    )
}

GenericFormField.propTypes = propTypes

export default GenericFormField
