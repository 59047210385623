import {
    INITIALIZE,
    AUTH_TUTOR,
    ADMIN_SCHOOLS_DELETE,
    ADMIN_TUTORS_IMPERSONATE,
    ADMIN_SCHOOLS_UPDATE,
} from 'actions'
export default (state = [], action) => {
    if (action.error) return state

    switch (action.type) {
        case AUTH_TUTOR:
        case INITIALIZE:
        case ADMIN_TUTORS_IMPERSONATE:
            return action.payload.tutorSchools
        case ADMIN_SCHOOLS_DELETE:
            return action.payload.tutorSchools.filter(
                school => school.id !== action.payload
            )
        case ADMIN_SCHOOLS_UPDATE:
            const tutorSchools = [...state]
            const index = tutorSchools.findIndex(
                school => school.id === action.payload.id
            )
            tutorSchools[index] = action.payload
            return tutorSchools
        default:
            return state
    }
}
