import { lazy } from 'react'

const Account = lazy(() => import('./account'))
const NoAccess = lazy(() => import('./no-access'))
const ChangePassword = lazy(() => import('./change-password'))
const ResetPassword = lazy(() => import('./reset-password'))
const Session = lazy(() => import('./session'))
const SessionDemo = lazy(() => import('./session-demo'))
const SessionGroup = lazy(() => import('./session-group'))

export default {
    Account,
    NoAccess,
    ChangePassword,
    ResetPassword,
    Session,
    SessionDemo,
    SessionGroup,
}
