import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import Text from 'components/text'
import Link from 'components/link'
import ExtendFreeTrial from 'components/extend-free-trial'
import BaseComponent from 'components/base-component'
import styles from './index.css'
import { daysFrom } from 'util/time'

class FreeTrialBanner extends BaseComponent {
    handleDismiss = event => {
        event.preventDefault()
        this.props.closePopup()
    }

    handleSubmit = (values, callback) => {
        if (!this.state.isRequestActive) {
            this.startRequests([
                this.props.requestFreeTrialExtension(values),
            ]).then(() => {
                callback()
            })
        }
    }

    handleExtendClicked = event => {
        event.preventDefault()
        this.props.openPopup(
            <ExtendFreeTrial
                onClose={this.handleDismiss}
                onSubmit={this.handleSubmit}
                tutor={this.props.tutor}
            />
        )
    }

    /**
     * Between day 1 to 25th just show days count down only
     * @param {*} daysLeft
     */
    renderOnDay1to25 = daysLeft => {
        return (
            <>
                <Text family="montserrat" className={styles.truncate}>
                    You have <span className={styles.timeLeft}>{daysLeft}</span>{' '}
                    {daysLeft === 1 ? 'day' : 'days'} left in your free trial.
                </Text>
            </>
        )
    }

    /**
     * Between 25th to 33rd day
     * - Show wait for confirmation if he has sent a request to extend
     * - Show free trial has ended if he has not extended and past 30th day ~ account expired
     * - Show days count down if he has not extended and not expired, btn 25 to 30
     * @param {*} tutor
     * @param {*} daysLeft
     */
    renderOnDay25to33 = (tutor, daysLeft) => {
        return (
            <>
                {tutor.freeTrial.pendingExtension ? (
                    <Text family="montserrat" className={styles.truncate}>
                        Thanks for extending your free trial… You can keep
                        enjoying BookNook! Please look for a confirmation within
                        72 hours.
                    </Text>
                ) : tutor.freeTrial.expired ? (
                    <Text family="montserrat" className={styles.truncate}>
                        Your free trial has ended! &nbsp; To continue using
                        BookNook, click the “Extend Trial” button
                        <span className={styles.arrowRight}>&#10230;</span>
                    </Text>
                ) : (
                    <Text family="montserrat" className={styles.truncate}>
                        You have{' '}
                        <span className={styles.timeLeft}>{daysLeft}</span>{' '}
                        {daysLeft === 1 ? 'day' : 'days'} left in your free
                        trial.
                    </Text>
                )}
                {!tutor.freeTrial.pendingExtension ? (
                    <div className={cx(styles.buttonWrapper)}>
                        <button onClick={this.handleExtendClicked}>
                            EXTEND TRIAL
                        </button>
                    </div>
                ) : null}
            </>
        )
    }

    /**
     * On 34th day, the account has fully expired.
     * - Check if tutor has ever extended free trial before, if yes then deprive
     * him to send new extend free trial account and only add contact support btn
     * - If he has never extended before then allow him to send extend my trial request
     * @param {*} tutor
     */
    renderOnDay34Onwards = tutor => {
        return (
            <>
                {tutor.freeTrial.extensionCount === 0 ? (
                    <>
                        <Text family="montserrat" className={styles.truncate}>
                            Your free trial has ended! &nbsp; To continue using
                            BookNook, click the “Extend Trial” button
                            <span className={styles.arrowRight}>&#10230;</span>
                        </Text>
                        <div className={cx(styles.buttonWrapper)}>
                            <button onClick={this.handleExtendClicked}>
                                EXTEND TRIAL
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <Text family="montserrat">
                            Sorry, your Free Trial is up! Please contact
                            BookNook
                        </Text>
                        <div className={cx(styles.buttonWrapper)}>
                            <Link href="https://www.booknooklearning.com/demo/">
                                Contact BookNook
                            </Link>
                        </div>
                    </>
                )}
            </>
        )
    }

    render() {
        const { tutor, now } = this.props

        if (!tutor || !tutor.expiresAt) return <div />

        const daysLeft = daysFrom(now || new Date())(tutor.expiresAt)

        return (
            <div className={styles.root} data-testid="free-trial-banner">
                <div className={styles.rootInner}>
                    {!tutor.freeTrial.expired && daysLeft > 5
                        ? this.renderOnDay1to25(daysLeft)
                        : daysLeft <= 5 && daysLeft >= -3
                        ? this.renderOnDay25to33(tutor, daysLeft)
                        : tutor.freeTrial.fullyExpired
                        ? this.renderOnDay34Onwards(tutor)
                        : null}
                </div>
            </div>
        )
    }
}

FreeTrialBanner.propTypes = {
    tutor: PropTypes.shape({
        id: PropTypes.number,
    }),
    now: PropTypes.string,
}

export default FreeTrialBanner
