import {
    ADMIN_REPORTS_GET_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT,
    ADMIN_REPORTS_GET_STUDENT_PROGRESS_REPORT,
    ADMIN_REPORTS_GET_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT,
    ADMIN_REPORTS_GET_USAGE_DAILY_ROLLUP_REPORT,
    ADMIN_REPORTS_GET_USAGE_REPORT,
    ADMIN_REPORTS_GET_USAGE_WEEKLY_ROLLUP_REPORT,
    ADMIN_REPORTS_PLATFORM_METRICS,
    ADMIN_REPORTS_SCHOOL_METRICS,
} from 'actions'

const DEFAULT_STATE = {
    platformMetrics: null,
    schoolMetrics: null,
    studentProgressDailyRollupReport: null,
    studentProgressReport: null,
    studentProgressWeeklyRollupReport: null,
    usageReport: null,
    usageDailyRollupReport: null,
    usageWeeklyRollupReport: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_REPORTS_GET_STUDENT_PROGRESS_REPORT:
            return {
                ...state,
                studentProgressReport: action.payload,
            }
        case ADMIN_REPORTS_GET_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT:
            return {
                ...state,
                studentProgressDailyRollupReport: action.payload,
            }
        case ADMIN_REPORTS_GET_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT:
            return {
                ...state,
                studentProgressWeeklyRollupReport: action.payload,
            }
        case ADMIN_REPORTS_GET_USAGE_REPORT:
            return {
                ...state,
                usageReport: action.payload,
            }
        case ADMIN_REPORTS_GET_USAGE_DAILY_ROLLUP_REPORT:
            return {
                ...state,
                usageDailyRollupReport: action.payload,
            }
        case ADMIN_REPORTS_GET_USAGE_WEEKLY_ROLLUP_REPORT:
            return {
                ...state,
                usageWeeklyRollupReport: action.payload,
            }
        case ADMIN_REPORTS_PLATFORM_METRICS:
            return {
                ...state,
                platformMetrics: action.payload,
            }
        case ADMIN_REPORTS_SCHOOL_METRICS:
            return {
                ...state,
                schoolMetrics: action.payload,
            }
        default:
            return state
    }
}
