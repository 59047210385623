import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import GenericFormField from '../generic-form-field'
import DateRangeSelector from 'components/reporting/date-range-selector'

import styles from './index.css'

const propTypes = {
    initialValue: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onKeyPress: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    onValidChange: PropTypes.func,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
    inputStyle: PropTypes.object,
    setPropsOnLoad: PropTypes.bool,
}

const defaultProps = {
    initialValue: { startDate: new Date(), endDate: new Date() },
    isRequired: true,
    primaryFocus: false,
}

class DateRangeFormField extends Component {
    constructor() {
        super()
        this.changeFieldEntered = this.changeFieldEntered.bind(this)
        this.onDateRangeChanged = this.onDateRangeChanged.bind(this)
        this.onFieldValueChanged = this.onFieldValueChanged.bind(this)

        this.state = {
            fieldValue: {},
            fieldEntered: false,
            fieldValid: null,
        }
    }

    componentDidMount() {
        let fieldValue = this.props.initialValue

        if (this.props.setPropsOnLoad) {
            this.setState({ fieldValue })
        } else {
            this.onFieldValueChanged(fieldValue)
        }

        if (this.props.primaryFocus) {
            ReactDOM.findDOMNode(this.refs.input).focus()
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.showError =
            nextProps.isRequired &&
            nextState.fieldEntered &&
            !nextState.fieldValid
        return true
    }

    changeFieldEntered() {
        this.setState({ fieldEntered: true })
    }

    onFieldValueChanged(newFieldValue) {
        const newFieldValid = this.validateFieldValue(newFieldValue)
        const currentFieldValid = this.state.fieldValid
        this.setState({
            fieldValue: newFieldValue,
            fieldValid: newFieldValid,
        })
        if (this.props.onChange) {
            this.props.onChange(newFieldValue, newFieldValid)
        }
        if (newFieldValid !== currentFieldValid && this.props.onValidChange) {
            this.props.onValidChange(newFieldValid)
        }
    }

    validateFieldValue(value) {
        return (
            !this.props.isRequired ||
            (!!value &&
                (!this.props.onValidateFieldValue ||
                    this.props.onValidateFieldValue(value)))
        )
    }

    shouldShowValidationError() {
        return false
    }

    onDateRangeChanged(startDate, endDate) {
        this.onFieldValueChanged({ startDate, endDate })
    }

    render() {
        const showError =
            this.props.isRequired &&
            this.state.fieldEntered &&
            !this.state.fieldValid

        return (
            <GenericFormField
                label={this.props.label}
                isRequired={this.props.isRequired}
                style={this.props.style}
                hint={this.props.hint}
            >
                {this.shouldShowValidationError() ? (
                    <div
                        className={`${styles.alert} ${
                            showError ? styles.alertVisible : ''
                        }`}
                    >
                        {this.props.validationError}
                    </div>
                ) : (
                    ''
                )}

                <DateRangeSelector
                    selectedStartDate={
                        new Date(this.state.fieldValue.startDate)
                    }
                    selectedEndDate={new Date(this.state.fieldValue.endDate)}
                    onDateRangeChanged={this.onDateRangeChanged}
                />
            </GenericFormField>
        )
    }
}

DateRangeFormField.propTypes = propTypes
DateRangeFormField.defaultProps = defaultProps

export default DateRangeFormField
