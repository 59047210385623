import React from 'react'
import styles from './index.css'

const Link = ({
    href,
    onClick,
    fontFamily = 'lato',
    size,
    variant,
    children,
    underline,
}) => {
    const classNames = [styles.link]
    if (fontFamily && fontFamily.match(/^nunito$/i)) {
        classNames.push(styles.nunito)
    }
    if (fontFamily && fontFamily.match(/^montserrat$/i)) {
        classNames.push(styles.montserrat)
    }

    const fontSizeStyle = size
        ? {
              fontSize: size,
          }
        : {}

    const underlineStyle = {
        textDecoration: underline ? 'underline' : 'none',
    }

    switch (variant) {
        case 'danger':
            classNames.push(styles.danger)
            break
        default:
            classNames.push(styles.primary)
    }

    if (href) {
        return (
            <a
                href={href}
                className={classNames.join(' ')}
                style={{ ...fontSizeStyle, ...underlineStyle }}
            >
                {children}
            </a>
        )
    }
    return (
        <button onClick={onClick} className={classNames.join(' ')}>
            {children}
        </button>
    )
}

export default Link
