import Timer from './timer'

let beacons = {}

const DEFAULT_BEACON_FREQUENCY = 1000
const DEFAULT_MESSAGE_WAIT = 5000

export default class Messenger {
    static getTransport(pusherClient) {
        return pusherClient &&
            pusherClient.connection &&
            pusherClient.connection.connection
            ? pusherClient.connection.connection.transport.name
            : null
    }

    static disconnect(pusherClient) {
        if (pusherClient) {
            pusherClient.disconnect()
            pusherClient = null
        }
    }

    static subscribe(
        pusherClient,
        channelName,
        eventName = null,
        callback = null
    ) {
        return new Promise((res, rej) => {
            if (pusherClient) {
                let channel = pusherClient.channel(channelName)
                if (channel) {
                    if (eventName && callback) {
                        channel.bind(eventName, callback)
                    }
                    res(channel)
                } else {
                    channel = pusherClient.subscribe(channelName)
                    channel.bind('pusher:subscription_error', function(error) {
                        rej(
                            Error(
                                `Pusher returned ${error.type} ${
                                    error.error
                                } for channel (${channelName}) subscription.`
                            )
                        )
                    })
                    channel.bind('pusher:subscription_succeeded', function() {
                        if (eventName && callback) {
                            channel.bind(eventName, callback)
                        }
                        res(channel)
                    })
                }
                return true
            } else {
                rej(Error('Messenger has not been connected'))
            }
        })
    }

    static unsubscribeCallback(pusherClient, channelName, eventName, callback) {
        if (pusherClient) {
            const channel = pusherClient.channel(channelName)
            if (channel) {
                channel.unbind(eventName, callback)
            }
        }
    }

    static unsubscribe(pusherClient, channelName) {
        if (pusherClient) pusherClient.unsubscribe(channelName)
    }

    static sendMessage(pusherClient, channelName, eventName, payload = {}) {
        return Messenger.subscribe(pusherClient, channelName).then(c => {
            if (c) {
                c.trigger(eventName, payload)
            }
        })
    }

    static waitForMessage(
        pusherClient,
        channelName,
        eventName,
        timeout = DEFAULT_MESSAGE_WAIT
    ) {
        return new Promise((res, rej) => {
            let complete = false

            function onError(err) {
                if (!complete) {
                    complete = true
                    Messenger.unsubscribeCallback(
                        pusherClient,
                        eventName,
                        onResponseReceived
                    )
                    rej(err)
                }
            }

            function onResponseReceived(message) {
                if (!complete) {
                    complete = true
                    Messenger.unsubscribeCallback(
                        pusherClient,
                        eventName,
                        onResponseReceived
                    )
                    res(message)
                }
            }

            if (timeout > 0) {
                setTimeout(() => {
                    onError(
                        Error(
                            `Did not receive message ${eventName} within the timeout period.`
                        )
                    )
                }, timeout)
            }

            Messenger.subscribe(
                pusherClient,
                channelName,
                eventName,
                onResponseReceived
            ).catch(onError)
        })
    }

    static startBeacon(
        pusherClient,
        channelName,
        eventName,
        payload = {},
        frequency = DEFAULT_BEACON_FREQUENCY,
        options = { backoffEnabled: false }
    ) {
        Messenger.stopBeacon(channelName, eventName)

        const timer = new Timer(
            () => {
                Messenger.sendMessage(
                    pusherClient,
                    channelName,
                    eventName,
                    payload
                )
            },
            frequency,
            options.backoffEnabled
        )

        const uniqueId = `${channelName}-${eventName}`
        beacons[uniqueId] = timer
        timer.start()
    }

    static stopBeacon(channelName, eventName) {
        const uniqueId = `${channelName}-${eventName}`
        if (beacons[uniqueId]) {
            beacons[uniqueId].stop()
            beacons[uniqueId] = null
        }
    }

    static sendMessageWithResponse(
        pusherClient,
        channelName,
        eventName,
        payload,
        responseEvent,
        timeout = DEFAULT_MESSAGE_WAIT,
        broadcastFrequency = DEFAULT_BEACON_FREQUENCY
    ) {
        return new Promise((res, rej) => {
            function onComplete(message, err) {
                Messenger.stopBeacon(channelName, eventName)
                if (err) rej(err)
                else res(message)
            }

            Messenger.startBeacon(
                pusherClient,
                channelName,
                eventName,
                payload,
                broadcastFrequency
            )
            Messenger.waitForMessage(
                pusherClient,
                channelName,
                responseEvent,
                timeout
            )
                .then(m => onComplete(m))
                .catch(err => onComplete(null, err))
        })
    }
}
