import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openPopup, closePopup } from 'actions/popup'
import { requestFreeTrialExtension } from 'actions/tutor'
import BaseComponent from 'components/base-component'

import ExtendFreeTrial from 'components/extend-free-trial'

class FreeTrialExpiredPopup extends BaseComponent {
    componentDidMount() {
        this.showPopup()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tutor !== this.props.tutor) {
            this.showPopup()
        }
    }

    handleDismiss = event => {
        event.preventDefault()
        this.props.closePopup()
    }

    handleSubmit = (values, callback) => {
        if (!this.state.isRequestActive) {
            this.startRequests([
                this.props.requestFreeTrialExtension(values),
            ]).then(() => {
                callback()
            })
        }
    }

    showPopup = () => {
        const { tutor, openPopup } = this.props
        const params = new URLSearchParams(this.props.location.search)
        const showFormOverride = params.get('show-extend-free-trial-form')

        /**
         * Show extend trial popup when
         * - Account free trial is expired(today is past expires date)
         * - Account has never extended free trial Or has pending extend trial request
         */
        const show =
            tutor &&
            tutor.freeTrial &&
            tutor.freeTrial.expired &&
            (tutor.freeTrial.extensionCount === 0 ||
                !tutor.freeTrial.pending_extension)
        if (show || showFormOverride) {
            openPopup(
                <ExtendFreeTrial
                    onClose={this.handleDismiss}
                    onSubmit={this.handleSubmit}
                    isFormBusy={this.state.isRequestActive}
                    tutor={tutor}
                />
            )
        }
    }

    render() {
        return null
    }
}
const mapStateToProps = state => {
    return {
        tutor: state.tutor,
        popup: state.popup,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { openPopup, closePopup, requestFreeTrialExtension }
    )(FreeTrialExpiredPopup)
)
