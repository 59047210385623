import React from 'react'
import styles from './index.css'

export default props => {
    return (
        <div className={styles.root}>
            <div className={styles.header}>Please turn your tablet</div>
            <div className={`${styles.notice} ${styles.landscape}`}>
                <div className={styles.illustration} />
                <div className={styles.caption}>Yes, like this</div>
            </div>
            <div className={`${styles.notice} ${styles.portrait}`}>
                <div className={styles.illustration} />
                <div className={styles.caption}>Not like this</div>
            </div>
        </div>
    )
}
