import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { openPopup, closePopup } from 'actions/popup'
import { requestFreeTrialExtension } from 'actions/tutor'
import FreeTrialBanner from './FreeTrialBanner'

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
        popup: state.popup,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        { openPopup, closePopup, requestFreeTrialExtension }
    )(FreeTrialBanner)
)
