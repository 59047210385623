import PropTypes from 'prop-types'
import React from 'react'
import styles from './index.css'

const Notice = ({
    header,
    subheader,
    details,
    icon,
    ctaBtnText,
    onClick,
    linkBtn,
}) => {
    let iconComponent
    if (!!icon) {
        iconComponent = (
            <div className={styles.icon}>
                <img src={icon} alt="" />
            </div>
        )
    }

    return (
        <div>
            <div className={styles.header} />
            <div className={styles.content}>
                <div className={styles.textLarge}>{header}</div>
                <div className={styles.textLarge}>{subheader}</div>
                {iconComponent}
                <div className={styles.textSmall}>{details}</div>
                <div className={styles.linkContainer}>
                    <button
                        className={linkBtn ? styles.link : styles.button}
                        onClick={onClick}
                    >
                        {ctaBtnText}
                    </button>
                </div>
            </div>
        </div>
    )
}

Notice.propTypes = {
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    details: PropTypes.string,
    ctaBtnText: PropTypes.string,
    onClick: PropTypes.func,
    linkBtn: PropTypes.bool,
}

export default Notice
