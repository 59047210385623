import { APP_CHECK_FOR_UPDATES, APP_UPDATE } from 'actions'

import * as serviceWorker from 'serviceWorker'
import { Store } from 'containers/app'

export function checkForUpdates() {
    serviceWorker.update()

    return {
        type: APP_CHECK_FOR_UPDATES,
    }
}

// Service worker isn't connected to redux, so dispatch it directly.
export function update() {
    Store.dispatch({
        type: APP_UPDATE,
    })
}
