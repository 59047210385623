import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import styles from './index.css'

export const SIZE_LARGE = 'large'
export const SIZE_SMALL = 'small'
export const SHAPE_ROUND = 'round'
export const SHAPE_BASIC = 'basic'

const SubmitButton = props => {
    const onClick = () => {
        if (props.allowSubmit && props.onClick) props.onClick()
    }
    const style = {}
    if (props.height) style.height = `${props.height}px`
    if (props.width) style.width = `${props.width}px`
    if (props.fontSize) style.fontSize = `${props.fontSize}px`

    const classes = classNames(
        styles.button,
        props.className,
        { [styles.small]: props.size === SIZE_SMALL },
        { [styles.basic]: props.shape === SHAPE_BASIC },
        { [styles.disabled]: !props.allowSubmit }
    )

    return (
        <button
            type="submit"
            onClick={onClick}
            className={classes}
            style={style}
        >
            {props.content}
        </button>
    )
}

SubmitButton.propTypes = {
    allowSubmit: PropTypes.bool.isRequired,
    size: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired,
    className: PropTypes.string,
}

SubmitButton.defaultProps = {
    allowSubmit: true,
    size: SIZE_LARGE,
    shape: SHAPE_ROUND,
    className: '',
}

export default SubmitButton
