import Auth from 'util/auth'
import { objKeysToCamelCase } from 'util/misc'
import {
    TUTOR_UPDATE_ACCOUNT,
    AUTH_TUTOR,
    ADMIN_TUTORS_IMPERSONATE,
    AUTH_LOGOUT,
    TUTOR_GET_TUTOR_TYPES,
    TUTOR_CHANGE_PASSWORD,
    TUTOR_SET_ONBOARDED,
    TUTOR_SET_TRIED_DEMO_SESSION,
    TUTOR_SET_SHOW_FREE_TRIAL_TUTORIAL,
    ADMIN_TUTORS_GET_TUTOR_STUDENTS,
    ADMIN_TUTORS_GET_TUTOR_STUDENTS_COUNT,
    INITIALIZE,
} from 'actions'

const initialState = {
    students: [],
    studentsCount: 0,
    hasStudentAccess: false,
}

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case TUTOR_SET_ONBOARDED:
        case TUTOR_SET_TRIED_DEMO_SESSION:
        case TUTOR_CHANGE_PASSWORD:
        case TUTOR_UPDATE_ACCOUNT:
            return {
                ...initialState,
                ...objKeysToCamelCase(action.payload),
                isLoggedIn: true,
                hasStudentAccess: action.payload.hasStudentAccess,
                tutorTypes: state.tutorTypes,
            }
        case AUTH_TUTOR:
        case ADMIN_TUTORS_IMPERSONATE:
        case INITIALIZE:
            return {
                ...initialState,
                ...objKeysToCamelCase(action.payload.tutorProfile),
                isLoggedIn: true,
                hasStudentAccess: action.payload.hasStudentAccess,
            }
        case TUTOR_GET_TUTOR_TYPES:
            return {
                ...state,
                tutorTypes: action.payload,
            }
        case TUTOR_SET_SHOW_FREE_TRIAL_TUTORIAL:
            return {
                ...state,
                showFreeTrialTutorial: action.payload,
            }
        case ADMIN_TUTORS_GET_TUTOR_STUDENTS:
            return {
                ...state,
                students: action.payload,
            }
        case ADMIN_TUTORS_GET_TUTOR_STUDENTS_COUNT:
            return {
                ...state,
                studentsCount: action.payload.count,
            }
        case AUTH_LOGOUT:
            Auth.logout()
            return null
        default:
            return state
    }
}
