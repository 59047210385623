import {
    APP_LAYOUT_CONFIGURED,
    APP_LAYOUT_RESET,
    APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED,
} from 'actions'

export function setBackgroundColor(color) {
    return {
        type: APP_LAYOUT_CONFIGURED,
        payload: { backgroundColor: color },
    }
}

export function hideMenu() {
    return {
        type: APP_LAYOUT_CONFIGURED,
        payload: { hideMenu: true },
    }
}

export function resetAppLayout() {
    return {
        type: APP_LAYOUT_RESET,
    }
}

export function enablePortraitWarnings() {
    return {
        type: APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED,
        payload: true,
    }
}

export function disablePortraitWarnings() {
    return {
        type: APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED,
        payload: false,
    }
}
