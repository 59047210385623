import React from 'react'

import monsters from './images/app-update-warning-monsters.png'

import styles from './index.css'

export default () => {
    const reload = () => {
        window.location.reload()
    }
    return (
        <div className={styles.root}>
            <div className={styles.title}>New version detected…</div>
            <img className={styles.monsters} src={monsters} alt="monsters" />
            <div className={styles.message}>
                Booknook is restarting. If you do not get redirected,{' '}
                <span className={styles.link} onClick={reload}>
                    click here
                </span>
                .
            </div>
        </div>
    )
}
