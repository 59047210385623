import {
    SESSION_CONNECTION_AUTH,
    SESSION_SET,
    SESSION_END,
    SESSION_RESET_STUDENT_NEEDS_ATTENTION,
    SESSION_ACKNOWLEDGE_STUDENT_STATUS_TIMEOUT_RESET,
    SESSION_VOCABULARY_GET_RECENT_GAME_QUESTIONS,
    AUTH_LOGOUT,
    LOAD_PDF_BOOK,
} from 'actions'

export default (state = null, action) => {
    if (action.error) {
        return action.payload.name === 'FirebaseError'
            ? { ...state, error: action.payload.name }
            : state
    }

    switch (action.type) {
        case LOAD_PDF_BOOK:
            return {
                ...state,
                pdf: { data: action.payload },
            }
        case SESSION_CONNECTION_AUTH:
            return {
                ...state,
                sessionAuthToken: action.payload.token,
            }
        case SESSION_RESET_STUDENT_NEEDS_ATTENTION:
            return {
                ...state,
                pendingResetStudentStatusTimeout: true,
            }
        case SESSION_ACKNOWLEDGE_STUDENT_STATUS_TIMEOUT_RESET:
            return {
                ...state,
                pendingResetStudentStatusTimeout: false,
            }
        case SESSION_VOCABULARY_GET_RECENT_GAME_QUESTIONS:
            return {
                ...state,
                recentVocabularyGameQuestions: action.payload,
            }
        case SESSION_SET:
            return {
                ...state,
                ...action.payload,
            }
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
