import PropTypes from 'prop-types'
import React from 'react'
import styles from './index.css'

const BusyIndicator = props => {
    const style = {
        height: `${props.size}px`,
        width: `${props.size}px`,
    }
    if (!!props.color) {
        style.borderLeftColor = props.color
    }
    return <div className={styles.loader} style={style} />
}

BusyIndicator.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
}

BusyIndicator.defaultProps = {
    size: 36,
}

export default BusyIndicator
