import PropTypes from 'prop-types'
import React from 'react'
import InputFormField from '../input-form-field'
import styles from './index.css'

const propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    initialValue: PropTypes.array,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    isRequired: PropTypes.bool,
    validationError: PropTypes.string,
    primaryFocus: PropTypes.bool,
    onChange: PropTypes.func,
    onBeforeItemRemoved: PropTypes.func,
    onValidChange: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
}

const defaultProps = {
    initialValue: [],
    minLength: 1,
    primaryFocus: false,
    isRequired: true,
}

function ListItem(props) {
    return (
        <div id={props.item.id} className={styles.listItem}>
            <div className={styles.listItemValue}>{props.item.value}</div>
            <div
                className={styles.removeButton}
                onClick={() => props.onItemRemoved(props.item)}
            >
                X
            </div>
        </div>
    )
}

class ListFormField extends InputFormField {
    constructor() {
        super()
        this.onKeyPress = this.onKeyPress.bind(this)
        this.onInputTextChanged = this.onInputTextChanged.bind(this)
        this.onItemRemoved = this.onItemRemoved.bind(this)
        this.onBeforeItemRemoved = this.onBeforeItemRemoved.bind(this)
        this.state = {
            fieldValue: [],
            fieldEntered: false,
            fieldValid: false,
            textValue: '',
            textFieldTimestamp: new Date().getTime(),
        }
    }

    generateTempId() {
        return Math.floor(Math.random() * 1000000)
    }

    onKeyPress(event) {
        if (event.charCode === 13) {
            event.preventDefault()
            event.stopPropagation()

            if (this.state.textValue) {
                let newValue = this.state.fieldValue.slice(0)
                newValue.push({
                    id: this.generateTempId(),
                    value: this.state.textValue,
                    isNew: true,
                })
                this.changeFieldValue(newValue)
                this.setState({
                    textValue: this.textValue,
                    textFieldTimestamp: new Date().getTime(),
                })
            }
        }
    }

    onInputTextChanged(event) {
        this.setState({ textValue: event.target.value })
    }

    onBeforeItemRemoved(item) {
        if (this.props.onBeforeItemRemoved) {
            this.props.onBeforeItemRemoved(item, () => this.onItemRemoved(item))
        } else {
            this.onItemRemoved(item)
        }
    }

    onItemRemoved(item) {
        let newValue = this.state.fieldValue.slice(0)
        const index = this.state.fieldValue.indexOf(item)
        this.changeFieldEntered()
        if (index >= 0) {
            newValue.splice(index, 1)
            this.changeFieldValue(newValue)
        }
    }

    buildInput() {
        let inputField
        if (
            this.state.fieldValue &&
            (!this.props.maxLength ||
                this.state.fieldValue.length < this.props.maxLength)
        ) {
            inputField = (
                <input
                    className={styles.input}
                    key={this.state.textFieldTimestamp}
                    ref="input"
                    type="text"
                    placeholder={this.props.placeholder}
                    onChange={this.onInputTextChanged}
                    onBlur={this.changeFieldEntered}
                    onKeyPress={this.onKeyPress}
                />
            )
        }

        return (
            <div>
                <div>
                    {this.state.fieldValue.map(i => (
                        <ListItem
                            key={i.id}
                            item={i}
                            onItemRemoved={this.onBeforeItemRemoved}
                        />
                    ))}
                </div>
                {inputField}
            </div>
        )
    }
}

ListFormField.propTypes = propTypes
ListFormField.defaultProps = defaultProps

export default ListFormField
