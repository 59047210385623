import { SESSION_SET, SESSION_END, AUTH_LOGOUT } from 'actions'

import { K_ONE_LESSON_TYPE } from 'util/lesson-types'

const DEFAULT_STATE = {
    activeActivity: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    if (
        !!action.payload &&
        !!action.payload.lesson &&
        !!action.payload.lesson.lesson_type &&
        action.payload.lesson.lesson_type.code === K_ONE_LESSON_TYPE
    ) {
        if (action.type === SESSION_SET) {
            const session = action.payload
            const activeActivity = session.lesson.activities.find(
                a => a.id === session.state.activeActivityId
            )

            return {
                ...state,
                activeActivity,
            }
        }
    }

    switch (action.type) {
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
