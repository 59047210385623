import {
    SESSION_SET,
    SESSION_END,
    SESSION_LEVELING_GET_PASSAGE,
    SESSION_LEVELING_UPDATE_RECORD,
    SESSION_LEVELING_UPDATE_ASSESSMENT_PROGRESS,
    SESSION_LEVELING_GET_SCORE,
    AUTH_LOGOUT,
} from 'actions'
import { LEVELING_SESSION_TYPE } from 'util/session-types'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_SET:
            if (
                !!action.payload.session_type &&
                action.payload.session_type.code !== LEVELING_SESSION_TYPE
            )
                return state

            const result = {
                ...state,
                ...action.payload.state.leveling,
            }

            if (!!action.payload.students) {
                result.selectedStudent = action.payload.students.find(
                    s => s.id === result.selectedStudentId
                )
            }

            return result
        case SESSION_LEVELING_GET_PASSAGE:
            return {
                ...state,
                passage: action.payload,
            }
        case SESSION_LEVELING_UPDATE_RECORD:
            return {
                ...state,
                record: {
                    ...state.record,
                    ...action.meta,
                },
            }
        case SESSION_LEVELING_GET_SCORE:
            return {
                ...state,
                levelingScore: action.payload,
            }
        case SESSION_LEVELING_UPDATE_ASSESSMENT_PROGRESS:
            const updateAssessmentProgressResult = { ...state }

            updateAssessmentProgressResult.assessmentProgress[
                action.meta.passageId
            ][action.meta.studentId] = {
                ...state.assessmentProgress[action.meta.passageId][
                    action.meta.studentId
                ],
                ...action.meta.assessmentProgress,
            }

            return updateAssessmentProgressResult
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
