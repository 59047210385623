import PropTypes from 'prop-types'
import InputFormField from '../input-form-field'

const propTypes = {
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onValidChange: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
}

const defaultProps = {
    initialValue: '',
    inputType: 'text',
    isRequired: true,
    minLength: 1,
    primaryFocus: false,
    validationError: 'Please enter a valid phone number.',
}

class PhoneFormField extends InputFormField {
    constructor() {
        super()
        this.onKeyPress = this.onKeyPress.bind(this)
        this.validateFieldValue = this.validateFieldValue.bind(this)
    }

    onKeyPress(event) {
        if (event.charCode === 32) {
            event.preventDefault()
            event.stopPropagation()
        } else if (this.props.onKeyPress) {
            this.props.onKeyPress(event)
        }
    }

    validateFieldValue(phone) {
        const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
        return this.props.isRequired
            ? !!phone && phoneNumberPattern.test(phone)
            : !phone || phoneNumberPattern.test(phone)
    }
}

PhoneFormField.propTypes = propTypes
PhoneFormField.defaultProps = defaultProps

export default PhoneFormField
