import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ErrorBoundary from 'containers/app/app-container/error-boundary'
import Root from 'containers/app/Root'
import LoginContainers from 'containers/login'
import TutorContainers from 'containers/tutor'
import StudentContainers from 'containers/student'
import ManageSchoolContainers from 'containers/manage-school'
import AdminContainers from 'containers/admin'
import BookTestContainers from 'containers/book-test'
import NetworkTestContainers from 'containers/network-test'
import Loading from 'containers/loading'
import Logout from 'containers/logout'
import StudentRoute from 'components/student-route'
import TutorRoute from 'components/tutor-route'
import TutorAdminRoute from 'components/tutor-admin-route'
import TutorPublisherAdminRoute from 'components/tutor-publisher-admin-route'

const NotFound = lazy(() => import('containers/not-found'))

function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route exact path="/" component={Root} />
                    <Route
                        exact
                        path="/book_tester"
                        component={BookTestContainers.Main}
                    />
                    <Route
                        exact
                        path="/network_test"
                        component={NetworkTestContainers.Main}
                    />

                    <Route
                        exact
                        path="/clever/oauth"
                        component={LoginContainers.OAuth}
                    />

                    <Route
                        exact
                        path="/tutor/onboarding"
                        component={TutorContainers.ChangePassword}
                    />
                    <Route
                        exact
                        path="/tutor/no_access"
                        component={TutorContainers.NoAccess}
                    />
                    <Route
                        exact
                        path="/tutor/login"
                        component={LoginContainers.Tutor}
                    />
                    <Route
                        exact
                        path="/tutor/claim"
                        component={LoginContainers.ClaimTutor}
                    />
                    <Route
                        exact
                        path="/tutor/reset_password"
                        component={TutorContainers.ResetPassword}
                    />
                    <TutorRoute
                        exact
                        path="/tutor/account"
                        component={TutorContainers.Account}
                    />

                    <TutorRoute
                        exact
                        path="/session_setup/:stage/:sessionType/:sessionTypeStage?"
                        component={TutorContainers.Session}
                    />
                    <TutorRoute
                        exact
                        path="/session_setup/:stage?"
                        component={TutorContainers.Session}
                    />

                    <TutorRoute
                        exact
                        path="/tutor/sessions/demo"
                        component={TutorContainers.SessionDemo}
                    />
                    <TutorRoute
                        exact
                        path="/tutor/sessions/:id"
                        component={TutorContainers.Session}
                    />
                    <TutorRoute
                        exact
                        path="/tutor/session_groups/:id"
                        component={TutorContainers.SessionGroup}
                    />

                    <StudentRoute
                        exact
                        path="/student"
                        component={StudentContainers.Session}
                    />
                    <Route
                        path="/student/login"
                        component={LoginContainers.Student}
                    />
                    <StudentRoute
                        path="/student/sessions/:id"
                        component={StudentContainers.Session}
                    />

                    <Redirect
                        exact
                        from="/schools/:schoolId"
                        to="/schools/:schoolId/overview"
                    />
                    <TutorRoute
                        path="/schools/:schoolId"
                        component={ManageSchoolContainers.Home}
                    />

                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/books"
                        component={AdminContainers.Books}
                    />
                    <TutorAdminRoute
                        exact
                        path="/admin/books/create"
                        component={AdminContainers.CreateBook}
                    />
                    <Route
                        exact
                        path="/admin/books/:id/preview"
                        component={AdminContainers.PreviewBook}
                    />
                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/books/:id/stopping_points"
                        component={AdminContainers.StoppingPoints}
                    />
                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/books/:id"
                        component={AdminContainers.EditBook}
                    />

                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/lessons"
                        component={AdminContainers.Lessons}
                    />
                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/lessons/create"
                        component={AdminContainers.CreateLesson}
                    />
                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/lessons/:id/activate"
                        component={AdminContainers.ActivateLesson}
                    />
                    <TutorPublisherAdminRoute
                        exact
                        path="/admin/lessons/:id"
                        component={AdminContainers.EditLesson}
                    />

                    <TutorAdminRoute
                        exact
                        path="/admin/passages"
                        component={AdminContainers.Passages}
                    />
                    <TutorAdminRoute
                        path="/admin/passages/create"
                        component={AdminContainers.CreatePassage}
                    />
                    <TutorAdminRoute
                        path="/admin/passages/:id"
                        component={AdminContainers.EditPassage}
                    />

                    <TutorAdminRoute
                        exact
                        path="/admin/schools"
                        component={AdminContainers.Schools}
                    />
                    <TutorAdminRoute
                        path="/admin/schools/create"
                        component={AdminContainers.CreateSchool}
                    />
                    <TutorAdminRoute
                        path="/admin/schools/:id/features"
                        component={AdminContainers.ManageSchoolFeatures}
                    />
                    <TutorAdminRoute
                        path="/admin/schools/:id/publishers"
                        component={AdminContainers.ManageSchoolPublishers}
                    />
                    <TutorAdminRoute
                        path="/admin/schools/:id"
                        component={AdminContainers.EditSchool}
                    />
                    <TutorAdminRoute
                        exact
                        path="/admin/multisites"
                        component={AdminContainers.Multisites}
                    />
                    <TutorAdminRoute
                        path="/admin/multisites/create"
                        component={AdminContainers.CreateMultisite}
                    />
                    <TutorAdminRoute
                        path="/admin/multisites/:id/schools"
                        component={AdminContainers.EditMultisiteSchools}
                    />
                    <TutorAdminRoute
                        path="/admin/multisites/:id"
                        component={AdminContainers.EditMultisite}
                    />
                    <TutorAdminRoute
                        exact
                        path="/admin/standards"
                        component={AdminContainers.Standards}
                    />
                    <TutorAdminRoute
                        path="/admin/standards/create"
                        component={AdminContainers.CreateStandard}
                    />
                    <TutorAdminRoute
                        path="/admin/standards/:id"
                        component={AdminContainers.EditStandard}
                    />
                    <TutorAdminRoute
                        exact
                        path="/admin/students"
                        component={AdminContainers.Students}
                    />
                    <TutorAdminRoute
                        path="/admin/students/create"
                        component={AdminContainers.CreateStudent}
                    />
                    <TutorAdminRoute
                        path="/admin/students/:id/devices"
                        component={AdminContainers.StudentDevices}
                    />
                    <TutorAdminRoute
                        path="/admin/students/:id"
                        component={AdminContainers.EditStudent}
                    />
                    <TutorAdminRoute
                        exact
                        path="/admin/tutors"
                        component={AdminContainers.Tutors}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/create"
                        component={AdminContainers.CreateTutor}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id/edit"
                        component={AdminContainers.EditTutor}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id/schools"
                        component={AdminContainers.TutorSchools}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id/multisites"
                        component={AdminContainers.TutorMultisites}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id/admin_schools"
                        component={AdminContainers.TutorAdminSchools}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id/publishers"
                        component={AdminContainers.TutorPublishers}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id/devices"
                        component={AdminContainers.TutorDevices}
                    />
                    <TutorAdminRoute
                        path="/admin/tutors/:id"
                        component={AdminContainers.TutorProfile}
                    />
                    <TutorAdminRoute
                        path="/admin/platform_metrics"
                        component={AdminContainers.PlatformMetrics}
                    />
                    <TutorAdminRoute
                        path="/admin/school_metrics"
                        component={AdminContainers.SchoolMetrics}
                    />
                    <TutorAdminRoute
                        path="/admin/tutoring"
                        component={AdminContainers.Tutoring}
                    />

                    <Logout path="/logout" component={Logout} />

                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </ErrorBoundary>
    )
}

export default Routes
