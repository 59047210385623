import { SESSION_SET, SESSION_END, AUTH_LOGOUT } from 'actions'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_SET:
            if (!!action.payload.state.vocabulary) {
                return {
                    ...state,
                    ...action.payload.state.vocabulary,
                }
            } else {
                return state
            }
        case SESSION_END:
        case AUTH_LOGOUT:
            return null
        default:
            return state
    }
}
