import React from 'react'
import styles from './terms-of-service.css'

export const TermsOfService = () => (
    <div className={styles.root}>
        <div>
            <div>
                <div>
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h2>
                                        <span>Terms of Service</span>
                                    </h2>
                                    <div>
                                        <ul>
                                            <li>
                                                <a href="#spanish">
                                                    Haga clic aquí para español
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#chinese">
                                                    點擊這裡轉換中文
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#bengali">
                                                    বাংলার জন্য এখানে ক্লিক করুন
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#urdu">
                                                    اردو زبان کے لئے یہاں کلک
                                                    کریں
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#arabic">
                                                    للغة العربية اضغط هنا
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>{' '}
                        <div>
                            <div>
                                <h3>
                                    Welcome to BookNook. We want to protect your
                                    rights and those of any other user or third
                                    party who interacts with us, we created
                                    these Terms of Service (“Terms”). By
                                    accessing or using the BookNook
                                    applications, website, services, software,
                                    and/or content made available by BookNook
                                    Inc. (“the BookNook Service,” “we,” and
                                    “us”), you agree to these Terms. If you
                                    don’t agree to these Terms, do not access or
                                    use the BookNook Service.
                                </h3>
                                <p />
                                <h3>1. You and Your Account</h3>
                                <p>
                                    By registering for the BookNook Service you
                                    affirm that you are (i) 18 years of age or
                                    the applicable age of majority or older in
                                    your province, territory, or country, or
                                    (ii) an emancipated minor. You also affirm
                                    that you are fully able and competent to
                                    enter into these Terms and to abide by and
                                    comply with them.
                                </p>
                                <p>
                                    We may provide you with a unique ID and
                                    ability to select a BookNook avatar
                                    (“Avatar”) to represent your account within
                                    the BookNook Service. If you register and
                                    subscribe, you agree to: (a) provide
                                    accurate and current information as prompted
                                    by the registration form; (b) maintain and
                                    update such information so that it is
                                    accurate and current at all times; (c) not
                                    share your password or unique ID or let
                                    anyone else access your account other than
                                    in those situations permitted under these
                                    Terms, or do anything else that might
                                    jeopardize the security of your account; (d)
                                    not use a username with the intent to
                                    impersonate another person or in a manner
                                    that infringes another person’s rights; (e)
                                    not use a username that is obscene or
                                    vulgar; and (f) immediately notify us of any
                                    unauthorized use of your password or any
                                    other breach of security related to the
                                    BookNook Service. WHEN YOU SHARE YOUR
                                    PASSWORD OR ALLOW A THIRD PARTY TO ACCESS
                                    YOUR BOOKNOOK ACCOUNT, YOU AGREE TO REMAIN
                                    RESPONSIBLE FOR COMPLIANCE WITH THESE TERMS
                                    BY ANY SUCH THIRD PARTY. WE WILL NOT BE
                                    LIABLE FOR ANY LOSS OR DAMAGE ARISING FROM
                                    YOUR FAILURE TO ADEQUATELY SAFEGUARD YOUR
                                    PASSWORD OR FOR ANY ACTIONS OCCURRING UNDER
                                    YOUR PASSWORD.
                                    <br />
                                    Your use of the BookNook Service is subject
                                    to all applicable technical and non-
                                    technical specifications, guidelines and
                                    policies which we may provide from time to
                                    time including but not limited to the
                                    Privacy Policy located at{' '}
                                    <a href="https://www.booknooklearning.com/privacy/">
                                        booknooklearning.com/privacy
                                    </a>{' '}
                                    (hereinafter collectively referred to as
                                    “the Policies”) you agree and undertake to
                                    comply with the Policies. Right now, the
                                    BookNook Service is only intended for use by
                                    citizens of the United States and Canada and
                                    anyone who accesses or uses the BookNook
                                    Service from outside of the United States or
                                    Canada does so at his/her own risk and is
                                    responsible for compliance with applicable
                                    local laws.
                                </p>
                                <p />
                                <h3>2. Subscription Members</h3>
                                <p>
                                    Although certain features and/or content of
                                    the BookNook Service may be available at no
                                    charge to certain users, other features
                                    and/or content may only be available to who
                                    agree to pay for a subscription plan (each,
                                    a “Subscription Member”). Each subscription
                                    plan may be described in more detail in
                                    other areas of the BookNook Service. Any
                                    payment terms displayed to you in the
                                    process of becoming a Subscription Member
                                    are deemed part of these Terms.
                                </p>
                                <p />
                                <h3>3. Our License to You</h3>
                                <p>
                                    We grant you a personal, revocable,
                                    non-exclusive, non-transferable,
                                    non-sublicensable, world-wide, license to
                                    access and use the BookNook Service solely
                                    for personal, non-commercial purposes as
                                    permitted by these Terms and intended by us
                                    through the normal functionality of the
                                    BookNook Service. This license to you will
                                    automatically terminate if you violate these
                                    Terms. Except for the foregoing limited
                                    license, no right, title or interest is
                                    transferred to you. We may allow you to
                                    store encrypted, cached content on your
                                    tablet, smartphone or other compatible
                                    internet-connected device. You may not
                                    transfer copies of cached content to any
                                    other device.
                                </p>
                                <p />
                                <h3>4. Your Conduct</h3>
                                <p>
                                    We would like to keep the BookNook Service
                                    as safe as possible for our users but need
                                    your help to do so. Accordingly, you agree
                                    not to use the BookNook Service to: (i)
                                    violate any local, state, national, or
                                    international law or regulation; (ii)
                                    transmit any material that is abusive,
                                    harassing, tortious, defamatory, vulgar,
                                    pornographic, obscene, libelous, graphically
                                    violent, invasive of another’s privacy,
                                    hateful, or racially, ethnically, or
                                    otherwise objectionable; (iii) transmit any
                                    unsolicited or unauthorized advertising,
                                    promotional materials, junk mail, spam,
                                    chain letters, pyramid schemes, or any other
                                    form of solicitation; (iv) knowingly
                                    transmit any material that contains adware,
                                    malware, spyware, software viruses, or any
                                    other computer code, files, or programs
                                    designed to interrupt, destroy, or limit the
                                    functionality of any computer software or
                                    hardware or telecommunications equipment;
                                    (v) breach or circumvent any security or
                                    authentication measures (including
                                    geo-restriction measures); (vi) attempt to
                                    decompile or reverse engineer any software
                                    contained on the BookNook Service; (vii)
                                    remove any copyright, trademark, or other
                                    proprietary notations from any Content (as
                                    defined below) or User Submissions (as
                                    defined below) of other users; (viii) stalk,
                                    harass, or harm another individual; (ix)
                                    impersonate any person or entity, or
                                    otherwise misrepresent your affiliation with
                                    a person or entity; (x) interfere with or
                                    disrupt the BookNook Service or servers or
                                    networks connected to the BookNook Service,
                                    or disobey any requirements, procedures,
                                    policies, or regulations of networks
                                    connected to the BookNook Service; or (xi)
                                    rent or lease your account. You also agree
                                    not to use or launch any automated system,
                                    including without limitation, “robots,”
                                    “spiders,” “offline readers,” etc., that
                                    accesses the BookNook Service in a manner
                                    that sends more request messages to the
                                    BookNook Service servers in a given period
                                    of time than a human can reasonably produce
                                    in the same period by using a conventional
                                    web browser.
                                </p>
                                <p />
                                <h3>5. Ownership; Restrictions On Use</h3>
                                <p>
                                    All right, title, and interest in and to the
                                    BookNook Service site and services are and
                                    will remain the exclusive property of
                                    BookNook, LLC. and its licensors. The
                                    content on the BookNook Service, including
                                    without limitation, books, text, software,
                                    graphics, photos, sounds, music, videos,
                                    User Submissions (as defined below),
                                    interactive features and the like
                                    (“Content”) and the “BookNook” word mark and
                                    design mark, as well as certain other of the
                                    names, logos, and materials displayed on or
                                    through the BookNook Service that constitute
                                    trademarks, tradenames, service marks or
                                    logos (“Marks”) are owned by or licensed to
                                    BookNook, LLC and are subject to copyright,
                                    trademark, and other intellectual property
                                    rights under U.S. and international laws.
                                    You agree not to reproduce, duplicate, copy,
                                    sell, resell, or exploit for any purposes
                                    the BookNook Service or any portion of the
                                    BookNook Service, including, without
                                    limitation, Content and Marks, except as
                                    authorized by these Terms or as otherwise
                                    authorized in writing by us or our
                                    applicable licensors. You also agree to view
                                    Content (including but not limited to books
                                    and videos) as made available through the
                                    BookNook Service only in geographic
                                    locations where we offer the BookNook
                                    Service and have licensed such Content. The
                                    BookNook Service is provided for your
                                    personal, non-commercial use only. You must
                                    abide by all copyright notices, information,
                                    or restrictions contained in or associated
                                    with any Content.
                                </p>
                                <p />
                                <h3>6. User Submissions</h3>
                                <p>
                                    We may provide functionality that enables
                                    you to post, upload, submit or otherwise
                                    make available text (including quizzes, quiz
                                    responses, lesson plans), images, audio,
                                    video, presentations, competition entries or
                                    other content (“User Submissions”) to the
                                    BookNook Service. Access to such
                                    functionality may be restricted to users
                                    above a certain age. You understand that
                                    such User Submissions may be accessible by
                                    the public and we do not guarantee any
                                    confidentiality for such User Submissions.
                                </p>
                                <p>
                                    You retain all of your ownership rights to
                                    your User Submissions. For all User
                                    Submissions, by making those User
                                    Submissions available to the BookNook
                                    Service, you grant us a worldwide,
                                    non-exclusive, royalty-free, sublicensable
                                    and transferable license under all
                                    intellectual property rights to use,
                                    reproduce, distribute, display, and perform
                                    such User Submissions, in whole or in part,
                                    in connection with the BookNook Service and
                                    our (and our successors’ and affiliates’)
                                    business, including without limitation for
                                    promoting and redistributing part or all of
                                    the BookNook Service (and derivative works
                                    thereof) in any media formats and through
                                    any media channels now known or hereafter,
                                    with or without attribution, and without
                                    further notice to you, in perpetuity. You
                                    also grant each user of the BookNook Service
                                    a non-exclusive license to view your User
                                    Submission through the BookNook Service, and
                                    to use, reproduce, distribute, display and
                                    perform such User Submission as permitted
                                    through the normal functionality of the
                                    BookNook Service and these Terms in
                                    perpetuity.
                                </p>
                                <p>
                                    You may not submit User Submissions that are
                                    (i) defamatory, harassing, threatening,
                                    bigoted, hateful, violent, vulgar, obscene,
                                    pornographic, or otherwise offensive or that
                                    harms or can reasonably be expected to harm
                                    any person or entity, in each case whether
                                    or not such content is protected by law, or
                                    (ii) otherwise contrary to applicable local,
                                    national, and international laws and
                                    regulations.
                                </p>
                                <p>
                                    You may not submit User Submissions that
                                    contain third party copyrighted material, or
                                    material that is subject to other third
                                    party proprietary rights, unless you have
                                    permission from the rightful owner of the
                                    material or you are otherwise legally
                                    authorized to submit such content and grant
                                    us the licenses described above. You
                                    represent and warrant that you own or have
                                    the necessary licenses, rights, consents,
                                    and permissions to publish the User
                                    Submissions and that the User Submissions
                                    conform to the terms described in this
                                    Section 6. You also agree not to enforce any
                                    moral rights, ancillary rights or similar
                                    rights in or to the User Submissions against
                                    us or our licensees, distributors, agents,
                                    representatives and other authorized users,
                                    and agree to procure the same agreement not
                                    to enforce from others who may possess such
                                    rights. You understand and agree that you
                                    will be solely responsible for your User
                                    Submissions and the consequences of
                                    submitting and making available such User
                                    Submissions to the BookNook Service.
                                </p>
                                <p>
                                    We have the right but not the obligation to
                                    monitor, screen, post, remove, modify, store
                                    and review User Submissions submitted to the
                                    BookNook Service, at any time and for any
                                    reason, including to confirm that the User
                                    Submissions are consistent with these terms,
                                    without prior notice to you.
                                </p>
                                <p>
                                    We do not endorse or guarantee the opinions,
                                    recommendations, or advice contained in any
                                    User Submission and we expressly disclaim
                                    any and all liability in connection with
                                    such content.
                                </p>
                                <p />
                                <h3>7. Content Availability</h3>
                                <p>
                                    The BookNook Service provides its users with
                                    access to books and other Content streamed
                                    through the Internet to certain devices. The
                                    availability of these books and other
                                    Content may change from time to time, and
                                    from geographic territory to territory, for
                                    a number of reasons (including publisher
                                    deals and available Internet bandwidth). As
                                    a result, we do not guarantee that any
                                    Content will be available or remain
                                    available on the BookNook Service (including
                                    during any subscription period for a
                                    Subscription Member).
                                </p>
                                <p>
                                    Mobile Networks. If you access the BookNook
                                    Service through a mobile network, your
                                    network or roaming provider’s messaging,
                                    data and other rates and fees will apply.
                                    Downloading, installing or using certain
                                    BookNook Service features may be prohibited
                                    or restricted by your network provider and
                                    not all BookNook Service features may work
                                    with your network provider or device.
                                </p>
                                <p />
                                <h3>8. Termination</h3>
                                <p>
                                    Your right to use the BookNook Service
                                    automatically terminates if you violate
                                    these Terms or any rules or guidelines
                                    posted in connection with the BookNook
                                    Service. We reserve the right, in our sole
                                    discretion, to terminate your access to all
                                    or part of the BookNook Service, for any
                                    reason, with or without notice.
                                </p>
                                <p />
                                <h3>
                                    9. Links or Third Party Applications/Devices
                                </h3>
                                <p>
                                    Links. For parents or educators, the
                                    BookNook Service may contain links to third
                                    party websites or interact with third party
                                    applications (including, without limitation,
                                    widgets, software, or other software
                                    utilities) that are not owned or controlled
                                    by the BookNook Service. Our provision of a
                                    link to any other website or third party
                                    application is for your convenience only and
                                    does not signify our endorsement of such
                                    other website or resource or its contents.
                                    WE WILL HAVE NO RESPONSIBILITY OR LIABILITY
                                    FOR ANY CONTENT, INFORMATION, SOFTWARE,
                                    MATERIALS OR PRACTICES OF ANY THIRD PARTY
                                    WEBSITE OR THIRD PARTY APPLICATION.
                                </p>
                                <p>
                                    Devices. If you access the BookNook Service
                                    using an Apple iOS or Android-powered
                                    device, then Apple Inc. or Google, Inc.,
                                    respectively, will be a third-party
                                    beneficiary to this agreement. However,
                                    these third-party beneficiaries are not a
                                    party to this contract and are not
                                    responsible for the provision or support of
                                    the BookNook Service. You agree that your
                                    access to the BookNook Service using these
                                    devices also shall be subject to the usage
                                    terms set forth in the applicable
                                    third-party beneficiary’s terms of service.
                                </p>
                                <p />
                                <h3>10. Disclaimer of Warranties</h3>
                                <p>
                                    YOU EXPRESSLY AGREE THAT USE OF THE BOOKNOOK
                                    SERVICE IS AT YOUR SOLE RISK. THE BOOKNOOK
                                    SERVICE IS PROVIDED ON AN “AS IS” AND “AS
                                    AVAILABLE” BASIS. TO THE FULLEST EXTENT
                                    PERMITTED BY LAW, BOOKNOOK CREATIONS, INC.
                                    AND ITS AFFILIATES EXPRESSLY DISCLAIM ALL
                                    WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                                    IMPLIED, WITH RESPECT TO THE BOOKNOOK
                                    SERVICE (INCLUDING, BUT NOT LIMITED TO, THE
                                    IMPLIED WARRANTIES OF MERCHANTABILITY,
                                    FITNESS FOR A PARTICULAR USE OR PURPOSE, AND
                                    NON-INFRINGEMENT). We make no warranties or
                                    representations about the accuracy or
                                    completeness of content available on or
                                    through the BookNook Service or the content
                                    of any websites linked to the BookNook
                                    Service and assume no liability or
                                    responsibility for any: (i) errors,
                                    mistakes, or inaccuracies of content; (ii)
                                    personal injury or property damage, of any
                                    nature whatsoever, resulting from your
                                    access to or use of the BookNook Service or
                                    the Content on the BookNook Service; (iii)
                                    any unauthorized access to or use of our
                                    secure servers and/ or any and all personal
                                    information and/or financial information
                                    stored therein; (iv) any interruption or
                                    cessation of transmission to or from the
                                    BookNook Service; (v) any bugs, viruses,
                                    Trojan horses, or the like which may be
                                    transmitted to or through the BookNook
                                    Service by any third party; and/or (vi) for
                                    any loss or damage of any kind incurred as a
                                    result of the use of any content posted,
                                    emailed, transmitted, or otherwise made
                                    available on or through the BookNook
                                    Service.
                                </p>
                                <p />
                                <h3>11. Limitation of Liability</h3>
                                <p>
                                    YOU UNDERSTAND THAT TO THE EXTENT PERMITTED
                                    UNDER APPLICABLE LAW, IN NO EVENT WILL
                                    BOOKNOOK, LLC OR ITS OFFICERS, EMPLOYEES,
                                    DIRECTORS, SHAREHOLDERS, SUBSIDIARIES,
                                    AFFILIATES, AGENTS, OR LICENSORS BE LIABLE
                                    UNDER ANY THEORY OF LIABILITY (WHETHER IN
                                    CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR
                                    ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                                    CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                                    INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
                                    LOSS OF REVENUES, PROFITS, GOODWILL, USE,
                                    DATA OR OTHER INTANGIBLE LOSSES (EVEN IF
                                    SUCH PARTIES WERE ADVISED OF, KNEW OF OR
                                    SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
                                    DAMAGES), RESULTING FROM YOUR (OR ANYONE
                                    USING YOUR ACCOUNT’S) USE OF THE BOOKNOOK
                                    SERVICE.
                                </p>
                                <p />
                                <h3>12. Exclusions</h3>
                                <p>
                                    Some jurisdictions do not allow the
                                    exclusion of certain warranties or the
                                    limitation or exclusion of liability for
                                    incidental or consequential damages.
                                    Accordingly, some of the above limitations
                                    and disclaimers may not apply to you. To the
                                    extent BookNook, LLC may not, as a matter of
                                    applicable law, disclaim any implied
                                    warranty or limit its liabilities, the scope
                                    and duration of such warranty and the extent
                                    of our liability shall be the minimum
                                    permitted under such applicable law.
                                </p>
                                <p />
                                <h3>13. Indemnification</h3>
                                <p>
                                    You agree to indemnify, defend, and hold
                                    harmless BookNook, LLC, its subsidiaries,
                                    affiliates, officers, directors, employees,
                                    consultants and agents from and against any
                                    and all claims, liabilities, damages,
                                    losses, costs, expenses, fees (including
                                    reasonable attorneys’ fees and costs) that
                                    such parties may incur as a result of or
                                    arising from (a) any information (including,
                                    without limitation, your User Submissions,
                                    feedback, or any other content) you (or
                                    anyone using your account) submit, post, or
                                    transmit on or through the BookNook Service;
                                    (b) your (or anyone using your account’s)
                                    use of the BookNook Service; (c) your (or
                                    anyone using your account’s) violation of
                                    these Terms; or (d) your (or anyone using
                                    your account’s) violation of any rights of
                                    any other person or entity, including,
                                    without limitation, any copyright, patent,
                                    trademark, trade secret, right of
                                    publicity/privacy, or other proprietary
                                    rights of any person or entity. BookNook,
                                    LLC reserves the right, at its own expense,
                                    to assume the exclusive defense and control
                                    of any matter otherwise subject to
                                    indemnification by you, in which event you
                                    will cooperate with BookNook, LLC in
                                    asserting any available defenses.
                                </p>
                                <p />
                                <h3>14. Disputes</h3>
                                <p>
                                    These Terms shall be construed and enforced
                                    in accordance with the laws of the State of
                                    California without regard to any choice of
                                    law or conflict of laws principles,
                                    regardless of where you live. You will
                                    resolve any claim, cause of action or
                                    dispute you have with us arising out of or
                                    relating to these Terms or the BookNook
                                    Service exclusively in a state or federal
                                    court located in Alameda County, California.
                                    You agree to submit to the personal
                                    jurisdiction of the courts located in
                                    Alameda County, California for the purpose
                                    of litigation all such claims or causes of
                                    action.
                                </p>
                                <p />
                                <h3>15. Communications</h3>
                                <p>
                                    By using the BookNook Service, you agree
                                    that any notice, agreements, disclosure or
                                    other communications that we send to you
                                    electronically will satisfy any legal
                                    communications requirement (including that
                                    such communications must be in writing).
                                </p>
                                <p />
                                <h3>
                                    16. Additional Terms for Apple iOS
                                    Applications
                                </h3>
                                <p>
                                    The following additional terms and
                                    conditions apply with respect to our
                                    applications designed for use on an Apple
                                    iOS-powered mobile device obtained by you
                                    from the iTunes Store (“iOS App”). You agree
                                    that these Terms are solely between you and
                                    us, not Apple, and that Apple has no
                                    responsibility for our iOS App, the BookNook
                                    Service, or any Content. You agree that your
                                    use of our iOS App will be subject to the
                                    Usage Rules in Apple’s then-current App
                                    Store Terms of Service. The parties agree
                                    that Apple shall have no obligation to
                                    provide maintenance and support services
                                    with respect to our iOS App. In the event of
                                    any failure of the iOS App to conform to any
                                    applicable warranty, you may notify Apple,
                                    and Apple will refund the purchase price (if
                                    any) for our iOS App to you. To the maximum
                                    extent permitted by law, Apple will have no
                                    other warranty obligation with respect to
                                    our iOS App, and any other claims, losses,
                                    liabilities, damages, costs or expenses
                                    attributable to any failure to conform to
                                    any warranty will be solely governed by
                                    these Terms and any law applicable to us as
                                    provider of the software. You agree that we,
                                    and not Apple, are responsible for
                                    addressing any claims of you or any third
                                    party relating to our iOS App or your
                                    possession and/or use of our iOS App,
                                    including, but not limited to: (i) product
                                    liability claims; (ii) any claim that our
                                    iOS App fails to conform to any applicable
                                    legal or regulatory requirement; and (iii)
                                    claims arising under consumer protection or
                                    similar legislation; and all such claims are
                                    governed solely by these Terms and any law
                                    applicable to us as provider of the
                                    software. You agree that, in the event of
                                    any third party claim that our iOS App or
                                    your possession and use of that iOS App
                                    infringes such third party’s intellectual
                                    property rights, we, not Apple, will be
                                    responsible for the investigation, defense,
                                    settlement and discharge of such claim. The
                                    parties agree that Apple and its
                                    subsidiaries are third party beneficiaries
                                    to the Terms applicable to your use of our
                                    iOS App. Upon your acceptance of the Terms,
                                    Apple will have the right (and will be
                                    deemed to have accepted the right) to
                                    enforce the Terms applicable to your use of
                                    our iOS App against you as a third party
                                    beneficiary thereof.
                                </p>
                                <p />
                                <h3>17. Miscellaneous</h3>
                                <p>
                                    These Terms, together with any other rules
                                    or guidelines posted in connection with the
                                    BookNook Service constitute the entire and
                                    exclusive and final statement of the
                                    agreement between you and us. Our failure to
                                    exercise or enforce any right or provision
                                    of these Terms shall not constitute a waiver
                                    of such right or provision. If for any
                                    reason any provision of these Terms is found
                                    to be unenforceable, that provision shall be
                                    enforced to the maximum extent permissible
                                    so as to effect the intent of the parties as
                                    reflected in that provision, and the
                                    remainder of these Terms shall continue in
                                    full force and effect. The section titles in
                                    these Terms are for convenience only and
                                    have no legal or contractual effect. The
                                    terms of Sections 2, 5, 6, 7, 8, 9, 10, 11,
                                    12, 13, 14, 15, 16, 17, 18, 19, and 20 of
                                    these Terms, as well as any other
                                    limitations on liability explicitly set
                                    forth herein, shall remain in full force and
                                    effect notwithstanding any termination of
                                    your use of the BookNook Service. These
                                    Terms are personal to you and are not
                                    assignable, transferable or sublicensable by
                                    you except with our prior written consent.
                                    We may assign, transfer or delegate our
                                    rights and obligations under these Terms
                                    without consent from you. The section and
                                    subsection headings in these Terms are for
                                    convenience only and shall not affect their
                                    interpretation. Unless otherwise specified
                                    in these Terms, all notices must be in
                                    writing and will be treated as given on
                                    receipt, as verified by written automated
                                    receipt or by electronic logs (as
                                    applicable). The email address for notices
                                    being sent to the BookNook Service is{' '}
                                    <span>info@booknooklearning</span>.
                                </p>
                                <p />
                                <h3>18. Modifications</h3>
                                <p>
                                    We may, in our sole and absolute discretion,
                                    change these Terms from time to time. If we
                                    do, we will update this posting, send you
                                    notice through the BookNook Service, or
                                    email you. Your continued use of the
                                    BookNook Service constitutes your agreement
                                    to abide by the Terms as changed. If you
                                    object to any such changes, your sole
                                    recourse will be to stop using the BookNook
                                    Service.
                                </p>
                                <p />
                                <h3>19. Legal</h3>
                                <p>
                                    Apple, the Apple logo, iMac, MacBook Pro,
                                    and iPad are trademarks of Apple Inc.,
                                    registered in the U.S. and other countries.
                                    App Store is a service mark of Apple Inc.
                                </p>
                                <p />
                                <p>____________</p>
                                <p />
                                <p>Last updated: June 22, 2017.</p>
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div id="spanish">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h2>
                                        <span>Términos de Servicio</span>
                                    </h2>
                                </div>
                            </div>
                        </div>{' '}
                        <div>
                            <div>
                                <h3>
                                    Bienvenido a BookNook. Queremos proteger sus
                                    derechos y los de cualquier otro usuario o
                                    tercero que interactúe con nosotros, creamos
                                    estos Términos de Servicio (“Términos”). Al
                                    acceder o utilizar las aplicaciones, el
                                    sitio web, los servicios, el software y/o el
                                    contenido de BookNook que BookNook Inc. pone
                                    a su disposición (“el Servicio de BookNook”,
                                    “nosotros”), usted acepta estos Términos. Si
                                    no está de acuerdo con estos Términos, no
                                    acceda ni utilice el Servicio de BookNook.
                                </h3>
                                <p />
                                <h3>1. Usted y su Cuenta</h3>
                                <p>
                                    Al registrarse para el Servicio de BookNook
                                    usted afirma que (i) tiene 18 años de edad o
                                    la mayoría de edad aplicable en su
                                    provincia, territorio o país o más o (ii) es
                                    un menor emancipado. También afirma que es
                                    completamente capaz y competente para
                                    participar en estos Términos y para atenerse
                                    a ellos y cumplirlos.
                                </p>
                                <p>
                                    Podemos proporcionarle un ID único y la
                                    posibilidad de seleccionar un avatar de
                                    BookNook (“Avatar”) que represente su cuenta
                                    dentro del Servicio de BookNook. Si se
                                    registra y se suscribe, acepta: (a)
                                    proporcionar información precisa y
                                    actualizada según lo solicitado por el
                                    formulario de registro; (b) mantener y
                                    actualizar dicha información de modo que sea
                                    precisa y esté actualizada en todo momento;
                                    (c) no compartir su contraseña o ID único ni
                                    permitir que alguien más acceda a su cuenta
                                    salvo en aquellas situaciones permitidas
                                    bajo estos Términos, ni hacer cualquier otra
                                    cosa que pueda poner en peligro la seguridad
                                    de su cuenta; (d) no utilizar un nombre de
                                    usuario con la intención de hacerse pasar
                                    por otra persona o de una manera que viole
                                    los derechos de otra persona; (e) no
                                    utilizar un nombre de usuario que sea
                                    obsceno o vulgar; y (f) notificarnos de
                                    inmediato cualquier uso no autorizado de su
                                    contraseña o cualquier violación de
                                    seguridad relacionada con el Servicio de
                                    BookNook. CUANDO COMPARTE SU CONTRASEÑA O
                                    PERMITE QUE UN TERCERO ACCEDA A SU CUENTA DE
                                    BOOKNOOK, ACEPTA SER RESPONSABLE DEL
                                    CUMPLIMIENTO DE ESTOS TÉRMINOS POR PARTE DE
                                    CUALQUIER TERCERO. NO SEREMOS RESPONSABLES
                                    DE NINGUNA PÉRDIDA O DAÑO QUE SURJA COMO
                                    CONSECUENCIA DE UN ERROR SUYO AL GUARDAR
                                    INCORRECTAMENTE SU CONTRASEÑA O DE CUALQUIER
                                    ACCIÓN QUE OCURRA EN RELACIÓN CON SU
                                    CONTRASEÑA.
                                    <br /> Su uso del Servicio de BookNook está
                                    sujeto a todas las especificaciones técnicas
                                    y no técnicas, lineamientos y políticas
                                    aplicables que podamos proporcionar de vez
                                    en cuando incluyendo, entre otros, la
                                    Política de Privacidad que se encuentra en
                                    booknooklearning.com/privacy (en lo
                                    sucesivo, denominadas en conjunto “las
                                    Políticas”) usted acepta y se compromete a
                                    cumplir con las Políticas. En este momento,
                                    el Servicio de BookNook únicamente está
                                    destinado a ser utilizado por ciudadanos de
                                    los Estados Unidos y Canadá y cualquier
                                    persona que acceda o utilice el Servicio de
                                    BookNook desde afuera de los Estados Unidos
                                    o Canadá lo hace bajo su propio riesgo y es
                                    responsable del cumplimiento de las leyes
                                    locales aplicables.
                                </p>
                                <p />
                                <h3>2. Miembros con Suscripción</h3>
                                <p>
                                    Aunque ciertas funciones y/o contenido del
                                    Servicio de BookNook puede estar disponibles
                                    gratuitamente para ciertos usuarios, otras
                                    funciones y/o contenido pueden estar
                                    disponibles únicamente para quienes aceptan
                                    pagar un plan de suscripción (cada uno, un
                                    “Miembro con Suscripción”). Cada plan de
                                    suscripción puede describirse con más
                                    detalle en otras áreas del Servicio de
                                    BookNook. Los términos de pago que se
                                    muestren durante el proceso de conversión a
                                    Miembro con Suscripción se consideran parte
                                    de estos Términos.
                                </p>
                                <p />
                                <h3>3. Nuestra Licencia para Usted</h3>
                                <p>
                                    Le otorgamos una licencia personal,
                                    revocable, no exclusiva, intransferible, no
                                    sublicenciable y mundial para acceder y
                                    utilizar el Servicio de BookNook únicamente
                                    con fines personales, no comerciales, según
                                    lo permitido por estos Términos y lo
                                    previsto por nosotros a través de la
                                    funcionalidad normal del Servicio de
                                    BookNook. Esta licencia expirará
                                    automáticamente si usted viola estos
                                    Términos. Con la excepción de la licencia
                                    limitada antes mencionada, no se le
                                    transfiere ningún derecho, título o interés
                                    a usted. Podemos permitirle almacenar
                                    contenido en caché, encriptado en su
                                    tableta, teléfono inteligente u otro
                                    dispositivo compatible con conexión a
                                    internet. No puede transferir copias del
                                    contenido almacenado en caché a ningún otro
                                    dispositivo.
                                </p>
                                <p />
                                <h3>4. Su Conducta</h3>
                                <p>
                                    Nos gustaría mantener el Servicio de
                                    BookNook lo más seguro posible para nuestros
                                    usuarios, pero necesitamos su ayuda para
                                    hacerlo. Por consiguiente, usted acepta no
                                    utilizar el Servicio de BookNook para: (i)
                                    violar cualquier ley o regulación local,
                                    estatal, nacional o internacional; (ii)
                                    transmitir cualquier material que sea
                                    abusivo, acosador, malintencionado,
                                    difamatorio, vulgar, pornográfico, obsceno,
                                    calumnioso, gráficamente violento, invasivo
                                    de la privacidad de otra persona, cargado de
                                    odio u objetable racialmente, éticamente o
                                    de cualquier otra forma; (iii) transmitir
                                    cualquier publicidad no solicitada o no
                                    autorizada, materiales promocionales, correo
                                    basura, spam, cadenas, esquemas piramidales
                                    o cualquier otra forma de petición; (iv)
                                    transmitir conscientemente cualquier
                                    material que contenga adware, malware,
                                    spyware, virus de software o cualquier otro
                                    código, archivo o programa de computadora
                                    diseñado para interrumpir, destruir o
                                    limitar la funcionalidad del software o
                                    hardware informático o equipo de
                                    telecomunicaciones; (v) violar o evadir
                                    cualquier medida de seguridad o
                                    autenticación (incluidas las medidas de
                                    restricción geográfica); (vi) intentar
                                    descompilar o aplicar ingeniería en reversa
                                    a cualquier software incluido en el Servicio
                                    de BookNook; (vii) eliminar cualquier
                                    copyright, marca registrada u otras
                                    anotaciones de propiedad de cualquier
                                    Contenido (como se define más adelante) o
                                    Contribuciones de Usuario (como se definen
                                    más adelante) de otros usuarios; (viii)
                                    espiar, acosar o dañar a otra persona; (ix)
                                    hacerse pasar por otra persona o entidad o
                                    tergiversar su afiliación con otra persona o
                                    entidad; (x) interferir con o interrumpir el
                                    Servicio de BookNook o los servidores o
                                    redes conectadas al Servicio de BookNook o
                                    hacer caso omiso a cualquier requisito,
                                    procedimiento, política o regulación de las
                                    redes conectadas al Servicio de BookNook; o
                                    (xi) alquilar o arrendar su cuenta. También
                                    acepta no utilizar ni ejecutar ningún
                                    sistema automatizado, incluyendo, entre
                                    otros, “robots,” “arañas”, “lectores sin
                                    conexión”, etc., que accedan al Servicio de
                                    BookNook de manera que envíen más mensajes
                                    de solicitud a los servidores del Servicio
                                    de BookNook en un periodo de tiempo
                                    determinado de los que un humano puede
                                    producir razonablemente en el mismo periodo
                                    usando un navegador web convencional.
                                </p>
                                <p />
                                <h3>5. Propiedad; Restricciones de Uso</h3>
                                <p>
                                    Todo derecho, título e interés en y para el
                                    sitio y los servicios del Servicio de
                                    BookNook son y seguirán siendo propiedad
                                    exclusiva de BookNook, LLC. y sus
                                    licenciantes. El contenido del Servicio de
                                    BookNook, incluyendo, sin limitación,
                                    libros, texto, software, gráficos, fotos,
                                    sonidos, música, videos, Contribuciones de
                                    Usuario (como se definen más adelante),
                                    funciones interactivas y similares
                                    (“Contenido”) y la marca nominativa y marca
                                    de diseño “BookNook”, así como otros
                                    nombres, logos y materiales determinados que
                                    se muestran en o a través del Servicio de
                                    BookNook que constituyen marcas registradas,
                                    nombres comerciales, marcas de servicio o
                                    logos (“Marcas”) son propiedad de o
                                    autorizados para BookNook, LLC y están
                                    sujetos a copyright, marca registrada y
                                    otros derechos de propiedad intelectual
                                    conforme a las leyes internacionales y de
                                    los EE.UU. Usted acepta no reproducir,
                                    duplicar, copiar, vender, revender o
                                    explotar con ningún propósito el Servicio de
                                    BookNook o cualquier parte del Servicio de
                                    BookNook, incluyendo, sin limitación,
                                    Contenido y Marcas, excepto lo autorizado
                                    por estos Términos o lo autorizado por
                                    escrito por nosotros o nuestros licenciantes
                                    correspondientes. También acepta ver el
                                    Contenido (incluyendo, entre otros, libros y
                                    videos) disponible a través del Servicio de
                                    BookNook, únicamente en ubicaciones
                                    geográficas donde ofrecemos el Servicio de
                                    BookNook y tenemos licencia para dicho
                                    Contenido. El Servicio de BookNook es
                                    proporcionado únicamente para su uso
                                    personal y no comercial. Debe atenerse a
                                    todos los avisos de copyright, información o
                                    restricciones incluidas en o asociadas con
                                    cualquier Contenido.
                                </p>
                                <p />
                                <h3>6. Contribuciones de Usuario</h3>
                                <p>
                                    Podemos brindarle funcionalidades que le
                                    permitan publicar, cargar, enviar o proveer
                                    texto (incluyendo cuestionarios, respuestas
                                    de cuestionarios, planes de lecciones),
                                    imágenes, audio, video, presentaciones,
                                    participaciones en competencias u otro
                                    contenido (“Contribuciones de Usuario”) al
                                    Servicio de BookNook. El acceso a dichas
                                    funcionalidades puede estar restringido a
                                    usuarios mayores de cierta edad. Usted
                                    comprende que dichas Contribuciones de
                                    Usuario pueden ser accesibles para el
                                    público y que no garantizamos ninguna
                                    confidencialidad para dichas Contribuciones
                                    de Usuario.
                                </p>
                                <p>
                                    Usted conserva todos sus derechos de
                                    propiedad para sus Contribuciones de
                                    Usuario. Para todas las Contribuciones de
                                    Usuario, al proveer esas Contribuciones de
                                    Usuario al Servicio de BookNook, nos otorga
                                    una licencia mundial, no exclusiva, libre de
                                    regalías, sublicenciable y transferible bajo
                                    todos los derechos de propiedad intelectual
                                    para usar, reproducir, distribuir, mostrar y
                                    realizar dichas Contribuciones de Usuario,
                                    total o parcialmente, en relación con el
                                    Servicio de BookNook y nuestros (y el de
                                    nuestros sucesores y afiliados) negocios,
                                    incluyendo sin limitación, para la promoción
                                    y redistribución parcial o total del
                                    Servicio de BookNook (y trabajos derivados
                                    del mismo) en cualquier formato de medios y
                                    a través de cualquier canal de comunicación
                                    ahora conocido o en lo sucesivo, con o sin
                                    atribución, y sin avisarle previamente, a
                                    perpetuidad. También otorga a cada usuario
                                    del Servicio de BookNook una licencia no
                                    exclusiva para ver sus Contribuciones de
                                    Usuario a través del Servicio de BookNook, y
                                    para usar, reproducir, distribuir, mostrar y
                                    realizar dichas Contribuciones de Usuario
                                    según lo permitido a través de la
                                    funcionalidad normal del Servicio de
                                    BookNook y estos Términos a perpetuidad
                                </p>
                                <p>
                                    No puede enviar Contribuciones de Usuario
                                    que sean (i) difamatorias, acosadoras,
                                    amenazadoras, intolerantes, cargadas de
                                    odio, violentas, vulgares, obscenas,
                                    pornográficas u ofensivas de cualquier
                                    manera o que dañen o pueda esperarse
                                    razonablemente que dañen a cualquier persona
                                    o entidad, en cada caso si dicho contenido
                                    está o no protegido por la ley o (ii) de
                                    otra manera contrarias a las leyes y
                                    regulaciones locales, nacionales e
                                    internacionales aplicables.
                                </p>
                                <p>
                                    No puede enviar Contribuciones de Usuario
                                    que contengan material con copyright
                                    registrado por terceros o material que este
                                    sujeto a otros derechos de propiedad de
                                    terceros, a menos que tenga el permiso del
                                    propietario legítimo del material o que
                                    tenga otro tipo de autorización legal para
                                    enviar dicho contenido y otorgarnos las
                                    licencias descritas anteriormente. Usted
                                    declara y garantiza que posee o tiene las
                                    licencias, derechos, consentimientos y
                                    permisos necesarios para publicar las
                                    Contribuciones de Usuario y que las
                                    Contribuciones de Usuario se ajustan a los
                                    términos descritos en esta Sección 6.
                                    Además, acepta no ejecutar ningún derecho
                                    moral, derecho suplementario o derecho
                                    similar en o para las Contribuciones de
                                    Usuario en contra de nosotros o nuestros
                                    licenciatarios, distribuidores, agentes,
                                    representantes y otros usuarios autorizados,
                                    y acepta procurar el mismo acuerdo de no
                                    ejecutarlos por parte de otros que puedan
                                    poseer dichos derechos. Usted comprende y
                                    acepta que será el único responsable de sus
                                    Contribuciones de Usuario y las
                                    consecuencias de enviar y proporcionar
                                    dichas Contribuciones de Usuario al Servicio
                                    de BookNook.
                                </p>
                                <p>
                                    Tenemos el derecho pero no la obligación de
                                    monitorear, filtrar, publicar, eliminar,
                                    modificar, almacenar y revisar las
                                    Contribuciones de Usuario enviadas al
                                    Servicio de BookNook, en cualquier momento y
                                    por cualquier razón, incluyendo para
                                    confirmar que las Contribuciones de Usuario
                                    sean acordes a estos términos, sin avisarle
                                    previamente.
                                </p>
                                <p>
                                    No respaldamos ni garantizamos las
                                    opiniones, recomendaciones o consejos
                                    incluidos en cualquier Contribución de
                                    Usuario y renunciamos expresamente a
                                    cualquier y toda responsabilidad en relación
                                    con dicho contenido.
                                </p>
                                <p />
                                <h3>7. Disponibilidad del Contenido</h3>
                                <p>
                                    El Servicio de BookNook proporciona a sus
                                    usuarios acceso a libros y demás Contenido
                                    transmitido a ciertos dispositivos a través
                                    de Internet. La disponibilidad de estos
                                    libros y demás Contenido puede cambiar de
                                    vez en cuando y de un territorio geográfico
                                    a otro, por varias razones (incluyendo los
                                    acuerdos de los editores y el ancho de banda
                                    de Internet disponible). Como resultado, no
                                    garantizamos que ningún Contenido estará
                                    disponible o seguirá disponible en el
                                    Servicio de BookNook (incluyendo durante
                                    cualquier periodo de suscripción para un
                                    Miembro con Suscripción).
                                </p>
                                <p>
                                    Redes Móviles. Si accede al Servicio de
                                    BookNook a través de una red móvil, se
                                    aplicarán las tarifas y comisiones de
                                    mensajería, datos y otros servicios de su
                                    proveedor de red o roaming. La descarga,
                                    instalación o el uso de ciertas funciones
                                    del Servicio de BookNook pueden estar
                                    prohibidos o restringidos por su proveedor
                                    de red y es posible que no todas las
                                    funciones del Servicio de BookNook sirvan
                                    con su proveedor de red o su dispositivo.
                                </p>
                                <p />
                                <h3>8. Cancelación</h3>
                                <p>
                                    Su derecho a usar el Servicio de BookNook se
                                    cancela automáticamente si usted viola estos
                                    Términos o cualquier regla o lineamiento
                                    publicado en relación con el Servicio de
                                    BookNook. Nos reservamos el derecho, a
                                    nuestra propia discreción, para cancelar su
                                    acceso a todo o parte del Servicio de
                                    BookNook, por cualquier razón, con o sin
                                    previo aviso.
                                </p>
                                <p />
                                <h3>
                                    9. Enlaces o Aplicaciones/Dispositivos de
                                    Terceros
                                </h3>
                                <p>
                                    Enlaces. Para los padres o educadores, el
                                    Servicio de BookNook puede contener enlaces
                                    a sitios web de terceros o interactuar con
                                    aplicaciones de terceros (incluyendo, sin
                                    limitación, widgets, software, u otras
                                    utilidades de software) que no son propiedad
                                    ni son controladas por el Servicio de
                                    BookNook. Nuestra aportación de un enlace a
                                    cualquier otro sitio web o aplicación de
                                    terceros es solo para su conveniencia y no
                                    indica nuestro respaldo de dicho sitio web o
                                    recurso o sus contenidos. NO TENDREMOS
                                    NINGUNA RESPONSABILIDAD U OBLIGACIÓN POR
                                    CUALQUIER CONTENIDO, INFORMACIÓN, SOFTWARE,
                                    MATERIALES O PRACTICAS DE CUALQUIER SITIO
                                    WEB DE TERCEROS O APLICACIÓN DE TERCEROS.
                                </p>
                                <p>
                                    Dispositivos. Si accede al Servicio de
                                    BookNook usando un dispositivo con sistema
                                    operativo Apple iOS o Android, entonces
                                    Apple Inc. o Google, Inc., respectivamente,
                                    serán un tercero beneficiario de este
                                    acuerdo. Sin embargo, estos terceros
                                    beneficiarios no son una parte de este
                                    contrato y no son responsables de la
                                    prestación o el soporte del Servicio de
                                    BookNook. Usted acepta que su acceso al
                                    Servicio de BookNook usando estos
                                    dispositivos también estará sujeto a los
                                    términos de uso establecidos en los términos
                                    de servicio correspondientes del tercero
                                    beneficiario.
                                </p>
                                <p />
                                <h3>10. Exención de Garantías</h3>
                                <p>
                                    USTED ACEPTA EXPRESAMENTE QUE EL USO DEL
                                    SERVICIO DE BOOKNOOK ES BAJO SU PROPIO
                                    RIESGO. EL SERVICIO DE BOOKNOOK SE
                                    PROPORCIONA “TAL CUAL” Y “SEGÚN
                                    DISPONIBILIDAD”. EN LA MAYOR MEDIDA QUE LO
                                    PERMITA LA LEY, BOOKNOOK CREATIONS, INC. Y
                                    SUS AFILIADOS RENUNCIAN EXPRESAMENTE A TODAS
                                    LAS GARANTÍAS DE CUALQUIER TIPO, YA SEAN
                                    EXPRESAS O IMPLÍCITAS, CON RESPECTO AL
                                    SERVICIO DE BOOKNOOK (INCLUYENDO, ENTRE
                                    OTRAS, LAS GARANTÍAS IMPLÍCITAS DE
                                    COMERCIABILIDAD, ADECUACIÓN PARA UN USO O
                                    PROPÓSITO PARTICULAR Y NO VIOLACIÓN). No
                                    ofrecemos garantías ni representaciones
                                    acerca de la precisión o integridad del
                                    contenido disponible en o a través del
                                    Servicio de BookNook o el contenido de
                                    cualquier sitio web enlazado al Servicio de
                                    BookNook y no asumimos obligación o
                                    responsabilidad alguna por: (i) errores,
                                    equivocaciones o imprecisiones de contenido;
                                    (ii) lesiones personales o daños a la
                                    propiedad, de cualquier naturaleza, que
                                    resulten de su acceso o uso del Servicio de
                                    BookNook o del Contenido en el Servicio de
                                    BookNook; (iii) cualquier acceso no
                                    autorizado o uso de nuestros servidores
                                    seguros y/o toda la información personal y/o
                                    información financiera almacenada en los
                                    mismos; (iv) cualquier interrupción o cese
                                    de la transmisión hacia o desde el Servicio
                                    de BookNook; (v) cualquier bug, virus,
                                    troyano o similar que un tercero pueda
                                    transmitir a través de o al Servicio de
                                    BookNook; y/o (vi) por cualquier pérdida o
                                    daño de cualquier tipo en que se incurra
                                    como resultado del uso de cualquier
                                    contenido publicado, enviado por correo
                                    electrónico, transmitido o proporcionado de
                                    otra manera en o a través del Servicio de
                                    BookNook.
                                </p>
                                <p />
                                <h3>11. Limitación de Responsabilidad</h3>
                                <p>
                                    USTED COMPRENDE QUE EN LA MEDIDA QUE LA LEY
                                    APLICABLE LO PERMITA, EN NINGÚN CASO
                                    BOOKNOOK, LLC O SUS FUNCIONARIOS, EMPLEADOS,
                                    DIRECTORES, ACCIONISTAS, SUBSIDIARIAS,
                                    AFILIADOS, AGENTES O LICENSIANTES SERÁN
                                    RESPONSABLES BAJO CUALQUIER TEORÍA DE
                                    RESPONSABILIDAD (YA SEA POR CONTRATO,
                                    AGRAVIO, ESTATUTORIA O DE OTRA FORMA) POR
                                    CUALQUIER DAÑO DIRECTO, INDIRECTO,
                                    INCIDENTAL, ESPECIAL, CONSECUENTE O
                                    EJEMPLAR, INCLUYENDO ENTRE OTROS, DAÑOS POR
                                    PÉRDIDA DE INGRESOS, GANANCIAS, FONDO DE
                                    COMERCIO, USO, DATOS U OTRAS PÉRDIDAS
                                    INTANGIBLES (INCLUSO SI DICHAS PARTES FUERON
                                    INFORMADAS, SABÍAN O DEBERÍAN HABER CONOCIDO
                                    LA POSIBILIDAD DE DICHOS DAÑOS), QUE
                                    RESULTEN DE SU USO (O EL DE CUALQUIERA QUE
                                    UTILICE SU CUENTA) DEL SERVICIO DE BOOKNOOK.
                                </p>
                                <p />
                                <h3>12. Exclusiones</h3>
                                <p>
                                    Algunas jurisdicciones no permiten la
                                    exclusión de ciertas garantías o la
                                    limitación o exclusión de responsabilidad
                                    por daños incidentales o consecuentes. Por
                                    lo tanto, es posible que algunas de las
                                    limitaciones y exclusiones de
                                    responsabilidad anteriores no apliquen para
                                    usted. En la medida en que BookNook, LLC no
                                    pueda, como cuestión de la ley aplicable,
                                    renunciar a ninguna garantía o limitar sus
                                    responsabilidades, el alcance y duración de
                                    dicha garantía y el alcance de nuestra
                                    responsabilidad serán los mínimos permitidos
                                    bajo dicha ley aplicable.
                                </p>
                                <p />
                                <h3>13. Indemnización</h3>
                                <p>
                                    Usted acepta indemnizar, defender y eximir
                                    de toda responsabilidad a BookNook, LLC, sus
                                    subsidiarias, afiliados, funcionarios,
                                    directores, empleados, consultores y agentes
                                    de y contra todos y cada uno de los
                                    reclamos, responsabilidades, daños,
                                    pérdidas, costos, gastos, cuotas (incluyendo
                                    las cuotas y costos razonables de los
                                    abogados) en que dichas partes puedan
                                    incurrir como resultado de o que surjan de
                                    (a) cualquier información (incluyendo, sin
                                    limitación, sus Contribuciones de Usuario,
                                    comentarios o cualquier otro contenido) que
                                    usted (o cualquiera que utilice su cuenta)
                                    envíe, publique o transmita en o a través
                                    del Servicio de BookNook; (b) su uso (o el
                                    de cualquiera que utilice su cuenta) del
                                    Servicio de BookNook; (c) su violación (o la
                                    de cualquiera que utilice su cuenta) de
                                    estos Términos; o (d) su violación (o la de
                                    cualquiera que utilice su cuenta) de
                                    cualquier derecho de alguna otra persona o
                                    entidad, incluyendo, sin limitación,
                                    cualquier copyright, patente, marca
                                    registrada, secreto comercial, derecho de
                                    publicidad/privacidad u otro derecho de
                                    propiedad de cualquier persona o entidad.
                                    BookNook, LLC se reserva el derecho, por su
                                    propia cuenta, de asumir la defensa y el
                                    control exclusivos de cualquier asunto
                                    sujeto de otra forma a indemnización por su
                                    parte, en cuyo caso usted cooperará con
                                    BookNook, LLC para reivindicar cualquier
                                    defensa disponible.
                                </p>
                                <h3>14. Disputas</h3>
                                <p>
                                    Estos Términos se interpretarán y aplicarán
                                    de acuerdo con las leyes del Estado de
                                    California sin tener en cuenta ninguna
                                    elección de ley o conflicto de principios
                                    legales, sin importar donde viva. Usted
                                    resolverá cualquier reclamo, fundamento de
                                    demanda o disputa que tenga con nosotros y
                                    surja de o se relacione con estos Términos o
                                    el Servicio de BookNook exclusivamente en un
                                    tribunal estatal o federal ubicado en el
                                    Condado de Alameda, California. Usted acepta
                                    someterse a la jurisdicción personal de los
                                    tribunales ubicados en el Condado de
                                    Alameda, California con el propósito de
                                    litigar todas esos reclamos o fundamentos de
                                    demanda.
                                </p>
                                <p />
                                <h3>15. Comunicaciones</h3>
                                <p>
                                    Al utilizar el Servicio de BookNook, usted
                                    acepta que cualquier notificación, acuerdo,
                                    divulgación u otras comunicaciones que le
                                    enviemos vía electrónica cumplirán cualquier
                                    requisito de comunicación legal (incluyendo
                                    que dichas comunicaciones deben ser por
                                    escrito).
                                </p>
                                <p />
                                <h3>
                                    16. Términos Adicionales para Aplicaciones
                                    de Apple iOS
                                </h3>
                                <p>
                                    Se aplican los siguientes términos y
                                    condiciones con respecto a nuestras
                                    aplicaciones diseñadas para su uso en un
                                    dispositivo móvil con sistema operativo
                                    Apple iOS que obtenga de iTunes Store (“iOS
                                    App”). Usted acepta que estos Términos
                                    únicamente son entre usted y nosotros, no
                                    Apple, y que Apple no es responsable de
                                    nuestra Aplicación para iOS, del Servicio de
                                    BookNook, o de cualquier Contenido. Acepta
                                    que su uso de nuestra Aplicación para iOS
                                    estará sujeto a las Reglas de Uso de los
                                    Términos de Servicio de la App Store de
                                    Apple que estén vigentes en ese momento. Las
                                    partes acuerdan que Apple no tendrá la
                                    obligación de proveer servicios de
                                    mantenimiento y soporte en lo que respecta a
                                    nuestra aplicación para iOS. En caso de que
                                    la Aplicación para iOS no cumpla cualquier
                                    garantía aplicable, puede notificar a Apple,
                                    y Apple le reembolsará el precio de compra
                                    (si corresponde) de nuestra Aplicación para
                                    iOS. En la mayor medida que la ley lo
                                    permita, Apple no tendrá obligación de
                                    garantía con respecto a nuestra Aplicación
                                    para iOS y cualquier otro reclamo, pérdida,
                                    responsabilidad, daño, costo o gasto
                                    atribuible al incumplimiento de alguna
                                    garantía será regulado únicamente por estos
                                    Términos y cualquier ley aplicable a
                                    nosotros como proveedores del software.
                                    Acepta que nosotros, y no Apple, somos
                                    responsables de atender cualquiera de sus
                                    reclamos, suyos o de un tercero,
                                    relacionados con nuestra Aplicación para iOS
                                    o su posesión y/o uso de nuestra Aplicación
                                    para iOS, incluyendo, entre otros: (i)
                                    reclamos de responsabilidad por productos;
                                    (ii) cualquier reclamo de que nuestra
                                    Aplicación para iOS no cumple con algún
                                    requisito legal o regulatorio aplicable; y
                                    (iii) reclamos derivados de la protección al
                                    consumidor o una legislación similar; y
                                    todos estos reclamos se regirán únicamente
                                    por estos Términos y cualquier ley aplicable
                                    a nosotros como proveedores del software.
                                    Acepta que, en caso cualquier reclamo por
                                    parte de un tercero de que nuestra
                                    Aplicación para iOS o su posesión y uso de
                                    esa Aplicación para iOS App infringe los
                                    derechos de propiedad intelectual de dicho
                                    tercero, nosotros, no Apple, seremos
                                    responsables de la investigación, defensa,
                                    resolución y liberación de dicho reclamo. La
                                    partes acuerdan que Apple y sus subsidiarias
                                    son los terceros beneficiarios de los
                                    Términos aplicables a su uso de nuestra
                                    Aplicación para iOS. Luego de que acepte los
                                    Términos, Apple tendrá el derecho (y se
                                    considerará que ha aceptado el derecho) de
                                    hacer cumplir los Términos aplicables a su
                                    uso de nuestra Aplicación para iOS en su
                                    contra como un tercero beneficiario de la
                                    misma.
                                </p>
                                <p />
                                <h3>17. Misceláneos</h3>
                                <p>
                                    Estos Términos, junto con cualquier otra
                                    regla o lineamiento publicado en relación
                                    con el Servicio de BookNook constituyen la
                                    manifestación completa, exclusiva y final
                                    del acuerdo entre usted y nosotros. Nuestra
                                    incapacidad para ejercer o hacer cumplir
                                    cualquier derecho o disposición de estos
                                    Términos no constituirá una exención a dicho
                                    derecho o disposición. Si por cualquier
                                    razón no se puede hacer cumplir alguna
                                    disposición de estos Términos, esa
                                    disposición se hará cumplir en la mayor
                                    medida permitida con el fin de dar efecto a
                                    la intención de las partes como se refleja
                                    en esa disposición, y el resto de estos
                                    Términos continuará en plena validez y
                                    efecto. Los títulos de sección de estos
                                    Términos son únicamente para nuestra
                                    conveniencia y no tienen ningún efecto legal
                                    ni contractual. Los términos de las
                                    Secciones 2, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                    14, 15, 16, 17, 18, 19 y 20 de estos
                                    Términos, así como cualquier otra limitación
                                    de responsabilidad establecida
                                    explícitamente en este documento,
                                    permanecerán en plena validez y efecto a
                                    pesar de cualquier cancelación de su uso del
                                    Servicio de BookNook. Estos Términos son
                                    personales para usted y no pueden ser
                                    asignados, transferidos o sublicenciados por
                                    usted excepto con nuestro consentimiento
                                    previo por escrito. Podemos asignar,
                                    transferir o delegar nuestros derechos y
                                    obligaciones en virtud de estos Términos sin
                                    su consentimiento. Los encabezados de
                                    sección y subsección de estos Términos son
                                    solo por conveniencia y no afectarán su
                                    interpretación. A menos que se especifique
                                    lo contrario en estos Términos, todos los
                                    avisos deben ser por escrito y se
                                    considerarán otorgados a su recepción, según
                                    lo verificado por el recibo automático por
                                    escrito o por los registros electrónicos
                                    (según corresponda). La dirección de correo
                                    electrónico para los avisos que se envían al
                                    Servicio de BookNook es{' '}
                                    <span>info@booknooklearning.com</span>.
                                </p>
                                <p />
                                <h3>18. Modificaciones</h3>
                                <p>
                                    Podemos, a nuestro exclusivo y absoluto
                                    criterio, cambiar estos Términos de vez en
                                    cuando. Si lo hacemos, actualizaremos esta
                                    publicación, le enviaremos un aviso a través
                                    del Servicio de BookNook o le enviaremos un
                                    correo electrónico. Su uso continuo del
                                    Servicio de BookNook constituye su
                                    aceptación para cumplir los Términos
                                    modificados. Si se opone a dichos cambios,
                                    su único recurso será dejar de usar el
                                    Servicio de BookNook.
                                </p>
                                <p />
                                <h3>19. Legal</h3>
                                <p>
                                    Apple, el logo de Apple, iMac, MacBook Pro,
                                    y iPad son marcas registradas de Apple Inc.,
                                    registradas en los EE.UU. y en otros países.
                                    La App Store es una marca de servicio de
                                    Apple Inc.
                                </p>
                                <p />
                                <p>____________</p>
                                <p />
                                <p>Última actualización: 22 de junio de 2017</p>
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div id="chinese">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h2>
                                        <span>服務條款</span>
                                    </h2>
                                </div>
                            </div>
                        </div>{' '}
                        <div>
                            <div>
                                <h3>
                                    歡迎使用BookNook。我們希望保護您以及與我們互動的任何其他用戶或第三方的權利，因此我們制定了這服務條款（以下簡稱“條款”）。通過訪問或使用BookNook應用程序、網站、服務、軟件和/或BookNook
                                    Inc.（以下簡稱“
                                    BookNook服務”和“我們”）提供的內容，即表示您同意這些條款。如果您不同意這些條款，請不要訪問或使用BookNook服務。
                                </h3>
                                <p />
                                <h3>1. 您和您的帳戶</h3>
                                <p>
                                    A通過註冊BookNook服務，您確認您（i）年滿18歲，或您所在的省、地區或國家/地區中適用的成年年齡或以上年齡，或（ii）脫離父母而獨立生活的未成年人。您確認您完全有能力遵守這些條款。我們會為您提供獨特的ID和BookNook頭像（“頭像”）來代表您在BookNook服務中的帳戶。如果您進行註冊和訂閱，則表示您同意：（a）根據註冊表格提供準確和最新的信息；（b）及時更新此類信息，以使其始終保持準確和最新；（c）除本條款允許的情況外，不得共享您的密碼或ID，或讓其他任何人訪問您的帳戶，或採取其他可能危害您帳戶安全的行動；（d）不得假冒他人或以侵犯他人權利的方式設置用戶名；（e）不得使用淫穢或粗俗的用戶名；（f）如果未經授權，您的密碼被盜用或發生與BookNook服務有關的任何其他違反安全性的事，請立即通知我們。當您共享密碼或允許第三方訪問您的Booknook帳戶時，您同意對任何此類第三方遵守這些條款的行為負責。由於您未能充分保護密碼而導致的任何損失或損害，或因密碼而導致的任何行為，我們概不負責。
                                    <br />{' '}
                                    您對BookNook服務的使用受我們不時提供的所有適用的技術和非技術規範、指南和政策的約束，包括但不限於位於booknooklearning.com/privacy的隱私政策（以下統稱“政策”），您同意並承諾遵守政策。目前，BookNook服務僅適用於美國和加拿大公民，任何從美國或加拿大以外地區訪問或使用BookNook服務的人均需自擔風險，並負責遵守適用的當地法律。
                                </p>
                                <p />
                                <h3>2. 訂閱會員</h3>
                                <p>
                                    儘管BookNook服務的某些功能和/或內容可能免費提供給某些用戶，但其他功能和/或內容可能僅對同意支付訂閱計劃的人（以下統稱“訂閱會員”）可用。條款其他部分會更詳細地描述此訂閱計劃。成為訂閱會員時向您顯示的任何付款條款均被視為這些條款的一部分。
                                </p>
                                <p />
                                <h3>3. 我們給您的許可證</h3>
                                <p>
                                    我們授予您個人、可撤銷、非排他、不可轉讓、不可再許可、全球範圍的許可，以僅出於本條款允許且我們通過以下目的用於個人、非商業目的訪問和使用BookNook服務的正常功能。如果您違反這些條款，則授予您的許可將自動終止。除上述有限許可外，任何權利、所有權或利益均不會轉讓給您。
                                    我們可能允許您將加密的緩存內容存儲在平板電腦、智能手機或其他兼容的互聯網連接設備上。您不得將緩存內容的副本傳輸到任何其他設備。
                                </p>
                                <p />
                                <h3>4. 您的行為</h3>
                                <p>
                                    我們希望盡可能確保BookNook服務對用戶的安全，但是這需要您的幫助。因此，您同意不使用BookNook服務來：（i）違反任何當地、州、國家或國際法律或法規；（ii）傳播任何辱罵、騷擾、侵權、誹謗、粗俗、色情、淫穢、圖形暴力、侵犯他人隱私、仇恨或種族、民族、或其他令人反感的內容；（iii）傳播任何未經請求或未經授權的廣告、促銷材料、垃圾郵件、連鎖信件、金字塔計劃或任何其他形式的邀請；（iv）故意傳播包含廣告軟件、惡意軟件、間諜軟件、軟件病毒或旨在中斷、破壞或限制任何計算機軟件、硬件或電信設備功能的任何其他計算機代碼、文件或程序的任何材料；（v）違反或規避任何安全或認證措施（包括地理限制措施）；（vi）嘗試對BookNook服務中包含的任何軟件進行反編譯或反向工程；（vii）從其他用戶的任何內容（定義如下）或用戶提交的內容（定義如下）中刪除任何版權、商標或其他專有符號；（viii）跟踪、騷擾或傷害他人；（ix）冒充任何人或實體、或以其他方式歪曲您與該人或實體的隸屬關係；（x）干擾或破壞BookNook服務或連接到BookNook
                                    的服務器或網絡、或不遵守連接到BookNook服務的網絡的任何要求、過程、策略或規定；或（xi）出租或租賃您的帳戶。您也同意不使用或啟動任何自動化系統，包括但不限於“robots”、“spiders”、“offline
                                    readers”等，以在特定時間內向BookNook服務的服務器發送比使用傳統網絡瀏覽器在同一時間內合理産生更多請求信息的方式訪問BookNook服務。
                                </p>
                                <p />
                                <h3>5. 所有權; 使用限制</h3>
                                <p>
                                    BookNook服務站點和服務的所有權利、所有權和利益將繼續屬於BookNook，LLC及其許可人的專有財產。BookNook服務上的內容，包括但不限於書籍、文本、軟件、圖形、照片、聲音、音樂、視頻、用戶提交的內容（定義如下）、交互功能等（“內容”）和BookNook服務上或通過BookNook服務顯示的“
                                    BookNook”文字商標和設計商標，以及某些其他名稱、徽標、構成商標、商品名、服務標記或徽標（“商標”），均歸BookNook，LLC擁有或許可使用並受美國和國際法律的版權、商標和其他知識產權的約束。您同意不為任何目的複製、抄襲、出售、轉售或利用BookNook服務或BookNook服務的任何部分（包括但不限於內容和商標），除非這些條款授權或本協議中的其他授權由我們或我們適用的許可方書面授權。您也同意僅在我們提供BookNook服務並已獲得此類內容許可的地理位置查看通過BookNook服務提供的內容（包括但不限於書籍和視頻）。BookNook服務僅供您個人非商業用途。您必須遵守任何內容中包含的或與之相關的所有版權聲明、信息或限制。
                                </p>
                                <p />
                                <h3>6. 用戶提交的內容</h3>
                                <p>
                                    我們可能提供使您能夠在BookNook服務發布、上傳、提交或以其他方式使文本（包括測驗、測驗答案、課程計劃）、圖像、音頻、視頻、演示文稿、比賽條目或其他內容（“用戶提交的內容”）可用的功能。這種功能僅開放給一定年齡以上的用戶。請了解公眾可以訪問此類用戶提交的內容，我們不保證此類用戶提交的任何機密性。
                                </p>
                                <p>
                                    您保留對用戶提交的內容的所有權力。對於所有用戶提交的內容，通過將這些用戶提交的內容提供給BookNook服務，即表示您授予我們全球性的、非排他性的、免版稅的、可分許可的和可轉讓的許可，以根據所有知識產權來使用、複製、分發、展示和執行與BookNook服務以及我們（以及我們的繼承人和關聯公司）業務相關的全部或部分此類用戶提交的內容，包括但不限於以任何媒體格式和通過任何媒體渠道推廣和重新分發部分或全部的BookNook服務（及其衍生作品），無論是否註明出處，也不需另行通知您，且永久有效。）。您還授予BookNook服務的每個用戶非排他性許可，以通過BookNook服務查看您的用戶提交的內容，以及使用、複製、分發、顯示和執行BookNook服務正常功能所允許的此類用戶提交內容。
                                </p>
                                <p>
                                    您不得提交(i)誹謗、騷擾、威脅、偏執、仇恨、暴力、粗俗、淫穢、色情、或其他攻擊性的內容，或傷害或可合理預期傷害任何個人或實體的內容，無論此類內容是否受法律保護，或者（ii）其他違反適用的當地、國家和國際法律和法規的內容。
                                </p>
                                <p>
                                    您不得提交包含第三方版權材料或受其他第三方所有權保護的材料的用戶提交的內容，除非您已獲得該材料的合法所有者的許可，或者您在法律上被授權提交此類內容並授予我們上述許可。您聲明並保證您擁有或擁有發布用戶提交的內容必要的許可、權利、同意和許可，並且用戶提交的內容符合本第6節中所述的條款。您還同意不執行任何附帶的精神權利對我們或我們的被許可人、發行人、代理商、代表和其他授權用戶的用戶提交的內容中的權利或類似權利，並從其他可能擁有此類權利的人那裡獲得不執行的相同協議。您理解並同意，您對您的用戶提交的內容以及提交和提供這些內容給BookNook服務的後果負全部責任。
                                </p>
                                <p>
                                    我們有權（但無義務）出於任何時間和任何原因監視、篩選、發布、刪除、修改、存儲和查看提交給BookNook服務的用戶提交的內容，包括確認用戶提交的內容是否符合這些條款，恕不另行通知。
                                    <br />{' '}
                                    我們不認可或保證任何用戶提交的意見或建議，並且我們明確表示不承擔與此類內容有關的任何責任。
                                </p>
                                <p />
                                <h3>7. 內容可用性</h3>
                                <p>
                                    BookNook服務為用戶提供了用一些設備通過互聯網訪問書籍和其他內容的訪問權限。出於多種原因（包括出版商交易和可用的网络带宽），這些書籍和其他內容的可用性可能會不時地在不同地域之間變化。因此，我們不保證任何內容將在BookNook服務上可用或保持可用（包括在訂閱會員的任何訂閱期內）。
                                </p>
                                <p>
                                    移動網絡。如果您通過移動網絡訪問BookNook服務，則將產生網絡或漫遊提供商的消息傳遞、數據以及其他費用。您的網絡提供商可能會禁止或限制下載、安裝或使用某些BookNook服務功能，並且並非所有BookNook
                                    服務功能都適用於您的網絡提供商或設備。
                                </p>
                                <p />
                                <h3>8. 終止</h3>
                                <p>
                                    如果您違反這些條款或與BookNook服務相關的任何規則或準則，則您使用BookNook服務的權利將自動終止。我們保留自行決定以任何理由終止您對全部或部分BookNook服務的訪問的權利，恕不另行通知。
                                </p>
                                <p />
                                <h3>9.鏈接或第三方應用程序/設備</h3>
                                <p>
                                    鏈接。對於父母或教育者，BookNook服務可能包含指向第三方網站的鏈接，或者與非BookNook服務擁有或控制的第三方應用程序（包括但不限於小部件、軟件或其他軟件實用程序）進行交互。我們提供任何其他網站或第三方應用程序的鏈接只是為了您的方便，並不表示我們認可此類其他網站或資源或其內容。對於任何第三方網站或第三方應用程序的任何內容、信息、軟件、材料或實踐，我們概不負責。
                                </p>
                                <p>
                                    設備。如果您使用Apple
                                    iOS或Android設備訪問BookNook服務，則Apple
                                    Inc.或Google，Inc.將分別是本協議的第三方受益人。但是，這些第三方受益人不是本合同的當事人，也不對BookNook服務的提供或支持負責。您同意使用這些設備訪問BookNook服務也應遵守適用的第三方受益人的服務條款中規定的使用條款。
                                </p>
                                <p />
                                <h3>10.免責聲明</h3>
                                <p>
                                    您明確同意自行承擔使用BookNook服務的風險。BookNook服務是按“原樣”和“可用”的基礎提供的。在法律允許的最大範圍內，BookNook
                                    Creations, INC.
                                    及其關聯公司明確否認對BookNook服務的任何形式的明示或暗示擔保（包括但不限於對商業的暗示擔保，用於特定用途或目的，並且不侵權）。我們對BookNook服務上或通過BookNook服務或與BookNook服務鏈接的任何網站的內容的準確性或完整性不做任何保證或陳述，並且對以下任何方面不承擔任何責任：（i）錯誤或不正確的內容;
                                    （ii）由於您訪問或使用BookNook服務或BookNook服務上的內容而造成的任何形式的人身傷害或財產損失；（iii）未經授權訪問或使用我們的安全服務器和/或存儲在其中的任何和所有個人信息和/或財務信息；（iv）與BookNook服務之間的傳送中斷或停止；（v）任何第三方可能通過BookNook服務傳輸或通過BookNook服務傳播的任何錯誤、病毒、特洛伊木馬等；和/或（vi）因使用在BookNook服務上或通過BookNook服務發布，通過電子郵件發送、傳輸或以其他方式提供的任何內容而導致的任何形式的損失或損害。
                                </p>
                                <p />
                                <h3>11. 責任範圍</h3>
                                <p>
                                    您了解在適用法律允許的範圍內，在任何情況下，BookNook,
                                    LLC或其職員、員工、董事、股東、子公司、關聯公司、代理商或許可方都不會根據任何責任理論（無論是在合同、侵權行為、法定或其他方面）對您（或任何使用您帳戶的人）對BookNook服務的使用導致的任何直接、間接、偶發、特殊、繼發性或懲戒性損害賠償負責，包括但不限於損失的收入、利潤、商譽、用途、數據或其他無形損失（即使此類當事人被告知或本應該知道這種損害的可能性）
                                    。
                                </p>
                                <p />
                                <h3>12. 排除</h3>
                                <p>
                                    一些司法管轄區不允許排除某些擔保，也不允許限製或排除偶然或間接損失的責任。因此，上述某些限制和免責聲明可能不適用於您。就適用法律而言，BookNook,
                                    LLC在一定程度上不否認任何默示擔保或限制其責任，擔保的範圍和期限以及我們的責任範圍應為該適用法律所允許的最低限度。
                                </p>
                                <p />
                                <h3>13. 保障</h3>
                                <p>
                                    您同意對BookNook,
                                    LLC，其子公司、關聯公司、高級職員、董事、僱員、顧問和代理商可能因以下原因而産生的任何及所有索賠、責任、損害、損失、成本、費用（包括合理的律師費和成本）進行賠償、辯護並使其免受損害：（a）您（或使用您帳戶的任何人）通過BookNook服務發布或傳輸的任何信息（包括但不限於您提交的内容、反饋或任何其他內容）；（b）您（或使用您帳戶的任何人）對BookNook服務的使用；（c）您（或使用您帳戶的任何人）違反這些條款；或（d）您（或使用您帳戶的任何人）侵犯任何其他人或實體的任何權利，包括但不限於任何版權、專利、商標、商業秘密、公開/隱私權或任何其他人或實體的專有權利。
                                    BookNook,
                                    LLC保留自付費用的權利，對您應賠償的任何事項承擔唯一的抗辯和控制權，在這種情況下，您將與BookNook,
                                    LLC合作以主張任何可用的辯護。
                                </p>
                                <h3>14. 糾紛</h3>
                                <p>
                                    無論您身在何處，均應根據California的法律解釋和執行這些條款，而不考慮任何法律選擇或法律衝突原則。您將僅在Alameda
                                    County的州或聯邦法院解決由本條款或BookNook服務引起的或與之相關的任何索賠、訴訟因由或爭議。您同意出於訴訟目的將所有此類索償或訴因提交給位於Alameda
                                    County的法院個人管轄。
                                </p>
                                <p />
                                <h3>15. 通訊</h3>
                                <p>
                                    通過使用BookNook服務，您同意我們以電子方式發送給您的任何通知、協議、披露或其他通訊將滿足任何法律通訊要求（包括此類通訊必須為書面形式）。
                                </p>
                                <p />
                                <h3>16. Apple iOS應用程序的附加條款</h3>
                                <p>
                                    以下其他條款和條件適用於我們設計的應用程序，這些應用程序設計用於您從iTunes
                                    Store獲得的Apple
                                    iOS支持的移動設備（“iOS應用程序”）。您同意這些條款僅用於您和我們之間，而非Apple之間，並且Apple對我們的iOS應用程序、BookNook服務或任何內容不承擔任何責任。您同意您對我們的iOS應用程序的使用應遵守Apple當時最新的App
                                    Store服務條款中的使用規則。雙方同意，Apple沒有義務為我們的iOS應用程序提供維護和支持服務。如果iOS
                                    App未能遵守任何適用的保證，您可以通知Apple，Apple會將您的iOS應用程序的購買價格（如有）退還給您。在法律允許的最大範圍內，Apple對我們的iOS應用程序不承擔任何其他保修義務，並且由於不遵守任何保修條款而引起的任何其他索賠、損失、責任、損害、成本或費用將受本條款及適用於本公司作為軟體提供者的任何法律管轄。您同意我們，而非Apple，負責解決您或任何第三方與我們的iOS應用程序或您擁有和/或使用我們的iOS應用程序有關的任何索賠，包括但不限於：（i）產品責任索賠；（ii）任何聲稱我們的iOS應用程序不符合任何適用法律或法規要求的主張；（iii）根據消費者保護或類似法規引起的索賠；並且所有此類聲明僅受這些條款以及適用於我們作為軟件提供商的任何法律的管轄。您同意，如果任何第三方聲稱我們的iOS應用程序或您對iOS應用程序的擁有和使用侵犯了該第三方的知識產權，我們，而非Apple將負責該等索賠之調查、辯護、和解和解除。雙方同意，Apple及其子公司是適用於您使用我們的iOS應用程序的條款的第三方受益人。您接受條款後，Apple有權（將被視為已接受權利）對作為您的第三方受益人的用戶執行適用於您使用我們的iOS應用程序的條款。
                                </p>
                                <p />
                                <h3>17. 其它</h3>
                                <p>
                                    這些條款，以及與BookNook服務相關的任何其他規則或準則，構成您與我們之間協議的完整且排他性和最終性聲明。我們未能行使或執行這些條款的任何權利或規定，並不構成對該權利或規定的放棄。如果出於任何原因，發現無法執行本條款中的任何規定，則該條款應在允許的最大範圍內執行，以實現該條款中所反映的當事方的意圖，並且本條款的其餘部分應繼續具有完整執行力與效果。這些條款中的章節標題僅是為了方便起見，沒有法律或合同效力。第2、5、6、7、8、9、10、11、12、13、14、15、16、17、18、19和20條的條款，以及任何其他責任限制即使您終止對BookNook服務的使用，本協議中明確規定的內容仍然具有完全的效力。這些條款僅供您使用，除非獲得我們的事先書面同意，否則您不得轉讓、轉移或再許可。未經您的同意，我們可以轉讓、轉移或委託我們在這些條款下的權利和義務。這些條款中的章節標題僅是為了方便起見，並不影響其解釋。除非這些條款中另有規定，否則所有通知都必須採用書面形式，並且將按照收據上給出的方式處理，並由書面自動收據或電子日誌（如適用）進行驗證。可以把通知發送到BookNook服務的電子郵件地址為
                                    <span>info@booknooklearning</span>.com。
                                </p>
                                <p />
                                <h3>18. 修改</h3>
                                <p>
                                    我們可以自行全權決定不時更改這些條款。如果這樣做，我們將更新此帖子，並通過BookNook服務向您發送通知、或給您發送電子郵件。您繼續使用BookNook服務即表示您同意遵守已更改的條款。如果您反對任何此類更改，請停止使用BookNook服務。
                                </p>
                                <p />
                                <h3>19. 法律聲明</h3>
                                <p>
                                    Apple、Apple徽標、iMac、MacBook
                                    Pro和iPad是Apple
                                    Inc.在美國和其他國家/地區的註冊商標。App
                                    Store是Apple Inc.的服務商標。
                                </p>
                                <p />
                                <p>____________</p>
                                <p />
                                <p>最近更新時間: 2017年6月22日。</p>
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div id="bengali">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h2>
                                        <span>সার্ভিসের শর্তাবলী </span>
                                    </h2>
                                </div>
                            </div>
                        </div>{' '}
                        <div>
                            <div>
                                <h3>
                                    BookNook এ আপনাকে স্বাগতম। আমরা আপনাদের এবং
                                    অন্য ব্যবহারকারী অথবা তৃতীয় পক্ষ যারা আমাদের
                                    সাথে যোগাযোগ করেন, তাদের জন্য এই শর্তাবলী
                                    (“Terms”) তৈরি করেছি। BookNook এর
                                    অ্যাপ্লিকেশন, ওয়েবসাইট, সার্ভিস, সফটওয়্যারে
                                    প্রবেশ এবং ব্যবহারের মাধ্যমে এবং/অথবা
                                    BookNook Inc (“the BookNook Service,” “we,”
                                    and “us”) থেকে প্রাপ্ত বিষয়বস্তুর মাধ্যমে,
                                    আপনি এই শর্তাবলী মেনে নিতে সম্মতি দিচ্ছেন।
                                    আপনি যদি এই শর্তাবলী মেনে না নেন, তাহলে
                                    BookNook সার্ভিসে প্রবেশ এবং ব্যবহার করবেন
                                    না।
                                </h3>
                                <p />
                                <h3>১। আপনি এবং আপনার অ্যাকাউন্ট</h3>
                                <p>
                                    BookNook সার্ভিসে নিবন্ধনের মাধ্যমে আপনি
                                    নিশ্চিত করছেন যে আপনি (i) ১৮ বছর বা
                                    প্রাপ্তবয়স্কতার উপযুক্ত বয়স বা আপনার প্রদেশ,
                                    রাজ্য অথবা রাষ্ট্রে প্রাপ্তবয়স্ক বা (ii)
                                    নাবালকত্ত থেকে মুক্তিপ্রাপ্ত। এছাড়াও আপনি
                                    নিশ্চিত করেন যে আপনি এই শর্তাবলীতে প্রবেশ
                                    করতে, মেনে নিতে এবং স্বীকৃতি দিতে সক্ষম এবং
                                    যোগ্যতাসম্পন্ন।
                                </p>
                                <p>
                                    আমরা একটি একক আইডি সরবরাহ করতে পারি এবং
                                    BookNook সার্ভিসের অভ্যন্তরে BookNook আভাটার
                                    (“Avatar”) নির্বাচন এবং আপনার অ্যাকাউন্টটি
                                    প্রতিনিধিত্ব করার ক্ষমতা প্রদান করতে পারি।
                                    আপনি যদি নিবন্ধন এবং স্বাক্ষর করেন, আপনি
                                    সম্মতি দিচ্ছেন, (ক) সঠিক এবং বর্তমান তথ্য
                                    সরবরাহ করতে যা রেজিস্ট্রেশান ফর্মের মাধ্যমে
                                    অনুরোধ করা হয়েছে; (খ) এ জাতীয় তথ্য রক্ষা এবং
                                    আপডেট করতে যাতে তা সবসময় সঠিক ও বর্তমান
                                    থাকে; (গ) আপনার পাসওয়ার্ড অথবা একক আইডি
                                    কাউকে না দেয়ার অথবা আপনার অ্যাকাউন্টের
                                    শর্তগুলোর অধীনে অনুমোদিত পরিস্থিতিগুলো
                                    ব্যতীত কাউকে প্রবেশের অনুমতি না দেয়া, অথবা
                                    এমন কিছু না করা যা আপনার অ্যাকাউন্টের
                                    নিরাপত্তা হুমকির সম্মুখিন করতে পারে; (ঘ)
                                    অন্য কোন ব্যক্তিকে ছদ্মবেশের উদ্যেশে অথবা
                                    কোন পদ্ধতিতে কারো অধিকার লঙ্ঘনের উদ্যেশে কোন
                                    ইউজার নেম ব্যবহার করবেন না; (ঙ) অশ্লীল এবং
                                    কুরুচিপূর্ণ কোন ইউজার নেম ব্যবহার করবেন না;
                                    এবং (চ) আপনার পাসওয়ার্ডের অননুমোদিত ব্যবহার
                                    বা BookNook সার্ভিস সম্পর্কিত কোন নিরাপত্তা
                                    লঙ্ঘন হলে আমাদের অবিলম্বে অবহিত করবেন। আপনি
                                    যদি কাউকে নিজের পাসওয়ার্ড প্রদান করেন অথবা
                                    কোন তৃতীয় ব্যক্তিকে আপনার BookNook
                                    অ্যাকাউন্টে প্রবেশের অনুমতি দেন, আপনি এ
                                    জাতীয় কোন তৃতীয় ব্যক্তির শর্তাবলী মেনে নিতে
                                    দায়ী থাকবেন। আপনার পাসওয়ার্ড নিরাপদ রাখতে
                                    ব্যর্থ হলে অথবা পাসওয়ার্ডের অধীনে কোন
                                    কার্যকলাপ হলে তা থেকে উদ্ভূত কোন ক্ষতি কিংবা
                                    লোকসানের জন্য আমরা দায়ী থাকবো না।
                                </p>
                                <p>
                                    আপনার BookNook সার্ভিসের ব্যবহার সমস্ত
                                    উপযুক্ত প্রযুক্তি এবং অ-প্রযুক্তিগত
                                    বৈশিষ্ট্য, নির্দেশিকা ও নীতিমালার অধীনে যা
                                    আমরা সময়সাপেক্ষে সরবরাহ করতে পারি কিন্তু তা
                                    booknooklearning.com/privacy-তে অবস্থিত
                                    গোপনীয় নীতিমালায় সীমাবদ্ধ না (এরপর যা
                                    সম্মিলিতভাবে “the Policies” হিসাবে উল্লেখ
                                    করা হয়), আপনি নীতিমালাগুলো মেনে চলতে এবং
                                    দায়িত্বগ্রহণ করতে সম্মতি দিছেন। এই মুহূর্তে,
                                    BookNook সার্ভিস শুধুমাত্র United States এবং
                                    Canada’র নাগরিকদের ব্যবহারের উদ্যেশে এবং
                                    যারা United States এবং Canada’র বাইরে থেকে
                                    BookNook সার্ভিসে প্রবেশ ও ব্যবহার করেন তারা
                                    নিজ দ্বায়িত্বে করতে পারেন এবং উপযুক্ত
                                    স্থানীয় আইন মেনে চলার দায়বদ্ধতা তাদের।
                                </p>
                                <p />
                                <h3>২। সাবস্ক্রিপশন সদস্য</h3>
                                <p>
                                    ২। সাবস্ক্রিপশন সদস্য
                                    <br /> যদিও BookNook সার্ভিসের কিছু সেবা
                                    এবং/অথবা বিষয়বস্তু কোন চার্জ ছাড়াই কিছু
                                    ব্যবহারকারীর জন্য সহজলভ্য, অন্যান্য সেবা
                                    এবং/অথবা বিষয়বস্তু শুধুমাত্র তাদের জন্য
                                    সহজলভ্য যারা সাবস্ক্রিপশন প্ল্যানে চার্জ
                                    প্রদান করতে সম্মত হন (প্রত্যেকে, একজন
                                    সাবস্ক্রিপশন সদস্য) । প্রতিটি সাবস্ক্রিপশন
                                    প্ল্যান BookNook সার্ভিসের অন্যান্য ক্ষেত্রে
                                    আরও বিশদভাবে বর্ণিত হতে পারে। সাবস্ক্রিপশন
                                    সদস্য হওয়ার প্রক্রিয়ায় আপনাকে প্রদর্শিত
                                    যে কোনও অর্থ পরিশোধের শর্ত এই শর্তগুলোর অংশ
                                    হিসাবে বিবেচিত হবে।
                                </p>
                                <p />
                                <h3>৩। আমাদের লাইসেন্স আপনার জন্য</h3>
                                <p>
                                    আমরা আপনাকে একটি ব্যক্তিগত, প্রত্যাহারযোগ্য,
                                    একচেটিয়া, অ-স্থানান্তরযোগ্য, সাব-লাইসেন্সের
                                    অযোগ্য, বিশ্বব্যাপী একটি লাইসেন্স প্রদান
                                    করবো BookNook সার্ভিসে প্রবেশ ও সম্পূর্ণ
                                    ব্যক্তিগত, অ-বাণিজ্যিকভাবে ব্যবহারের
                                    উদ্দেশ্যে যা শর্ত দ্বারা অনুমোদিত এবং
                                    BookNook সার্ভিসের সাধারণ কার্যকারিতার বিষয়ে
                                    আমাদের উদ্দেশ্য। আপনি এই শর্তগুলো ভঙ্গ করলে
                                    আপনার এই লাইসেন্সটি স্বয়ংক্রিয়ভাবে বাতিল হয়ে
                                    যাবে। পূর্বের নিয়ন্ত্রিত লাইসেন্স বাদে, কোন
                                    অধিকার, স্বত্ব বা স্বার্থ আপনার কাছে
                                    হস্থান্তর করা হয়নি। আমরা আপনাকে আপনার
                                    ট্যাবলেট, স্মার্টফোন বা অন্যান্য মানানসই
                                    ইন্টারনেট-সংযুক্ত ডিভাইসে এনক্রিপ্ট করা,
                                    ক্যাশেড সামগ্রী সংরক্ষণ করার অনুমতি দিতে
                                    পারি। আপনি ক্যাশেড কন্টেন্টের কপি অন্য কোনও
                                    ডিভাইসে স্থানান্তর করতে পারবেন না।
                                </p>
                                <p />
                                <h3>৪। আপনার আচরণ</h3>
                                <p>
                                    আমরা BookNook সার্ভিস যতটা সম্ভব নিরাপদ
                                    রাখতে চাই আমাদের ব্যবহারকারীদের জন্য কিন্তু
                                    এজন্য আপনাদের সাহায্যের দরকার। অতএব, আপনি
                                    সম্মতি প্রদান করছেন যে BookNook সার্ভিস
                                    ব্যবহার করবেন না, (i) যে কোন স্থানীয়,
                                    রাজ্য, জাতীয় বা আন্তর্জাতিক আইন বা
                                    নিয়মকানুন লঙ্ঘন করে; (ii) অপমানজনক,
                                    হয়রানিমূলক, উত্তেজনাপূর্ণ, মানহানিকর,
                                    অশ্লীল, কুরুচিপূর্ণ, কুৎসিত, অবজ্ঞাপূর্ণ,
                                    গ্রাফিকালি হিংস্র, অন্যের গোপনীয়তায়
                                    আক্রমণাত্মক, ঘৃণ্য, বা বর্ণগতভাবে,
                                    জাতিগতভাবে বা অন্যথায় আপত্তিকর কোন উপাদান
                                    পাঠিয়ে; (iii) যে কোন অযৌক্তিক বা অননুমোদিত
                                    বিজ্ঞাপন, প্রচারমূলক সামগ্রী, জাঙ্ক মেইল,
                                    স্প্যাম, চেইন লেটারস, পিরামিড স্কিম বা অন্য
                                    কোন প্রকার অনুরোধ করে; (iv) অ্যাডওয়্যার,
                                    ম্যালওয়্যার, স্পাইওয়্যার, সফ্টওয়্যার
                                    ভাইরাস, বা অন্য কোনও কম্পিউটার কোড, ফাইল, বা
                                    কোন কম্পিউটার সফ্টওয়্যার বা হার্ডওয়্যার বা
                                    টেলিযোগাযোগ সরঞ্জামগুলির কার্যকারিতা সীমিত
                                    করার জন্য ডিজাইন করা প্রোগ্রাম বা ফাইলগুলিকে
                                    ইচ্ছে করে সংক্রমণ করে; (v) কোন নিরাপত্তা বা
                                    বিশুদ্ধতা প্রমাণের পদক্ষেপগুলো
                                    (ভূ-সীমাবদ্ধতার ব্যবস্থা সহ) লঙ্ঘন বা
                                    প্রতিরোধ করে; (vi) BookNook সার্ভিসে থাকা
                                    কোন সফটওয়্যার নষ্ট করার ও তার প্রকৌশল বিপরীত
                                    দিকে নেয়ার চেষ্টা করে; (vii) যে কোন
                                    বিষয়বস্তু থেকে কপিরাইট, ট্রেডমার্ক, বা
                                    অন্যান্য মালিকানার নিদর্শন (নিম্নে বর্ণীত)
                                    কিংবা অন্যান্য ব্যবহারকারীর ব্যবহারকারী
                                    বিবৃতি (নিম্নে বর্ণীত) সরিয়ে ফেলে; (viii)
                                    অন্য কোন ব্যক্তিকে অনুসরণ,হয়রানী কিংবা ক্ষতি
                                    করে; (ix) কোন ব্যক্তি বা সত্ত্বাকে অনুকরণ
                                    করে বা অন্যথায় কোন ব্যক্তি বা সত্তার সাথে
                                    আপনার সম্পর্ককে ভুলভাবে উপস্থাপন করে; (x)
                                    BookNook সার্ভিস বা BookNook সার্ভিসের সাথে
                                    সংযুক্ত সার্ভার বা নেটওয়ার্কে হস্তক্ষেপ বা
                                    ধ্বংস করে অথবা BookNook সার্ভিসের সাথে
                                    সংযুক্ত নেটওয়ার্কগুলির কোনও প্রয়োজনীয়তা,
                                    পদ্ধতি, নীতি বা নিয়ম অমান্য করে; বা (xi)
                                    আপনার অ্যাকাউন্ট ভাড়া বা লিজ দিয়ে। এছাড়াও
                                    আপনি সম্মতি প্রদান করছেন কোন স্বয়ংক্রিয়
                                    পদ্ধতি, “robots,” “spiders,” “offline
                                    readers,” ইত্যাদি কোন সীমাবদ্ধতা ব্যতীত
                                    ব্যবহার ও চালু না করার, যা BookNook সার্ভিসে
                                    এমনভাবে প্রবেশ করতে পারে যে BookNook
                                    সার্ভিসের সার্ভারে একটি নির্দিষ্ট সময়ে কোনো
                                    মানুষ প্রচলিত ওয়েব ব্রাউজার ব্যবহার করে একই
                                    সময়ের মধ্যে যুক্তিসঙ্গতভাবে উৎপাদন করে তার
                                    চেয়ে বেশি অনুরোধ বার্তা পাঠাতে পারে।
                                </p>
                                <p />
                                <h3>৫। মালিকানা; ব্যবহারের বিধিনিষেধ</h3>
                                <p>
                                    BookNook সার্ভিস সাইট ও সার্ভিসের সমস্ত
                                    অধিকার, স্বত্ব এবং স্বার্থ BookNook, LLC.
                                    এবং এর লাইসেন্সদাতার একচেটিয়া সম্পত্তি এবং
                                    তা থাকবে। BookNook সার্ভিসের বিষয়বস্তুতে,
                                    সীমাবদ্ধতা ছাড়া কোন বই, পাঠ্যাংশ,
                                    সফ্টওয়্যার, গ্রাফিক্স, ছবি, শব্দ, সঙ্গীত,
                                    ভিডিও, ব্যবহারকারীর সাবমিশন (নিম্নে বর্ণীত),
                                    পারস্পরিকভাবে এবং তাদের মতো সক্রিয় বৈশিষ্ট্য
                                    (“Content”) এবং “BookNook” ওয়ার্ড মার্ক ও
                                    ডিজাইন মার্ক, এর পাশাপাশি কিছু নাম, লোগো এবং
                                    BookNook সার্ভিসে বা এর মাধ্যমে প্রদর্শিত
                                    বিষয়বস্তু যা ট্রেডমার্ক, ট্রেডনেমস, সার্ভিস
                                    মার্কস বা লোগোস (“Marks”) গঠন করে, তা
                                    BookNook, LLC. এর মালিকানাধীন বা
                                    লাইসেন্সপ্রাপ্ত, এবং মার্কিন যুক্তরাষ্ট্র
                                    এবং আন্তর্জাতিক আইনের অধীনে স্বত্বাধিকার,
                                    ট্রেডমার্ক এবং অন্যান্য বুদ্ধিবৃত্তিক
                                    সম্পত্তির অধিকারের আলোচনার বিষয়। শর্ত দ্বারা
                                    অনুমোদিত বা অন্যভাবে উপযুক্ত লাইসেন্সদাতাদের
                                    দ্বারা লিখিতভাবে অনুমোদিত এমন কোন সীমাবদ্ধতা
                                    ছাড়া, আপনি BookNook সার্ভিস বা BookNook
                                    সার্ভিসের কোন অংশের বিষয়বস্তু এবং চিহ্ন
                                    পুনরায় উৎপাদন, নকল, কপি, বিক্রয়, পুনরায়
                                    বিক্রয়, বা নিজের কাজে না লাগাতে সম্মতি
                                    দিচ্ছেন। আপনি বিষয়বস্তুগুলো (বই এবং ভিডিওতে
                                    সীমাবদ্ধ না) দেখতেও সম্মতি দিচ্ছেন যা
                                    শুধুমাত্র BookNook সার্ভিসের এমন ভৌগলিক
                                    স্থানে সহজলভ্য যেখানে আমরা BookNook সার্ভিস
                                    প্রদান করি এবং এসব বিষয়বস্তুর লাইসেন্স আছে।
                                    BookNook সার্ভিসটি কেবল আপনার ব্যক্তিগত,
                                    অ-বাণিজ্যিক ব্যবহারের জন্য সরবরাহ করা
                                    হয়েছে। আপনাকে অবশ্যই বিষয়বস্তুর সাথে
                                    সম্পর্কিত যে কোন কপিরাইট নোটিশ, তথ্য বা
                                    বিধিনিষেধ মেনে চলতে হবে।
                                </p>
                                <p />
                                <h3>৬। ব্যবহারকারী সাবমিশনস</h3>
                                <p>
                                    আমরা এমন কোন কার্যকারিতা সরবরাহ করতে পারি যা
                                    পাঠ্য (কুইজ, কুইজ প্রতিক্রিয়া, পাঠ
                                    পরিকল্পনা সহ), চিত্র, অডিও, ভিডিও,
                                    উপস্থাপনা, প্রতিযোগিতায় এন্ট্রি, বা অন্যান্য
                                    সামগ্রী (&#8220;ব্যবহারকারী সাবমিশনস&#8221;)
                                    BookNook সার্ভিসে পোস্ট করতে, আপলোড করতে,
                                    জমা দিতে বা অন্যথায় সহজলভ্য করতে সক্ষম করে।
                                    নির্দিষ্ট বয়সের নিচে এসব কার্যকারিতায় প্রবেশ
                                    করা নিষেধ। আপনি বুঝতে পারছেন যে এই জাতীয়
                                    ব্যবহারকারি সাবমিশনসগুলি জনসাধারণের
                                    প্রবেশযোগ্য এবং আমরা এই ব্যবহারকারী
                                    সাবমিশনসের গোপনীয়তার নিশ্চয়তা দেইনা।
                                </p>
                                <p>
                                    আপনি আপনার ব্যবহারকারী সাবমিশনসের সমস্ত
                                    মালিকানার অধিকার রাখতে পারবেন। সমস্ত
                                    ব্যবহারকারী সাবমিশনের জন্য, BookNook
                                    সার্ভিসের ব্যবহারকারী সাবমিশনস সহজলভ্য করার
                                    মাধ্যমে আপনি আমাদের বিশ্বব্যাপী,
                                    অ-একচেটিয়া, রয়্যালটি-মুক্ত,
                                    সাবলাইসেন্সযোগ্য এবং হস্তান্তরযোগ্য লাইসেন্স
                                    প্রদান করবেন সমস্ত বুদ্ধিবৃত্তিক সম্পত্তির
                                    ব্যবহার, পুনরায় উৎপাদন, বিতরণ, প্রদর্শন, এবং
                                    এই জাতীয় ব্যবহারকারী সাবমিশনস কার্যকর করার
                                    অধিকারের অধীনে, সামগ্রিক বা আংশিকভাবে,
                                    BookNook সার্ভিসের এবং আমাদের (আমাদের
                                    উত্তরসূরিদের &#8216;এবং সহযোগী&#8217;)
                                    ব্যবসায়ের সাথে সম্পর্কিত, কোন সীমাবদ্ধতা
                                    ছাড়া সমস্ত BookNook সার্ভিস বা তার অংশ
                                    প্রচার বা পুনরায় বিতরণের জন্য কোনও মিডিয়া
                                    ফরম্যাটে এবং মিডিয়া চ্যানেলের মাধ্যমে
                                    বর্তমানে বা এরপরেও পরিচিত, এবং কোন বিজ্ঞপ্তি
                                    ছাড়াই, স্থায়ীভাবে। আপনি BookNook সার্ভিসের
                                    প্রত্যেক ব্যবহারকারীকে একটি অ-একচেটিয়া
                                    লাইসেন্স প্রদান করবেন BookNook সার্ভিসের
                                    ব্যবহারকারী সাবমিশনস দেখার জন্য, এবং
                                    BookNook সার্ভিসের সাধারণ কার্যকারিতা ও
                                    স্থায়ীভাবে শর্ত দ্বারা অনুমোদিত ব্যবহারকারী
                                    সাবমিশনস ব্যবহার, পুনরায় উৎপাদন, বিতরণ,
                                    প্রদর্শন এবং সম্পাদন করার জন্য।
                                </p>
                                <p>
                                    আপনি এমন কোন ব্যবহারকারীর সাবমিশনস জমা দিতে
                                    পারবেন না যা, (i) মানহানিকর, হয়রানিমূলক,
                                    হুমকিমূলক, ধর্মান্ধ, ঘৃণ্য, হিংসাত্মক,
                                    অশ্লীল, কুৎসিত, কুরুচিপূর্ণ, বা অন্যথায়
                                    আক্রমণাত্মক বা যে কোন ব্যক্তি বা সত্তাকে
                                    যুক্তিসঙ্গতভাবে ক্ষতিগ্রস্থ করতে পারে, এবং
                                    এমন কোন বিষয়বস্তু আইন দ্বারা সুরক্ষিত হোক
                                    বা না হোক, (ii) নতুবা প্রয়োগসাধ্য স্থানীয়,
                                    জাতীয় এবং আন্তর্জাতিক আইন এবং বিধিগুলির
                                    বিপরীত।
                                </p>
                                <p>
                                    আপনি এমন কোন ব্যবহারকারী সাবমিশনস জমা দিতে
                                    পারবেন না যা তৃতীয় পক্ষের কপিরাইটযুক্ত
                                    উপাদান কিংবা এমন কোন উপাদান যা তৃতীয় পক্ষের
                                    মালিকানা অধিকারের অধীনে, যদি না উপাদানের
                                    যথাযথ মালিকের অনুমতি থাকে অথবা আইনগতভাবে এ
                                    জাতীয় বিষয়বস্তু জমা দেওয়ার এবং উপরে
                                    বর্ণিত লাইসেন্সগুলি প্রদানের অনুমতি থাকে।
                                    আপনি উপস্থাপন ও নিশ্চিত করেন যে আপনার
                                    প্রয়োজনীয় লাইসেন্সের অধিকার, সম্মতি এবং
                                    ব্যবহারকারীর সাবমিশন প্রকাশ করার অনুমতি
                                    রয়েছে এবং ব্যবহারকারী সাবমিশনগুলি এই বিভাগ
                                    ৬ এ বর্ণিত শর্তগুলো মেনে চলে। আপনি আরও
                                    সম্মতি প্রদান করেন আমাদের লাইসেন্সদাতা,
                                    বিতরণকারী, এজেন্ট, প্রতিনিধি এবং অন্যান্য
                                    অনুমোদিত ব্যবহারকারীর বিরুদ্ধে ব্যবহারকারীর
                                    সাবমিশন বা কোন নৈতিক অধিকার, আনুষঙ্গিক
                                    অধিকার বা অনুরূপ অধিকার প্রয়োগ না করতে এবং,
                                    এই জাতীয় অধিকার থাকতে পারে এমন কারো কাছ থেকে
                                    একই চুক্তি প্রয়োগ না করতে। আপনি বুঝতে পারছেন
                                    এবং সম্মতি প্রদান করছেন যে ব্যবহারকারী
                                    সাবমিশনগুলি এবং BookNook সার্ভিসে এই জাতীয়
                                    ব্যবহারকারী সাবমিশনগুলি জমা দেয়া ও সহজলভ্য
                                    করার ফলাফলের জন্য কেবল আপনি দায়ী থাকবেন।
                                </p>
                                <p>
                                    কোন পূর্ব নোটিশ ছাড়াই আমাদের BookNook
                                    সার্ভিসে জমাকৃত ব্যবহারকারী সাবমিশনগুলির যে
                                    কোন সময়ে যে কোন কারণে পর্যবেক্ষণ, প্রদর্শন,
                                    প্রকাশ, অপসারণ, সংশোধন, সংরক্ষন এবং
                                    পর্যালোচনা করার, এর সাথে যে ব্যবহারকারী
                                    সাবমিশনগুলি সামঞ্জস্যপূর্ণ, এটাও আপনার কাছে
                                    নিশ্চিত করার অধিকার আছে কিন্তু বাধ্যবাধকতা
                                    নেই।
                                </p>
                                <p>
                                    আমরা কোনও ব্যবহারকারী সাবমিশনে থাকা
                                    বিষয়বস্তু নিয়ে মতামত, সুপারিশ, বা পরামর্শের
                                    সমর্থন বা গ্যারান্টি দেই না এবং এর সাথে জড়িত
                                    যে কোন এবং সমস্ত দায়বদ্ধতা স্পষ্টভাবে
                                    অস্বীকার করি।
                                </p>
                                <p />
                                <h3>৭। বিষয়বস্তুর উপস্থিতি</h3>
                                <p>
                                    BookNook সার্ভিস ব্যবহারকারীদের নির্দিষ্ট
                                    ডিভাইসে ইন্টারনেটের মাধ্যমে বই এবং অন্যান্য
                                    কন্টেন্টে প্রবেশের ব্যবস্থা করে। এই বইগুলো
                                    এবং কনটেন্টের সহজলভ্যতা সময় অনুযায়ী এবং
                                    ভৌগলিক অঞ্চল থেকে অঞ্চল পর্যন্ত বিভিন্ন
                                    কারণে পরিবর্তন হতে পারে (প্রকাশকের লেনদেন
                                    এবং ইন্টারনেট ব্যান্ডউইথের সহজলভ্যতাসহ)।
                                    ফলস্বরূপ, আমরা গ্যারান্টি দিচ্ছিনা যে কোন
                                    কনটেন্ট BookNook সার্ভিসে পাওয়া যাবে এবং
                                    সহজলভ্য হবে (সাবস্ক্রিপশন সদস্যের জন্য
                                    সাবস্ক্রিপশন সময়কালীন সহ)।
                                </p>
                                <p>
                                    মোবাইল নেটওয়ার্ক। যদি আপনি মোবাইল
                                    নেটওয়ার্কের মাধ্যমে BookNook সার্ভিসে প্রবেশ
                                    করেন, আপনার নেটওয়ার্ক অথবা রোমিং সরবরাহকারীর
                                    বার্তা, ডাটা এবং অন্যান্য রেট ও ফি প্রয়োগ
                                    হবে। BookNook সার্ভিসের নির্দিষ্ট কিছু
                                    বৈশিষ্ট্য ডাউনলোড, ইন্সটল বা ব্যবহার নিষিদ্ধ
                                    বা সীমাবদ্ধ হতে পারে নেটওয়ার্ক প্রভাইডার
                                    দ্বারা এবং সব BookNook সার্ভিসের বৈশিষ্ট্য
                                    আপনাদের নেটওয়ার্ক প্রভাইডার কিংবা ডিভাইসের
                                    সাথে কাজ নাও করতে পারে।
                                </p>
                                <p />
                                <h3>৮। সমাপ্তিকরণ</h3>
                                <p>
                                    আপনার BookNook সার্ভিস ব্যবহার করার অধিকার
                                    স্বয়ংক্রিয়ভাবে শেষ হয়ে যাবে যদি আপনি
                                    BookNook সার্ভিসের সাথে সম্পর্কিত পোস্ট করার
                                    কোন নিয়ম বা নীতিমালা লঙ্ঘন করেন। আমরা আমাদের
                                    নিজস্ব বিবেচনার ভিত্তিতে BookNook সার্ভিসের
                                    সমস্ত বা কিছু অংশে আপনার প্রবেশ বন্ধ করার
                                    অধিকার রাখি কোন কারণ কিংবা নোটিশ ছাড়াই।
                                </p>
                                <p />
                                <h3>
                                    ৯। লিঙ্ক বা তৃতীয় পক্ষের
                                    অ্যাপ্লিকেশন/ডিভাইস
                                </h3>
                                <p>
                                    লিঙ্ক। বাবা-মা বা শিক্ষাবিদদের জন্য,
                                    BookNook সার্ভিসে তৃতীয় পক্ষের
                                    ওয়েবসাইটগুলির লিঙ্ক থাকতে পারে বা তৃতীয়
                                    পক্ষের অ্যাপ্লিকেশনগুলির সাথে যোগাযোগ থাকতে
                                    পারে (উইজেটস, সফ্টওয়্যার, বা অন্যান্য
                                    সফ্টওয়্যার ইউটিলিটি সহ অন্যান্য) যা
                                    BookNook সার্ভিসের মালিকানাধীন বা নিয়ন্ত্রণে
                                    নয়। আমাদের অন্য যে কোন ওয়েবসাইট বা তৃতীয়
                                    পক্ষের অ্যাপ্লিকেশনে লিঙ্কের বিধান কেবল
                                    আপনার সুবিধার্থে দেয়া এবং এই জাতীয়
                                    ওয়েবসাইট বা সম্পদ বা এর কন্টেন্টে আমাদের
                                    অনুমোদনের নিদর্শন দেয়া হয়না। কোন কন্টেন্ট,
                                    তথ্য, সফ্টওয়্যার, উপাদান বা তৃতীয় পক্ষের
                                    ওয়েবসাইট বা তৃতীয় পক্ষের আবেদনের জন্য
                                    আমাদের কোন দায়িত্ব কিংবা দায়বদ্ধতা থাকবে
                                    না।
                                </p>
                                <p>
                                    ডিভাইস। আপনি যদি Apple iOS অথবা
                                    অ্যান্ড্রয়েড চালিত ডিভাইস ব্যবহার করে
                                    BookNook সার্ভিসে প্রবেশ করেন, তাহলে Apple
                                    Inc. বা Google, Inc এই চুক্তির তৃতীয় পক্ষের
                                    সুবিধাভোগী হবে। তবে এই তৃতীয় পক্ষের
                                    সুবিধাভোগীরা এই চুক্তির কোন পক্ষ নয় এবং
                                    BookNook সার্ভিসের নিয়ম বা সাহায্যের জন্য
                                    দায়বদ্ধ নয়। আপনি সম্মতি প্রদান করেন যে এই
                                    ডিভাইসগুলি ব্যবহার করে BookNook সার্ভিসে
                                    আপনার প্রবেশ উপযুক্ত তৃতীয় পক্ষের
                                    সুবিধাভোগীর নির্ধারিত ব্যবহারের শর্ত
                                    সাপেক্ষ।
                                </p>
                                <p />
                                <h3>১০। ওয়ারেন্টি পরিত্যাগ</h3>
                                <p>
                                    আপনি স্পষ্টভাবে সম্মতি প্রদান করেন যে
                                    BookNook সার্ভিসের ব্যবহার আপনার নিজস্ব
                                    ঝুঁকিতে সম্পন্ন হবে। BookNook সার্ভিস
                                    &#8220;যেমন আছে&#8221; এবং &#8220;যেমন
                                    উপলব্ধ&#8221; এই ভিত্তিতে সরবরাহ করা হয়।
                                    আইন দ্বারা অনুমোদিত সম্পূর্ণ পরিমাণে,
                                    BOOKNOOK CREATIONS, INC এবং এর সাথে জড়িত
                                    BOOKNOOK সার্ভিসের সাথে সম্পর্কিত
                                    (ব্যবসায়ের দায়িত্বে ওয়্যারেন্টি,
                                    নির্দিষ্ট ব্যবহার বা উদ্দেশের জন্য যোগ্যতা
                                    এবং লঙ্ঘন না করার অন্তর্ভুক্ত তবে সীমাবদ্ধ
                                    না) সব ধরণের প্রকাশ্য বা অপ্রকাশ্য
                                    ওয়ারেন্টি স্পষ্টভাবে অস্বীকার করে। আমরা
                                    BookNook সার্ভিস বা এর মাধ্যমে পাওয়া
                                    বিষয়বস্তুর নির্ভুলতা বা সম্পূর্ণতা সম্পর্কে
                                    বা BookNook সার্ভিসের সাথে সংযুক্ত কোন
                                    ওয়েবসাইটের বিষয়বস্তুর কোনও গ্যারান্টি বা
                                    বিবরণ দেইনা এবং কারো কোন দায়বদ্ধতা বা
                                    দায়িত্ব ধরে নেইনাঃ (i) বিষয়বস্তুর ভুল,
                                    ভ্রান্তি বা অসম্পূর্ণতা; (ii) BookNook
                                    সার্ভিস বা BookNook সার্ভিসে থাকা কনটেন্টে
                                    আপনার প্রবেশ বা ব্যবহারের ফলে যেকোন প্রকৃতির
                                    ব্যক্তিগত আঘাত বা সম্পত্তির ক্ষতি; (iii)
                                    আমাদের সুরক্ষিত সার্ভারে রাখা এবং/অথবা যে
                                    কোনও ব্যক্তিগত তথ্য এবং/বা আর্থিক তথ্যের
                                    অননুমোদিত প্রবেশ বা ব্যবহার; (iv) BookNook
                                    সার্ভিস বা এর থেকে আসা কোন সংক্রমণ বাঁধা
                                    দিলে বা বন্ধ করলে; (v) যে কোন বাগ, ভাইরাস,
                                    ট্রোজান হর্স বা এই জাতীয় কিছু তৃতীয় পক্ষ
                                    দ্বারা BookNook সার্ভিসে বা এর মাধ্যমে
                                    সংক্রমিত হলে; এবং/অথবা (vi) BookNook সার্ভিস
                                    বা এর মাধ্যমে পোস্ট করা, ইমেল করা, প্রেরণ
                                    করা বা অন্য কোনও উপকরণ ব্যবহারের ফলস্বরূপ যে
                                    কোন ধরণের ক্ষতি বা লোকসান হলে।
                                </p>
                                <p />
                                <h3>১১। দায়িত্বের সীমাবদ্ধতা</h3>
                                <p>
                                    আপনি বুঝতে পারছেন যে প্রযোজ্য আইনের অধীনে
                                    অনুমোদিত BOOKNOOK, LLC বা এর অফিসার,
                                    কর্মচারী, পরিচালক, শেয়ারহোল্ডার,
                                    সাবসিডিয়ারি, সহযোগী, এজেন্টস, বা
                                    লাইসেন্সধারীরা যে কোন ঘটনায় কোন থিয়োরি বা
                                    দায়বদ্ধতার (চুক্তি, টর্ট, পরিসংখ্যান বা
                                    অন্য যে কোনও ক্ষেত্রে) আওতায় আপনার (অথবা
                                    অন্য কেউ আপনার অ্যাকাউন্ট) BookNook সার্ভিস
                                    ব্যবহারের ফলে কোনও প্রত্যক্ষ, স্বতন্ত্র,
                                    উদ্ভাবনী, বিশেষ, সংক্ষিপ্ত বা উদাহরণস্বরূপ
                                    ক্ষতিপূরণ, এছাড়াও আরোএমন রাজস্বের ক্ষতি,
                                    লাভ, ব্যবসায়ের সুনাম, ব্যবহার, ডাটা বা
                                    অন্যান্য সংক্ষিপ্ত হারের ক্ষতিপূরণের (যদি
                                    কোন পক্ষ ক্ষয়ক্ষতি সম্ভাবনা সম্পর্কে পরামর্শ
                                    পায়, জেনে থাকে বা জানা উচিত ছিল) জন্য দায়ী
                                    থাকবেনা।
                                </p>
                                <p />
                                <h3>১২। ব্যতিক্রম</h3>
                                <p>
                                    কিছু এখতিয়ার নির্দিষ্ট ওয়্যারেন্টিগুলি বাদ
                                    দেওয়া অথবা গঠনমূলক বা আনুষঙ্গিক ক্ষতিপূরণের
                                    সীমাবদ্ধতা বা বাদ দেয়ার অনুমতি দেয়না।
                                    একইভাবে, উপরের কিছু সীমাবদ্ধতা এবং অস্বীকৃতি
                                    আপনার ক্ষেত্রে প্রযোজ্য নয়। BookNook, LLC এর
                                    প্রযোজ্য আইনের বিষয় অনুসারে, কোন উহ্য
                                    ওয়্যারেন্টি অস্বীকার বা এর দায় সীমাবদ্ধ
                                    করা, এ জাতীয় ওয়্যারেন্টির সুযোগ ও সময়কাল
                                    নির্ধারণ এবং আমাদের দায়বদ্ধতার পরিমাণ
                                    নুন্যতমভাবে প্রযোজ্য আইনের আওতায় অনুমোদিত
                                    হবে।
                                </p>
                                <p />
                                <h3>১৩। ক্ষতিপূরণ</h3>
                                <p>
                                    আপনি BookNook, LLC এবং এর সহায়ক,
                                    অনুমোদনকারি, কর্মকর্তা, পরিচালক, কর্মচারী,
                                    পরামর্শদাতা এবং এজেন্টদের থেকে এবং কারো
                                    বিরদ্ধে এবং কোন দাবী, দায়, ক্ষতি, অপচয়, খরচ,
                                    ব্যয় (যুক্তিসঙ্গত অ্যাটর্নিদের ফি এবং ব্যয়
                                    সহ) যা এই জাতীয় পক্ষদের থেকে বা তাদের
                                    ফলস্বরূপ হতে পারে, (ক) এমন তথ্য (আপনার
                                    ব্যবহারকারী সাবমিশনস, প্রতিক্রিয়া বা অন্য
                                    কোন বিষয়বস্তুসহ সীমাবদ্ধতা ছাড়া) আপনি (বা
                                    আপনার অ্যাকাউন্টটি যে ব্যবহার করছে) BookNook
                                    সার্ভিস বা এর মাধ্যমে জমা, পোষ্ট এবং প্রেরণ
                                    করলে; (খ) আপনি বা (আপনার অ্যাকাউন্টটি যে
                                    ব্যবহার করছে) BookNook সার্ভিসের ব্যবহার
                                    করলে; (গ) আপনি বা (আপনার অ্যাকাউন্টটি যে
                                    ব্যবহার করছে) এই শর্তগুলি লঙ্ঘন করলে; অথবা
                                    (ঘ) কোন ব্যক্তি বা সত্তার সীমাবদ্ধতা ছাড়াও
                                    যে কোন কপিরাইট, পেটেন্ট, ট্রেডমার্ক,
                                    ব্যবসায়ের গোপনীয়তা, প্রচার/গোপনীয়তার
                                    অধিকার, বা কোন ব্যক্তি বা সত্তার অন্যান্য
                                    মালিকানার অধিকার আপনি (আপনার অ্যাকাউন্টটি যে
                                    ব্যবহার করছে) লঙ্ঘন করলে, ক্ষতিপূরণ, রক্ষা
                                    এবং ধরে রাখার সম্মতি দিবেন। একচেটিয়া
                                    প্রতিরক্ষার অনুমান করতে এবং এবং অন্যথায়
                                    ক্ষতিপূরণের বিষয়ে, যে ইভেন্টে আপনি BookNook,
                                    LLC কে কোন উপলব্ধ সুরক্ষার দাবী করে সহযোগিতা
                                    করবেন, BookNook, LLC তার নিজস্ব ব্যয়ে সেই
                                    অধিকার সংরক্ষণ করবে।
                                </p>
                                <h3>১৪। বিরোধ</h3>
                                <p>
                                    এই শর্তগুলো ক্যালিফোর্নিয়া রাজ্যের আইন
                                    অনুসারে কার্যকর করা হবে এবং আইনের কোন পছন্দ
                                    বা আইন নীতিগুলির দ্বন্দ্বকে বিবেচনা না করে
                                    আপনি যেখানেই থাকুন না কেন তা প্রয়োগ করা
                                    হবে। আপনি ক্যালিফোর্নিয়ার আলামেডা কাউন্টিতে
                                    অবস্থিত একটি রাজ্য বা ফেডারেল আদালতে
                                    একচেটিয়াভাবে এই শর্তগুলো বা BookNook সার্ভিস
                                    দ্বারা উদ্ভূত বা সম্পর্কিত যে কোন দাবি,
                                    কার্যকারণ বা বিতর্ককে সমাধান করবেন। আপনি এই
                                    জাতীয় সমস্ত দাবী বা পদক্ষেপের কারণ শুনানির
                                    উদ্দেশ্যে ক্যালিফোর্নিয়ার আলামেডা কাউন্টিতে
                                    অবস্থিত আদালতের ব্যক্তিগত এখতিয়ারে জমা দিতে
                                    সম্মত হচ্ছেনন।
                                </p>
                                <p />
                                <h3>১৫। যোগাযোগ</h3>
                                <p>
                                    BookNook সার্ভিস ব্যবহারের মাধ্যমে আপনি
                                    সম্মতি প্রদান করেন যে কোন নোটিশ, চুক্তি,
                                    প্রকাশ বা অন্যান্য যোগাযোগ যা আমরা আপনাকে
                                    বৈদ্যুতিকভাবে প্রেরণ করি তা কোন আইনি
                                    যোগাযোগের প্রয়োজনীয়তা পূরণ করবে (এই জাতীয়
                                    যোগাযোগ অবশ্যই লিখিত থাকতে হবে)।
                                </p>
                                <p />
                                <h3>
                                    ১৬। অ্যাপেলের iOS অ্যাপ্লিকেশানের অতিরিক্ত
                                    শর্তগুলো
                                </h3>
                                <p>
                                    iTunes স্টোর (“iOS App”) থেকে আপনার দ্বারা
                                    প্রাপ্ত অ্যাপেল iOS-চালিত মোবাইল ডিভাইসে
                                    ব্যবহারের জন্য ডিজাইন করা আমাদের
                                    অ্যাপ্লিকেশনগুলির ক্ষেত্রে নিম্নলিখিত
                                    অতিরিক্ত শর্তাবলী প্রযোজ্য। আপনি সম্মতি
                                    দিচ্ছেন যে এই শর্তগুলো সম্পূর্ণ আপনার এবং
                                    আমাদের মধ্যে, অ্যাপেলের না এবং অ্যাপেলের কোন
                                    দায়িত্ব নেই আমাদের iOS অ্যাপ, BookNook
                                    সার্ভিস এবং কোন কন্টেন্টে। আপনি সম্মতি
                                    দিচ্ছেন যে আমাদের iOS অ্যাপে আপনার ব্যবহার
                                    অ্যাপেলের তৎকালীন অ্যাপ স্টোর সার্ভিসের
                                    শর্তগুলো ব্যবহারের নিয়ম অনুযায়ী হবে।
                                    পক্ষগুলো সম্মতি দিবে যে আমাদের iOS
                                    অ্যাপ্লিকেশন সম্পর্কিত রক্ষণাবেক্ষণ এবং
                                    সহায়তা সার্ভিস সরবরাহ করার জন্য অ্যাপেলের
                                    কোনও বাধ্যবাধকতা থাকবে না। iOS অ্যাপের কোন
                                    প্রযোজ্য ওয়ারেন্টি মেনে চলার ক্ষেত্রে
                                    ব্যর্থতায়, আপনি অ্যাপেলকে অবহিত করতে পারেন
                                    এবং অ্যাপেল আপনাকে আমাদের iOS অ্যাপের
                                    ক্রয়মূল্য (যদি থাকে তবে) ফেরত দেবে। আইনের
                                    সর্বোচ্চ সীমার অনুমোদন দ্বারা, আমাদের iOS
                                    অ্যাপ্লিকেশন সম্পর্কিত কোন ওয়্যারেন্টির
                                    বাধ্যবাধকতা অ্যাপেলের থাকবে না এবং এবং অন্য
                                    কোন দাবি, ক্ষতি, দায়, ক্ষতিপূরণ, খরচ বা
                                    ব্যয় যে কোনও ওয়্যারেন্টির সাথে মানিয়ে
                                    নিতে ব্যর্থ হলে, সম্পূর্ণরূপে এই শর্তগুলো
                                    এবং সফ্টওয়্যার সরবরাহকারী হিসাবে আমাদের
                                    প্রযোজ্য কোন আইন দ্বারা পরিচালিত হবে। আপনি
                                    সম্মতি দিচ্ছেন যে অ্যাপেল নয়, আমরা, iOS
                                    অ্যাপ বা iOS অ্যাপে আপনার অধিকার বা ব্যবহার
                                    সম্পর্কিত তবে সীমাবদ্ধ নয়, আপনার বা তৃতীয়
                                    পক্ষের এমন কোন দাবী মোকাবেলার জন্য দায়বদ্ধঃ
                                    (i) পণ্যের দায়ের দাবী; (ii) এমন কোন দাবী যা
                                    iOS অ্যাপ কোন প্রযোজ্য আইন বা নিয়ন্ত্রক
                                    প্রয়োজনীয়তা মেনে চলতে ব্যর্থ হয়; (iii)
                                    ভোক্তা সুরক্ষা বা একইরকম আইনের অধীনে দাবী;
                                    এবং এই জাতীয় সমস্ত দাবিগুলি কেবলমাত্র এই
                                    শর্তগুলো এবং সফ্টওয়্যার সরবরাহকারী হিসাবে
                                    আমাদের প্রযোজ্য আইন দ্বারা পরিচালিত হয়।
                                    আপনি সম্মতি দিচ্ছেন যে, কোন তৃতীয় পক্ষের
                                    দাবীর ক্ষেত্রে আমাদের iOS অ্যাপ বা তাদের দখল
                                    বা iOS অ্যাপের ব্যবহার এই জাতীয় তৃতীয় পক্ষের
                                    বুদ্ধিবৃত্তিক সম্পত্তির অধিকার লঙ্ঘন করে,
                                    এবং এসব দাবীর তদন্ত, প্রতিরক্ষা, নিষ্পত্তি
                                    এবং মুক্তির জন্য আমরা দায়ী থাকবো, অ্যাপেল
                                    না। পক্ষগুলো সম্মতি প্রদান করে যে অ্যাপেল
                                    এবং এর সহায়কগুলি আপনার iOS অ্যাপের
                                    ব্যবহারের ক্ষেত্রে প্রযোজ্য শর্তের তৃতীয়
                                    পক্ষের সুবিধাভোগী। শর্তগুলো সমর্থনের উপরে,
                                    অ্যাপেলের অধিকার হবে (এবং অধিকার গ্রহণ করেছে
                                    বলে গণ্য হবে) তৃতীয় পক্ষের সুবিধাভোগী
                                    হিসাবে আপনার iOS অ্যাপের ব্যবহারের বিরুদ্ধে
                                    প্রযোজ্য শর্ত কার্যকর করার।
                                </p>
                                <p />
                                <h3>১৭। বিবিধ</h3>
                                <p>
                                    BookNook সার্ভিসে পোস্ট হওয়া এই শর্তগুলোর
                                    সাথে অন্য কোন নীতিমালা বা নির্দেশিকা আপনার
                                    এবং আমাদের মধ্যে চুক্তির সম্পূর্ণ এবং
                                    একচেটিয়া এবং চূড়ান্ত বিবৃতি গঠন করে। এই
                                    শর্তগুলোর কোন অধিকার বা বিধান ব্যবহার বা
                                    প্রয়োগ করতে আমাদের ব্যর্থতা এই জাতীয়
                                    অধিকার বা বিধান শিথিল করবে না। যদি কোন কারণে
                                    এই শর্তগুলোর কোন বিধান কার্যকর করা যাবে না
                                    বলে মনে হয়, ঐ বিধানটি সর্বাধিক পরিমাণে
                                    অনুমোদনের মাধ্যমে কার্যকর করা হবে যাতে
                                    বিধানগুলি প্রতিফলিত পক্ষের উদ্দেশ্যকে
                                    কার্যকর করতে পারে এবং এই শর্তগুলোর অবশিষ্ট
                                    অংশ পুরো শক্তির সাথে এবং কার্যকরভাবে চলতে
                                    থাকবে। এই শর্তগুলোর বিভাগের শিরোনাম
                                    কেবলমাত্র সুবিধার জন্য দেয়া হয়েছে এবং এর কোন
                                    আইনি বা চুক্তিভিত্তিক প্রভাব নেই। বিভাগের
                                    শর্তাবলীর
                                    ২,৫,৬,৭,৮,৯,১০,১১,১২,১৩,১৪,১৫,১৬,১৭,১৮,১৯
                                    এবং ২০ এর শর্তাবলী, এর পাশাপাশি দায়বদ্ধতার
                                    উপর স্পষ্টভাবে বর্ণিত অন্য যে কোন
                                    সীমাবদ্ধতা, সম্পূর্ণ কার্যকর থাকবে এবং
                                    BookNook সার্ভিসে আপনার ব্যবহার সমাপ্ত হলেও
                                    তার প্রভাব থাকবে। এই শর্তগুলো আপনার কাছে
                                    ব্যক্তিগত এবং আমাদের পূর্ব লিখিত সম্মতি
                                    ছাড়া আপনার দ্বারা অর্পণযোগ্য,
                                    হস্তান্তরযোগ্য বা সাবলাইসেন্সযোগ্য না। আমরা
                                    আপনার অনুমোদন ছাড়াই এই শর্তগুলোর অধীনে
                                    আমাদের অধিকার এবং বাধ্যবাধকতাগুলি অর্পণ,
                                    স্থানান্তর বা প্রতিনিধিত্ব করতে পারি। এই
                                    শর্তগুলোর সেকশন এবং সাবসেকশন কেবল সুবিধার
                                    জন্য এবং তাদের ব্যাখ্যার কোন প্রভাব পরবে না।
                                    এই শর্তাবলীতে নির্দিষ্ট না করা হলে সমস্ত
                                    নোটিশ অবশ্যই লিখিত থাকতে হবে এবং রসিদ
                                    অনুযায়ী দেওয়া হবে, লিখিত স্বয়ংক্রিয় রসিদ
                                    বা বৈদ্যুতিক লগ দ্বারা যাচাই করে (প্রযোজ্য)।
                                    BookNook সার্ভিসে নোটিশ পাঠানোর জন্য ইমেইলের
                                    ঠিকানাটি হল,{' '}
                                    <span>info@booknooklearning</span>.com.
                                </p>
                                <p />
                                <h3>১৮। পরিবর্তনসমূহ</h3>
                                <p>
                                    আমরা আমাদের একক এবং নিখুঁত বিচক্ষণতার
                                    মাধ্যমে সময়ের সাথে এই শর্তগুলি পরিবর্তন করতে
                                    পারি। যদি আমরা তা করি, তাহলে আমরা পোস্টিং
                                    আপডেট করবো, আপনাকে BookNook সার্ভিসের
                                    মাধ্যমে নোটিশ প্রেরণ করবো বা ইমেইল করবো।
                                    BookNook সার্ভিসের অব্যহত ব্যবহার আপনার
                                    পরিবর্তিত শর্তগুলো মেনে নেয়া নিশ্চিত করে।
                                    যদি আপনি এমন কোন পরিবর্তনে দ্বিমত হন, আপনার
                                    একমাত্র অবলম্বন হবে BookNook সার্ভিসের
                                    ব্যবহার বন্ধ করে দেয়া।
                                </p>
                                <p />
                                <h3>১৯। আইনসংক্রান্ত</h3>
                                <p>
                                    Apple, Apple এর লোগো, iMac, MacBook Pro, এবং
                                    iPad Apple Inc. এর ট্রেডমার্ক মার্কিন
                                    যুক্তরাষ্ট্র এবং অন্যান্য দেশে নিবন্ধিত। App
                                    Store হচ্ছে Apple Inc. এর একটি সার্ভিস
                                    চিহ্ন।
                                </p>
                                <p />
                                <p>____________</p>
                                <p />
                                <p>সর্বশেষ আপডেট: ২২ জুন, ২০১৭।</p>
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div id="urdu">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h2>
                                        <span>سروس کی شرائط</span>
                                    </h2>
                                </div>
                            </div>
                        </div>{' '}
                        <div>
                            <div>
                                <h3>
                                    BookNook میں خوش آمدید۔ ہم آپ کے اور دیگر
                                    دوسرے صارفین یا تھرڈ پارٹی جو ہم سے رابطہ
                                    کرتے ہیں، کے حقوق کا تحفظ چاہتے ہیں، ہم نے
                                    یہ سروس کی شرائط (“Terms”) تشکیل دی ہیں۔
                                    BookNook کی ایپلیکیشنز، ویب سائٹ، سروسز،
                                    سوفٹ ویئر، اور/ یا مواد جوکہ BookNook Inc.
                                    کی طرف سے مہیا کیا گیا ہے (“the BookNook
                                    Service”, “we,” and “us”) ، ان تک رسائی حاصل
                                    کرنے یا استعمال کرنے سے آپ ان شرائط پر
                                    آمادگی ظاہر کرتے ہیں۔ اگر آپ ان شرائط پر
                                    آمادہ نہیں ہیں، تو برائے مہربانی BookNook کی
                                    سروس استعمال مت کریں۔
                                </h3>
                                <p />
                                <h3>1۔ آپ اور آپ کا اکاؤنٹ</h3>
                                <p>
                                    BookNook کی سروس پر رجسٹر ہونے سے آپ یہ
                                    تصدیق کرتے ہیں کہ آپ (ا) کی عمر 18 سال ہے یا
                                    اکثریت کی قابل اطلاق عمر کے ہیں یا اپنے
                                    صوبے، علاقے یا ملک میں بڑی عمر کے ہیں یا (ب)
                                    آزاد نابالغ ہیں۔ آپ یہ بھی تصدیق کرتے ہیں کہ
                                    آپ ان شرائط کو قبول کرنے کی مکمل طور پر
                                    قابلیت رکھتے ہیں اور ان کی پابندی کریں گے
                                    اور ان پر عمل کریں گے۔
                                </p>
                                <p>
                                    ہم آپ کو ایک مخصوص آئی ڈی مہیا کرسکتے ہیں
                                    اور BookNook سروس میں اپنے اکاؤنٹ کی
                                    نمائندگی کی لیے(“Avatar”) BookNook avatar
                                    منتخب کرنے کی اجازت دے سکتے ہیں۔ اگر آپ
                                    رجسٹر اور سبسکرائب کرتے ہیں تو (ا) رجسٹریشین
                                    فارم میں پوچھی گئی معلومات کے مطابق درست اور
                                    موجودہ معلومات فراہم کریں؛ (ب) ایسی معلومات
                                    فراہم کریں اور اپ ڈیٹ کریں جو کہ بالکل درست
                                    اور موجودہ معلومات ہوں؛ (ج) اپنا پاسورڈ اور
                                    منفرد آئی ڈی کسی کے ساتھ شیئر مت کریں یا کسی
                                    کو بھی اپنے اکاؤنٹ تک رسائی حاصل نہ کرنے
                                    دیں، ماسوائے ان حالات میں جن کی ان شرائط میں
                                    اجازت دی گئی ہے، یا ایسا کچھ بھی مت کریں جس
                                    کی وجہ سے آپ کے اکاؤنٹ کی سیکورٹی خطرے میں
                                    پڑسکے؛ (د) ایسا username استعمال مت کریں جو
                                    کسی اور کا روپ دھارنے کے مقصد کے لئے رکھا
                                    گیا ہو یا کسی دوسرے شخص کے حقوق کی خلاف ورزی
                                    کرتا ہو؛ (ہ) ایسا username استعمال مت کریں
                                    جو فحش یا بیہودہ ہو؛ اور (ر) اپنے پاسورڈ کے
                                    بلااجازت استعمال یا کوئی بھی دیگر سیکورٹی
                                    ایشو، جو کہ BookNook سروس کی سیکورٹی سے
                                    متعلق ہو، کے بارے میں ہمیں فوراً اطلاع کریں۔
                                    جب آپ اپنا پاسورڈ کسی سے شیئر کرتے ہیں یا
                                    کسی تھرڈ پارٹی کو اپنے BookNook اکاؤنٹ تک
                                    رسائی دیتے ہیں تو آپ اس تھرڈ پارٹی کی طرف سے
                                    ان شرائط کی پابندی کے ذمے دار رہنے پر
                                    رضاماندی ظاہر کرتے ہیں۔ ہم کسی بھی ایسے
                                    نقصان کے ذمے دار نہیں ہوں گے جو آپ کے اپنے
                                    پاسورڈ کی حفاظت سے استعمال کرنے میں ناکامی
                                    کی وجہ سے سامنے آئیں یا آپ کے پاسورڈ کے
                                    استعمال سے متعلق ہوں۔
                                </p>
                                <p>
                                    آپ کے BookNook سروس کے استعمال کا دارومدار
                                    تمام قابل اطلاق تکنیکی اور غیرتکنیکی
                                    تفصیلات، رہنمائیوں اور پالیسیوں پر ہے جو ہم
                                    گاہے بگاہے مہیا کرسکتے ہیں بشمول پرائیویسی
                                    پالیسی جو کہ booknooklearning.com/privacy پر
                                    موجود ہے (جسے اب اس سے آگے اجتماعی طور پر
                                    “the Policies” کہا جائے گا) اور صرف اس تک ہی
                                    محدود نہیں ہیں۔ آپ رضامند ہیں اور عہد کرتے
                                    ہیں کہ آپ پالیسیز کی تعمیل کریں گے۔ اِس وقت،
                                    BookNook سروس صرف امریکہ اور کینیڈا کے
                                    شہریوں کے استعمال کے لیے ہے اور کوئی بھی فرد
                                    جو امریکہ یا کینیڈا کے باہر سے BookNook کی
                                    سروس تک رسائی حاصل کرے گا یا استعمال کرے گا
                                    تو وہ ایسا اپنی ذمہ داری پر اور اپنے مقامی
                                    قوانین کے مطابق کرے گا/گی۔
                                </p>
                                <p />
                                <h3>2۔ سبسکرپشن ممبران</h3>
                                <p>
                                    اگرچہ BookNook سروس کے مخصوص فیچرز اور/ یا
                                    مواد مخصوص صارفین کے لیے مفت دستیاب ہوسکتا
                                    ہے، دیگر فیچرز اور/یا مواد صرف ان کے لیے
                                    دستیاب ہوسکتا ہے جو ایک سبسکرپشن پلان
                                    (ایک”Subscription Member” ) کے تحت رقم کی
                                    ادائیگی پر رضامندی ظاہر کریں گے۔ ہر سبسکرپشن
                                    پلان BookNook سروس کے دیگر حصوں میں تفصیل
                                    میں بیان کیا جاسکتا ہے۔ سبسکرپشن ممبر بننے
                                    کے دوران کوئی بھی ادائیگی کی شرائط جو آپ کو
                                    بتائی گئی ہوں وہ ان شرائط کا حصہ سمجھی جائیں
                                    گی۔
                                </p>
                                <p />
                                <h3>3۔ آپ کے لیے ہمارا لائسنس</h3>
                                <p>
                                    ہم آپ کو ذاتی، قابل منسوخی، غیرخصوصی، ناقابل
                                    انتقال، ناقابل سب لائسنسنگ، عالمی لائسنس
                                    دیتے ہیں تاکہ آپ BookNook کی سروس تک رسائی
                                    حاصل کرسکیں اور انہیں صرف اور صرف ذاتی،
                                    غیرتجارتی مقاصد کے لیے استعمال کرسکیں جیسا
                                    کہ ان شرائط میں اجازت دی گئی ہے اور جیسا کہ
                                    ہم نے BookNook کی سروس کا عمومی طریقہ کار طے
                                    کیا ہے۔ اگر آپ ان شرائط کی خلاف ورزی کرتے
                                    ہیں تو یہ لائسنس خودبخود ختم ہو جائے گا۔
                                    ماسوائے سابقہ محدود لائسنس کے، کوئی حقوق،
                                    ٹائٹل یا مفادات آپ کو منتقل نہیں کئے جائیں
                                    گے۔ ہم آپ کو انکرپٹڈ اور کیشڈ(cached) کیا
                                    گیا مواد اپنے ٹیبلٹ، اسمارٹ فون یا دیگر
                                    انٹرنیٹ سے کنیکٹ ہونے والی ڈیوائسز پر محفوظ
                                    کرنے کی اجازت دے سکتے ہیں۔ آپ کیشڈ کیے گئے
                                    مواد کی کاپیز کسی اور ڈیوائس پر منتقل نہیں
                                    کرسکتے۔
                                </p>
                                <p />
                                <h3>4۔ آپ کا طرزعمل</h3>
                                <p>
                                    ہم اپنے صارفین کے لیے BookNook سروس کو
                                    ہرممکن حد تک محفوظ رکھنا چاہتے ہیں لیکن ایسا
                                    کرنے کے لیے ہمیں آپ کی مدد درکار ہے۔ اسی طرح
                                    آپ رضامندی ظاہر کرتے ہیں کہ BookNook سروس
                                    کو: (ا) کسی بھی مقامی، ریاستی، قومی، یا بین
                                    الاقوامی قانون کی خلاف ورزی کرنے میں؛ (ب)
                                    کوئی ایسا مواد منتقل کرنے میں جو آبروریزی،
                                    ہراسانی، نقصان پہنچانے والا، بدنام کرنے
                                    والا، فحش، فحش نگاری پر مبنی، توہین آمیز،
                                    گرافک کے طور پر متشدد، کسی دوسرے کی
                                    پرائیویسی کے خلاف، نفرت انگیز، نسل پرستانہ،
                                    یا قابل اعتراض ہو؛ (ج) کسی بھی غیراعلانیہ یا
                                    غیرمجاز تشہیر کی منتقلی، تشہیری مواد، جنک
                                    میل، اسپیم میل، غیرضروری ای میلز، پیرامڈ
                                    اسکیمز، یا کسی بھی دوسری شکل کی امدادی
                                    درخواستیں بھیجنے میں؛ (د) جان بوجھ کر ایسا
                                    مواد منتقل کرنے میں جس میں ایڈویئر، مال
                                    ویئر، اسپائی ویئر،سوفٹ ویئر وائرسز یا دیگر
                                    کمپیوٹر کوڈ شامل ہوں، ایسی فائلز یا پروگرامز
                                    جو کہ کسی بھی کمپیوٹر سوفٹ ویئر یا ہارڈویئر
                                    یا ٹیلی کمیونیکیشن آلات کو خراب کرنے، تباہ
                                    کرنے یا ان کے کام کی صلاحیت کو محدود کرنے کے
                                    لیے ڈیزائن کئے گئے ہوں؛ (ہ) کسی بھی سیکورٹی
                                    یا تصدیقی اقدامات کی خلاف ورزی کرنے یا انہیں
                                    دھوکہ دینے میں (جن میں ارضی پابندیوں کے
                                    اقدامات شامل ہوں)؛ (ر) BookNook سروس میں
                                    موجود کسی بھی سوفٹ ویئر کو decompile یا
                                    reverse engineer کرنے میں؛ (ز) کسی بھی مواد
                                    سے متعلقہ کسی بھی کاپی رائٹ، ٹریڈ مارک، یا
                                    دیگر ملکیتی اشاریوں کو مٹانے میں (جیسا کہ
                                    نیچے واضح کیا گیا ہے) یا دوسرے صارفین کی
                                    گزارشات (جیسا کہ نیچے واضح کیا گیا ہے) میں؛
                                    (س) کسی دوسرے فرد کا پیچھا کرنے، اسے ہراساں
                                    کرنے یا نقصان پہنچانے میں؛ (ش) کسی فرد یا
                                    شخصیت کی نقل کرنے، یا کسی فرد یا شخصیت سے
                                    اپنی غلط وابستگی ظاہر کرنے میں؛ (ط) BookNook
                                    سروس یا سرورز یا نیٹ ورکس کو جو کہ BookNook
                                    سروس سے منسلک ہوں ان میں مداخلت کرنے یا
                                    متاثر کرنے میں، یا BookNook سروس سے منسلک
                                    نیٹ ورکس کی مطلوب معلومات، طریقہ کار،
                                    پالیسیز، یا ظابطوں کی پابندی نہ کرنے میں، یا
                                    (ظ) اپنے اکاؤنٹ کو کرائے پر یا لیز پر دینے
                                    میں استعمال نہیں کریں گے۔ آپ اس پر بھی
                                    رضامندی ظاہر کرتے ہیں کہ آپ کوئی بھی ایسا
                                    خودکار سسٹم لانچ نہیں کریں گے جو “robots”،
                                    “spiders”، “offline readers” وغیرہ کے ذریعے
                                    BookNook سروس تک اس انداز میں رسائی حاصل کرے
                                    کہ BookNook سروس کے سرورز کو ایک مخصوص وقت
                                    میں اس سے زیادہ درخواستیں بھیجے جوکہ ایک
                                    انسان اسی مخصوص وقت میں ایک روایتی ویب
                                    براؤزر استعمال کرتے ہوئے بھیج سکتا ہو۔
                                </p>
                                <p />
                                <h3>5۔ ملکیت؛ استعمال پر پابندیاں</h3>
                                <p>
                                    BookNook سروس سائٹ اور خدمات کے تمام حقوق،
                                    ٹائٹل، اور مفادات BookNook, LLC اور اس کے
                                    لائسنس دہندوں کی خصوصی ملکیت ہیں اور رہیں
                                    گے۔ BookNook سروس پر موجود مواد بشمول اور
                                    بغیر حدود کے، کتابیں، ٹیکسٹ، سوفٹ ویئر،
                                    گرافکس، فوٹوز، ساؤنڈز، میوزک، وڈیوز، صارف کی
                                    درخواستیں (جیسا کہ نیچے وضاحت کی گئی)
                                    انٹرایکٹو فیچرز اور (“Content”) اور
                                    “BookNook” کا لفظی نشان اور ڈیزائن، اور کچھ
                                    دیگر مخصوص نام، لوگوز اور مٹیریئلز BookNook
                                    سروس پر موجود ہیں یا اس کے ذریعے ظاہر کی گئی
                                    جو ٹریڈ مارکس، ٹریڈ نیمز، سروس نشانات اور
                                    لوگوز (“Marks”) بناتی ہیں، وہ BookNook کی
                                    ملکیت ہیں یا لائسنس شدہ ہیں اور امریکہ اور
                                    بین الاقوامی قوانین کے کاپی رائٹ، ٹریڈ مارک
                                    اور دیگر حقوق برائے دانشورانہ املاک کے تحت
                                    ہیں۔ آپ رضامندی ظاہر کرتے ہیں کہ آپ BookNook
                                    سروس یا BookNook سروس کا کوئی بھی حصہ، بشمول
                                    اور بغیر حدود کے، مواد اور نشانات، ماسوائے
                                    ان شرائط میں اجازت دیئے گئے یا اگر ہم اور
                                    ہمارے لائنسنس دہندہ لکھی ہوئی صورت میں اجازت
                                    دیں، کو کسی بھی مقصد کے لیے دوبارہ تیار،
                                    نقل، کاپی، بیچنا، دوبارہ بیچنا یا ان کا غلط
                                    استعمال نہیں کریں گے۔ آپ یہ بھی رضامندی ظاہر
                                    کرتے ہیں کہ آپ BookNook سروس کے ذریعے مہیا
                                    کیا گیا مواد (بشمول لیکن صرف کتابوں اور
                                    وڈیوز کی حد تک نہیں) صرف ان جغرافیائی حدود
                                    میں دیکھیں گے جہاں ہم BookNook سروس آفر کرتے
                                    ہیں اور جہاں ہم نے ایسے مواد کا لائسنس جاری
                                    کیا ہوا ہے۔ BookNook سروس آپ کے ذاتی اور
                                    غیرتجارتی استعمال کے لیے مہیا کی گئی ہے۔ آپ
                                    لازماً ان تمام کاپی رائٹ نوٹسز، معلومات، یا
                                    پابندیوں کی تعمیل کریں گے جو کسی بھی مواد
                                    میں یا مواد سے متعلق ہوں گی۔
                                </p>
                                <p />
                                <h3>6۔ صارف کی سبمیشنز</h3>
                                <p>
                                    ہم آپ کو ایسی فعالیت مہیا کرسکتے ہیں جو آپ
                                    کو ٹیکسٹ (بشمول کوئز، کوئز رسپانس، سبق کے
                                    پلانز) کو جمع کروانے یا ظاہر کرنے، تصویریں،
                                    آواز، وڈیو، پریزنٹیشن، مقابلے کی انٹریز یا
                                    دوسرے مواد (“User Submissions”) کو BookNook
                                    سروس پر پوسٹ، اپ لوڈ کرنے کے قابل بناتی ہے۔
                                    ایسی فعالیت تک رسائی ایک خاص عمر سے زیادہ
                                    عمر والے صارفین تک محدود ہو سکتی ہے۔ آپ
                                    سمجھتے ہیں کہ صارف کی طرف سے ایسی جمع کی گئ
                                    گزارشات تک عوام کی رسائی ہوسکتی ہے اور ہم
                                    ایسی سبمیشنز کی رازداری کے بارے میں کوئی
                                    گارنٹی نہیں دے سکتے۔
                                </p>
                                <p>
                                    آپ اپنے تمام ملکیتی حقوق اپنی صارفی سبمیشنز
                                    تک برقرار رکھتے ہیں۔ تمام صارف کی سبمیشنز کے
                                    لیے، سبمیشنز BookNook کی سروس پر مہیا کرنے
                                    سے، آپ ہمیں عالمی، غیرخصوصی، رائلٹی فری،
                                    قابل سب لائنسنسنگ اور قابل منتقلی لائسنسن
                                    دیتے ہیں جو کہ دانشورانہ ملکیتی حقوق کے تحت
                                    ہوتے ہیں کہ ہم انہیں استعمال، دوبارہ تیار،
                                    تقسیم، ظاہر کرسکتے ہیں اور ہم BookNook سروس
                                    اور ہمارے بزنس (اور ہمارے جانشین اور وابستہ
                                    افراد)، بشمول بغیر کسی حدود کے، اور ان سے
                                    متعلق مکمل یا حصوں پر مشتمل ایسی صارف
                                    گزارشات بھی کرسکتے ہیں جن کے ذریعے BookNook
                                    سروس کو کسی بھی میڈیا فارمیٹ میں یا کسی بھی
                                    میڈیا چینل جو فی الوقت یا بعد میں معلوم ہوں،
                                    کے ذریعے انتساب کے ساتھ یا انتساب کے بغیر
                                    اور آپ کو کسی دائمی نوٹس کے بغیر پروموٹ
                                    کرسکتے ہیں اور مزید تقسیم کرسکتے ہیں۔ آپ
                                    BookNook سروس کے ہر صارف کو ایک غیرخصوصی
                                    لائسنس بھی دیتے ہیں کہ وہ آپ کی سبمیشنز کو
                                    BookNook سروس کے ذریعے دیکھے، استعمال کرے،
                                    دوبارہ تیار کرے، تقسیم کرے، ظاہر کرے، اور ان
                                    سبمیشنز کو ایسے پرفارم کرسکے جیسے BookNook
                                    سروس اور ان شرائط میں عمومی فعالیت کے ذریعے
                                    اجازت دی گئی ہے۔
                                </p>
                                <p>
                                    آپ ایسی صارفی سبمیشنز جمع نہیں کروا سکتے جو
                                    (ا) بدنامی، ہراسانی، دھمکی آمیز، متعصب، نفرت
                                    انگیز، پرتشدد، فحش، پورنوگرافک یا جارحانہ
                                    ہوں یا کسی کو نقصان پہنچا سکتی ہوں یا جن سے
                                    کسی بھی شخصیت یا فرد کو نقصان پہنچنے کی امید
                                    ہو بیشک ایسا مواد قانون کے تحت ہی کیوں نہ ہو
                                    یا (ب) دیگر صورت میں قابل اطلاق مقامی، قومی
                                    اور بین الاقوامی قوانین اور ضابطوں کے برعکس
                                    ہو۔
                                </p>
                                <p>
                                    آپ ایسی صارفی سبمیشنز جمع نہیں کروا سکتے جن
                                    میں تھرڈ پارٹی کا کاپی رائٹ شدہ مواد موجود
                                    ہو، یا ایسا مواد جو دیگر تھرڈ پارٹی کے
                                    ملکیتی حقوق کے تحت ہو، ماسوائے اس کے کہ آپ
                                    کے پاس ایسے مواد کے لیے اصل مالک کی طرف سے
                                    اجازت ہو یا آپ کے پاس قانونی طور پر اجازت ہے
                                    کہ آپ ایسا مواد جمع کروا سکیں اور ہمیں اوپر
                                    واضح کیے گئے لائسنسز دے سکیں۔ آپ پیش کریں یا
                                    گارنٹی دیں کہ آپ کی ملکیت میں ضروری لائسنسز،
                                    حقوق، رضامندیاں اور اجازت نامے ہیں کہ آپ
                                    صارفی سبمیشنز جمع کروا سکتے ہیں اور یہ کہ
                                    صارف گزارشات سیکشن 6 میں واضح کی گئی شرائط
                                    کے تحت ہیں۔ آپ یہ بھی رضامندی ظاہر کرتے ہیں
                                    کہ آپ ہمارے لائسنسیز، تقسیم کاروں، ایجنٹس،
                                    نمائندوں اور دیگر اجازت شدہ صارفین کے خلاف
                                    کوئی اخلاقی حقوق، ذیلی حقوق یا ان جیسے دیگر
                                    حقوق کا دعوٰی نہیں کریں گے اور دوسرے لوگ جو
                                    ایسے حقوق کے حامل ہوں ان سے ایسے ہی عہد پر
                                    عمل کرنے پر رضامند ہیں۔ آپ سمجھتے ہیں اور
                                    رضامند ہیں کہ آپ اپنی صارفی سبمیشنز کے لیے
                                    اور BookNook سروس پر ایسی صارفی سبمیشنز جمع
                                    کروانے اور مہیا کروانے کے نتائج کے مکمل طور
                                    پر ذمہ دار ہوں گے۔ ہمیں یہ حق حاصل ہے لیکن
                                    ہم پر فرض نہیں ہے کہ ہم BookNook سروس پر جمع
                                    کروائی گئی صارفی سبمیشنز کو آپ کو نوٹس دیئے
                                    بغیر کسی بھی وقت یا کسی بھی وجہ سے مانیٹر
                                    کریں، جانچیں، پوسٹ کریں، حذف کریں، تبدیل
                                    کریں، محفوظ کریں یا جائزہ لیں، بشمول اس کے
                                    کہ صارف کی سبمیشنز ان شرائط کے مطابق ہیں یا
                                    نہیں۔ ہم کسی بھی صارفی سبمیشنز میں موجود
                                    رائے، تجاویز یا ہدایات کی توثیق نہیں کرتے یا
                                    گارنٹی نہیں دیتے اور ہم ایسے مواد سے متعلق
                                    کسی بھی اور تمام ذمہ داری سے واضح طور پر
                                    دستبردار ہیں۔
                                </p>
                                <p />
                                <h3>7۔ مواد کی دستیابی</h3>
                                <p>
                                    BookNook سروس اپنے صارفین کو کتابوں اور دیگر
                                    مواد تک رسائی مہیا کرتی ہے جوکہ انٹرنیٹ کے
                                    ذریعے مخصوص ڈیوائسز پر ڈاؤن لوڈ کیا جاتا ہے۔
                                    ان کتابوں اور مواد کی دستیابی وقتاً فوقتاً،
                                    اور جغرافیائی علاقوں میں، کئی وجوہات (جن میں
                                    پبلشرز سے معاہدے اور دستیاب انٹرنیٹ اسپیڈ
                                    شامل ہیں) کی بناء پر تبدیل ہو سکتی ہے۔ نتیجے
                                    کے طور پر ہم یہ گارنٹی نہیں دے سکتے کہ کوئی
                                    بھی مواد BookNook سروس پر دستیاب ہے یا
                                    دستیاب رہے گا (بشمول اس دورانئیے کے جو کسی
                                    بھی سبسکپرشن ممبر کے لیے کسی بھی سبسکرپشن کے
                                    دوران ہو)۔
                                </p>
                                <p>
                                    موبائل نیٹ ورکس۔ اگر آپ BookNook سروس تک ایک
                                    موبائل نیٹ ورک کے ذریعے رسائی حاصل کرتے ہیں
                                    تو آپ کو نیٹ ورک یا رومنگ مہیا کرنے والے کی
                                    میسجنگ، ڈیٹا، دیگر اخراجات اور فیس وغیرہ ادا
                                    کرنا ہوں گی۔ کچھ مخصوص BookNook سروس کے
                                    فیچرز کو ڈائون لوڈ کرنا، انسٹال کرنا یا
                                    استعمال کرنا آپ کے نیٹ ورک پروائیڈر کی طرف
                                    سے ممنوع یا محدود ہوسکتا ہے اور ایسا بھی ہو
                                    سکتا ہے کہ BookNook سروس کے تمام فیچرز آپ کے
                                    نیٹ ورک پروائیڈر یا ڈیوائس کے ساتھ کام نہ
                                    کریں۔
                                </p>
                                <p />
                                <h3>8۔ اختتام</h3>
                                <p>
                                    اگر آپ ان شرائط یا BookNook سروس کے حوالے سے
                                    پوسٹ کئے گئے کسی بھی قانون یا گائیڈلائن کی
                                    خلاف ورزی کرتے ہیں تو BookNook سروس کو
                                    استعمال کرنے کا حق خودبخود ختم ہو جاتا ہے۔
                                    ہمیں یہ صوابدیدی اختیار اور حق حاصل ہے کہ ہم
                                    کسی بھی وجہ سے کسی نوٹس کے ساتھ یا نوٹس کے
                                    بغیر، BookNook سروس کے تمام یا کچھ حصوں تک
                                    آپ کی رسائی کو ختم کردیں۔
                                </p>
                                <p />
                                <h3>
                                    9۔ لنکس یا تھرڈ پارٹی ایپلیکیشنز / ڈیوائسز
                                </h3>
                                <p>
                                    لنکس۔ والدین اور اساتذہ کے لیے، BookNook
                                    سروس میں تھرڈ پارٹی ویب سائٹس کے لنکس موجود
                                    ہوسکتے ہیں یا BookNook تھرڈ پارٹی ایپلیکیشنز
                                    سے رابطہ کرسکتی ہے (بشمول، بغیر حدود کے،
                                    وجٹس، سوفٹ ویئر یا دیگر سوف ویئر یوٹیلٹیز)
                                    جوکہ BookNook سروس کی ملکیت یا کنٹرول میں
                                    نہیں ہوتیں۔ ہم صرف آپ کی سہولت کے لیے آپ کو
                                    کسی دوسری ویب سائٹ یا تھرڈ پارٹی ایپلیکیشن
                                    کا لنک مہیا کرتے ہیں اور ایسی دیگر ویب سائٹ
                                    یا وسائل یا ان کے مواد کی توثیق نہیں کرتے۔
                                    ہم کسی بھی تھرڈ پارٹی ویب سائٹ یا تھرڈ پارٹی
                                    ایپلیکیشن کے کسی بھی مواد، معلومات، سوفٹ
                                    ویئر، مٹیرئیل یا طور طریقوں کے ذمہ دار نہیں
                                    ہوں گے۔
                                </p>
                                <p>
                                    ڈیوائسز۔ اگر آپ BookNook کی سروس تک ایک
                                    Apple iOS یا Android ڈیوائس کے ذریعے رسائی
                                    حاصل کرتے ہیں تو Apple Inc. یا Google Inc.
                                    بالترتیب اس معاہدے کے تھرڈ پارٹی بینیفشری
                                    ہوں گے۔ البتہ، یہ تھرڈ پارٹی بینیفشریز اس
                                    معاہدے کے فریق نہیں ہیں اور BookNook سروس کی
                                    دستیابی یا سپورٹ کے ذمہ دار نہیں ہے۔ آپ
                                    رضامند ہیں کہ آپ کی ان ڈیوائسز کے ذریعے
                                    BookNook سروس تک رسائی قابل اطلاق تھرڈ پارٹی
                                    بینیفشریز شرائط برائے سروس میں طے کی گئی
                                    شرائط کے مطابق ہوگی۔
                                </p>
                                <p />
                                <h3>10۔ وارنٹیوں کا اعلان</h3>
                                <p>
                                    آپ واضح طور پر رضامند ہیں کہ BookNook سروس
                                    کے استعمال کے نفع نقصان کے آپ خود ذمہ دار
                                    ہوں گے۔ BookNook سروس &#8220;جیسا ہے&#8221;
                                    اور &#8220;جہاں ہے&#8221; کی بنیاد پر فراہم
                                    کی جاتی ہے۔ قانون کی طرف سے دی گئی اجازت کے
                                    تحت مکمل طور پر BookNook Creations, Inc. اور
                                    اس کے وابستگان BookNook سروس کے حوالے سے
                                    (بشمول لیکن محدود نہیں، مضمر تجارتی ضمانتوں،
                                    مخصوص استعمال کی قابلیت یا مقصد اور عدم خلاف
                                    ورزی) کسی بھی قسم کی تمام ضمانتوں سے واضح
                                    طور پر دستبردار ہیں، چاہے وہ ظاہری ہوں یا
                                    مضمر۔ ہم BookNook سروس میں یا اس کے ذریعے
                                    دستیاب مواد کی درستگی اور مکمل ہونے کے بارے
                                    میں یا BookNook سروس سے منسلک کسی بھی ویب
                                    سائٹ کے مواد کے بارے میں ضمانتیں یا
                                    نمائندگیاں نہیں دیتے اور کسی بھی (ا) غلطیوں،
                                    بھول چوک، یا مواد کی غیردرستگی؛ (ب) BookNook
                                    سروس تک آپ کی رسائی یا سروس کے استعمال یا
                                    BookNook سروس کے مواد کی وجہ سے کسی بھی قسم
                                    کی ذاتی چوٹ یا جائیداد کے نقصان؛ (ج) ہمارے
                                    محفوظ سرورز کے استعمال یا ان تک غیرمجاز
                                    رسائی اور/ یا کچھ یا تمام ذاتی معلومات اور/
                                    یا معاشی معلومات جو ان میں محفوظ کی گئی ہوں؛
                                    (د) BookNook سروس میں یا اس سے ہونے والی
                                    ٹرانسمیشن میں کسی بھی قسم کی رکاوٹ یا
                                    اختتام؛ (ہ) کسی بھی قسم کے بگس، وائرسز،
                                    ٹروجن ہارسز یا ایسا کچھ بھی جو کسی بھی تھرڈ
                                    پارٹی کے تحت BookNook سروس کے ذریعے منتقل ہو
                                    سکے؛ اور / یا (ر) کسی بھی قسم کا خسارہ یا
                                    نقصان جوکہ BookNook سروس میں یا اس کے ذریعے
                                    مہیا کئے گئے، پوسٹ کئے گئے، ای میل کئے گئے
                                    یا منتقل کئے گئے مواد کی وجہ سے سامنے آئے،
                                    کی جوابدہی یا ذمہ داری قبول نہیں کرتے۔
                                </p>
                                <p />
                                <h3>11۔ ذمہ داری کی حدود</h3>
                                <p>
                                    آپ سمجھتے ہیں کہ قابل اطلاق قانون کے تحت دی
                                    گئی اجازت کی حد تک، کسی بھی صورت میں
                                    BookNook, LLC یا اس کے افسران، ملازمین،
                                    ڈائریکٹرز، شیئرہولڈرز، ماتحت ادارے، شراکت
                                    دار، ایجنٹس یا لائنسنس دہندگان جوابدہی کی
                                    کسی بھی تعریف کے تحت (چاہے وہ معاہدے کی صورت
                                    میں ہو، قانونی ہو، یا دیگر صورتوں میں ہو)
                                    BookNook سروس کو آپ کے استعمال کرنے کے نتیجے
                                    میں (یا کسی اور کے آپ کا اکاؤنٹ استعمال کرنے
                                    کے نتیجے میں) ہونے والے کسی بھی بالواسطہ،
                                    بلاواسطہ، حادثاتی، خصوصی، اتفاقی یا مثالی
                                    طور پر بشمول لیکن محدود نہیں، آمدنی میں
                                    خسارے، منافع، نیک نیتی، یا دیگر غیرمادی
                                    نقصانات (اگرچہ ایسی پارٹیز کو آگاہ کیا گیا
                                    ہو، وہ جانتے ہوں یا انہیں ایسے ممکنہ نقصان
                                    سے آگاہ ہونا چاہئے ہو) کے بارے میں جوابدہ
                                    نہیں ہوں گے۔
                                </p>
                                <p />
                                <h3>12۔ خارج کی گئیں</h3>
                                <p>
                                    کچھ دائرہ کار مخصوص ضمانتوں کو حادثاتی یا
                                    اتفاقی نقصانات کے لیے محدود کرنے یا ذمہ داری
                                    کو خارج کرنے کی اجازت نہیں دیتے۔ اسی کے
                                    مطابق، اوپر بتائی گئی حدود اور اعلانات میں
                                    سے کچھ کا آپ پر اطلاق نہیں ہوسکتا۔ اس حد تک
                                    BookNook, LLC قابل اطلاق قانون کے مطابق کسی
                                    مضمر ضمانت سے دستبردار نہیں ہوسکتی یا اپنی
                                    جوابدہی کو محدود نہیں کرسکتی۔ ایسی ضمانت اور
                                    ہماری جوابدہی کا دائرہ کار اور دورانیہ قابل
                                    اطلاق قانون میں اجازت کے مطابق کم سے کم
                                    ہوگا۔
                                </p>
                                <p />
                                <h3>13۔ ہرجانے کی ادائیگی</h3>
                                <p>
                                    آپ رضامندی ظاہر کرتے ہیں کہ آپ (ا) کسی بھی
                                    معلومات (بشمول، بغیر حدود کے، آپ کی صارف
                                    گزارشات، فیڈبیک، یا کسی بھی دوسرے مواد) آپ
                                    (یا کوئی بھی جو آپ کا اکاؤنٹ استعمال کررہا
                                    ہو) جو کہ BookNook سروس پر یا سروس کے ذریعے
                                    جمع کروائے، پوسٹ کرے یا ترسیل کرے؛ (ب) آپ کے
                                    BookNook سروس کے استعمال (یا کسی کے بھی جو
                                    آپ کا اکاؤنٹ استعمال کررہا ہو)؛ (ج) ان شرائط
                                    کی خلاف ورزی جو آپ کریں (یا کوئی بھی جو آپ
                                    کا اکاؤنٹ استعمال کررہا ہو)؛ یا (د) کسی فرد
                                    یا ہستی کے کسی بھی حقوق بشمول کسی بھی حد،
                                    کسی کاپی رائٹ، پیٹنٹ، ٹریڈ مارک، ٹریڈ سیکرٹ،
                                    پبلسٹی/پرائییویسی کا حق، یا کسی فرد یا ہستی
                                    کے دیگر ملکیتی حقوق کی خلاف ورزی جو آپ کی
                                    طرف سے کی گئی (یا کسی کی طرف سے جو آپ کا
                                    اکاؤنٹ استعمال کررہا ہو) کے نتیجے کےطور پر
                                    BookNook LLC کو، اس کے ماتحت اداروں، شراکت
                                    داروں، افسران، ڈائریکٹرز، ملازمین، کنسلٹنٹس
                                    اور ایجنٹس کو بے قصور مانیں گے اور کسی بھی
                                    دعوے، جوابدہی، نقصانات، خساروں، لاگت،
                                    اخراجات، اجرت (بشمول وکیل کی معقول اجرت اور
                                    لاگت) جو کہ ان پارٹیز کو ادا کرنی پڑیں، کی
                                    ہرجانے کے طور پر ادائیگی کریں گے۔ BookNook,
                                    LLC یہ حق محفوظ رکھتی ہے کہ اپنے خرچ پر اپنا
                                    خصوصی دفاع کرے اور کسی بھی مسئلے کو کنٹرول
                                    کرے یا آپ کی طرف سے ہرجانے کی ادائیگی کی
                                    صورت میں کسی بھی دستیاب دفاع کو استعمال کرنے
                                    میں آپ BookNook, LLC سے تعاون کریں گے۔
                                </p>
                                <h3>14۔ تنازعات</h3>
                                <p>
                                    یہ شرائط ریاست کیلی فورنیا کے قوانین کے
                                    مطابق نافذ ہوں گی اور بغیر کسی قانونی پسند
                                    یا قانون سے تنازع کے، اس سے فرق نہیں پڑتا کہ
                                    آپ کی رہائش کہاں ہے، سمجھی جائیں گی۔ آپ ان
                                    شرائط پر عمل کرنے یا ان سے متعلق یا BookNook
                                    کی سروس کے استعمال کی وجہ سے سامنے آنے والے
                                    کسی بھی دعوے، کاروائی کی وجہ یا تنازعے کو
                                    Alameda County کیلی فورنیا میں واقع کسی
                                    ریاستی یا وفاقی عدالت میں حل کریں گے۔ آپ
                                    ایسے تمام دعوؤں یا کاروائی کی وجوہات کی
                                    قانونی چارہ جوئی کے لیے Alameda County کیلی
                                    فورنیا میں واقع عدالتوں کے ذاتی دائرہ کار کو
                                    تسلیم کرنے پر رضامندی ظاہر کرتے ہیں۔
                                </p>
                                <p />
                                <h3>15. مواصلات</h3>
                                <p>
                                    BookNook سروس کے استعمال سے آپ رضامندی ظاہر
                                    کرتے ہیں کہ کوئی بھی نوٹس، معاہدے، انکشاف یا
                                    دیگر مواصلات جوکہ ہم آپ کو برقی ذریعے سے
                                    ارسال کرتے ہیں، وہ کسی بھی قانونی مواصلاتی
                                    ضرورت کو پورا کرتی ہیں (بشمول اس کے کہ ایسی
                                    مواصلات لکھی ہوئی صورت میں لازماً ہوں)۔
                                </p>
                                <p />
                                <h3>16. Apple iOS ایپلیکیشنز کی اضافی شرائط</h3>
                                <p>
                                    درج ذیل شرائط و ضوابط iTunes Store (“iOS
                                    App”) سے آپ کے خریدے گئے Apple iOS موبائل
                                    میں استعمال کے لیے ڈیزائن کی گئی ہماری
                                    ایپلیکیشنز کے حوالے سے لا گو ہوتی ہیں۔ آپ
                                    رضامند ہیں کہ یہ شرائط Apple کے نہیں بلکہ آپ
                                    کے اور ہمارے درمیان ہیں، اور ہماری iOS App،
                                    BookNook سروس یا کسی مواد کے لیے Apple کی
                                    کوئی ذمہ داری نہیں ہے۔ آپ رضامند ہیں کہ آپ
                                    کا ہماری iOS App کا استعمال Apple کی موجودہ
                                    App Store سروس شرائط کے قوانین برائے استعمال
                                    کے تحت ہوگا۔ تمام پارٹیز رضامند ہیں کہ ہماری
                                    iOS App کے حوالے سے دیکھ بھال اور سپورٹ سروس
                                    مہیا کرنے کے لیے Apple کی کوئی ذمہ داری نہیں
                                    ہے۔ iOS App کے کسی بھی قابل اطلاق وارنٹی کے
                                    مطابق ناکام ہونے کی صورت میں آپ Apple کو
                                    مطلع کرسکتے ہیں اور Apple آپ کو ہماری iOS
                                    App کی قیمت خرید (اگر کوئی ہے) واپس کردے گا۔
                                    ہماری iOS App کے حوالے سے قانون کی زیادہ سے
                                    زیادہ اجازت کی حد تک Apple کی کوئی دیگر
                                    وارنٹی کی ذمہ داری نہیں ہوگی اور کسی بھی قسم
                                    کے دیگر کلیمز، خسارے، جوابدہی، نقصانات، لاگت
                                    یا اخراجات جو کہ کسی بھی وارنٹی سے منسوب کئے
                                    جاسکیں وہ مکمل طور پر ان شرائط اور کسی بھی
                                    قابل اطلاق قانون کے تحت ہمارے دائرہ کار میں
                                    آئیں گے جیسا کہ ہم ہی یہ سافٹ ویئر مہیا کرنے
                                    والے ہیں۔ آپ رضامند ہیں کہ آپ یا کسی تھرڈ
                                    پارٹی کے کسی بھی کلیم کے حل کے لیے جو کہ
                                    ہماری iOS App یا آپ کے ہماری iOS App کے
                                    استعمال سے متعلق ہوں، Apple نہیں بلکہ ہم ذمہ
                                    دار ہیں۔ اس میں شامل ہیں لیکن محدود نہیں (ا)
                                    پراڈکٹ کے جوابدہی کلیمز؛ (ب) کوئی بھی کلیم
                                    جو ہماری iOS App کے قابل اطلاق قانونی یا
                                    انضباطی طلب کے مطابق کام نہ کرنے کی وجہ سے
                                    ہو؛ اور (ج) کلیمز جوکہ صارفین کے تحفظ یا اس
                                    جیسے قوانین کے تحت سامنے آئیں؛ اور اس جیسے
                                    تمام کلیمز صرف اور صرف ان شرائط کے تحت اور
                                    سافٹ ویئر مہیا کرنے والے کے طور پر ہم پر
                                    لاگو کسی بھی قانون کے تحت ہوں گے۔ آپ رضامند
                                    ہیں کہ ہماری iOS App یاiOS App کی آپ کی
                                    ملکیت میں ہوتے ہوئے اگر iOS App کے استعمال
                                    سے کسی تھرڈ پارٹی کے دانشورانہ ملکیتی حقوق
                                    کی خلاف ورزی ہوتی ہے تو تحقیقات، دفاع، تصفیہ
                                    اور ایسے کلیمز کی ادائیگی کے لیے Apple نہیں
                                    بلکہ ہم ذمہ دار ہوں گے۔ تمام پارٹیز رضامند
                                    ہیں کہ Apple اور اس کے ماتحت ادارے iOS App
                                    کے آپ کے استعمال پر لاگو ہونے والی شرائط کے
                                    تھرڈ پارٹی بینیفشریز ہیں۔ ان شرائط کو آپ کے
                                    قبول کرنے پر Apple کو یہ حق حاصل ہوگا (اور
                                    اس کا حق تسلیم کیا ہوا سمجھا جائے گا) کہ وہ
                                    بطور تھرڈ پارٹی بینیفشری کے آپ کے ہماری iOS
                                    App کے استعمال پر لاگو ہونے والی شرائط کو آپ
                                    کے خلاف نافذ کرسکے۔
                                </p>
                                <p />
                                <h3>17. متفرق</h3>
                                <p>
                                    یہ شرائط بمع دیگر کوئی بھی قوانین یا ہدایات
                                    جوکہ BookNook سروس کے حوالے سے جاری کی گئی
                                    ہوں، آپ کے اور ہمارے درمیان مکمل اور خصوصی
                                    اور حتمی معاہدے کو تشکیل دیتی ہیں۔ ان شرائط
                                    میں واضح کئے گئے حقوق کو نافذ کرنے اور
                                    استعمال کرنے میں ہماری ناکامی کا مطلب ایسے
                                    حقوق سے دستبرداری ہرگز نہیں ہوگا۔ اگر کسی
                                    بھی وجہ سے ان شرائط کی کوئی بھی شرط ناقابل
                                    نفاذ پائی گئی تو وہ شرط اس ممکنہ حد تک لاگو
                                    کی جائے گی کہ جس سے تمام پارٹیز کا ارادہ اس
                                    شرط کے مطابق ظاہر ہو، اور بقایا شرائط پوری
                                    طاقت اور اثر میں جاری رہیں گی۔ ان شرائط میں
                                    سیکشن ٹائٹلز صرف سہولت کے لیے ہیں اور ان کا
                                    کوئی قانونی یا معاہداتی اثر نہیں ہے۔ ان
                                    شرائط کے سیکشنز 2، 5، 6، 7، 8، 9، 10، 11،
                                    12، 13، 14، 15، 16، 17، 18، 19 اور 20، اس کے
                                    ساتھ ساتھ دیگر کوئی بھی ذمہ داری کی حدود جو
                                    یہاں واضح طور پر طے کی گئی ہیں، آپ کے
                                    BookNook سروس کے استعمال کو ختم کردینے کے
                                    باوجود پوری طاقت اور اثر کے ساتھ لاگو رہیں
                                    گی۔ یہ شرائط آپ کی ذات کے لیے ہیں اور آپ کی
                                    طرف سے کسی کو تفویض کرنے، منتقل کرنے یا سب
                                    لائنسنس کرنے کے لیے نہیں ہیں، ماسوائے ہماری
                                    پیشگی تحریری رضامندی کے ساتھ ۔ ہم آپ کی
                                    رضامندی کے بغیر ان شرائط کے تحت اپنے حقوق
                                    اور فرائض کسی کو تفویض کرسکتے ہیں، منتقل
                                    کرسکتے ہیں یا سونپ سکتے ہیں۔ ان شرائط میں
                                    سیکشن اور سب سیکشن ہیڈنگز صرف سہولت کے لیے
                                    ہیں اور ان کی تشریح پر اثرانگیز نہیں ہوں گی۔
                                    جب تک ان شرائط میں واضح نہ کیا گیا ہو، تمام
                                    نوٹسز لازمی طور پر تحریری ہونے چاہئیں اور
                                    رسید کے تحت دیئے گئے سمجھے جائیں گے، جیسا کہ
                                    تحریری خودکار رسید یا الیکٹرانک لاگ سے تصدیق
                                    شدہ ہوں (جیسے بھی قابل اطلاق ہو)۔ BookNook
                                    سروس کو بھیجے جارہے نوٹسز کے لیے ای میل
                                    ایڈریس legal-
                                    <span>info@booknooklearning</span>.com ہے۔
                                </p>
                                <p />
                                <h3>18. ترامیم</h3>
                                <p>
                                    ہم اپنی واحد اور مطلق صوابدید کے تحت ان
                                    شرائط کو وقتاً فوقتاً تبدیل کرسکتے ہیں۔
                                    اگرہم ایسا کرتے ہیں تو ہم اس اعلانئے کو اپ
                                    ڈیٹ کریں گے، BookNook سروس کے ذریعے نوٹس
                                    بھیجیں گے یا آپ کو ای میل کریں گے۔ آپ کا
                                    BookNook سروس کا استعمال جاری رکھنا یہ ثابت
                                    کرتا ہے کہ آپ ان شرائط میں کی گئی تبدیلیوں
                                    پر رضامند ہیں۔ اگر آپ کو کسی بھی تبدیلی پر
                                    اعتراض ہے تو آپ کو BookNook سروس کے استعمال
                                    کو چھوڑنا ہوگا۔
                                </p>
                                <p />
                                <h3>19۔ قانونی</h3>
                                <p>
                                    Apple، Apple کا لوگو، iMac، MacBook Pro، اور
                                    iPad امریکہ اور دیگر ممالک میں Apple Inc. کے
                                    رجسٹرڈ ٹریڈ مارکس ہیں۔ App Store Apple Inc.
                                    کا سروس مارک ہے۔
                                </p>
                                <p />
                                <p>____________</p>
                                <p />
                                <p>22 جون 2017 کو آخری بار تبدیلی کی گئی</p>
                            </div>
                        </div>
                    </div>
                </div>{' '}
                <div id="arabic">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <h2>
                                        <span>شروط الخدمة</span>
                                    </h2>
                                </div>
                            </div>
                        </div>{' '}
                        <div>
                            <div>
                                <h3>
                                    مرحبًا بك في BookNook، نحن نرغب في حماية
                                    حقوقك وحقوق أي مستخدم أو طرف ثالث آخر يتعامل
                                    معنا، ولذلك قمنا بإعداد شروط الخدمة هذه
                                    (يُشار إليها فيما بعد باسم
                                    &#8220;الشروط&#8221;). عند استخدامك أو وصولك
                                    إلى تطبيقات، وموقع، وخدمات، وبرامج BookNook
                                    و/أو أي محتوى متاح عبر مؤسسة BookNook (يشار
                                    إليها فيما بعد بـ &#8220;خدمة
                                    BookNook&#8221; و&#8221;نحن&#8221; و
                                    &#8220;نا الفاعلين&#8221;)، فإن هذا يعني
                                    موافقتك على هذه الشروط. إذا كنت لا توافق على
                                    هذه الشروط، فلن يُمكنك استخدام أو الوصول إلى
                                    خدمة BookNook.
                                </h3>
                                <p />
                                <h3>1. أنتَ وحسابك</h3>
                                <p>
                                    بتسجيلك في خدمة BookNook فإنك تُؤكد بموجب
                                    ذلك (1) أنك تبلغ من العمر 18 عامًا أو أنك
                                    بلغت أو تجاوزت سن الرشد في مقاطعتك أو إقليمك
                                    أو بلدك أو (2) أنك قاصر متحرر، كما أنك تؤكد
                                    أيضًا أن لديك القدرة والصلاحية الكاملة
                                    للدخول في هذه الشروط والامتثال والالتزام
                                    بها.
                                </p>
                                <p>
                                    قد نزودك بمُعرِّف مميز لك ونتيح لك اختيار
                                    صورة رمزية على BookNook (يُشار إليها فيما
                                    بعد باسم &#8220;الصورة الرمزية&#8221;)
                                    لتمثيل حسابك الشخصي أثناء استخدامك لخدمة
                                    BookNook. إذا قمت بالتسجيل والاشتراك، فإنك
                                    توافق على: (أ) تقديم معلومات صحيحة وحديثة
                                    كما هو مطلوب في نموذج التسجيل (ب) تحديث تلك
                                    المعلومات وإبقائها صحيحة ومحدثة بشكل دائم
                                    (جـ) عدم مشاركة كلمة المرور أو المُعرِّف
                                    المميز لك، أو السماح لأي شخص آخر غيرك
                                    بالوصول إلى حسابك الشخصي باستثناء الأشخاص
                                    المسموح لهم في الحالات التي تنص عليها هذه
                                    الشروط، أو القيام بأي شيء آخر قد يعرض أمان
                                    حسابك للخطر (د) عدم استخدام اسم مستخدم بقصد
                                    انتحال شخصية شخص آخر أو بصورة تنتهك حقوق أي
                                    شخص آخر (هـ) عدم استخدام اسم مستخدم فاحش أو
                                    مبتذل (و) إخطارنا فورًا بأي استخدام غير مصرح
                                    به لكلمة المرور الخاصة بك، أو أي انتهاك
                                    لتدابير الأمن والحماية المتعلقة بخدمة
                                    BookNook. عندما تشارك كلمة المرور الخاصة بك
                                    أو تسمح لأي طرف ثالث بالوصول إلى حسابك على
                                    BOOKNOOK، فإنك توافق على تحمل المسئولية عن
                                    امتثال هذا الطرف الثالث لشروط الخدمة هذه كما
                                    أننا لن نتحمل المسئولية عن أي أضرار أو خسائر
                                    تنتج عن عدم قدرتك على حماية كلمة المرور
                                    الخاصة بك على النحو الملائم، أو أي أفعال
                                    تحدث نتيجة استخدام كلمة المرور الخاصة بك.
                                </p>
                                <p>
                                    يخضع استخدامك لخدمة BookNook لكافة المواصفات
                                    والإرشادات والسياسات التقنية وغير التقنية
                                    المعمول بها والتي قد نوفرها من حين إلى آخر
                                    ومنها على سبيل المثال لا الحصر: سياسة
                                    الخصوصية الموجودة في
                                    booknooklearning.com/privacy (يُشار إليهم
                                    جميعًا معًا فيما بعد بـ
                                    &#8220;السياسات&#8221;) وأنت توافق وتتعهد
                                    بالامتثال إلى هذه السياسات. يقتصر استخدام
                                    خدمة BookNook في الوقت الحالي على مواطني
                                    الولايات المتحدة الأمريكية وكندا، وأي شخص
                                    يستخدم أو يصل إلى خدمة BookNook من خارج
                                    الولايات المتحدة الأمريكية أو كندا، فإنه
                                    يقوم بذلك على مسئوليته/مسئوليتها الخاصة كما
                                    أنه/أنها مسئول/مسئولة عن الامتثال للقوانين
                                    المحلية المعمول بها.
                                </p>
                                <p />
                                <h3>2. أعضاء الاشتراك</h3>
                                <p>
                                    على الرغم من أن هناك بعض المميزات و/أو
                                    المحتويات الخاصة بخدمة BookNook قد تكون
                                    متاحة مجانًا لبعض المستخدمين، فإن هناك
                                    مميزات و/أو محتويات أخرى قد تكون متاحة فقط
                                    لمن يوافق على الدفع مقابل خطة اشتراك (يُشار
                                    إليه باسم &#8220;عضو اشتراك&#8221;). قد يتم
                                    وصف وشرح كل خطة من خطط الاشتراك بمزيد من
                                    التفاصيل في مواضع أخرى من خدمة BookNook، أي
                                    شروط دفع يتم عرضها أثناء عملية الترقية إلى
                                    عضو اشتراك سُتعتبر جزءًا من هذه الشروط.
                                </p>
                                <p />
                                <h3>3. التراخيص الممنوحة لك</h3>
                                <p>
                                    نحن نمنحك ترخيصًا شخصيًا، قابلًا للإلغاء،
                                    غير حصري، غير قابل للانتقال أو منحه لشخصٍ
                                    آخر، عالميًا للوصول إلى خدمة BookNook
                                    واستخدامها فقط للأغراض الشخصية غير التجارية
                                    كما هو مسموح به بموجب هذه الشروط وعلى النحو
                                    الذي نقصده نحن من خلال المهام والوظائف
                                    الطبيعية التي نقدمها عبر خدمة BookNook. سيتم
                                    إنهاء هذا الترخيص الممنوح لك تلقائيًا بمجرد
                                    انتهاكك لهذه الشروط، لا يتم نقل أي حق أو
                                    ملكية أو مصلحة إليك فيما عدا التراخيص
                                    المحدودة السابقة، قد نسمح لك بتخزين المحتوى
                                    المشفر والمُخزَّن مؤقتًا على جهازك اللوحي،
                                    أو هاتفك الشخصي، أو أي جهاز آخر خاص بك
                                    متوافق مع الاتصال بالإنترنت، لا يجوز لك نقل
                                    نسخ من المحتوى المُخزَّن مؤقتًا إلى أي جهاز
                                    آخر.
                                </p>
                                <p />
                                <h3>4. سلوكك عند استخدام الخدمة</h3>
                                <p>
                                    نود الحفاظ على خدمة BookNook آمنة قدر
                                    الإمكان لمستخدمينا لكننا نحتاج إلى مساعدتك
                                    لتحقيق ذلك، وبناء على ذلك، فأنت توافق على
                                    عدم استخدام خدمة BookNook من أجل: (1) مخالفة
                                    أي قوانين أو لوائح محلية، أو معمول بها في
                                    الولاية، أو وطنية، أو دولية (2) نقل ومشاركة
                                    أي مواد مسيئة، أو مزعجة، أو ضارة، أو
                                    افترائية، أو مبتذلة، أو إباحية، أو فاحشة، أو
                                    تشهيرية، أو تحتوي على مشاهد عنيفة، أو تنتهك
                                    خصوصية الآخرين، أو تحض على الكراهية، أو
                                    عنصرية، أو عرقية، أو مرفوضة بأي شكل من
                                    الأشكال (3) نقل ومشاركة أي إعلانات تطفلية أو
                                    غير مصرح بها، أو مواد ترويجية، أو رسائل
                                    البريد الإلكتروني العشوائية أو غير المرغوب
                                    فيها، أو الرسائل المتسلسلة، أو مخططات
                                    التسويق الهرمي أو أي شكل من أشكال الإعلانات
                                    والعروض (4) تعمد نقل أي مواد تحتوي على برامج
                                    إعلانية، أو برامج خبيثة، أو برامج تجسس، أو
                                    فيروسات برمجية، أو أي رموز حاسوبية أخرى، أو
                                    برامج مصممة لتعطيل أو إتلاف أو تقييد الأداء
                                    الوظيفي لأي برنامج حاسوبي أو مكون مادي
                                    للحاسوب أو معدات مستخدمة في الاتصال عن بعد
                                    (5) اختراق أو التحايل على أي تدابير متعلقة
                                    بالأمن أو المصادقة (بما في ذلك تدابير القيود
                                    الجغرافية) (6) محاولة إلغاء تحويل برمجة أو
                                    عكس هندسة أي برنامج موجود في خدمة BookNook
                                    (7) إزالة أي حقوق طبع ونشر أو علامة تجارية
                                    أو رموز ملكية أخرى من أي محتوى (كما هو موضح
                                    بالأسفل) أو من مساهمات المستخدم (كما هو موضح
                                    بالأسفل) (8) مطاردة أو مضايقة أو إيذاء
                                    الأشخاص الآخرين (9) انتحال شخصية أي شخص آخر
                                    أو كيان أو أي شكل من أشكال تحريف الهوية
                                    الشخصية أو الانتساب إلى أحد الكيانات (10)
                                    اعتراض أو تعطيل خدمة BookNook أو الخوادم أو
                                    شبكات الاتصال الخاصة بخدمة BookNook، أو
                                    مخالفة أي متطلبات أو عمليات أو سياسات أو
                                    لوائح خاصة بشبكات الاتصال المستخدمة في خدمة
                                    BookNook (11) تأجير أو استئجار حسابك الشخصي،
                                    أنت توافق أيضًا على عدم استخدام أو تشغيل أي
                                    نظام آلي، على سبيل المثال لا الحصر:
                                    &#8220;الروبوتات،&#8221; &#8220;عناكب
                                    الشبكة&#8221; &#8220;أجهزة القراءة غير
                                    المتصلة بالإنترنت&#8221; وغيرها من الأنظمة
                                    التي لديها إمكانية الوصول إلى خدمة BookNook
                                    وإرسال الكثير من رسائل الطلب إلى خوادم خدمة
                                    BookNook في فترة زمنية معينة بصورة أكبر مما
                                    يمكن أن يقوم به الإنسان الطبيعي في نفس
                                    الفترة باستخدام متصفح ويب تقليدي.
                                </p>
                                <p />
                                <h3>
                                    5. الملكية: القيود المفروضة على الاستخدام
                                </h3>
                                <p>
                                    جميع الحقوق والملكيات والمصالح الخاصة بموقع
                                    خدمة BookNook وخدماته هي ملكية حصرية وستظل
                                    كذلك لمؤسسة BookNook ذ.م.م (ذات مسئولية
                                    محدودة) ومانحي الترخيص لها، والمحتوى الموجود
                                    على خدمة BookNook بما في ذلك، على سبيل
                                    المثال لا الحصر: الكتب، والنصوص، والبرامج،
                                    والرسومات، والصور، والمقاطع الصوتية،
                                    والموسيقى، ومقاطع الفيديو، ومساهمات المستخدم
                                    (كما هو موضح بالأسفل)، والخصائص التفاعلية
                                    وما شابه ذلك (يُشار إليها فيما بعد بـ
                                    &#8220;المحتوى&#8221;)، وعلامة اسم وتصميم
                                    “BookNook&#8221;، بالإضافة إلى بعض الأسماء
                                    والشعارات والمواد الأخرى المعروضة على أو عبر
                                    خدمة BookNook التي تُمثِّل العلامات
                                    التجارية، والأسماء التجارية، وعلامات أو
                                    شعارات الخدمة (يُشار إليها فيما بعد بـ
                                    &#8220;العلامات&#8221;) هي مملوكة أو مرخصة
                                    لمؤسسة BookNook ذ.م.م (ذات مسئولية محدودة)
                                    وتخضع لحقوق النشر والطبع وحقوق العلامات
                                    التجارية وحقوق الملكية الفكرية الأخرى
                                    المحمية بموجب القوانين الأمريكية والدولية.
                                    أنت توافق على عدم إعادة إنتاج، أو محاكاة، أو
                                    نسخ، أو بيع، أو إعادة بيع، أو استغلال خدمة
                                    BookNook أو أي جزء من خدمة BookNook لأي
                                    أغراض، بما في ذلك، على سبيل المثال لا الحصر:
                                    المحتوى والعلامات، باستثناء ما تسمح به هذه
                                    الشروط أو بموجب إذن كتابي من جانبنا أو من
                                    قِبل مانحي الترخيص المعتمدين لدينا، أنت
                                    توافق أيضًا على مشاهدة المحتوى (بما في ذلك،
                                    على سبيل المثال لا الحصر: الكتب ومقاطع
                                    الفيديو) وفقًا لما هو متاح عبر خدمة BookNook
                                    في المواقع الجغرافية التي ندعم تقديم خدمة
                                    BookNook بها والمُرخص بها مشاهدة هذه
                                    المحتوى، تقتصر خدمة BookNook على الاستخدام
                                    الشخصي لك وغير التجاري، يجب عليك الالتزام
                                    بجميع الاخطارات المتعلقة بحقوق النشر والطبع
                                    والمعلومات والقيود المنصوص عليها أو المرتبطة
                                    بأي محتوى.
                                </p>
                                <p />
                                <h3>6. مساهمات المستخدم</h3>
                                <p>
                                    قد نوفر لك إمكانية نشر أو إرسال أو تقديم أو
                                    إتاحة نصوص (مثل الاختبارات، أو إجابات
                                    الاختبارات، أو خطط الدروس)، أو صور، أو مقاطع
                                    صوتية، أو مقاطع فيديو، أو عروض تقديمية، أو
                                    أعمال لازمة للاشتراك في المسابقات، أو أي
                                    محتوى آخر (يُشار إليهم فيما بعد ب
                                    &#8220;مساهمات المستخدم&#8221;) على خدمة
                                    BookNook، قد تتاح هذه الخاصية فقط لبعض
                                    المستخدمين الذين يتجاوزون سنًا معينًا من
                                    العمر، أنت على علم أن مساهمات المستخدم هذه
                                    سيتمكن العامة من الاطلاع عليها ونحن لا نضمن
                                    أي سرية لمساهمات المستخدم تلك، لديك كافة
                                    حقوق الملكية لمساهمات المستخدم الخاصة بك.
                                </p>
                                <p>
                                    بالنسبة لجميع مساهمات المستخدم، بإتاحة
                                    مساهمات المستخدم تلك على خدمة BookNook، فإنك
                                    تمنحنا بموجب ذلك ترخيصًا عالميًا، غير حصري،
                                    خالي من حقوق الملكية، يمكن نقله ومنحه بموجب
                                    كافة حقوق الملكية الفكرية لاستخدام وإعادة
                                    إنتاج وتوزيع وعرض وتأدية مساهمات المستخدم
                                    هذه، بشكل كلي أو جزئي، وذلك فيما يتعلق بخدمة
                                    BookNook وأنشطتنا وأنشطة (&#8220;خلفائنا
                                    “و&#8221;شركائنا&#8221;)، بما في ذلك، على
                                    سبيل المثال لا الحصر: تطوير وإعادة توزيع
                                    خدمة BookNook بشكل كلي أو جزئي (والأنشطة
                                    المشتقة منها) على هيئة أي تنسيق من تنسيقات
                                    الوسائط وعبر أي قنوات إعلامية معروفة الآن أو
                                    فيما بعد، بإسناد لك أو دون إسناد، وبدون
                                    إخطار مسبق لك وذلك لأجل غير مسمى كما أنك
                                    تمنح كل مستخدم من مستخدمي خدمة BookNook
                                    ترخيصًا غير حصري للاطلاع على مساهمات
                                    المستخدم الخاصة بك عبر خدمة BookNook
                                    واستخدام وإعادة إنتاج وتوزيع وعرض وتأدية
                                    مساهمات المستخدم تلك على النحو الطبيعي
                                    المسموح به عبر خدمة BookNook وبموجب هذه
                                    الشروط إلى أجل غير مسمى.
                                </p>
                                <p>
                                    لا يجوز لك إرسال مساهمات المستخدم (1)
                                    التشهيرية، أو المزعجة، أو التي تحتوي على
                                    تهديدات، أو شديدة التعصب، أو التي تحض على
                                    الكراهية والعنف، أو المبتذلة، أو الفاحشة، أو
                                    الإباحية، أو المسيئة بأي شكل من الأشكال أو
                                    التي قد تتسبب في أضرار، أو التي يُتوقع بشكل
                                    منطقي أن تُلحق الضرر بأي شخص أو كيان، سواء
                                    كان ذلك المحتوى محميًا بموجب بالقانون أو لا
                                    في كل حالة من تلك الحالات، أو (2) المخالفة
                                    بأي شكل من الأشكال القوانين واللوائح المحلية
                                    والوطنية والدولية المعمول بها.
                                </p>
                                <p>
                                    لا يجوز لك إرسال مساهمات المستخدم التي تحتوي
                                    على مواد محمية بحقوق الطبع والنشر والتي تعود
                                    ملكيتها إلى طرف ثالث، أو مواد تخضع لحقوق
                                    ملكية خاصة بطرف ثالث آخر مالم تحصل على إذن
                                    وتصريح من المالك الشرعي للمادة أو أنك مصرح
                                    لك قانونيًا بشكل ما أو بآخر بإرسال هذا
                                    المحتوى ومنحنا التراخيص المذكورة بالأعلى.
                                    أنت تقر وتتعهد بأنك تمتلك أو لديك التراخيص
                                    والحقوق والموافقات والأذونات اللازمة لنشر
                                    مساهمات المستخدم وأن مساهمات المستخدم تلك
                                    تتوافق مع الشروط الموضحة في القسم 6.
                                </p>
                                <p>
                                    أنت توافق أيضًا على عدم فرض أي حقوق معنوية
                                    أو حقوق تبعية أو حقوق مشابهة في أو على
                                    مساهمات المستخدم ضدنا أو ضد الأطراف المرخص
                                    لهم من قِبلنا أو موزعينا أو وكلائنا أو
                                    ممثلينا أو غيرهم من المستخدمين الآخرين
                                    المرخص لهم، كما أنك توافق على قبول عدم قيام
                                    الآخرين بفرض هذه الحقوق التي قد تكون لديهم،
                                    أنت تُدرك وتوافق على أنك المسئول الوحيد عن
                                    مساهمات المستخدم الخاصة بك وما ينتج عن تقديم
                                    وإتاحة مساهمات المستخدم هذه على خدمة
                                    BookNook.
                                </p>
                                <p>
                                    لدينا الحق، ولكننا لسنا ملزمين، بمراقبة وفحص
                                    ونشر وإزالة وتخزين ومراجعة مساهمات المستخدم
                                    المُقدَّمة إلى خدمة BookNook في أي وقت من
                                    الأوقات ولأي سبب من الأسباب، بما في ذلك
                                    للتأكد من أن مساهمات المستخدم تتوافق مع هذه
                                    الشروط وبدون إخطار مسبق لك.
                                </p>
                                <p>
                                    نحن لا نؤيد أو نضمن صحة الآراء أو التوصيات
                                    أو النصائح الواردة في أي مساهمات المستخدم
                                    كما أننا نخلي مسئوليتنا صراحة عن أي وجميع
                                    المسئوليات المتعلقة بهذا المحتوى.
                                </p>
                                <p />
                                <h3>7. توفر المحتوى</h3>
                                <p>
                                    تُقدِّم خدمة BookNook لمستخدميها إمكانية
                                    الوصول للكتب وغيرها من المحتويات التي يتم
                                    بثها عبر الإنترنت على أجهزة معينة، قد تتغير
                                    إمكانية توفر هذه الكتب وغيرها من المحتويات
                                    من وقت لآخر، ومن منطقة جغرافية إلى أخرى،
                                    ولعدة أسباب (بما في ذلك تغير الاتفاقات مع
                                    دار النشر والنطاق الترددي المتاح للإنترنت)
                                    ونتيجة لذلك، فإننا لا نضمن أن أي محتوى سيكون
                                    متوفرًا أو سيظل كذلك على خدمة BookNook (بما
                                    في ذلك خلال أي فترة اشتراك لعضو الاشتراك).
                                </p>
                                <p>
                                    شبكات الهاتف المحمول. إذا قمت بالوصول إلى
                                    خدمة BookNook عبر أحد شبكات الهاتف المحمول،
                                    فسيتم تطبيق رسوم وأسعار خدمات الرسائل
                                    والبيانات المُقدِّمة من شبكتك أو مزود خدمة
                                    التجوال الخاص بك، قد يقوم مزود خدمات الشبكة
                                    الخاص بك بحظر أو تقييد عمليات التحميل أو
                                    التثبيت أو الاستخدام لبعض مميزات خدمة
                                    BookNook وقد لا تعمل جميع مميزات خدمة
                                    BookNook على جهازك أو عبر مزود خدمات شبكة
                                    الاتصال الخاصة بك.
                                </p>
                                <p />
                                <h3>8. الإنهاء</h3>
                                <p>
                                    سيتم إنهاء حقك في استخدام خدمة BookNook
                                    تلقائيًا إذا قمت بانتهاك هذه الشروط أو أي
                                    قواعد أو إرشادات منصوص عليها ومتعلقة بخدمة
                                    BookNook. نحن نملك الحق، وفقًا لتقديرنا
                                    الخاص، في إنهاء صلاحية وصولك إلى كافة خدمة
                                    BookNook أو أي جزء منها، لأي سبب من الأسباب،
                                    بإخطار أو دون إخطار.
                                </p>
                                <p />
                                <h3>
                                    9. روابط أو تطبيقات/أجهزة الأطراف الأجنبية
                                </h3>
                                <p>
                                    الروابط. للآباء والمُعلِّمين، قد تحتوي خدمة
                                    BookNook على روابط لمواقع أجنبية أو روابط
                                    تطبيقات أجنبية (بما في ذلك، على سبيل المثال
                                    لا الحصر: عناصر تحكم الواجهة الرسومية أو
                                    البرامج أو غيرها من أدوات البرامج المساعدة)
                                    والتي لا ترجع ملكيتها لخدمة BookNook أو
                                    تتحكم بها، نهدف من خلال إتاحتنا لرابط أي
                                    موقع أو تطبيق أجنبي آخر إلى إراحتك والتيسير
                                    عليك فحسب، ولا يعني ذلك موافقتنا على هذا
                                    الموقع أو المرجع أو محتوياته، لن نتحمل أي
                                    تبعية أو مسئولية عن أي محتوى أو معلومات أو
                                    برامج أو مواد أو ممارسات يتبعها أي موقع أو
                                    تطبيق أجنبي.
                                </p>
                                <p>
                                    الأجهزة. إذا قمت بالوصول إلى خدمة BookNook
                                    باستخدام جهاز يعمل بنظام (Apple iOS) أو
                                    أندرويد Android))، فستعتبر شركة Apple أو
                                    شركة Google أطرافًا أجنبية مستفيدة من هذه
                                    الاتفاقية، إلا أن هذه الأطراف الأجنبية
                                    المستفيدة ليسوا طرفًا في هذا العقد وليسوا
                                    مسئولين عن توفير أو دعم خدمة BookNook، أنت
                                    توافق بموجب ذلك على أن وصولك إلى خدمة
                                    BookNook باستخدام هذه الأجهزة سيخضع أيضًا
                                    لشروط الاستخدام المنصوص عليها في شروط الخدمة
                                    المعمول بها لدى الأطراف الأجنبية المستفيدة.
                                </p>
                                <p />
                                <h3>10. إخلاء المسئولية عن الضمانات</h3>
                                <p>
                                    أنت توافق صراحة على أن استخدامك لخدمة
                                    BOOKNOOK مسئوليتك أنت وحدك، يتم تقديم خدمة
                                    BOOKNOOK &#8220;كما هي&#8221; و&#8221;حسبما
                                    تكون متاحة&#8221;، تُخلي BOOKNOOK CREATIONS
                                    وشركاؤها مسئوليتها صراحة، إلى أقصى حد يسمح
                                    به القانون، عن أي ضمانات مهما كان نوعها،
                                    سواء كانت صريحة أو ضمنية، تتعلق بخدمة
                                    BOOKNOOK (بما في ذلك، على سبيل المثال لا
                                    الحصر: الضمانات الضمنية المتعلقة بالقابلية
                                    للتسويق، والصلاحية لغرض أو استخدام معين،
                                    وعدم التعدي على حقوق الآخرين)، نحن لا نُقدم
                                    أي ضمانات أو إقرارات حول دقة أو صحة المحتوى
                                    المتاح على أو عبر خدمة BookNook أو المحتوى
                                    الوارد في أي موقع إلكتروني متصل بخدمة
                                    BookNook ولا نتحمل أي تبعية أو مسئولية عن
                                    أي: (1) أخطاء، أو أغلاط، أو معلومات غير
                                    دقيقة يتضمنها المحتوى (2) الإصابات الشخصية
                                    أو أضرار الممتلكات، مهما كانت طبيعتها، التي
                                    تنتج عن استخدامك أو وصولك إلى خدمة BookNook
                                    أو أي محتوى متاح على خدمة BookNook (3) أي
                                    استخدام أو وصول غير مصرح به إلى خوادمنا
                                    الآمنة و/أو أي وجميع المعلومات الشخصية و/أو
                                    المعلومات المالية المُخزنة فيها (4) أي
                                    انقطاع أو توقف للإرسال إلى أو من خدمة
                                    BookNook (5) أخطاء برمجية أو فيروسات أو
                                    أحصنة طروادة أو ما شابه ذلك مما يُمكن إرساله
                                    إلى أو عبر خدمة BookNook بواسطة أي طرف
                                    أجنبي، و/أو (6) أي خسائر أو أضرار، مهما كانت
                                    طبيعتها، تحدث نتيجة استخدام أي محتوى منشور
                                    أو تم إرساله عبر البريد الإلكتروني أو إرساله
                                    أو تم إتاحته بطريقة ما أو أخرى على أو عبر
                                    خدمة BookNook.
                                </p>
                                <p />
                                <h3>11. حدود المسئولية القانونية</h3>
                                <p>
                                    أنت تُدرك أنه حسب القدر الذي يسمح به القانون
                                    المعمول به، لن تتحمل مؤسسة BOOKNOOK بأي حالٍ
                                    من الأحوال، أو القائمون عليها، أو موظفوها،
                                    أو مديروها، أو المساهمون بها، أو فروعها، أو
                                    شركاؤها، أو وكلاؤها، أو المرخصون التابعون
                                    لها أي مسئولية من أي جانب من جوانب المسئولية
                                    القانونية (سواء كانت بالتعاقد، أو بالإضرار
                                    بالأشخاص والممتلكات، أو قانونية أو غير ذلك)
                                    عن أي أضرار مباشرة، أو غير مباشرة، أو عرضية،
                                    أو من نوع خاص، أو تبعية، أو تأديبية، بما في
                                    ذلك، على سبيل المثال لا الحصر: الأضرار
                                    الناتجة عن فقدان الإيرادات أو الأرباح أو
                                    الشهرة التجارية أو الاستخدام أو البيانات أو
                                    أي خسائر أخرى غير ملموسة (حتى إذا تم إخطار
                                    هذه الأطراف أو كانت هذه الأطراف على علم أو
                                    كان من المفترض أن تكون على علم باحتمالية
                                    حدوث مثل هذه الأضرار) الناتجة عن استخدامك
                                    (أو أي شخص آخر يستخدم حسابك) لخدمة BOOKNOOK.
                                </p>
                                <p />
                                <h3>12. الاستثناءات</h3>
                                <p>
                                    لا تسمح بعض السلطات القضائية باستثناء بعض
                                    الضمانات أو تقييد أو الإعفاء من المسئولية عن
                                    الأضرار العرضية أو التبعية، لذلك فقد لا
                                    تنطبق عليك بعض القيود والإعفاءات من
                                    المسئولية المذكورة بالأعلى، لا يجوز لمؤسسة
                                    BookNook وفقًا للقدر المسموح به قانونًا
                                    إخلاء مسئوليتها عن أي ضمانات ضمنية أو الحد
                                    من التزاماتها، ويجب أن يكون نطاق ومدة هذا
                                    الضمان ومدى مسئوليتنا متفقًا مع الحد الأدنى
                                    الذي يسمح به هذا القانون المعمول به.
                                </p>
                                <p />
                                <h3>13. التعويض</h3>
                                <p>
                                    أنت توافق على تعويض مؤسسة BookNook والدفاع
                                    عنها وإعفاءها هي وفروعها، والقائمين عليها،
                                    ومديريها، وموظفيها، ومستشاريها، ووكلائها من
                                    وضد أي وكل مطالبات أو مسئوليات أو أضرار أو
                                    خسائر أو تكاليف أو نفقات أو رسوم (بما في ذلك
                                    أتعاب وتكاليف المحاماة المنطقية) التي قد
                                    يتحملها أحد هذه الأطراف والناتجة أو الناجمة
                                    عن (أ) أي معلومات (بما في ذلك، على سبيل
                                    المثال لا الحصر: مساهمات المستخدم الخاصة بك،
                                    أو أرائك وتعليقاتك أو أي محتوى آخر) تقوم أنت
                                    (أو أي شخص آخر يستخدم حسابك) بتقديمه أو نشره
                                    أو إرساله على أو عبر خدمة BookNook (ب)
                                    استخدامك (أو استخدام أحد الأشخاص الذين لديهم
                                    إمكانية الوصول إلى حسابك) لخدمة BookNook،
                                    (ج) انتهاكك (أو انتهاك أي شخص يستخدم حسابك)
                                    هذه الشروط أو (د) انتهاكك (أو انتهاك أي شخص
                                    يستخدم حسابك) أية حقوق تخص أي شخص أو كيان،
                                    بما في ذلك، على سبيل المثال لا الحصر: أي
                                    حقوق نشر أو طبع أو براءة اختراع أو علامة
                                    تجارية، أو أسرار تجارية، أو حقوق
                                    الدعاية/الخصوصية أو حقوق الملكية لأي شخص أو
                                    كيان، تحتفظ مؤسسة BookNook، على نفقتها
                                    الخاصة، بالحق في تولي الدفاع والإشراف الحصري
                                    على أي مسائل متعلقة بشكل ما أو بآخر بأي
                                    تعويضات مستحقة من جانبك، وسوف تكون مطالبا في
                                    جميع الأحوال بالتعاون مع مؤسسة BookNook
                                    للتصديق على أي دفاعات متاحة.
                                </p>
                                <p />
                                <h3>14. النزاعات</h3>
                                <p>
                                    يجب تفسير وتطبيق هذه الشروط وفقًا لقوانين
                                    ولاية كاليفورنيا بغض النظر عن أي اختيار
                                    لقانون أو تضارب في مبادئ وأسس القوانين وبغض
                                    النظر عن المكان الذي تسكن به، ستقوم بتسوية
                                    أي مطالب أو دعاوى أو نزاعات لديك ضدنا والتي
                                    قد تنشأ عن أو تتعلق بهذه الشروط أو بخدمة
                                    BookNook بشكل حصري في أحد محاكم الولاية أو
                                    المحاكم الفيدرالية الواقعة في مقاطعة
                                    ألاميدا، كاليفورنيا، كما أنك توافق على
                                    اللجوء إلى الاختصاص القضائي الشخصي للمحاكم
                                    الواقعة في مقاطعة ألاميدا، كاليفورنيا لأغراض
                                    التقاضي ورفع هذه المطالبات أو الدعاوى.
                                </p>
                                <p />
                                <h3>15. المراسلات</h3>
                                <p>
                                    باستخدامك لخدمة BookNook، فإنك توافق بموجب
                                    ذلك على أن أي إخطار أو اتفاقيات أو إفصاح عن
                                    معلومات أو أي شكل من أشكال المراسلات التي
                                    نرسلها إليك إلكترونيًا ستفي بأي متطلبات
                                    للمراسلات القانونية (بما في ذلك متطلب أن
                                    تكون مثل هذه المراسلات مكتوبة).
                                </p>
                                <p />
                                <h3>
                                    16. شروط إضافية لتطبيقات أبل (Apple iOS)
                                </h3>
                                <p>
                                    تنطبق الشروط والأحكام الإضافية التالية على
                                    تطبيقاتنا المصممة للاستخدام على أجهزة الهاتف
                                    المحمول التي تعمل بنظام أبل (Apple iOS)
                                    والتي تم تحميلها وتثبيتها من (متجر iTunes)
                                    (يُشار إليها فيما بعد بـ &#8220;تطبيق
                                    iOS&#8221;)، وأنت توافق على أن هذه الشروط
                                    تسري بينك وبيننا فقط، وليس شركة Apple، وأن
                                    شركة Apple لا تتحمل أي مسئولية عن تطبيق iOS
                                    الخاص بنا، أو خدمة BookNook، أو أي محتوى.
                                    أنت توافق على أن استخدامك لتطبيق iOS الخاص
                                    بنا يخضع لقواعد الاستخدام الواردة والمعمول
                                    بها حاليًا في شروط الخدمة لمتجر التطبيق لـ
                                    Apple، تتفق الأطراف على أن شركة Apple ليست
                                    ملزمة بتقديم خدمات الصيانة والدعم المتعلقة
                                    بتطبيق iOS الخاص بنا، وفي حال حدوث أي فشل في
                                    مطابقة تطبيق iOS الخاص بنا لأي ضمانات
                                    مستحقة، فيمكنك حينها إبلاغ شركة Apple وستقوم
                                    شركة Apple برد ثمن الشراء (إن وجد) لتطبيق
                                    iOS الخاص بنا إليك.
                                </p>
                                <p>
                                    إلى الحد الأقصى الذي يسمح به القانون، ليس
                                    لدى شركة Apple أي التزامات أخرى متعلقة
                                    بتطبيق iOS الخاص بنا، وأي مطالبات أو خسائر
                                    أو مسئوليات أو أضرار أو تكاليف أو مصاريف
                                    تنتج عن عدم مطابقة أي ضمان سيتم إخضاعها
                                    حصريًا إلى هذه الشروط وأي قانون يسري علينا
                                    بصفتنا مزود الخدمة. أنت توافق على أننا،
                                    وليست شركة Apple، المسئولون عن التصدي لأي
                                    مطالبات، من جانبك أو من جانب أي طرف ثالث،
                                    متعلقة بتطبيق iOS الخاص بنا أو بامتلاكك و/و
                                    استخدام لتطبيق iOS الخاص بنا، بما في ذلك،
                                    على سبيل المثال لا الحصر: (1) مطالبات
                                    المسئولية المتعلقة بالمنتج (2) أي مطالبات
                                    تفيد بأن تطبيق iOS الخاص بنا لا يتوافق مع أي
                                    متطلبات قانونية أو تنظيمية معمول بها (3)
                                    المطالبات المُقدِّمة بموجب حماية المستهلك أو
                                    التشريعات الأخرى المماثلة، وسيتم إخضاع جميع
                                    هذه المطالبات حصريًا إلى هذه الشروط أو أي
                                    قانون يسري علينا بصفتنا مزود الخدمة. أنت
                                    توافق على أنه في حالة قيام أي طرف ثالث
                                    بمطالبة تفيد بأن تطبيق iOS الخاص بنا أو أن
                                    امتلاكك واستخدامك لتطبيق iOS ينتهك حقوق
                                    الملكية الفكرية لهذا الطرف الثالث، فإننا،
                                    وليس شركة Apple، سنكون المسئولين عن التحقيق
                                    والدفاع والتسوية والتصدي لهذه المطالبة، تتفق
                                    الأطراف على أن شركة Apple والشركات التابعة
                                    لها هي أطراف ثالثة مستفيدة من الشروط التي
                                    تسري على استخدامك لتطبيق iOS الخاص بنا. عند
                                    موافقتك للشروط، فإن شركة Apple سيكون لديها
                                    الحق (وسيعتبر أنها قبلت الحق) في تطبيق
                                    الشروط السارية على استخدامك لتطبيق iOS الخاص
                                    بنا عليك بصفتها طرف ثالث مستفيد منها.
                                </p>
                                <p />
                                <h3>17. شروط أخرى</h3>
                                <p>
                                    تُعتبر هذه الشروط بجانب أي قواعد أو إرشادات
                                    أخرى منشورة ومتعلقة بخدمة BookNook بمثابة
                                    البيان النهائي والحصري للاتفاقية المنعقدة
                                    بينك وبيننا، ولا يعني فشلنا في ممارسة أو
                                    إنفاذ أي حق أو حكم من هذه الشروط تنازلًا عن
                                    هذا الحق أو الحكم، وإذا تبين لأي سبب من
                                    الأسباب أن بند من هذه الشروط غير قابل
                                    للإنفاذ، فسيتم تطبيق هذا البند إلى أقصي قدر
                                    مسموح من أجل تحقيق أغراض الأطراف كما هو
                                    مُبين في ذلك البند وسيتم استكمال سريان مفعول
                                    ونفاذ باقي الشروط والأحكام. عناوين الأقسام
                                    المذكورة في هذه الشروط لأغراض التيسير
                                    والتسهيل فحسب وليس لها أي أثر قانوني أو
                                    تعاقدي، وستظل شروط الأقسام
                                    2،5،6،7،8،9،10،11،12،13،14،15،16،17،18،19،20
                                    الواردة في هذه الشروط بالإضافة إلى أي قيود
                                    وحدود أخرى للمسئولية منصوص عليها صراحة هنا
                                    سارية المفعول والنفاذ بغض النظر عن أي إنهاء
                                    لاستخدامك لخدمة BookNook، وهذه الشروط شخصية
                                    ومخصصة لك فقط ولا يمكن التنازل عنها أو نقلها
                                    أو منح ترخيصها إلى أي شخص آخر بدون موافقة
                                    كتابية مسبقة من جانبنا بينما يحق لنا تفويض
                                    أو نقل أو التنازل عن حقوقنا والتزاماتنا
                                    بموجب هذه الشروط دون الحصول على موافقة منك،
                                    يتم ذكر عناوين الأجزاء الرئيسية والفرعية
                                    الموضحة في هذه الشروط لأغراض التسهيل فقط
                                    وليس من المفترض أن تؤثر على تفسير هذه الشروط
                                    ما لم يتم النص على خلاف ذلك في هذه الشروط،
                                    يجب أن تكون جميع الإخطارات مكتوبة ويجب
                                    التعامل معها كإيصالات تم تقديمها و التحقق
                                    منها من خلال الإيصالات الآلية المكتوبة أو
                                    السجلات الإلكترونية (عند الحاجة)، عنوان
                                    البريد الإلكتروني الخاص بالإخطارات التي يتم
                                    إرسالها إلى خدمة BookNook هو
                                    <span>info@booknooklearning</span>.com.
                                </p>
                                <p />
                                <h3>18. التعديلات</h3>
                                <p>
                                    يجوز لنا، وفقًا لتقديرنا الخاص والمطلق،
                                    التعديل على هذه الشروط من وقت إلى آخر وإذا
                                    قمنا بذلك، سنقوم بتحديث هذا المنشور وإرسال
                                    إخطار لك عبر خدمة BookNook أو إرسال بريد
                                    إلكتروني إليك، استمرار استخدامك لخدمة
                                    BookNook يُعتبر موافقة ضمنية على الالتزام
                                    بالتعديلات التي تم إدخالها على الشروط، إذا
                                    كان لديك أي اعتراض على هذه التعديلات، فخيارك
                                    الوحيد هو التوقف عن استخدام خدمة BookNook.
                                </p>
                                <p />
                                <h3>19. ملاحظات قانونية</h3>
                                <p>
                                    إن اسم شركة Apple وشعار Apple وiMac وMacBook
                                    Pro وiPad هي علامات تجارية لشركة Apple
                                    ومسجلة في الولايات المتحدة الأمريكية وغيرها
                                    من الدول، واسم App Store هو علامة الخدمة
                                    الخاصة بشركة Apple.
                                </p>
                                <p />
                                <p>____________</p>
                                <p />
                                <p>آخر تحديث: 22 يونيو، 2017</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{' '}
        </div>
    </div>
)
