import { AUTH_TUTOR, INITIALIZE } from 'actions'

/**
 * @type raceState = race[]
 */
const initialState = []

const raceReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_TUTOR:
        case INITIALIZE:
            return action.payload.races || []
        default:
            return state
    }
}

export default raceReducer
