import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { alertError } from 'actions/popup'
import { randomString } from 'util/random-string'

export function mapDispatch(actions = {}) {
    return dispatch => {
        actions = { ...actions, alertError }
        return bindActionCreators(actions, dispatch)
    }
}

export default class BaseComponent extends Component {
    constructor() {
        super()
        this.id = Math.random()
        this.requestState = {}
        this.isUnmounted = false
        this.state = { isRequestActive: false }
        this.onRequestStarted = this.onRequestStarted.bind(this)
        this.validateAction = this.validateAction.bind(this)
        this.onRequestCompleted = this.onRequestCompleted.bind(this)
    }

    componentWillUnmount() {
        this.isUnmounted = true
        this.setState({ isRequestActive: false })
    }

    startRequests(requests) {
        const requestId = randomString()
        this.onRequestStarted(requestId)
        const promises = requests.map(p => p.then(this.validateAction))
        return Promise.all(promises).finally(() =>
            this.onRequestCompleted(requestId)
        )
    }

    onRequestStarted(requestId) {
        this.requestState[requestId] = true
        this.setState({ isRequestActive: true })
    }

    onRequestCompleted(requestId) {
        delete this.requestState[requestId]

        if (!this.isUnmounted) {
            const activeRequests = Object.keys(this.requestState)
            if (activeRequests.length === 0) {
                this.setState({ isRequestActive: false })
            }
        }
    }

    validateAction(action) {
        return new Promise((res, rej) => {
            if (action.error) {
                this.props.alertError(action.payload, action.type)
                res(null)
            } else {
                res(action.payload)
            }
        })
    }
}
