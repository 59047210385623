import moment from 'moment'

export const daysFrom = from => to => {
    if (!from || !to) return NaN
    const fromDate = moment(new Date(from))
    const toDate = moment(new Date(to))
    const timeDifference = moment.duration(toDate.diff(fromDate))
    return Math.ceil(timeDifference.asDays())
}

/**
 *
 * @param {Date} datetime
 */
export const formatDatetime = datetime => {
    const offsetMs = datetime.getTimezoneOffset() * 60 * 1000
    const offset = datetime.getTimezoneOffset() / 60
    const formattedOffset =
        offset > 0 ? `UTC-${offset * 100}` : `UTC+${offset * 100}`
    const dateLocal = new Date(datetime.getTime() - offsetMs)
    const str =
        dateLocal
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, '/')
            .replace('T', ' ') + ` ${formattedOffset}`
    return str
}

export function formatDateString(dateString) {
    const date = new Date(dateString)
    return date.toLocaleString('en-us', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    })
}
