import React from 'react'
import Button from 'components/button'

const StudentLoginErrorNudge = ({ history, closePopup }) => {
    const handleClick = retry => event => {
        event.preventDefault()
        if (retry) {
            closePopup()
        } else {
            closePopup()
            history.push('/tutor/login')
        }
    }
    return (
        <div>
            <p>
                We don't have a student account with that username. It looks
                like you might be trying to log in as a guide or admin.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClick()}>Log in as guide</Button>
                &nbsp;&nbsp;
                <Button onClick={handleClick(true)} variantColor="secondary">
                    Retry student login
                </Button>
            </div>
        </div>
    )
}

export default StudentLoginErrorNudge
