import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { debounce } from 'lodash'
import cx from 'classnames'
import onClickOutside from 'react-onclickoutside'

import Forms from 'components/forms'
import { reset } from 'actions/manage-school'
import styles from './index.css'

const MIN_SCHOOLS_FOR_SEARCH_ENABLED = 5

class School extends Component {
    constructor(props) {
        super(props)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.onWindowClick = this.onWindowClick.bind(this)
        this.onMenuClick = this.onMenuClick.bind(this)
        this.onSearchChanged = this.onSearchChanged.bind(this)
        this.state = { open: false, filteredSchools: props.tutorSchools }
    }

    handleClickOutside() {
        this.onWindowClick()
    }

    isTutorLoaded(props) {
        return !!props.tutor && !!props.tutorSchools
    }

    onMenuClick(event) {
        event.stopPropagation()
        if (event.target.tagName !== 'INPUT') {
            this.toggleMenu()
        }
    }

    onWindowClick() {
        this.setState({ open: false })
    }

    onSearchChanged(searchText) {
        this.setState({
            filteredSchools: this.props.tutorSchools.filter(
                s => s.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            ),
        })
    }

    toggleMenu() {
        this.setState({ open: !this.state.open })
    }

    handleNav = id => () => {
        const url = `/schools/${id}`
        this.props.reset()
        this.props.history.push(url)
    }

    render() {
        if (
            !this.isTutorLoaded(this.props) ||
            this.props.tutorSchools.length < 2
        )
            return <div />

        let popup

        this.props.tutorSchools.sort((i, j) => (i.name > j.name ? 1 : -1))
        if (this.state.open) {
            popup = (
                <div className={styles.popup}>
                    {this.props.tutorSchools.length >=
                        MIN_SCHOOLS_FOR_SEARCH_ENABLED && (
                        <div className={styles.search}>
                            <Forms.InputFormField
                                onChange={debounce(this.onSearchChanged, 500)}
                                placeholder="Search"
                            />
                        </div>
                    )}
                    {this.state.filteredSchools.map(s => (
                        <div
                            key={s.id}
                            className={styles.menuItem}
                            onClick={this.handleNav(s.id)}
                        >
                            {s.name}
                        </div>
                    ))}
                </div>
            )
        }

        const schoolName = !!this.props.school ? this.props.school.name : ''

        return schoolName ? (
            <div
                className={styles.menu}
                onClick={this.onMenuClick}
                onBlur={this.onMenuClick}
            >
                <div
                    className={cx(styles.tab, {
                        [styles.tabActive]: this.state.open,
                    })}
                >
                    {schoolName}
                </div>
                {popup}
            </div>
        ) : (
            ''
        )
    }
}

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
        tutorSchools: state.tutorSchools,
        school: state.manageSchool.school,
    }
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ reset }, dispatch)
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(onClickOutside(School))
)
