import ReactGA from 'react-ga'
import { GOOGLE_ANALYTICS_KEY } from 'config'

const enabled = !!GOOGLE_ANALYTICS_KEY

export const GAEvent = {
    addedANewStudent: () => {
        ReactGA.event({
            category: 'Onboarding',
            action: 'Added A Student',
        })
    },
    changedPassword: () => {
        ReactGA.event({
            category: 'Onboarding',
            action: 'Changed Password',
        })
    },
    claimedTutorAccount: () => {
        ReactGA.event({
            category: 'Onboarding',
            action: 'Claimed Tutor Account',
        })
    },
    startedASession: () => {
        ReactGA.event({
            category: 'Onboarding',
            action: 'Started A Session',
        })
    },
}

export default {
    ...ReactGA,
    set: enabled ? ReactGA.set : () => {},
    initialize: enabled ? ReactGA.initialize : () => {},
    event: enabled ? ReactGA.event : () => {},
    pageview: enabled ? ReactGA.pageview : () => {},
    enabled,
}
