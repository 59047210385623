import React from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import BaseComponent, { mapDispatch } from 'components/base-component'
import { authenticateCleverOAuth } from 'actions/auth'
import FullScreen from 'components/session-common/full-screen'
import Menu from 'components/session-common/menu'

import errorMonsters from './images/login-oauth-error-monsters.png'
import errorOhNo from './images/login-oauth-error-oh-no.png'
import loadingMonsters from './images/login-oauth-loading-monsters.png'
import styles from './index.css'

class Login extends BaseComponent {
    constructor() {
        super()

        this.state = {
            error: false,
            noAccount: false,
        }
    }
    componentDidMount() {
        if (this.props.student) {
            this.props.history.push('/')
        }

        const query = parse(this.props.location.search)
        if (!!query.code) {
            this.props.authenticateCleverOAuth(query.code).then(ret => {
                if (ret.error) {
                    if (ret.payload.status === 400) {
                        this.setState({ error: true })
                    } else {
                        this.setState({ noAccount: true })
                    }
                }
            })
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (!!props.student) {
            this.props.history.push('/')
        }
    }

    render() {
        if (this.state.error || this.state.noAccount) {
            return (
                <div className={classNames(styles.root, styles.error)}>
                    <Menu />
                    <img className={styles.ohNo} src={errorOhNo} alt="Oh No!" />
                    {this.state.error && (
                        <div className={styles.subtitle}>
                            Something went wrong. Please close the browser
                            window and try again.
                        </div>
                    )}
                    {this.state.noAccount && (
                        <div className={styles.message}>
                            We don’t have a BookNook account for this Clever
                            user. Please contact the BookNook support team to
                            let us know.
                        </div>
                    )}
                    <img
                        className={styles.errorMonsters}
                        src={errorMonsters}
                        alt="monsters"
                    />

                    <FullScreen />
                </div>
            )
        }

        return (
            <div className={styles.root}>
                <Menu />
                <div className={styles.title}>Here comes BookNook…</div>
                <img
                    className={styles.loadingMonsters}
                    src={loadingMonsters}
                    alt="monsters"
                />

                <FullScreen />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        student: state.student,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch({ authenticateCleverOAuth })
    )(Login)
)
