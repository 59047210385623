import {
    APP_LAYOUT_CONFIGURED,
    APP_LAYOUT_RESET,
    APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED,
} from 'actions'

const defaultState = {
    backgroundColor: '#fff',
    hideMenu: false,
    portraitWarningsEnabled: false,
}

export default (state = defaultState, action) => {
    if (action.error) return state

    switch (action.type) {
        case APP_LAYOUT_CONFIGURED:
            return {
                ...state,
                ...action.payload,
            }
        case APP_LAYOUT_PORTRAIT_WARNINGS_ENABLED:
            return {
                ...state,
                portraitWarningsEnabled: action.payload,
            }
        case APP_LAYOUT_RESET:
            return defaultState
        default:
            return state
    }
}
