import React from 'react'
import BaseComponent, { mapDispatch } from 'components/base-component'
import { connect } from 'react-redux'
import { getBooks } from 'actions/network-test'
import Forms from 'components/forms'
import DownloadTest from './download-test'
import PusherTest from './pusher-test'
import FirebaseTest from './firebase-test'
import styles from './index.css'
import Messenger from 'components/messenger'

const PUSHER_TEST_MAX_SECONDS = 60
const PUSHER_TEST_MAX_MESSAGES = 50

const Welcome = props => {
    return (
        <div>
            <div className={styles.subheader}>
                Welcome to the BookNook Network Test. We're going to run a few
                diagnostics to try to catch some network issues that may
                interfere with BookNook. This may take a few minutes to
                complete.
            </div>
            <Forms.SubmitButton
                content="Start the test"
                onClick={props.onStartClicked}
            />
        </div>
    )
}

const DownloadTestBatch = props => {
    let status
    const { id, batch } = props

    if (batch.isStarted) {
        status = batch.isCompleted
            ? `Batch ${id} completed. ${batch.getSuccessPercentage()}% successful in ${batch.getTotalSeconds()}s. (Assets=${
                  batch.books.length
              } Size=${batch.size}bytes).`
            : `Batch ${id} is in progress...`
    } else {
        status = `Batch ${id} has not started.`
    }

    return <div className={styles.testItem}>{status}</div>
}

const PusherTestMessage = props => {
    let status
    const { id, message } = props

    if (message.isCompleted) {
        const seconds = message.getTotalSeconds()
        status = message.success
            ? `Message ${id} completed successfully in ${seconds}s using transport ${
                  message.transport
              }.`
            : `Message ${id} failed using transport ${message.transport}: ${
                  message.errorMessage
              }`
    } else {
        status = `Message ${id} is in progress...`
    }

    return <div className={styles.testItem}>{status}</div>
}

const FirebaseTestMessage = props => {
    let status
    const { id, message } = props

    if (message.isCompleted) {
        const seconds = message.getTotalSeconds()
        status = message.success
            ? `Firebase update ${id} completed successfully in ${seconds}s.`
            : `Firebase update ${id} failed: ${message.errorMessage}`
    } else {
        status = `Firebase ${id} is in progress...`
    }

    return <div className={styles.testItem}>{status}</div>
}

class NetworkTest extends BaseComponent {
    constructor() {
        super()
        this.onStartClicked = this.onStartClicked.bind(this)
        this.onTestProgress = this.onTestProgress.bind(this)
        this.onDownloadTestCompleted = this.onDownloadTestCompleted.bind(this)
        this.onPusherTestCompleted = this.onPusherTestCompleted.bind(this)
        this.onEmailResultsClicked = this.onEmailResultsClicked.bind(this)
        this.state = {
            loaded: false,
        }
    }

    componentDidMount() {
        this.startRequests([this.props.getBooks()]).then(() => {
            this.downloadTest = new DownloadTest(
                this.props.books,
                this.onTestProgress,
                this.onTestProgress,
                this.onDownloadTestCompleted
            )
            this.firebaseTest = new FirebaseTest(
                PUSHER_TEST_MAX_SECONDS,
                PUSHER_TEST_MAX_MESSAGES,
                this.onTestProgress,
                this.onTestProgress
            )
            this.setState({ loaded: true })
        })
    }

    onStartClicked() {
        this.downloadTest.start()
    }

    onTestProgress() {
        this.forceUpdate()
    }

    onDownloadTestCompleted() {
        if (this.props.pusher.client) {
            this.pusherTest = new PusherTest(
                this.props.pusher.client,
                PUSHER_TEST_MAX_SECONDS,
                PUSHER_TEST_MAX_MESSAGES,
                this.onTestProgress,
                this.onPusherTestCompleted
            )
            this.pusherTest.start()
        } else {
            throw Error(
                `Could not connect to pusher. Pusher connection state: ${
                    this.props.pusher.connectionState
                }.`
            )
        }
        this.forceUpdate()
    }

    onPusherTestCompleted() {
        this.firebaseTest.start()
        this.forceUpdate()
    }

    onEmailResultsClicked() {
        const destEmail = 'support@booknooklearning.com'
        const subject = encodeURIComponent('BookNook Network Test')

        const downloadReport = this.downloadTest.generateReport()
        const messagingReport = this.pusherTest.generateReport()
        const firebaseReport = this.firebaseTest.generateReport()
        const body = encodeURIComponent(
            `${downloadReport}\n\n\n${messagingReport}\n\n\n${firebaseReport}`
        )
        const url = `mailto:${destEmail}?subject${subject}&body=${body}`
        window.open(url)
    }

    render() {
        if (!this.state.loaded) return <div />

        let component
        if (
            !this.downloadTest.isStarted &&
            !(this.pusherTest && this.pusherTest.isStarted)
        ) {
            component = <Welcome onStartClicked={this.onStartClicked} />
        } else {
            let downloadTestComponent
            if (this.downloadTest.isStarted) {
                downloadTestComponent = (
                    <div className={styles.testSection}>
                        {this.downloadTest.batches.map((b, i) => (
                            <DownloadTestBatch key={i} id={i + 1} batch={b} />
                        ))}
                    </div>
                )
            }

            let pusherTestComponent
            if (this.pusherTest && this.pusherTest.isStarted) {
                pusherTestComponent = (
                    <div className={styles.testSection}>
                        {this.pusherTest.messages.map((m, i) => (
                            <PusherTestMessage key={i} id={i + 1} message={m} />
                        ))}
                    </div>
                )
            }

            let firebaseTestComponent
            if (this.firebaseTest.isStarted) {
                firebaseTestComponent = (
                    <div className={styles.testSection}>
                        {this.firebaseTest.messages.map((m, i) => (
                            <FirebaseTestMessage
                                key={i}
                                id={i + 1}
                                message={m}
                            />
                        ))}
                    </div>
                )
            }

            let emailResultsButton
            const isTestCompleted =
                this.downloadTest.isCompleted &&
                this.pusherTest.isCompleted &&
                this.firebaseTest.isCompleted
            if (isTestCompleted) {
                emailResultsButton = (
                    <Forms.SubmitButton
                        content="Email Test Results"
                        onClick={this.onEmailResultsClicked}
                    />
                )
            }

            const status = this.downloadTest.isActive()
                ? 'Running Download Test...'
                : this.pusherTest.isActive()
                ? 'Running Real Time Messaging Test...'
                : this.firebaseTest.isActive()
                ? 'Running Firebase Test...'
                : isTestCompleted
                ? 'Test Completed'
                : ''

            component = (
                <div>
                    <div className={styles.subheader}>{status}</div>
                    {emailResultsButton}
                    {downloadTestComponent}
                    {pusherTestComponent}
                    {firebaseTestComponent}
                </div>
            )
        }

        return (
            <div className={styles.root}>
                <Messenger />
                <div className={styles.header}>BookNook Network Test</div>
                {component}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        books: state.networkTest.books,
        pusher: state.pusher,
    }
}

export default connect(
    mapStateToProps,
    mapDispatch({ getBooks })
)(NetworkTest)
