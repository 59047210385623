import PropTypes from 'prop-types'
import InputFormField from '../input-form-field'

const propTypes = {
    initialValue: PropTypes.node,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onValidateFieldValue: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
}

const defaultProps = {
    initialValue: '',
    inputType: 'number',
    isRequired: true,
    label: '',
    minLength: 1,
    placeholder: '',
    primaryFocus: false,
}

class NumericFormField extends InputFormField {
    constructor() {
        super()
        this.onKeyPress = this.onKeyPress.bind(this)
    }

    onKeyPress(event) {
        if (event.charCode >= 48 && event.charCode <= 57) {
            if (this.props.onKeyPress) this.props.onKeyPress(event)
        } else {
            event.preventDefault()
            event.stopPropagation()
        }
    }
}

NumericFormField.propTypes = propTypes
NumericFormField.defaultProps = defaultProps

export default NumericFormField
