import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import Forms from 'components/forms'
import styles from './index.css'

class LoginForm extends Forms.Form {
    constructor() {
        super()
        this.onForgotPassword = this.onForgotPassword.bind(this)
        this.onEmailChanged = this.onEmailChanged.bind(this)
    }

    onForgotPassword() {
        const {
            fields: { email },
        } = this.props

        if (email.valid) {
            this.props.onForgotPassword(email.value)
        }
    }

    onEmailChanged(email, emailValid) {
        this.onFieldChange(this.props.fields.email)(email, emailValid)
        this.props.onEmailChanged(email, emailValid)
    }

    render() {
        const {
            fields: { password },
        } = this.props

        return (
            <form onSubmit={this.onSubmit}>
                <div className={styles.fieldWrapper}>
                    <Forms.EmailFormField
                        placeholder="Email"
                        onChange={this.onEmailChanged}
                        initialValue={this.props.initialEmailValue}
                    />
                    <Forms.PasswordFormField
                        placeholder="Password"
                        onChange={this.onFieldChange(password)}
                    />
                </div>
                <Forms.SubmitButton
                    content="Sign In"
                    allowSubmit={this.allowSubmit()}
                />
            </form>
        )
    }
}

LoginForm.propTypes = {
    onEmailChanged: PropTypes.func.isRequired,
    initialEmailValue: PropTypes.string,
}

export default reduxForm({
    form: 'TutorLoginForm',
    fields: ['email', 'password'],
})(LoginForm)
