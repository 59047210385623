import Batch from './batch'

const BATCH_SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
//const BATCH_SIZES = [1]

export default class DownloadTest {
    constructor(
        books,
        onDownloadCompletedCallback,
        onBatchCompletedCallback,
        onTestCompletedCallback
    ) {
        this.batches = []
        this.books = books
        this.onDownloadCompletedCallback = onDownloadCompletedCallback
        this.onBatchCompletedCallback = onBatchCompletedCallback
        this.onTestCompletedCallback = onTestCompletedCallback
        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null
        this.onBatchCompleted = this.onBatchCompleted.bind(this)
    }

    isActive() {
        return this.isStarted && !this.isCompleted
    }

    start() {
        this.batches = []
        let bookIndex = 0
        BATCH_SIZES.forEach(size => {
            const batchBooks = []
            for (let count = 0; count < size; count++, bookIndex++) {
                batchBooks.push(this.books[bookIndex])
            }
            this.batches.push(
                new Batch(
                    batchBooks,
                    this.onDownloadCompletedCallback,
                    this.onBatchCompleted
                )
            )
        })

        this.startedAt = new Date()
        this.isStarted = true
        this.batches[0].start()
    }

    onBatchCompleted(batch) {
        this.onBatchCompletedCallback(batch)
        const index = this.batches.indexOf(batch)
        if (index < this.batches.length - 1) {
            this.batches[index + 1].start()
        } else {
            this.completedAt = new Date()
            this.isCompleted = true
            this.onTestCompletedCallback(this)
        }
    }

    generateReport() {
        const batchReports = this.batches
            .map(b => b.generateReport())
            .join('\n\n')
        return `***Download Report***\n${batchReports}`
    }
}
