import Message from './message'
import FirebaseManager from 'util/firebase-manager'
import ApiClient from 'util/book-nook-api-client'

export default class FirebaseTest {
    constructor(
        maxSeconds,
        maxMessages,
        onMessageCompletedCallback,
        onTestCompletedCallback
    ) {
        this.messages = []
        this.maxSeconds = maxSeconds
        this.maxMessages = maxMessages
        this.onMessageCompletedCallback = onMessageCompletedCallback
        this.onTestCompletedCallback = onTestCompletedCallback
        this.messageCount = null
        this.isStarted = false
        this.isCompleted = false
        this.startedAt = null
        this.completedAt = null
        this.onMessageCompleted = this.onMessageCompleted.bind(this)
        this.firebaseId = null
        this.currentMessage = null
    }

    getTotalSeconds() {
        const end = !!this.completedAt ? this.completedAt : new Date()
        return (end - this.startedAt) / 1000
    }

    isActive() {
        return this.isStarted && !this.isCompleted
    }

    start() {
        this.startedAt = new Date()
        this.isStarted = true
        this.messages = []
        this.messageCount = 0

        ApiClient.startFirebaseNetworkTest()
            .then(({ id, token }) => {
                this.firebaseId = id
                return FirebaseManager.signIn(token)
            })
            .then(() => {
                this.unsubscribe = FirebaseManager.getNetworkTestDocument(
                    this.firebaseId
                ).onSnapshot(
                    doc =>
                        this.currentMessage &&
                        this.currentMessage.onMessageReceived(doc.data())
                )

                this.sendNextMessage()
            })
            .catch(err => {
                this.stop()
                console.error(err)
            })
    }

    stop() {
        this.completedAt = new Date()
        this.isCompleted = true

        this.currentMessage = null

        this.unsubscribe()

        ApiClient.cleanupFirebaseNetworkTest(this.firebaseId)
            .then(() => FirebaseManager.signOut())
            .catch(err => {
                if (!!this.onTestCompletedCallback) {
                    this.onTestCompletedCallback(this)
                }

                console.error(err)
            })
    }

    sendNextMessage() {
        this.messageCount++
        const message = new Message(this.firebaseId, this.onMessageCompleted)
        this.currentMessage = message
        this.messages.push(message)
        message.start()
    }

    onMessageCompleted(message) {
        if (
            this.maxSeconds > this.getTotalSeconds() &&
            this.messageCount < this.maxMessages &&
            !this.isCompleted
        ) {
            this.sendNextMessage()
            if (!!this.onMessageCompletedCallback) {
                this.onMessageCompletedCallback(message)
            }
        } else {
            this.stop()
            if (!!this.onMessageCompletedCallback) {
                this.onMessageCompletedCallback(message)
            }
            if (!!this.onTestCompletedCallback) {
                this.onTestCompletedCallback(this)
            }
        }
    }

    generateReport() {
        const messageReports = this.messages
            .map((m, i) => m.generateReport(i + 1))
            .join('\n')
        return `***Firebase Report***\n${messageReports}`
    }
}
