import _ from 'lodash'

import { updateDemoSessionState } from 'actions/session-demo'
import {
    GUIDED_INTRO,
    GUIDED_VOCABULARY_ASSESSMENT,
    GUIDED_COMPREHENSION_BOOK_COMPLETED,
    GUIDED_COMPREHENSION_ASSESSMENT,
} from 'session-checkpoints'

export const DEMO_SESSION_ID = -2

const STUDENT_RESPONSE_DELAY = 500
const STUDENT_RESPONSE_JITTER = 1000

// Look at how the session state changed, and check a set of triggers that can
// asynchronously update the demo session. Used to mimic student actions.
// E.g., when the intro screen is reached, have the students submit how they're
// feeling after a short delay.
export function checkUpdateTriggers(stateChanges, session) {
    // Comprehsion triggers
    if (stateChanges.checkpointId === GUIDED_INTRO) {
        studentsCompleteHowTheyreFeeling(session)
    }

    if (!!stateChanges.demonstrateStudentProgress) {
        studentsCompleteVocabularyActivities(session)
    }

    if (_.get(stateChanges, 'vocabulary.gameProgress.gameStarted') === true) {
        studentsCompleteVocabularyGame(session)
    }

    if (stateChanges.checkpointId === GUIDED_VOCABULARY_ASSESSMENT) {
        studentsCompleteVocabularyAssessment(session)
    }

    if (stateChanges.checkpointId === GUIDED_COMPREHENSION_BOOK_COMPLETED) {
        studentsCompleteBookRating(session)
    }

    if (
        _.get(stateChanges, 'comprehension.gameProgress.gameStarted') === true
    ) {
        studentsCompleteComprehensionGame(session)
    }

    if (stateChanges.checkpointId === GUIDED_COMPREHENSION_ASSESSMENT) {
        studentsCompleteComprehensionAssessment(session)
    }

    // Phonics triggers
    const activeActivityId = session.state.activeActivityId
    if (
        _.get(
            stateChanges,
            `activities.${activeActivityId}.waitingForInstructions`
        ) === false
    ) {
        studentsCompletePhonicsActivity(session, activeActivityId)
    }
}

function studentsCompleteHowTheyreFeeling(session) {
    session.students.forEach((s, i) => {
        let state = {}
        _.set(state, `feedback.${s.id}`, {
            startFeeling: 4 - i, // give a range of values
        })

        setTimeout(() => {
            updateDemoSessionState(state)
        }, STUDENT_RESPONSE_DELAY + 100 * i)
    })
}

function studentsCompleteVocabularyActivities(session) {
    session.students.forEach(s => {
        let state = {}
        _.set(state, `vocabulary.flashCardsProgress.${s.id}`, {
            successCount: session.lesson.vocabulary.length,
            completedVocabularyIds: session.lesson.vocabulary.map(v => v.id),
        })

        _.set(state, `vocabulary.matchingGameProgress.${s.id}`, {
            completed: true,
        })

        setTimeout(() => {
            updateDemoSessionState(state)
        }, STUDENT_RESPONSE_DELAY + STUDENT_RESPONSE_JITTER * Math.random())
    })
}

function studentsCompleteVocabularyGame(session) {
    let state = {}
    session.students.forEach(s => {
        _.forIn(
            session.state.vocabulary.gameProgress.rounds,
            (round, roundId) => {
                _.set(
                    state,
                    `vocabulary.gameProgress.rounds.${roundId}.students.${
                        s.id
                    }`,
                    {
                        completed: true,
                        score: Math.floor(Math.random() * 100),
                    }
                )
            }
        )
    })
    setTimeout(() => {
        updateDemoSessionState(state)
    }, 5000)
}

function studentsCompleteVocabularyAssessment(session) {
    session.students.forEach((s, i) => {
        let state = {}
        _.set(state, `vocabulary.assessmentProgress.${s.id}`, {
            completedQuestionIds: session.lesson.vocabulary_questions.map(
                q => q.id
            ),
            correctQuestionIds: session.lesson.vocabulary_questions.map(
                q => q.id
            ),
        })

        setTimeout(() => {
            updateDemoSessionState(state)
        }, STUDENT_RESPONSE_DELAY + STUDENT_RESPONSE_JITTER * Math.random())
    })
}

function studentsCompleteBookRating(session) {
    session.students.forEach((s, i) => {
        let state = {}

        _.set(state, `comprehension.bookProgress.${s.id}`, {
            hasRated: true,
        })

        setTimeout(() => {
            updateDemoSessionState(state)
        }, STUDENT_RESPONSE_DELAY + STUDENT_RESPONSE_JITTER * Math.random())
    })
}

function studentsCompleteComprehensionGame(session) {
    let state = {}
    session.students.forEach(s => {
        _.forIn(
            session.state.comprehension.gameProgress.rounds,
            (round, roundId) => {
                _.set(
                    state,
                    `comprehension.gameProgress.rounds.${roundId}.students.${
                        s.id
                    }`,
                    {
                        completed: true,
                        score: Math.floor(Math.random() * 100),
                    }
                )
            }
        )
    })
    setTimeout(() => {
        updateDemoSessionState(state)
    }, 5000)
}

function studentsCompleteComprehensionAssessment(session) {
    session.students.forEach((s, i) => {
        let state = {}
        _.set(state, `comprehension.assessmentProgress.${s.id}`, {
            completedQuestionIds: session.lesson.comprehension_questions.map(
                q => q.id
            ),
            correctQuestionIds: session.lesson.comprehension_questions.map(
                q => q.id
            ),
        })

        setTimeout(() => {
            updateDemoSessionState(state)
        }, STUDENT_RESPONSE_DELAY + STUDENT_RESPONSE_JITTER * Math.random())
    })
}

function studentsCompletePhonicsActivity(session, activityId) {
    const activity = session.lesson.activities.find(a => a.id === activityId)

    session.students.forEach((s, i) => {
        let state = { activities: {} }
        _.set(
            state,
            `activities.${activityId}.students.${s.id}.completedQuestionIds`,
            activity.questions.map(q => q.id)
        )
        _.set(
            state,
            `activities.${activityId}.students.${s.id}.correctQuestionIds`,
            activity.questions.map(q => q.id)
        )

        setTimeout(() => {
            updateDemoSessionState(state)
        }, STUDENT_RESPONSE_DELAY + STUDENT_RESPONSE_JITTER * Math.random())
    })
}
