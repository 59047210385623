export default Component => (stateProps, dispatchProps, ownProps) => {
    const globalOverrides =
        (ownProps.overrides && ownProps.overrides['global']) || {}
    const componentOverrides =
        (ownProps.overrides && ownProps.overrides[Component.displayName]) || {}

    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        ...globalOverrides,
        ...componentOverrides,
    }
}
