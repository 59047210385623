import React, { useEffect } from 'react'
import Pusher from 'pusher-js'
import PusherBatchAuthorizer from 'pusher-js-auth'
import Auth from 'util/auth'
import { useDispatch, useSelector } from 'react-redux'

import { PUSHER_APP_KEY, PUSHER_APP_CLUSTER, BASE_API_URL } from 'config'

import { pusherConnectionStateChange, setPusherClient } from 'actions/pusher'

const DEFAULT_AUTH_ENDPOINT = `${BASE_API_URL}/pusher/auth`

export const Messenger = () => {
    const authEndpoint = DEFAULT_AUTH_ENDPOINT
    const dispatch = useDispatch()
    const { client } = useSelector(state => state.pusher)

    useEffect(() => {
        const disconnect = () => {
            if (client) {
                client.disconnect()
                dispatch(setPusherClient(null))
            }
        }
        if (!client) {
            disconnect()
            const newClient = new Pusher(PUSHER_APP_KEY, {
                cluster: PUSHER_APP_CLUSTER,
                encrypted: true,
                disableStats: true,
                authEndpoint,
                authorizer: PusherBatchAuthorizer,
                authDelay: 200,
                auth: {
                    headers: { Authorization: Auth.getAuthToken() },
                },
            })
            newClient.connection.bind('state_change', function(states) {
                dispatch(pusherConnectionStateChange(states.current))
            })
            dispatch(setPusherClient(newClient))
        }
        return () => {
            disconnect()
        }
    }, [authEndpoint, client, dispatch])

    return <div />
}
