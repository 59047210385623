import PropTypes from 'prop-types'
import InputFormField from '../input-form-field'

const propTypes = {
    initialValue: PropTypes.string,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onValidChange: PropTypes.func,
    placeholder: PropTypes.string,
    primaryFocus: PropTypes.bool,
    validationError: PropTypes.string,
}

const defaultProps = {
    initialValue: '',
    inputType: 'text',
    isRequired: true,
    minLength: 1,
    primaryFocus: false,
    validationError: 'Please enter a valid email address.',
}

class EmailFormField extends InputFormField {
    constructor() {
        super()
        this.onKeyPress = this.onKeyPress.bind(this)
        this.validateFieldValue = this.validateFieldValue.bind(this)
    }

    onKeyPress(event) {
        if (event.charCode === 32) {
            event.preventDefault()
            event.stopPropagation()
        } else if (this.props.onKeyPress) {
            this.props.onKeyPress(event)
        }
    }

    validateFieldValue(email) {
        /*eslint-disable */
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        /*eslint-enable */
        return super.validateFieldValue(email) && re.test(email)
    }

    changeFieldValue(newFieldValue) {
        const trimmedFieldValue = newFieldValue.trim()
        this.refs.input.value = trimmedFieldValue
        super.changeFieldValue(trimmedFieldValue)
    }
}

EmailFormField.propTypes = propTypes
EmailFormField.defaultProps = defaultProps

export default EmailFormField
