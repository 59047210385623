import React from 'react'
import BusyIndicator from 'components/busy-indicator'
import PropTypes from 'prop-types'

import styles from './index.css'

const Loading = props => {
    return (
        <div className={styles.busyIndicator}>
            <BusyIndicator size={props.size} />
        </div>
    )
}

Loading.propTypes = {
    size: PropTypes.number,
}

Loading.defaultProps = {
    size: 36,
}

export default Loading
