import {
    IMPORT_GUIDES_COLUMN_MAPPINGS_SELECTED,
    IMPORT_GUIDES_DATA_SELECTED,
    IMPORT_GUIDES_GET_GUIDE_TYPES,
    IMPORT_GUIDES_RESET,
    IMPORT_GUIDES_SET_SUMMARY,
} from 'actions'

const defaultState = {
    columnMappings: null,
    guideData: null,
    guideTypes: null,
    summary: null,
    useDefaultReadingLevels: false,
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case IMPORT_GUIDES_DATA_SELECTED:
            return {
                ...defaultState,
                guideData: action.payload,
            }
        case IMPORT_GUIDES_COLUMN_MAPPINGS_SELECTED:
            return {
                ...state,
                columnMappings: action.payload,
            }
        case IMPORT_GUIDES_SET_SUMMARY:
            return {
                ...state,
                summary: action.payload,
            }
        case IMPORT_GUIDES_GET_GUIDE_TYPES:
            return {
                ...state,
                guideTypes: action.payload,
            }
        case IMPORT_GUIDES_RESET:
            return defaultState
        default:
            return state
    }
}
