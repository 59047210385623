import Auth from 'util/auth'
import { INITIALIZE, INITIALIZE_STUDENT } from 'actions'

export function initialize() {
    const payload = Auth.initTutor()

    return {
        type: INITIALIZE,
        payload: payload,
    }
}

export function initializeStudent() {
    const payload = Auth.initStudent()

    return {
        type: INITIALIZE_STUDENT,
        payload: payload,
    }
}
