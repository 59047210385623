import { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'

const ToastManager = ({ content, options }) => {
    const { addToast } = useToasts()
    useEffect(() => {
        if (content) {
            addToast(content, options)
        }
    }, [content, options, addToast])

    return null
}

export default ToastManager
