import rg4js from 'raygun4js'
import ReactGA from 'util/analytics'
import ApiClient from 'util/book-nook-api-client'

const TutorIdCookie = 'book-nook-tutor-id'
const TutorAuthTokenCookie = 'book-nook-tutor-auth-token'

const StudentIdCookie = 'book-nook-student-id'
const StudentAuthTokenCookie = 'book-nook-student-auth-token'

function setCookie(cookie, val) {
    if (val !== undefined) window.localStorage.setItem(cookie, val)
}

function getCookie(cookie) {
    return window.localStorage.getItem(cookie)
}

function removeCookie(cookie) {
    window.localStorage.setItem(cookie, '')
    window.localStorage.removeItem(cookie)
}

class Auth {
    static isTutorLoggedIn() {
        return !!getCookie(TutorIdCookie)
    }

    static getLoggedInTutorId() {
        return getCookie(TutorIdCookie)
    }

    static loginTutor(data) {
        setCookie(TutorIdCookie, data.id)
        setCookie(TutorAuthTokenCookie, data.token)

        rg4js('setUser', {
            identifier: data.id,
            isAnonymous: false,
            email: data.email,
            type: 'tutor',
            fullName: `${data.first_name} ${data.last_name}`,
            firstName: data.first_name,
            lastName: data.last_name,
        })

        ReactGA.set({
            userId: data.id,
            dimension2: 'TUTOR', //userType
            dimension3: data.id, //tutorId
        })
    }

    static isStudentLoggedIn() {
        return !!getCookie(StudentIdCookie)
    }

    static getLoggedInStudentId() {
        return getCookie(StudentIdCookie)
    }

    static loginStudent(data) {
        setCookie(StudentIdCookie, data.id)
        setCookie(StudentAuthTokenCookie, data.token)

        rg4js('setUser', {
            identifier: data.id,
            isAnonymous: false,
            type: 'student',
            username: data.username,
            fullName: `${data.first_name} ${data.last_name}`,
            firstName: data.first_name,
            lastName: data.last_name,
        })

        ReactGA.set({
            userId: data.id,
            dimension2: 'STUDENT', //userType
        })
    }

    static isLoggedIn() {
        return Auth.isStudentLoggedIn() || Auth.isTutorLoggedIn()
    }

    static getAuthToken() {
        const token = Auth.isTutorLoggedIn()
            ? getCookie(TutorAuthTokenCookie)
            : Auth.isStudentLoggedIn()
            ? getCookie(StudentAuthTokenCookie)
            : null

        if (!token) {
            Auth.logout()
            return null
        }

        return token
    }

    static logout() {
        removeCookie(TutorAuthTokenCookie)
        removeCookie(TutorIdCookie)

        removeCookie(StudentIdCookie)
        removeCookie(StudentAuthTokenCookie)
    }

    static initTutor() {
        return Promise.all([
            ApiClient.getAllReadingLevels(),
            ApiClient.getAllGradeLevels(),
            ApiClient.getGenderTypes(),
            ApiClient.getRaces(),
            ApiClient.getTutorProfile(),
            ApiClient.getTutorSchools({ tutorId: Auth.getLoggedInTutorId() }),
            ApiClient.getTutorPublisherAdmin(Auth.getLoggedInTutorId()),
            ApiClient.getTutorTypes(),
            ApiClient.getTutorMultisites({
                tutorId: Auth.getLoggedInTutorId(),
            }),
            ApiClient.hasStudentAccess({ tutorId: Auth.getLoggedInTutorId() }),
            ApiClient.getSessionTypes(),
        ]).then(
            ([
                readingLevels,
                gradeLevels,
                genders,
                races,
                tutorProfile,
                tutorSchools,
                tutorPublisherAdmin,
                tutorTypes,
                tutorMultisites,
                hasStudentAccess,
                sessionTypes,
            ]) => ({
                readingLevels,
                gradeLevels,
                genders,
                races,
                tutorProfile,
                tutorSchools,
                tutorPublisherAdmin,
                tutorTypes,
                tutorMultisites,
                hasStudentAccess,
                sessionTypes,
            })
        )
    }

    static initStudent() {
        return ApiClient.getStudent(Auth.getLoggedInStudentId())
    }

    static postTutorAuthentication(tutor) {
        Auth.logout()
        Auth.loginTutor(tutor)
        return Auth.initTutor()
    }
    static postStudentAuthentication(student) {
        Auth.logout()
        Auth.loginStudent(student)
        return Auth.initStudent()
    }
}

export default Auth
